.q-multimedia-scroller {
	.slick-arrow {
		opacity: 0;
	}

	@media #{$large-down} {
		.q-indicator-separator {
			display: block;
		}
	}

	@media #{$large-down} {
		.q-link-icon-inline {
			&:hover {
				.q-link-label {
					color: $grey-dark;
				}
			}
		}
	}

	@media #{$medium-only} {
		.q-content-title {
			font-size: $content-title-font-size-medium;
		}

		.q-headline {
			padding: .625rem;
		}
	}

	@media #{$small-only} {
		.q-content-title {
			font-size: $content-title-font-size-small;
			line-height: $content-title-line-height-small;
		}

		.q-headline {
			padding: .625rem;
		}
	}

	// Touch Styles
	.no-touch & {
		&:hover {
			/* Arrows */
			.slick-arrow {
				background-color: $black;
				opacity: $gl-mms-hover-slider-arrow-opacity;

				&:hover {
					opacity: $gl-slider-arrow-opacity;
				}
			}
		}
	}
}
