.q-vehicle-block {
	display: block;
	margin-bottom: $vehicle-block-margin-bottom;
	padding: 0;
	width: 100%;

	.q-vehicle-info-row {
		padding: 0;
	}
}

.q-vehicle-info-bar-buttons {
	padding: $vehicle-block-info-bar-button-padding;
}

.q-vehicle-info-bar {
	padding: $vehicle-block-info-bar-padding;
	position: $vehicle-block-info-bar-position;

	&:hover {
		cursor: pointer;

		.q-vehicle-block-text {
			.q-vehicle-block-title {
				.q-vehicle-block-title-content {
					color: $vehicle-block-title-hover-color;
				}
			}
		}

		&::after {
			border-color: $vehicle-block-hover-after-border-color;
			bottom: $vehicle-block-hover-after-bottom;
			left: $vehicle-block-hover-after-left;
			width: $vehicle-block-hover-after-width;
		}
	}

	&::after {
		border-bottom: $vehicle-block-after-border-bottom;
		bottom: 0;
		content: '';
		height: 0;
		left: $vehicle-block-after-left;
		position: absolute;
		right: 0;
		width: $vehicle-block-after-width;
	}
}

.q-vehicle-info-image-container {
	padding-bottom: 0;
	width: 100%;
}

.q-vehicle-block-text {
	display: $vehicle-block-text-display;
	padding: $vehicle-block-text-padding;
	width: 100%;

	.q-vehicle-block-title {
		display: map-get($vehicle-block-title-display-map, small);
		padding-bottom: map-get($vehicle-block-title-padding-bottom-map, small);
		text-align: left;
		vertical-align: bottom;
		width: map-get($vehicle-block-title-width-map, small);

		.q-vehicle-block-title-content {
			@extend %#{$vehicle-block-title-content-extend};
			color: $vehicle-block-title-content-color;
			margin-bottom: $vehicle-block-content-margin-bottom;
			text-transform: uppercase;
		}
	}

	.q-vehicle-info-text-price {
		display: map-get($vehicle-block-price-display-map, small);
		text-align: map-get($vehicle-block-price-text-align-map, small);
		vertical-align: $vehicle-block-price-vertical-align;
		width: map-get($vehicle-block-price-width-map, small);

		.q-from-label {
			@extend %#{$vehicle-block-price-label-extend};
			color: $vehicle-block-price-label-color;
			font-weight: $vehicle-block-price-label-font-weight;
			line-height: $vehicle-block-price-label-line-height;
			margin-bottom: $vehicle-block-price-label-margin-bottom;

			p {
				color: inherit;
				display: inline;
				font-size: inherit;
				font-weight: $vehicle-block-price-label-font-weight;
			}
		}

		.q-vehicle-info-as-shown-price {
			@extend %#{$vehicle-block-price-as-shown-extend};
			color: $vehicle-block-price-as-shown-color;
			font-weight: $vehicle-block-price-as-shown-font-weight;
			line-height: $vehicle-block-price-as-shown-line-height;
			margin-bottom: $vehicle-block-price-as-shown-margin-bottom;

			p {
				color: inherit;
				display: inline;
				font-size: inherit;
				font-weight: $vehicle-block-price-as-shown-font-weight;
			}
		}
	}
}

.q-vehicle-info-text-description {
	@extend %#{$vehicle-block-description-extend};
	padding: $vehicle-block-description-padding;
	padding-bottom: $vehicle-block-description-padding-bottom;
}

.q-vehicle-info {
	.separator-light {
		border: 0;
		border-top: $vehicle-block-seperator-border-top;
		display: block;
		height: 0;
		margin: 0;
		padding: 0;
	}
}

@media #{$xlarge-up} {
	.q-vehicle-block-text {
		.q-vehicle-block-title {
			display: map-get($vehicle-block-title-display-map, large);
			padding-bottom: map-get($vehicle-block-title-padding-bottom-map, large);
			width: map-get($vehicle-block-title-width-map, large);
		}

		.q-vehicle-info-text-price {
			display: map-get($vehicle-block-price-display-map, large);
			text-align: map-get($vehicle-block-price-text-align-map, large);
			width: map-get($vehicle-block-price-width-map, large);
		}
	}
}
