
.q-vehicle-attribute {

	border-bottom: $vehicle-attribute-bottom-border;
	padding: map-get($vehicle-attribute-padding-map, small);

	:last-of-type {
		border-bottom: 0;
	}

	.q-attribute-full-container {

		display: flex;
		flex-direction: map-get($vehicle-attribute-full-container-map, small);
		justify-content: space-between;
		text-align: $vehicle-attribute-text-align;

		.q-attribute-details {
			order: $vehicle-attribute-details-order;

			.q-attribute-details-label {
				@extend %#{$vehicle-attribute-details-text-font};
				display: inline-block;
				font-weight: $vehicle-attribute-details-font-weight;
				margin: 0;
				white-space: nowrap;
			}
		}

		.q-attribute-label-container {
			line-height: $vehicle-attribute-label-line-height;
			order: $vehicle-attribute-label-order;

			.q-attribute-label-text {
				@extend %#{$vehicle-attribute-label-text-font};
				display: inline-block;
				margin: 0;
				white-space: nowrap;
			}
		}
	}

	@media #{$medium-up} {
		border-bottom: 0;
		padding: map-get($vehicle-attribute-padding-map, medium);

		.q-attribute-full-container {
			flex-direction: map-get($vehicle-attribute-full-container-map, medium);
		}
	}
}
