.q-tab-nav {
	.q-floating-tab {
		&.q-swap-tabs {
			border: $tab-nav-border;

			.q-tabs {
				.q-tab-title {
					border: 0;

					.q-tab-button {
						background: $tab-nav-title-background-color;
					}
				}
			}
		}

		.q-tab-title {
			.q-tab-button {
				&.q-icon-stacked {
					padding: .3125rem 1.25rem 0;

					.q-button {
						.q-move-text {
							margin-top: .625rem;
						}
					}
				}
			}
		}
	}
}
