// iframe Component
//
// The iframe component can be used for adding external content. It can have scroll bars
// enabled or disabled and autosize to content height/width.
//
// markup:
// <div class="q-iframe" data-autosize="true/false" data-scrolling="yes/no">
// 		<iframe src="section/14.1" scrolling="yes/no" height="300" width="800"></iframe>
// </div>
//
// [Styleguide 14.1]

.q-iframe {
	display: block;
	margin: 0;
	overflow: auto;
	padding: 0;
	transform: translateZ(0);

	iframe {
		border: 0;
		display: block;
		transform: translateZ(0);

		&.q-responsive-iframe {
			width: 100%;
		}

		&.q-scroll-enabled {
			overflow: auto;
		}

		&.q-scroll-disabled {
			overflow: hidden;
		}
	}

	img {
		max-width: 100%;
	}

	&.scrolling-disabled {
		iframe {
			overflow: hidden;
		}
	}

	> div {
		display: block;
		float: left;
		width: 100%;
	}
}
