// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// ResponsiveImage
//
// This is the documentation for ResponsiveImage. Edit responsiveImage.scss to update this documentation.
//
// markup:
// <p>Sample markup for ResponsiveImage. Replace me with the real deal once you are done editing <strong>responsiveImage.html</strong> and <em>optionally</em> the responsiveImage.js file.</p>
//
// Styleguide 99.99

.q-responsive-image {
	position: relative;
	width: 100%;

	.q-responsive-image-container {
		overflow: hidden;
	}

	.q-round-image-responsive {
		@extend %crop-circle;
	}

	.q-rectangle-image-responsive {
		height: auto;
		margin: 0;
		padding: 0;
		position: relative;
		width: 100%;

		&.q-full-height {
			height: 100%;
			max-width: none;
			width: auto;
		}
	}

	.q-fadeout {
		background-color: $black;
		opacity: .4;
	}

	.q-space {
		flex-shrink: 0;
	}

	.q-index {
		z-index: -1;
	}

	.q-responsive-image-disclosure {
		position: absolute;
		width: 100%;
		z-index: 1;

		.q-responsive-image-disclosure-text {
			@extend %#{$responsive-image-disclosure-text};

			bottom: 2rem;
			position: absolute;
			right: 1rem;
		}
	}
}
