// Nav Tertiary
//
// This is the documentation for Nav Tertiary. Edit nav_tertiary.scss to update this documentation.
//
// markup:
// <p>Sample markup for Nav Tertiary. Replace me with the real deal once you are done editing <strong>nav_tertiary.html</strong> and <em>optionally</em> the nav_tertiary.js file.</p>
//
// Styleguide 99.99
%list-item-tertiary {
	background-color: $tertiary-mobile-background;
	border: 0;
	border-left: $tertiary-list-item-border-left;
	border-top: $tertiary-list-item-border-top;
	color: $tertiary-list-item-color;
	width: 100%;

	&:hover {
		border-left-color: $tertiary-list-item-hover-border-left-color;
	}

	&.active {
		border-left-color: $tertiary-list-item-hover-border-left-color;
		color: $tertiary-nav-list-item-active-color;
	}

	span {
		color: $tertiary-nav-list-item-color;
	}
}

.q-nav-tertiary {
	background-color: $tertiary-nav-background-color;
	position: relative;

	ul {
		margin-bottom: 0;

		li:last-child {
			.q-list-item {
				border-bottom: $tertiary-list-layout-border-bottom;
			}
		}
	}

	.q-list-layout {
		border-bottom: $tertiary-list-layout-border-bottom;
		border-top: $tertiary-list-layout-border-top;
		display: $tertiary-nav-flex-display;
		justify-content: $tertiary-nav-justify-content;
		margin: 0 auto;
		max-width: $gridwidth-max;


		li {
			padding: $tertiary-nav-list-padding;
			position: relative;

			&:hover {
				background-color: $tertiary-nav-hover-background;
			}

			&.active {
				a {
					border-bottom: $tertiary-nav-active-link-border;
					color: $tertiary-nav-link-active-color;
				}
			}

			a {
				border-bottom: 3px solid transparent;
				color: $tertiary-nav-link-text-color;
				font-family: $tertiary-nav-font-family;
				font-size: $tertiary-nav-link-font-size;
				font-weight: $tertiary-nav-font-weight;
				letter-spacing: $tertiary-nav-link-text-letter-spacing;
				line-height: $tertiary-nav-link-text-line-height;
				padding: $tertiary-nav-link-bottom;
				position: $tertiary-nav-link-position;
				text-align: center;
				transition: $tertiary-nav-link-transition;

				&::after {
					background-color: $tertiary-nav-hover-border-color;
					border-bottom: $tertiary-nav-hover-border-bottom;
					bottom: $tertiary-nav-hover-bottom;
					content: $tertiary-nav-hover-content;
					height: $tertiary-nav-hover-height;
					left: $tertiary-nav-hover-left;
					opacity: $tertiary-nav-hover-opacity;
					position: $tertiary-nav-hover-position;
					right: $tertiary-nav-hover-right;
					transition: $tertiary-nav-hover-transition;
					width: $tertiary-nav-hover-width;

				}

				&:hover {
					color: $tertiary-nav-hover-color;

					&::after {
						bottom: $tertiary-nav-hover-after-bottom;
						left: 0;
						opacity: $tertiary-nav-hover-after-opacity;
						transition: $tertiary-nav-hover-after-transition;
						width: 100%;
					}
				}
			}
		}
	}
}

