.q-dealer-locator {
	.filter-tags {
		.inline-list {
			margin-bottom: 0;

			> li {
				margin-bottom: .75rem;
				margin-left: 0;
				margin-right: .75rem;

				.button {
					background-color: $dl-filter-tag-button-background-color;
					border: $dl-filter-tag-button-border;
					color: $dl-filter-tag-color;
					display: flex;
					font-size: $dl-filter-button-font-size;
					margin-bottom: 0;
					margin-top: 0;
					overflow: hidden;
					padding: 0;
					text-align: left;
				}

				.q-label {
					font-family: $dl-filter-label-font-family;
					font-size: $dl-filter-label-font-size;
					font-weight: $dl-filter-label-font-weight;
					margin: auto;
					padding: .5rem;
				}

				.icon-close {
					border-left: .0625rem solid $white;
					color: $dl-filter-tag-color;
					font-size: $dl-filter-icon-font-size;
					font-weight: $dl-filter-icon-font-weight;
					margin: auto;
					padding: .5rem;
				}

				&:hover .button .icon-close {
					color: $black;
				}
			}
		}
	}

	.dealer-dropdown {
		margin-bottom: .75rem;
		position: relative;

		button {
			padding: .5rem 1rem;
		}

		.f-dropdown {
			border-color: $dl-filter-dropdown-border-color;
			border-width: $dl-filter-dropdown-border-width;
			display: block;
			left: inherit;
			margin-top: $dl-filter-dropdown-margin-top;
			max-width: none;
			min-width: 18.13rem;
			width: 100%;

			&::after,
			&::before {
				border: $dl-filter-dropdown-carrot-border;
			}

			li {
				position: relative;

				.q-label {
					color: $dl-filter-dropdown-label-color;
					font-size: $dl-filter-dropdown-label-font-size;
					font-weight: $dl-filter-label-font-weight;
					margin-left: 1rem;
				}

				label {
					display: flex;
					font-weight: $font-weight-xbold;
					margin: 0;

					.no-flex {
						flex: none;
					}

					span {
						margin: auto 0;
					}
				}
			}
		}

		.q-dropdown {
			background: transparent none;
			border: $dl-filter-dropdown-border-width solid $dl-filter-button-color;
			border-right-color: $dl-filter-button-color;
			border-right-width: $dl-filter-button-width;
			color: inherit;
			font-size: 1.125rem;
			margin-bottom: 0;
			text-align: left;
			width: 100%;

			&:hover {
				background: none;
				border-right-color: $grey-dark;
			}

			&::after {
				right: -2.375rem;
			}
		}
	}

	.slick-arrow {
		background-color: transparent;
		opacity: .25;

		&:hover {
			background-color: transparent;
			opacity: 1;
		}

		&::before {
			background-image: get-element(slick-arrow-grey);
			background-position: center;
			height: 1.938rem;
		}
	}
}
