.q-destination-freight-information {
	border-bottom: 1px solid $freight-info-border-color;
	font-family: $font-family-secondary;
	margin: 0;
	padding: 1.25rem;
	position: relative;
	width: 100%;

	.q-freight-description,
	.q-freight-price {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	@media #{$xlarge-up} {
		.q-details-container {
			@include vertical-align(absolute);
		}

		.q-freight-description,
		.q-freight-price {
			text-align: right;
		}
	}
}
