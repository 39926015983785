// This file should only i//nclude documentation and SCSS for this specific component
// Any global patterns should be created in /source/patterns/ and used here

// Nav Segment
//
// This is the documentation for Nav Segment. Edit nav_segment.scss to update this documentation.
//
// markup:
// <div class="q-nav-segment">
//  <ul class="inline-list q-content-container">
//      <li class="q-content-item"><a class="q-no-link q-selected" href="#"><span>ALL</span></a></li>
//      <li class="q-content-item"><a class="q-no-link" href="#"><span>SMALL CARS</span></a></li>
//      <li class="q-content-item"><a class="q-no-link" href="#"><span>FAMILY CARS</span></a></li>
//      <li class="q-content-item"><a class="q-no-link" href="#"><span>STATION WAGONS</span></a></li>
//      <li class="q-content-item"><a class="q-no-link" href="#"><span>SUV</span></a></li>
//      <li class="q-content-item"><a class="q-no-link" href="#"><span>ELECTRICAL CARS</span></a></li>
//      <li class="q-content-item"><a class="q-no-link" href="#"><span>SPORTS CARS</span></a></li>
//  </ul>
// </div>
//
// Styleguide 8.10

// This is namespaced to prevent collision - Mobile Styles go Here

.q-nav-segment {
	width: 100%;

	.q-content-container {
		background-color: $nav-segment-content-container-background-color;
		border-bottom: map-get($nav-segment-content-container-border-map, small);
		display: flex;
		flex-flow: row wrap;
		margin: 0;
		padding: map-get($nav-segment-content-container-padding-map, small);
	}

	.q-content-item {
		background-color: $nav-segment-content-item-background-color;
		border-bottom: map-get($nav-segment-content-item-border-map, small);
		flex: map-get($nav-segment-content-item-flex-map, small);
		font: $nav-segment-content-item-font;
		letter-spacing: $nav-segment-content-item-letter-spacing;
		margin: 0;
		padding: 0;
		text-align: map-get($nav-segment-content-item-text-align-map, small);

		a {
			color: map-get($nav-segment-content-item-color-map, small);
			display: block;
			padding: map-get($nav-segment-content-item-padding-map, small);
			position: relative;

			&:hover {
				background-color: $nav-segment-content-item-hover-background-color;
			}
		}
	}

	@media #{$large-up} {
		.q-content-item {
			a {
				padding: map-get($nav-segment-content-item-padding-map, large);
			}
		}
	}

	@media #{$xlarge-up} {
		.q-content-container {
			border-bottom: map-get($nav-segment-content-container-border-map, xlarge);
			padding: map-get($nav-segment-content-container-padding-map, xlarge);

			.q-content-item {
				border-bottom: map-get($nav-segment-content-item-border-map, xlarge);
				flex: map-get($nav-segment-content-item-flex-map, xlarge);
				margin: 0;
				padding: 0;
				text-align: map-get($nav-segment-content-item-text-align-map, xlarge);

				a {
					color: map-get($nav-segment-content-item-color-map, xlarge);
					height: 100%;
					padding: map-get($nav-segment-content-item-padding-map, xlarge);

					span {
						display: inline-block;
						padding: $nav-segment-content-item-label-padding;
						position: relative;
					}
				}
			}
		}
	}
}
