$element-map: (
	arrow-small-down-white: url('../../img/assets/ui-icons/ui_arrow-small-down-white.svg'),
	blank-car: url('../../img/blank_car.png'),
	carat-down: url('../../img/ui_arrow-small-down.svg'),
	carat-down-white: url('../../img/ui_arrow-small-down-white.svg'),
	carat-up: url('../../img/ui_arrow-small-up.svg'),
	check: url('../../img/assets/ui-icons/ui_check-icon-hmc.svg'),
	circle-arrow: url('../../img/circleArrow.svg'),
	close: url('../../img/close.svg'),
	close-circle-dark: url('../../img/assets/close-circle-dark.svg'),
	close-circle-white: url('../../img/assets/close-circle-white.svg'),
	close-grey-dark: url('../../img/ui_close-icon_v01.svg'),
	dealer-pin-map: url('../../img/assets/dealer/nav_dealer-pin-map.svg'),
	dealer-pin: url('../../img/assets/dealer/nav_dealer-pin.svg'),
	dealer-hours: url('../../img/assets/dealer/nav_dealer_hours-icon.svg'),
	dealer-phone: url('../../img/assets/dealer/nav_dealer_phone-icon.svg'),
	dealer-website: url('../../img/assets/dealer/nav_dealer_website-icon.svg'),
	geolocation: url('../../img/assets/navigation-icons/nav_target_white.svg'),
	exit-icon: url('../../img/exit.svg'),
	exit-dark-icon: url('../../img/exit-dark-gray.svg'),
	gallery-box-icon: url('../../img/quantum-Icon-gallery.svg'),
	minus: url('../../img/minus.svg'),
	minus-dark: url('../../img/minus-dark.svg'),
	modal-close: url('../../img/ui_close-icon-v02.svg'),
	more: url('../../img/more.svg'),
	plus: url('../../img/plus.svg'),
	plus-dark: url('../../img/plus-dark.svg'),
	plus-gold: url('../../img/plus_gold.svg'),
	plus-hover: url('../../img/plusHover.svg'),
	search-field-icon: url('../../img/assets/navigation-icons/nav_search-icon.svg'),
	search-icon-white: url('../../img/assets/navigation-icons/nav_search-icon-white.svg'),
	search-icon: url('../../img/assets/navigation-icons/nav_search-icon.svg'),
	search-icon-selected: url('../../img/assets/navigation-icons/nav_search-icon-selected.svg'),
	slick-arrow: url('../../img/slick-arrow.png'),
	slick-arrow-grey: url('../../img/slick-arrow-grey.png'),
	social-media-email: url('../../img/assets/social-icons/social-email-color.svg'),
	social-media-facebook: url('../../img/assets/social-icons/social-facebook-color.svg'),
	social-media-google-plus: url('../../img/assets/social-icons/social-google-plus-color.svg'),
	social-media-pinterest: url('../../img/assets/social-icons/social-pinterest-color.svg'),
	social-media-twitter: url('../../img/assets/social-icons/social-twitter-color.svg'),
	target-location: url('../../img/assets/navigation-icons/nav_target_white.svg'),
	vehicle-trim-selector-arrow: url('../../img/slick-arrow.png'),
	video-play-button: url('../../img/video_play_btn.svg'),
	zip-code-x: url('../../img/zip-code-x.svg')
);
