.q-current-offers {
	select {
		&:focus {
			font-size: .875rem;
		}
	}

	// Postalcode and Filters
	.q-co-results-top {
		margin-bottom: 2.5rem;
		padding: 0 5%;

		.q-postal-code-label,
		.filterby-select-label {
			@extend %q-descriptive2;
			display: inline-block;
			font-size: .75rem;
			line-height: 1.25rem;
			text-align: left;
		}

		.gmwc-stateful-dropdown-filterby {
			padding: .5625rem 4.375rem .5625rem 1.25rem;
		}

		.q-postal-code {
			margin-bottom: 1.25rem;
		}
	}

	.q-postal-code-input {
		position: relative;

		.q-pe-clear-zip {
			display: none;
		}

		.q-co-clear-zip {
			background-color: transparent;
			background-image: get-element('plus-dark');
			background-position: center center;
			background-repeat: no-repeat;
			border-color: transparent;
			font-size: 0;
			height: 1.25rem;
			opacity: .7;
			position: absolute;
			right: 5rem;
			top: 1.4rem;
			transform: rotate(45deg);
			transition: none;
			width: 1.25rem;
			z-index: 1;

			&:hover {
				cursor: pointer;
				opacity: 1;
			}
		}
	}

	.gmst-co-offer-item-flex-filter-container {
		.gmst-co-offer-item-table {
			.gmst-co-offer-item-bar-primary {
				h3 {
					@extend %q-descriptive1;
					font-size: .9375rem;
					line-height: 1.25rem;
					text-transform: uppercase;
				}
			}
		}

		.q-car-type-label {
			@extend %q-descriptive2;
		}

		.gmst-co-offer-item-flex-filter-row {
			.gmwc-multi-select-bodystylefilter {
				background-color: $grey-light;
				height: 1.25rem;
				margin: 0 .5rem 1rem 0;
				padding: 0;
				width: 1.25rem;

				&:checked {
					@include icon(checkmark);
					color: $grey;
					font-size: 2.5rem;
					z-index: 2;

					&::before {
						left: -.4rem;
						top: -.4rem;
					}
				}

				& + label {
					color: $grey;
					font-size: .75rem;
				}
			}
		}
	}

	.gmst-co-result-group {
		.current_offers_vehicle { // sass-lint:disable-line class-name-format
			&:not(:last-of-type) {
				border-bottom: 1px solid $grey-mid;
			}
		}

		.co-segment-headline {
			@extend %q-descriptive2;
			background: $grey-dark;
			color: $white;
			margin-bottom: 0;
			padding: .625rem;
			text-transform: uppercase;
		}

		.gmst-co-offer-result {
			padding: 1rem 2.5rem 1rem .833rem;

			.gmst-co-offer-result-cell {
				h2 {
					@extend %q-headline1;
					font-size: 1.375rem;
					line-height: 1.875rem;
					margin-bottom: 0;
					padding-left: 2.5rem;
				}

				.cof-count-display {
					@extend %q-descriptive1;
					font-size: .875rem;
					line-height: 1.5rem;
					padding-left: 2.5rem;
					text-transform: uppercase;
				}

				slot {
					&[name='nooffers'] {
						span {
							&[slot='nooffers'] {
								@extend %q-body2;
								color: $grey-dark;
								display: inline-block;
								font-size: 1rem;
								line-height: 1.3125rem;
								padding-left: 2.5rem;
							}

							a {
								@include hard-easeout(width, $anim-fast);
								font-size: inherit;
								font-weight: $font-weight-bold;
								line-height: inherit;
								text-decoration: underline;
								transition: color;

								&:hover {
									color: $brand-link-color-hover;
								}
							}
						}
					}

					&[name='loading'] {
						span {
							&[slot='loading'] {
								@extend %q-body2;
								color: $grey-dark;
								display: inline-block;
								font-size: 1rem;
								line-height: 1.3125rem;
								padding-left: 2.5rem;
							}
						}
					}
				}
			}
		}

		.gmst-co-offer-item-table {
			display: flex;
			flex-direction: column;

			.gmst-co-offer-item-bar-secondary {
				background: $grey-dark;
				display: inline-block;
				padding: .625rem;

				h3 {
					@extend %q-descriptive2;
					color: $white;
					display: inline-block;
					float: left;
					margin: 0;
					text-transform: uppercase;
				}

				.gmst-co-offer-print {
					@extend %q-body2;
					background: transparent;
					border: 0;
					color: $brand-primary-color;
					display: inline-block;
					float: right;
					font-size: .875rem;
					line-height: 1.25rem;
					margin: 0;
					padding: 0;
					text-decoration: underline;
				}
			}

			&.last {
				.gmst-co-offer-item-bar-primary {
					.q-featured-offer-label {
						text-transform: uppercase;
					}
				}
			}
		}

		.gmst-co-offer-collapsed-result {
			padding-left: 5%;
			padding-right: 5%;

			> .offer-detail-container {
				&::after {
					background-color: $white;
					border-radius: 8rem;
					color: $black;
					content: 'or';
					display: block;
					flex: 0 0 auto;
					font-size: $base-font-size-m;
					font-weight: $font-weight-normal;
					height: 2.5rem;
					line-height: 2.5rem;
					margin: auto;
					text-align: center;
					text-transform: uppercase;
					width: 2.5rem;
				}

				&:last-child {
					margin-left: 0;

					&::after {
						display: none;
					}
				}

				.gmst-co-offer-item-tile {
					.gmst-co-offer-item-flex-details-col {
						background-color: $white;

						> div {
							margin: 1rem;
						}

						.gmst-co-offer-item-flex {
							.btn-prim {
								@extend %button;
								@extend %q-primary-button;

								&:hover {
									@extend %q-primary-button-touch;
								}
							}
						}
					}
				}
			}

			.offer-detail-container {
				> .offer-detail-container {
					&::after {
						background-color: $white;
						color: $black;
						content: 'or';
						font-size: 1rem;
						font-weight: $font-weight-normal;
					}
				}
			}

			.gmst-co-offer-item-tile {
				.gmst-co-offer-item-flex-details-col {
					background-color: $white;
					border: 1px solid $grey;

					h2 {
						@extend %q-body2;
						background-color: $grey-light;
						color: $grey-dark;
						font-size: .875rem;
						font-weight: $font-weight-bold;
						line-height: 1.5625rem;
						margin: 0;
						padding: .625rem;
						text-transform: uppercase;
					}

					.gmst-co-offer-item-offer-body {
						.IOM_regular { //sass-lint:disable-line class-name-format
							@extend %q-body2;
							font-size: .875rem;
							line-height: 1.25rem;
							margin-top: 1.25rem;

							&:nth-of-type(1) {
								@extend %q-descriptive1;
								display: inline-block;
								font-size: .9375rem;
								line-height: 1.25rem;
								margin-bottom: 1.25rem;
								width: 100%;

								+ br {
									display: none;
								}
							}
						}

						.IOM_regular_bold { //sass-lint:disable-line class-name-format
							@extend %q-body2;
							display: inline-block;
							font-size: .875rem;
							line-height: 1.25rem;
							margin-bottom: 0;
						}

						.IOM_large { // sass-lint:disable-line class-name-format
							@extend %q-body2;
							color: $grey-dark;
							display: inline-block;
							font-size: 1.125rem;
							font-style: italic;
							line-height: 1.875rem;
							text-transform: uppercase;
						}

						.IOM_xlarge { // sass-lint:disable-line class-name-format
							@extend %q-display2;
							color: $grey-dark;
							display: inline-block;
							font-size: 1.875rem;
							font-weight: $font-weight-bold;
							line-height: 1.875rem;
							padding-right: .25rem;
							text-transform: uppercase;
						}

						.gmst-co-offer-item-offer-endEffDate { //sass-lint:disable-line class-name-format
							@extend %q-descriptive2;
							font-size: .75rem;
							line-height: 1.25rem;
							margin: 1.25rem 0;
						}

						+ div {
							&[data-offer='Finance'],
							&[data-offer='Lease'],
							&[data-offer='Cash'] {
								margin: 0;
								padding: 0 1rem;

								.gmst-co-offer-tooltip,
								.IOM_small, //sass-lint:disable-line class-name-format
								.IOM_small_bold { //sass-lint:disable-line class-name-format
									@extend %q-body2;
									font-size: .875rem;
									line-height: 1.5625rem;
								}

								.gmst-co-offer-tooltip {
									&.stat-disclaim {
										color: $grey;
										display: inline-block;
										text-decoration: underline;
									}

									.gmst-co-offer-tooltiptext {
										display: none;

										&.show {
											background: $black;
											border-radius: 0;
											display: block;
											height: 100%;
											left: 0;
											margin: 0;
											opacity: .9;
											padding: 2.5rem 11.25rem 3.75rem 8.75rem;
											position: fixed;
											top: 0;
											width: 100%;
											z-index: 1200;

											.IOM_small { // sass-lint:disable-line class-name-format
												@extend %q-body2;

												color: $white;
												opacity: .8;
											}

											.IOM_small_bold { // sass-lint:disable-line class-name-format
												@extend %q-body2;
												color: $white;
												font-weight: $font-weight-normal;
												opacity: .8;
											}

											.gmst-co-offer-tooltip-close {
												@include insert-icon($disclosure-close-icon, map-get($disclosure-close-icon-height-map, small),
													map-get($disclosure-close-icon-height-map, small), $disclosure-close-insert-icon-mq-use, $disclosure-toast-close-hover-icon);
												height: 1.875rem;
												opacity: .8;
												position: absolute;
												right: 4.375rem;
												top: 3.75rem;
												vertical-align: baseline;
												width: 1.875rem;

												&::after {
													content: '';
												}
											}
										}
									}

									&:hover {
										color: $brand-link-color-hover;
										cursor: pointer;
									}
								}
							}
						}
					}
				}
			}

			.gmst-co-offer-item-flex-container {
				margin: 1rem 0;

				.gmst-co-offer-item-flex-order-2 {
					flex-direction: row;

					.gmst-co-offer-item-flex-inner {
						h1 {
							@extend %q-descriptive1;
							font-size: .9375rem;
							line-height: 1.25rem;
						}
					}
				}

				.gmst-co-offer-item-flex-order-3 {
					a {
						padding: 1rem;

						&:first-child {
							margin-bottom: .625rem;
						}
					}
				}

				.gmst-co-offer-item-flex-order-2,
				.gmst-co-offer-item-flex-order-3 {
					margin-top: 1.25rem;
				}
			}
		}

		.gmwc-collapsable-panel-body {
			border-bottom: 1px solid $black;
			padding-bottom: 1.25rem;
		}

		.collapsed {
			.gmwc-collapsable-panel-body {
				border-bottom: 0;
				padding-bottom: 0;
			}

			// IE11 workaround to load asset then override immediately after
			.gmst-co-offer-show-hide {
				background-image: get-element('minus');
			}

			.gmst-co-offer-show-hide {
				background-color: transparent;
				border: 0;
				cursor: pointer;
				height: 1.875rem;
				height: 2rem;
				left: auto;
				margin: 0;
				padding: 0;
				position: absolute;
				right: .5rem;
				top: 40%;
				transform: translateX(-50%);
				width: 2rem;

				&::after {
					background-image: get-element('plus');
					content: '';
					display: inline-block;
					height: 2rem;
					left: auto;
					padding-left: 0;
					position: absolute;
					text-align: center;
					top: 0;
					width: 2rem;
				}
			}

			&:hover {
				.gmst-co-offer-show-hide {
					&::after {
						background-image: get-element('plus-gold');
					}
				}
			}
		}

		.gmst-co-offer-show-hide {
			background-color: transparent;
			background-image: get-element('minus');
			border: 0;
			cursor: pointer;
			height: 1.875rem;
			height: 2rem;
			left: auto;
			margin: 0;
			padding: 0;
			position: absolute;
			right: .5rem;
			top: 40%;
			transform: translateX(-50%);
			width: 2rem;

			&::after {
				background-image: get-element('minus');
				content: '';
				display: inline-block;
				height: 2rem;
				left: 0;
				padding-left: 0;
				right: 0;
				top: 0;
				width: 2rem;
			}
		}
	}

	@media #{$large-up} {
		.q-co-results-top {
			.q-postal-code {
				.q-postal-code-label {
					label {
						margin-top: 1.125rem;
					}
				}
			}

			gmwc-stateful-dropdown {
				.filterby-select-label {
					margin-top: 1.125rem;
				}

				.gmwc-stateful-dropdown-filterby {
					padding: .9375rem 4.375rem .9375rem 1.875rem;
				}
			}
		}

		.gmst-co-result-group {
			.gmst-co-offer-result {
				align-items: center;
				padding: .875rem 1.875rem;

				.gmst-co-offer-result-cell {
					h2 {
						font-size: 1.625rem;
						line-height: 1.875rem;
					}

					.cof-count-display {
						font-size: 1.125rem;
						line-height: 1.75rem;
					}
				}

				slot {
					&[name='nooffers'] {
						span {
							&[slot='nooffers'] {
								color: $grey;
							}
						}
					}

					&[name='loading'] {
						span {
							&[slot='loading'] {
								color: $grey;
							}
						}
					}
				}
			}

			.gmst-co-offer-collapsed-result {
				.gmst-co-offer-item-flex-container {
					margin: 1rem 0;

					.gmst-co-offer-item-flex-order-2 {
						flex-direction: row;
						margin-top: 0;

						.btn-prim {
							margin-top: 1rem;
							padding: 1rem;
						}

						.btn-sec {
							margin-top: 1rem;
							padding: 1rem;
						}

						.gmst-co-offer-item-flex-inner {
							padding-left: 1.25rem;

							h1 {
								font-size: 1.25rem;
								line-height: 1.875rem;
							}
						}
					}
				}

				.gmst-co-offer-item-flex-filter-col {
					margin: 1rem;
				}

				.gmst-co-offer-item-tile {
					.gmst-co-offer-item-flex-details-col {
						.gmst-co-offer-item-offer-body {
							.gmst-co-offer-item-offer-endEffDate { //sass-lint:disable-line class-name-format
								font-size: .875rem;
								line-height: 1.25rem;
							}
						}

						h2 {
							font-size: 1.125rem;
							line-height: 1.875rem;
						}

						.gmst-co-offer-item-offer-body {
							.IOM_regular { //sass-lint:disable-line class-name-format
								line-height: 1.5625rem;
								margin-top: 1.25rem;

								&:nth-of-type(1) {
									font-size: 1.25rem;
									line-height: 1.875rem;
								}
							}

							.IOM_regular_bold { //sass-lint:disable-line class-name-format
								line-height: 1.5625rem;
							}

							.IOM_xlarge { // sass-lint:disable-line class-name-format
								font-size: 3.125rem;
								line-height: 3.125rem;
							}

							+ div {
								&[data-offer='Finance'],
								&[data-offer='Lease'],
								&[data-offer='Cash'] {
									.gmst-co-offer-tooltip,
									.IOM_small, //sass-lint:disable-line class-name-format
									.IOM_small_bold { //sass-lint:disable-line class-name-format
										font-size: .875rem;
										line-height: 1.25rem;
									}
								}
							}
						}
					}
				}
			}

			.gmst-co-offer-item-flex-filter-container {
				.gmst-co-offer-item-table {
					.gmst-co-offer-item-bar-primary {
						h3 {
							font-size: 1.25rem;
							line-height: 1.875rem;
						}
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-co-results-top {
			.q-postal-code {
				margin-bottom: 0;

				.q-postal-code-label {
					font-size: .875rem;
				}

				.q-postal-code-input {
					padding-left: .625rem;

					.q-co-clear-zip {
						right: 5rem;
						top: 1.4rem;

						&:hover {
							cursor: pointer;
							opacity: 1;
						}
					}
				}

				.q-postal-code-label {
					label {
						text-align: right;
					}
				}
			}

			gmwc-stateful-dropdown {
				.filterby-select-label {
					font-size: .875rem;
					text-align: right;
				}

				.gmwc-stateful-dropdown-filterby {
					margin-left: .625rem;
					padding: .9375rem 4.375rem .9375rem 1.875rem;
				}
			}
		}

		.gmst-co-result-group {
			.gmst-co-offer-result {
				padding: .875rem 1.5rem .875rem 4.5rem;

				.gmst-co-offer-result-cell {
					h2 {
						font-size: 1.875rem;
						line-height: 2.5rem;
					}

					.cof-count-display {
						font-size: 1.25rem;
						line-height: 2.5rem;
					}

					slot {
						&[name='nooffers'] {
							span {
								&[slot='nooffers'] {
									color: $grey;
									font-size: .875rem;
									line-height: 1.25rem;
									padding-left: 0;
								}

								a {
									font-size: inherit;
									line-height: inherit;
								}
							}
						}

						&[name='loading'] {
							span {
								&[slot='loading'] {
									font-size: .875rem;
									line-height: 1.25rem;
									padding-left: 0;
								}
							}
						}
					}
				}

				.collapsed {
					.gmst-co-offer-show-hide {
						left: 2rem;
					}
				}

				.gmst-co-offer-show-hide {
					left: 2rem;
				}
			}

			.gmst-co-offer-collapsed-result {
				.gmst-co-offer-item-tile {
					.gmst-co-offer-item-flex-details-col {
						.gmst-co-offer-item-offer-body {
							.gmst-co-offer-item-offer-endEffDate { //sass-lint:disable-line class-name-format
								font-size: .875rem;
								line-height: 1.25rem;
							}
						}
					}
				}

				.gmst-offer-detail-container {
					.offer-detail-container {
						&:not(:last-child) {
							&::after {
								margin-right: 1px; // Needed to prevent it from getting cut off on right
							}
						}
					}
				}

				.gmst-co-offer-item-flex-container {
					margin: 1rem 0;

					.gmst-co-offer-item-flex-order-2,
					.gmst-co-offer-item-flex-order-3 {
						margin-top: 0;
					}
				}
			}
		}
	}

	gmst-postalcode {
		#gmst-postalcode-container { // sass-lint:disable-line no-ids
			border: 0;
			display: flex;
			flex-direction: row;
			height: auto;

			.gmst-postalcode-location {
				background-color: $grey;
				flex: none;
				height: 3.75rem;
				width: 3.75rem;

				&:hover {
					background-color: $grey-dark;
					cursor: pointer;
				}

				.gmst-postalcode-location-icon {
					@include insert-icon(geolocation);
					background-position: center;
					height: 1.25rem;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 1.25rem;
				}
			}

			.gmst-postalcode-input:invalid ~ .gmst-postalcode-submit {
				background-color: $grey;

				&:hover {
					background-color: $grey-dark;
				}
			}

			.gmst-postalcode-input {
				width: calc(100% + -7.5rem);
			}

			.gmst-postalcode-submit {
				background-color: $grey;
				flex: none;
				height: 3.75rem;
				margin: 0;
				width: 3.75rem;

				&[disabled] {
					background-color: $grey-mid;
				}

				&:hover {
					background-color: $grey-dark;
					cursor: pointer;
				}

				.gmst-postalcode-submit-icon {
					@include insert-icon(search-icon-white);
					background-position: center;
					height: 1.25rem;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 1.25rem;
				}
			}
		}
	}

	gmst-postalcode-popup {
		.gmst-postalcode-popup-shade {
			background-color: $black; /* Fallback color */
			color: $white;
			font-family: $font-family-secondary;
			font-size: .875rem;
			opacity: .9;
			z-index: 1;
		}

		.gmst-postalcode-popup-message {
			margin: 6rem auto;
			min-height: 17.5rem;
			padding: 2.5rem 2.5rem .9375rem 1.875rem;
			width: 26.3rem;
			z-index: 2;


			p {
				margin-bottom: 1.25rem;
			}

			.gmst-postalcode-popup-close {
				@include insert-icon($gl-close-icon, 1.25rem, 1.313rem, null, $gl-close-icon-hover);

				background-position: center;
				border: 0;
				color: transparent;
				flex: 0 0 auto;
			}
		}
	}

	.gmst-co-result-group {
		.q-headline {
			.q-headline1 {
				background-color: $grey-mid;
				color: $black;
			}
		}
	}
}
