/********
 * NOTE: These variables and mixins MUST be in this file so that this file can be used
 *       on its own for use in AEM dialog.
 ********/

// Icons
// - - - - - - - - - - - - - - - - - - - - - - - - -
$icon-font-size: 1rem !default;

@mixin icon-base {
	font-family: 'solar-icons';
	font-size: $icon-font-size;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	position: relative;
	speak: none;
	text-transform: none;
}

// For adding font icons to elements using CSS pseudo-elements
// http://jaydenseric.com/blog/fun-with-sass-and-font-icons
@mixin icon($icon, $position: before, $styles: true) {
	@if $position == both {
		$position: 'before, &::after';
	}

	// Either a ::before or ::after pseudo-element, or both, defaulting to ::before
	&::#{$position} {
		@if $icon {
			content: '#{map-get($icons-small, $icon)}';

			@if $icons-media-query {
				@media #{$medium-up}, print {
					content: '#{map-get($icons-large, $icon)}';
				}
			}

		}

		@if $styles {
			@include icon-base;
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
			text-align: inherit;
		}

		// Include any extra rules supplied for the pseudo-element
		@content;
	}
}

@mixin icon-stack($left: 0, $top: 0) {
	left: $left;
	position: absolute;
	top: $top;
}

// This mixin is used to auto-generate all icon classes
@mixin generate-icons {
	@each $icon-name, $icon in $icons-large {
		.icon-#{$icon-name} {
			@include icon($icon-name, before, false);
		}
	}
}

/* Use this mixin to update styles associated to icons
	This is required because of how @extend %icon works.
 */
@mixin icon-style($pseudo: before) {
	@if $pseudo == both {
		$pseudo: 'before, &:after';
	}

	&:#{$pseudo} {
		@content;
	}
}

@function get-element($key) {
	@return map-get($element-map, $key);
}

@font-face {
	font-family: 'solar-icons';
	font-style: normal;
	font-weight: normal;
	src: url('fonts/solar-icons.eot');
	src: url('fonts/solar-icons.eot?#iefix') format('embedded-opentype'),
	url('fonts/solar-icons.ttf') format('truetype'),
	url('fonts/solar-icons.woff') format('woff'),
	url('fonts/solar-icons.svg#solar-icons') format('svg');
}

%icon,
.icon {
	@include icon-base;
	vertical-align: middle;

	&::after,
	&::before {
		font-size: inherit;
		line-height: inherit;
		text-align: inherit;
	}
}

$icons-small: (
	accessories: '\e603',
	advice: '\e605',
	article-dropdown: '\e607',
	attention-conflict: '\e60a',
	awards: '\e60d',
	benefit-in-kind-calculator: '\e611',
	benefits-ecoflex: '\e613',
	brochure-request-negative: '\e616',
	brochure-request: '\e617',
	calculator: '\e619',
	car: '\006DE',
	chat: '\e61b',
	checkmark: '\e62c',
	checkbox-circle: '\e61e',
	checkbox-square: '\e622',
	checkbox-tick: '\e627',
	circle: '\e628',
	close: '\e632',
	close-modal-negative: '\e635',
	close-modal: '\e636',
	co2-efficiency: '\e638',
	co2-emmission: '\e63a',
	colors: '\e63e',
	configurator-negative: '\e641',
	configurator: '\e642',
	connect-your-phone: '\e644',
	consumption: '\e646',
	contact: '\e648',
	contact-request: '\e64a',
	contract: '\e64c',
	convertible-closed: '\e64e',
	convertible-open: '\e650',
	cruising-range: '\e652',
	dealer: '\e654',
	dealer-locator-negative: '\e657',
	dealer-locator: '\e658',
	download: '\e65a',
	eco-flex: '\e660',
	edit: '\e662',
	email-contact: '\e664',
	email-share-negative: '\e668',
	email-share: '\e66a',
	engine-power: '\e66c',
	extended-warranty: '\e670',
	favorites: '\e672',
	file-type-pdf: '\e674',
	finance-calculator: '\e676',
	financing-purchase: '\e678',
	fuel-type-petrol-diesel: '\e67a',
	gps: '\e67c',
	help-negative: '\e67f',
	help: '\e680',
	home-negative: '\e683',
	home: '\e684',
	info-negative: '\e687',
	info: '\e688',
	info-i: '\e68a',
	insurance: '\e690',
	knowledge-good-to-know: '\e692',
	leasing: '\e694',
	location-contact: '\e696',
	location-pin-text-negative: '\e69a',
	location-pin-text: '\e699',
	luggage-volume: '\e69c',
	mail: '\e69e',
	maximize-360: '\e6a0',
	// menu: '\e6a2',
	map: '\1F6A2',
	my-car: '\e6a4',
	navigation: '\e6a6',
	news: '\e6a8',
	newsletter-request-negative: '\e6ab',
	newsletter-request: '\e6ac',
	number-of-airbags: '\e6b2',
	number-of-doors: '\e6b4',
	number-of-seats: '\e6b6',
	offers-financing: '\e6b8',
	chevrolet-fleet-team: '\e6ba',
	chevrolet-shop: '\e6bc',
	optional-equipment: '\e6c6',
	owner-manual: '\e6c8',
	payload: '\e6cc',
	performance: '\e6ce',
	phone-contact: '\e6d0',
	// price-list: '\e6ec',
	print-negative: '\e6ef',
	print: '\e6f0',
	radio-music: '\e6f2',
	// rating-star-half: '\e6f5',
	// rating-star-half-yellow: '\e6f6',
	// rating-star-outline: '\e6f9',
	// rating-star-outline-yellow: '\e6fa',
	reccomend: '\e6fc',
	remove: '\e6fe',
	rent: '\e700',
	// request-quote-negative: '\e704',
	// request-quote: '\e706',
	rims: '\e708',
	save1: '\e70a',
	save2: '\e70c',
	search: '\e70e',
	service: '\e712',
	service-appointment: '\e716',
	service-appointment-negative: '\e715',
	service-packages: '\e711',
	// slider-pagination: '\e71b',
	square: '\e62f',
	standard-equipment: '\e750',
	steering-wheel-controls: '\e752',
	// tax: '\e754',
	test-drive-request-negative: '\e757',
	test-drive-request: '\e758',
	tick: '\e75a',
	// tire-sound-label1: '\e760',
	// tire-sound-label2: '\e76a',
	// tire-sound-label3: '\e774',
	torque: '\e77c',
	touchpad: '\e77e',
	towing-capacity: '\e780',
	// tv-spots: '\e782',
	// usb-video: '\e784',
	view: '\e601',
	voice: '\e786',
	// vot1: '\e788',
	// vot2: '\e78a',
	// vot3: '\e78c',
	// vot4: '\e78e',
	// whole-life-cost-calculator: '\e790',
	// wishlist-star: '\e794',
	// wishlist-star-yellow: '\e796',
	zoom: '\e798'
);

$icons-large: (
	accessories: '\e602',
	advice: '\e604',
	article-dropdown: '\e606',
	attention-conflict: '\e608',
	awards: '\e60c',
	benefit-in-kind-calculator: '\e610',
	benefits-ecoflex: '\e612',
	brochure-request-negative: '\e614',
	brochure-request: '\e615',
	calculator: '\e618',
	chat: '\e61a',
	checkmark: '\e62c',
	checkbox-circle: '\e61c',
	checkbox-square: '\e620',
	checkbox-tick: '\e626',
	circle: '\e628',
	close: '\e631',
	close-modal-negative: '\e633',
	close-modal: '\e634',
	co2-efficiency: '\e637',
	co2-emmission: '\e639',
	colors: '\e63d',
	configurator-negative: '\e63f',
	configurator: '\e640',
	connect-your-phone: '\e643',
	consumption: '\e645',
	contact: '\e647',
	contact-request: '\e649',
	contract: '\e64b',
	convertible-closed: '\e64d',
	convertible-open: '\e64f',
	cruising-range: '\e651',
	dealer: '\e653',
	dealer-locator-negative: '\e655',
	dealer-locator: '\e656',
	download: '\e659',
	eco-flex: '\e65f',
	edit: '\e661',
	email-contact: '\e663',
	email-share-negative: '\e665',
	email-share: '\e667',
	engine-power: '\e66b',
	extended-warranty: '\e66f',
	favorites: '\e671',
	file-type-pdf: '\e673',
	finance-calculator: '\e675',
	financing-purchase: '\e677',
	fuel-type-petrol-diesel: '\e679',
	gps: '\e67b',
	help-negative: '\e67d',
	help: '\e67e',
	home-negative: '\e681',
	home: '\e682',
	info-negative: '\e685',
	info: '\e686',
	info-i: '\e689',
	insurance: '\e68f',
	knowledge-good-to-know: '\e691',
	leasing: '\e693',
	location-contact: '\e695',
	location-pin-text-negative: '\e698',
	location-pin-text: '\e697',
	luggage-volume: '\e69b',
	mail: '\e69d',
	maximize-360: '\e69f',
	// menu: '\e6a1',
	my-car: '\e6a3',
	navigation: '\e6a5',
	news: '\e6a7',
	newsletter-request-negative: '\e6a9',
	newsletter-request: '\e6aa',
	number-of-airbags: '\e6b1',
	number-of-doors: '\e6b3',
	number-of-seats: '\e6b5',
	offers-financing: '\e6b7',
	chevrolet-fleet-team: '\e6b9',
	chevrolet-shop: '\e6bb',
	optional-equipment: '\e6c5',
	owner-manual: '\e6c7',
	payload: '\e6cb',
	performance: '\e6cd',
	phone-contact: '\e6cf',
	price-list: '\e6eb',
	print-negative: '\e6ed',
	print: '\e6ee',
	radio-music: '\e6f1',
	// rating-star-half: '\e6f3',
	// rating-star-half-yellow: '\e6f4',
	// rating-star-outline: '\e6f7',
	// rating-star-outline-yellow: '\e6f8',
	reccomend: '\e6fb',
	remove: '\e6fd',
	rent: '\e6ff',
	// request-quote-negative: '\e705',
	// request-quote: '\e703',
	rims: '\e707',
	save1: '\e709',
	save2: '\e70b',
	search: '\e70d',
	service: '\e70f',
	service-appointment: '\e714',
	service-appointment-negative: '\e713',
	service-packages: '\e710',
	// slider-pagination: '\e71a',
	square: '\e62f',
	standard-equipment: '\e74f',
	steering-wheel-controls: '\e751',
	// tax: '\e753',
	test-drive-request-negative: '\e755',
	test-drive-request: '\e756',
	tick: '\e759',
	// tire-sound-label1: '\e75b',
	// tire-sound-label2: '\e765',
	// tire-sound-label3: '\e76f',
	torque: '\e77b',
	touchpad: '\e77d',
	towing-capacity: '\e77f',
	// tv-spots: '\e781',
	// usb-video: '\e783',
	view: '\e600',
	voice: '\e785',
	// vot1: '\e787',
	// vot2: '\e789',
	// vot3: '\e78b',
	// vot4: '\e78d',
	// whole-life-cost-calculator: '\e78f',
	// wishlist-star: '\e791',
	// wishlist-star-yellow: '\e793',
	zoom: '\e797',
);

// Empty Element Map reference is intended to prevent compile error
$element-map: ();

@include generate-icons;
