
.q-document-finder {
	.q-document-finder-field-columns {
		@include grid-column(12);
	}

	.q-document-finder-vehicle-image {
		@include grid-column(12);
	}

	.q-year-dropdown-title,
	.q-vehicle-dropdown-title {
		@extend %q-descriptive2;

		margin-bottom: 0;
		padding: 1.25rem 0;

		&.disabled {
			opacity: .3;
		}
	}

	.q-year-dropdown:not([disabled]),
	.q-vehicle-dropdown:not([disabled]) {
		cursor: pointer;
	}

	.q-button-wrap-get-started {
		margin-top: 1.875rem;
		padding-left: 0;
	}

	.q-vehicle-image-container {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100%;
		height: 21.06rem;
		max-height: 21.06rem;
		opacity: 1;
		padding-top: 2.5rem;
		text-align: center;
		transition: opacity .2s linear;
		width: 100%;

		&.fade-in {
			opacity: 1;
		}

		&.fade-out {
			opacity: 0;
		}

		.q-doc-finder-image {
			max-height: 100%;
			width: auto;
		}
	}

	.q-document-finder-cta {
		@extend %button;
		@include button-border($button-primary-border-primary-color);

		line-height: 1.875rem;
		overflow: hidden;
		padding: 1.0625rem 0;
		white-space: nowrap;

		&.disabled {
			box-shadow: none;
			cursor: default;
			opacity: .3;
		}

		&:not(.disabled) {
			@include button-hover($button-icon-inline-border-primary-color, $button-icon-inline-border-fill, $fill-color: $button-icon-inline-border-fill-color, $box-shadow: $button-icon-inline-border-box-shadow);
		}
	}

	.q-hoverable-cta-icon:not(.disabled) {
		@include icon-hover(inline, none);

		&:hover {
			@include icon-hover(none, inline);
		}
	}

	.icon-hover {
		display: none;
	}

	.q-cta-wrapper {
		width: 100%;
	}

	.q-blank-car {
		@include insert-icon(blank-car);
	}

	.icon-normal,
	.icon-hover {
		height: 1.25rem;
		width: 1.25rem;
	}

	.q-exit-icon {
		@include insert-icon(exit-icon);
	}

	.q-exit-dark-icon {
		@include insert-icon(exit-dark-icon);

		height: 1.25rem;
		width: 1.25rem;
	}

	.q-doc-finder-cta-label {
		display: inline-block;
		margin: rem-calc(0 10 0 10);
	}

	.q-datacontainer {
		display: none;
	}

	@media #{$small-only} {
		.q-cta-wrapper {
			padding-right: 0;
		}
	}

	@media #{$large-up} {
		.q-document-finder-field-columns {
			@include grid-column(5);
		}

		.q-document-finder-vehicle-image {
			@include grid-column(6);
		}

		.q-cta-wrapper {
			width: 75%;
		}
	}

	@media #{$xlarge-up} {
		.q-document-finder-field-columns {
			@include grid-column(4);
		}

		.q-document-finder-vehicle-image {
			@include grid-column(7);
		}
	}
}
