// Button
//
// This is the documentation for Button. Edit button.scss to update this documentation.
//
// Styleguide REV: 2016-04-14
//IE Prevent button from shifting on active/click

// Button Placeholders
%button {
	@include hard-easeout(background, $anim-fast);
	background-color: $button-background-color;
	border-style: solid;
	border-width: $button-border-width;
	color: $button-color;
	display: block;
	font: $button-font;
	letter-spacing: $button-letter-spacing;
	line-height: $button-line-height;
	margin-bottom: 0;
	padding: $button-padding;
	text-align: center;
	width: 100%;
}

%button-no-border {
	border: 0;
	display: inline-block;
	text-align: inherit;
	width: $button-no-border-width;
}

%q-button-large {
	padding-bottom: $button-large-size-padding;
	padding-top: $button-large-size-padding;
}

%button-list-item {
	border: 0;
	border-left: $button-list-item-padding-border-left;
	box-shadow: 0;
	text-align: inherit;

	&.q-button-large {
		@extend %q-button-large;
	}

	&.q-button-padding-none {
		padding-left: 0;
		padding-right: 0;
	}

	&.q-button-padding {
		padding-left: $button-list-padding-left;
		padding-right: $button-list-padding-right;
	}
}

%list-item-primary {
	@include button-list-item($button-list-primary-bg-color, $button-list-primary-color, $button-list-primary-line-height, $button-list-primary-text-align, $button-list-primary-border-bottom);

	&.active,
	&:hover {
		background-color: $tertiary-list-item-hover-color;
	}
}

%q-invert {
	border-color: $button-secondary-invert-border-color;
	color: $button-secondary-invert-color;
}

%q-primary-button {
	@include button-border($button-primary-border-primary-color);

	&.q-invert {
		background-color: $button-primary-invert-background-color;
		border-color: $button-primary-invert-border-color;
		color: $button-primary-invert-color;
	}
}

%q-primary-button-touch {
	@include button-hover($button-primary-border-primary-color, $button-primary-border-fill, $fill-color: $button-primary-border-fill-color, $hover-color: $button-primary-border-hover-color);

	// Inverted Styling
	&.q-invert {
		&:active,
		&:hover,
		&:focus {
			background-color: $button-primary-invert-hover-background-color;
			color: $button-primary-invert-hover-color;
		}
	}
}

%q-secondary-button {
	@include button-border($button-secondary-border-primary-color, $button-secondary-border-background-color, $button-secondary-border-color);

	// Inverted Styling
	&.q-invert {
		@extend %q-invert;
	}
}

%q-secondary-button-touch {
	@include button-hover($button-secondary-border-primary-color, $button-secondary-border-fill, $button-secondary-border-fill-color, $button-secondary-border-hover-color);

	// Inverted Styling
	&.q-invert {
		&:active,
		&:hover,
		&:focus {
			background-color: $button-secondary-invert-hover-background-color;
			border-color: $button-secondary-invert-hover-background-color;
			color: $button-secondary-invert-hover-color;
		}
	}
}

%q-list-item-primary {
	@include button-list-item($button-list-primary-bg-color, $button-list-primary-color, $button-list-primary-line-height, $button-list-primary-text-align, $button-list-primary-border-bottom);
}

%q-list-item-primary-no-touch {
	&:active,
	&:hover {
		background-color: $button-list-primary-hover-bg-color;
		border-left-color: $button-list-primary-hover-left-bg-color;
	}
}

%q-list-item {
	@extend %button-list-item;

	&.q-list-item-primary,
	&.q-list-item-icon-primary {
		@extend %q-list-item-primary;
	}

	&.q-list-item-secondary,
	&.q-list-item-icon-secondary {
		@include button-list-item($button-list-secondary-bg-color, $button-list-secondary-color, $button-list-secondary-line-height, $button-list-secondary-text-align, $button-list-secondary-border-bottom);
	}

	&.q-list-item-tertiary,
	&.q-list-item-icon-tertiary {
		@include button-list-item($button-list-tertiary-bg-color, $button-list-tertiary-color, $button-list-tertiary-line-height, $button-list-tertiary-text-align, $button-list-tertiary-border-bottom);
		border-left: $button-list-item-tertiary-left-border;
	}

	&.q-list-item-icon-secondary {
		color: $button-list-item-icon-tertiary-color;
	}

	&.q-tertiary-nav-button {
		@extend %tertiary-nav-button;
	}
}

// Button Styling
.q-button {
	&:active div,
	&:focus div {
		left: 0;
		position: relative;
		top: 0;
	}

	@extend %button;

	// Primary and Secondary (Main) Buttons
	&.q-primary-button {
		@extend %q-primary-button;
	}

	&.q-secondary-button {
		@extend %q-secondary-button;
	}

	// Links and Icon Buttons
	&.q-link,
	&.q-icon-only {
		@extend %button-no-border;
	}

	&.q-link {
		font-size: $button-q-link-font-size;
		letter-spacing: $button-q-link-letter-spacing;
		line-height: $button-q-link-line-height;
		padding: $button-q-link-padding;

		&.q-invert {
			color: $button-q-link-q-invert-color;
		}
	}

	// Stacked and Inline Icon Buttons
	&.q-icon-stacked,
	&.q-inline-icon {
		@include button-border($button-icon-inline-border-primary-color);
	}

	&.q-icon-stacked-secondary,
	&.q-inline-icon-secondary {
		@include button-border($button-icon-inline-border-secondary-border-color, $color: $button-icon-inline-border-secondary-color);

		// Inverted Styling
		&.q-invert {
			border-color: $button-icon-inline-border-secondary-invert-border-color;
			color: $button-icon-inline-border-secondary-invert-color;
		}
	}

	// List Item Buttons
	&.q-list-item {
		@extend %q-list-item;
	}

	// SVG Icons
	@for $i from 1 through length($icon-size) {
		.q-svg-icon-#{nth($icon-size, $i)} {
			@include svg-icon-size(nth($icon-size-value, $i));
			margin: auto;
		}
	}

	&.q-icon-stacked,
	&.q-icon-stacked-secondary {
		.q-svg-icon {
			@include svg-icon-size($button-icon-size-stacked, $large-up, $button-icon-size-stacked-large);

			margin-bottom: $button-icon-margin-bottom;
		}

		.icon-font {
			font-size: $button-icon-size-stacked;
			margin-bottom: $button-icon-margin-bottom;

			@media #{$large-up} {
				font-size: $button-icon-size-stacked-large;
			}
		}
	}

	.icon-font {
		font-size: $button-icon-size;
	}

	.q-svg-icon {
		@include svg-icon-size($button-icon-size, $large-up, $button-icon-size-large);

		svg {
			height: 100%;
			width: 100%;
		}
	}

	.q-shift-icon {
		align-items: center;
		display: inline-flex;

		&.q-icon-right {
			.move-text {
				margin-right: $button-icon-margin;
				order: 1;
			}

			.move-icon {
				order: 2;
			}
		}

		&.q-icon-left {
			.move-text {
				order: 2;
			}

			.move-icon {
				margin-right: $button-icon-margin;
				order: 1;
			}
		}
	}

	// Inverted Styling
	&.q-invert {
		@include icon-fill($button-primary-invert-color, $button-primary-invert-color, false);
		color: $white; // FIXME - WTF?
	}

	&.hoverable-icon {
		@include icon-hover(inline, none);

		&:active,
		&:hover,
		&:focus {
			@include icon-hover(none, inline);
		}
	}
}

// Touch Styles
.q-button {
	.no-touch & {
		&:active,
		&:hover,
		&:focus {
			color: $button-hover-color;
		}

		// Primary and Secondary (Main) Buttons
		&.q-primary-button {
			@extend %q-primary-button-touch
		}

		&.q-secondary-button {
			@extend %q-secondary-button-touch;
		}

		&.q-link,
		&.q-icon-only {
			&:active,
			&:hover,
			&:focus {
				color: $button-q-link-text-hover;
			}

			&.q-invert {
				&:active,
				&:hover,
				&:focus {
					color: $button-q-link-q-invert-text-hover;
				}
			}
		}

		// Stacked and Inline Icon Buttons
		&.q-icon-stacked,
		&.q-inline-icon {
			@include button-hover($button-icon-inline-border-primary-color, $button-icon-inline-border-fill, $fill-color: $button-icon-inline-border-fill-color, $box-shadow: $button-icon-inline-border-box-shadow);

			// Inverted Styling
			&.q-invert {
				&:active,
				&:hover,
				&:focus {
					color: $button-primary-invert-hover-color;
				}
			}
		}

		&.q-icon-stacked-secondary,
		&.q-inline-icon-secondary {
			@include button-hover($button-icon-inline-border-secondary-border-color, $button-icon-inline-border-secondary-fill, $fill-color: $button-icon-inline-border-secondary-fill-color, $hover-color: $button-icon-inline-border-secondary-hover-color, $box-shadow: $button-icon-inline-border-secondary-box-shadow);

			// Inverted Styling
			&.q-invert {
				&:active,
				&:hover,
				&:focus {
					background-color: $button-icon-inline-border-secondary-invert-hover-background-color;
					border-color: $button-icon-inline-border-secondary-invert-hover-border-color;
					color: $button-icon-inline-border-secondary-invert-hover-color;
				}
			}
		}

		// List Item Buttons
		&.q-list-item {
			&.q-button-padding {
				&:active,
				&:hover {
					@include hard-easeout(border-color, $anim-fast);
					border-left-color: $button-list-border-left-color;
				}
			}

			&.q-list-item-primary,
			&.q-list-item-icon-primary {
				@extend %q-list-item-primary-no-touch;
			}

			&.q-list-item-secondary,
			&.q-list-item-icon-secondary {
				&:hover {
					background-color: $button-list-secondary-hover-bg-color;
					border-left-color: $button-list-secondary-hover-left-bg-color;
				}
			}

			&.q-list-item-tertiary,
			&.q-list-item-icon-tertiary {
				@include hard-easeout(border-color, $button-list-item-tertiary-anim-speed);

				&:active,
				&:focus,
				&:hover {
					border-left-color: $button-list-item-tertiary-hover-left-bg-color;
					color: $button-list-item-tertiary-hover-color;
				}
			}

			&.q-tertiary-nav-button {
				@extend %tertiary-nav-button;
			}
		}
	}
}
