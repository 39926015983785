.q-destination-freight-information {

	.q-freight-name {
		@extend %q-descriptive1;
		@extend %q-caps;
		display: inline-block;
	}

	.q-freight-year {
		@extend %q-descriptive1;
		display: inline-block;
	}

	.q-freight-price {
		@extend %q-body2;
	}

	.q-freight-description {
		@extend %q-body2;
	}
}
