// Footer styles
$footer-expander-button-padding: $gridgutter-enabled !default;
$footer-expander-button-margin: -5vw !default;

footer {
	background: $footer-background-color;
	color: $footer-text-color;
	padding-bottom: $footer-padding-bottom;
	padding-top: $footer-padding-top;

	@if $footer-remove-expander-anim {
		@include tabs-remove-transition;
	}

	.q-button {
		&.q-inline-icon {
			border-color: $footer-inlineicon-border-color;
			color: $footer-inlineicon-color;
		}

		&.q-link {
			color: map-get($footer-button-link-color, large);
			font-size: $footer-button-link-font-size;
			font-weight: $footer-button-link-font-weight;
			margin-bottom: $footer-button-link-margin-bottom;
			padding: map-get($footer-button-link-padding, large);
			text-align: left;
		}

		.hoverable-container {
			> img {
				height: $footer-button-icon-height;
			}
		}

		&.q-icon-only {
			display: block;
			float: left;
			height: $footer-button-icon-height;
			margin-right: .625rem;
			overflow: hidden;
			padding: 0 .25rem 0 0;
			width: auto;
		}
	}

	img {
		height: $footer-button-icon-height;
		vertical-align: $footer-image-vertical-align;
	}

	.q-static-image {
		text-align: right;
	}

	.q-expander {
		.q-button {
			&.q-link,
			&.q-invert {
				line-height: $footer-button-link-line-height;

				&:active,
				&:hover {
					color: $footer-button-link-hover-color;
				}
			}
		}

		> div .q-expander-button {
			background-color: $footer-expander-button-background-color;
			border-bottom: $footer-expander-button-border-bottom;
			border-top: $footer-expander-button-border-top;

			&:hover {
				border-bottom: $footer-expander-button-border-bottom-hover;
				border-top: $footer-expander-button-border-top-hover;
			}

			.q-headline-text {
				&.q-descriptive1 {
					color: $footer-expander-button-headline-color;
				}
			}
		}

		&.active,
		&.open {
			> div > .q-expander-button {
				background: none;
				border: 0;
			}
		}
	}

	@media #{map-get($media-query-names, $footer-expander-media-query)} {
		padding-top: $footer-expander-padding-top;

		.q-expander {
			margin-left: -.0625rem;
			margin-left: -2.5vw;
			margin-right: -.0625rem;
			margin-right: -2.5vw;

			// FIXME: This needs to be fixed at some point, but just migrating from
			// footer_reference_partial.scss for now
			&.q-disabled-med,
			&.q-disabled-lg,
			&.q-disabled-xl {
				margin-left: $footer-expander-button-margin;
				margin-right: $footer-expander-button-margin;
			}

			.q-link {
				border-bottom: $footer-button-link-border-bottom;
				border-top: $footer-button-link-border-top;
				color: map-get($footer-button-link-color, small);
				margin: $footer-button-link-margin;
				padding: map-get($footer-button-link-padding, small);
				width: calc(100% - .625rem);

				&:hover {
					color: $gold;
				}
			}

			> div .q-expander-button {
				border-left: 0;
				border-right: 0;
				padding-left: $footer-expander-button-padding;
			}

			&.active,
			&.open {
				> div {
					.q-expander-content {
						border-bottom: $footer-expander-content-border-bottom;
					}

					.q-expander-button {
						background: $footer-expander-button-active-background-color;
						border-bottom: $footer-expander-button-border-bottom-active;
						border-top: $footer-expander-button-border-top-active;
					}
				}
			}

			&:hover {
				> div > .q-expander-button {
					background-color: $footer-expander-button-hover-background-color;
				}
			}
		}

		.q-content-well {
			background: $footer-content-well-background;
			padding: $footer-content-well-padding;
		}
	}

	@media #{$large-up} {
		.q-button {
			&.q-icon-only {
				margin-right: .1875rem;
			}
		}
	}
}
