// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// StaticImage
//
// This is the documentation for StaticImage. Edit staticImage.scss to update this documentation.
//
// markup:
// <p>Sample markup for StaticImage. Replace me with the real deal once you are done editing <strong>staticImage.html</strong> and <em>optionally</em> the staticImage.js file.</p>
//
// Styleguide 99.99

.q-static-image {
	position: relative;

	.q-rectangle-image {
		border-radius: 0;
		margin: 0;
		max-width: none;
		padding: 0;
	}

	.q-round-image {
		max-width: none;
	}

	.fadeout {
		background-color: $black;
		opacity: .4;
	}
}
