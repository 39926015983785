// Primary Navigation Component (nav_primary)
//
// This section shows each section of the Primary Navigation.
//
// [Styleguide 8.3]

// Mobile & Tablet Styles go Here
.q-nav-primary {

	// Baseline Styles
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	// Primary Nav Top Bar
	.q-top-bar {
		background: $nav-primary-top-bar-background-color;
		border-bottom: $nav-primary-top-bar-border-bottom;
		padding: map-get($nav-primary-top-bar-padding-map, small);
		position: relative;
		top: 0;
		transition: top #{$anim-fast}s ease;
		z-index: 120;
	}

	.q-top-bar-section {
		align-items: center;
		display: flex;
		margin: 0 auto;
		max-width: $gridwidth-max;
		padding: $nav-primary-top-bar-section-padding;
		position: relative;

		// Primary Nav Logo
		.q-nav-logo {
			display: flex;
			flex: $nav-primary-brand-logo-flex;
			max-width: $nav-primary-brand-logo-max-width;
			padding: $nav-primary-brand-logo-padding;
			width: $nav-primary-brand-logo-width;

			.q-nav-logo-image {
				height: 100%;
				width: 100%;
			}
		}

		// Primary Nav Links
		.q-nav-list-container {
			align-items: center;
			display: flex;
			flex-grow: 1;
			justify-content: map-get($nav-primary-nav-list-container-justify-content-map, small);
			margin: 0;

			.q-content-nav {
				border-left: map-get($nav-primary-content-nav-border-map, small);
				border-right: map-get($nav-primary-content-nav-border-map, small);
				flex-grow: 1;
				justify-content: map-get($nav-primary-content-nav-justify-content-map, small);
				margin: map-get($nav-primary-content-nav-margin-map, small);
				padding: map-get($nav-primary-content-nav-padding-map, small);
			}
		}

		.q-nav-list {
			display: flex;
		}

		.q-nav-list-item {
			padding: map-get($nav-primary-nav-list-item-padding-map, small);
			position: relative;

			&.flyout-opened {
				.q-nav-primary-link {
					color: $nav-primary-link-label-flyout-open-color;
				}
			}

			&.q-nav-more-menu-item {
				&.q-menu-open {
					.q-nav-primary-link {
						color: $nav-primary-link-label-flyout-open-color;
					}
				}
			}
		}

		.q-nav-primary-link {
			color: $nav-primary-link-label-color;
			display: block;
			line-height: $nav-primary-link-label-line-height;
			padding: 0;
			text-align: center;

			&:hover {
				color: $nav-primary-link-label-hover-color;
			}

			// Override q-link active and focus coloring of primary nav links
			&:active,
			&:focus {
				color: $nav-primary-link-label-color;
			}
		}

		.q-nav-primary-link-label {
			display: inline-block;
			font-size: map-get($nav-primary-link-label-font-size-map, small);
			letter-spacing: $nav-primary-link-label-letter-spacing;
			max-width: $nav-primary-link-label-max-width;
			position: relative;
		}

		// TODO: verify the sizes of the svg icons in the styleguide vs. the standard sizes we have in _variables.scss
		.q-icon,
		.q-icon-svg {
			display: block;
			height: map-get($nav-primary-top-bar-icon-size-map, small);
			margin: .625rem auto;
			text-align: center;

			&::before {
				font-size: .875rem;
			}
		}

		.q-icon-more {
			background: get-element(more) no-repeat;
			background-position: center;
			background-size: cover;
			box-sizing: border-box;
			width: 100%;
		}
	}

	// Primary Nav Collapsed
	.q-nav-collapsed {
		background: $white;
		display: none;
		max-height: 100vh;
		max-height: $nav-primary-collapsed-max-height;
		overflow: auto;
		position: fixed;
		width: 100%;
		z-index: 120;

		.q-nav-collapsed-link {
			display: inline-block;
			position: relative;
		}

		.q-icon,
		.q-icon-svg {
			display: inline-block;
			height: $nav-primary-collapsed-menu-icon-size;
			margin-right: .625rem;
			width: auto;

			&::before {
				font-size: 1.25rem;
			}
		}
	}

	// Primary Nav Overlay
	.q-nav-overlay {
		background-color: $nav-primary-overlay-background-color;
		display: none;
		height: 100%;
		left: 0;
		opacity: .75;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 120;
	}

	// Primary Nav Flyout
	.nav-flyout {
		background: $nav-primary-flyout-background-color;
		box-shadow: 0 .5rem .3125rem -.125rem $nav-flyout-box-shadow;
		display: none;
		left: 0;
		max-width: $gridwidth-max;
		overflow: auto;
		position: fixed;
		width: 100%;
		z-index: 120;

		&.stack-for-small {
			.q-button-back {
				display: block;
			}
		}
	}

	// Primary Nav Vehicle Selector
	.q-vehicle-selector {

		.q-button-back,
		.q-vehicle-selector-subtitle {
			display: block;
		}

		.q-button-back {
			background: $white;
			position: fixed;
			transform: translateZ(0);
			z-index: 120;
		}
	}

	.q-button-back {
		display: none;
		position: relative;

		.q-back-button-label {
			margin-left: $nav-primary-flyout-back-button-label-margin-left;
			padding-left: $nav-primary-flyout-back-button-label-padding-left;
		}
	}

	// Animations and effects
	.q-sticky.top .sticky-child {
		left: 0;
		margin-left: 0;
		width: 100%;
	}

	@media #{$large-down} {
		.q-vehicle-selector {
			background: $nav-primary-flyout-background-color;
			display: none;
			left: -100%;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 120;
		}
	}

	@media #{$large-up} {
		.q-top-bar {
			padding: map-get($nav-primary-top-bar-padding-map, large);
		}

		.q-top-bar-section {
			.q-icon,
			.q-icon-svg {
				height: map-get($nav-primary-top-bar-icon-size-map, large);

				&::before {
					font-size: 1.25rem;
				}
			}

			.q-nav-list-container {
				.q-content-nav {
					border-left: map-get($nav-primary-content-nav-border-map, large);
					border-right: map-get($nav-primary-content-nav-border-map, large);
					justify-content: map-get($nav-primary-content-nav-justify-content-map, large);
					margin: map-get($nav-primary-content-nav-margin-map, large);
					padding: map-get($nav-primary-content-nav-padding-map, large);
				}
			}

			.q-nav-list-item {
				margin: map-get($nav-primary-nav-list-item-margin-map, large);
			}
		}

		.nav-flyout {
			&.stack-for-small {
				.q-button-back {
					display: none;
				}
			}
		}
	}

	@media #{$large-only} {
		.nav-flyout {
			&.stack-for-medium {
				.q-button-back {
					display: block;
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-button-back {
			display: none;
		}

		.q-top-bar {
			padding: map-get($nav-primary-top-bar-padding-map, xlarge);
		}

		.q-top-bar-section {
			.q-nav-list-container {
				justify-content: map-get($nav-primary-nav-list-container-justify-content-map, xlarge);

				.q-content-nav {
					justify-content: map-get($nav-primary-content-nav-justify-content-map, xlarge);
					margin: map-get($nav-primary-content-nav-margin-map, xlarge);
					padding: map-get($nav-primary-content-nav-padding-map, xlarge);
				}
			}

			.q-nav-primary-link-label {
				font-size: map-get($nav-primary-link-label-font-size-map, xlarge);
			}

			.q-nav-list-item {
				padding: map-get($nav-primary-nav-list-item-padding-map, xlarge);
			}

			.q-icon,
			.q-icon-svg {
				display: inline-block;
				margin: 0;
			}
		}

		.q-nav-collapsed {
			display: none !important; // sass-lint:disable-line no-important
		}

		.nav-flyout {
			left: 50%;
			transform: translateX(-50%);
		}

		.q-vehicle-selector {
			.q-nav-vehicle-selector-content {
				padding-top: 0 !important; // sass-lint:disable-line no-important
			}

			.q-button-back,
			.q-vehicle-selector-subtitle {
				display: none !important; // sass-lint:disable-line no-important
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-top-bar-section {
			.q-nav-primary-link-label {
				font-size: map-get($nav-primary-link-label-font-size-map, xxlarge);
			}
		}
	}
}
