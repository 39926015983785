// Disclosure Global Styles
* {
	.q-invert {
		.show-disclosure {
			color: $brand-invert-link-color;
			cursor: pointer;
			text-decoration: underline;

			&:hover {
				color: $brand-invert-link-color-hover;
			}

			&.active {
				color: $disclosure-link-invert-active-color;
			}
		}
	}

	.show-disclosure {
		color: $brand-link-color;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: $brand-link-color-hover;
		}

		&.active {
			color: $disclosure-link-active-color;
		}
	}
}

.q-headline {
	.show-disclosure {
		text-decoration: none;

		.q-disclosure-print-hide {
			border-bottom: $disclosure-char-border-bottom;
			text-decoration: $disclosure-text-decoration;
		}
	}

	.q-invert {
		.show-disclosure {
			&:hover {
				color: $brand-invert-link-color-hover;
			}
		}
	}
}
