// Multimedia Teaser Collection
//
// This is the documentation for Multimedia Teaser Collection. Edit multimedia_teaser_collection.scss to update this documentation.
// Styleguide 99.99

.q-multimedia-teaser-collection {
	.row {
		display: flex;
		flex-flow: row wrap;
	}

	.q-multimedia-teaser {
		margin: 1.25rem;
	}
}
