.q-dealer-locator {
	.q-radio {
		display: inline-block;
		left: -999rem;
		position: absolute;
		top: -999rem;

		+ .q-radio-label {
			color: $dl-radio-label-color;
			font-size: $dl-radio-label-font-size;
			font-weight: $dl-radio-label-font-weight;

			&::before {
				border-color: $dl-radio-label-color;
				color: $dl-radio-label-color;
			}
		}

		&:checked + .q-radio-label::before {
			border-color: $dl-radio-label-color;
			color: $dl-radio-label-color;
		}
	}

	.q-dealer-search {
		flex: 1 0 100%;

		label {
			color: $dl-search-label-color;
		}

		.q-input {
			// sass-lint:disable-block no-vendor-prefixes
			&::-ms-clear,
			&::-ms-reveal {
				display: none;
				height: 0;
				width : 0;
			}
		}

		.dl-search-field {
			margin: 0;
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}

		.searchby-label {
			@extend %q-descriptive2;

			margin-bottom: 1rem;
		}

		.dealer-search-by {

			.q-search-type {
				flex: 1 0 100%;

				.q-radio-button {
					display: none;

					+ .q-radio-label {
						@extend %q-radio-button;

						padding-top: $dl-radio-button-padding-top;
					}

					&:checked {
						+ .q-radio-label {
							@extend %q-radio-button-active;
						}
					}
				}

				.q-radio-label {
					&::before {
						display: none;
					}
				}
			}
		}

		.dealer-search {
			position: relative;
			width: 100%;
			z-index: 20;

			.search-bar {
				display: flex;
			}

			.search-field {
				flex: 1;
				position: relative;

				&.q-no-geolocate {
					width: 88%;
				}

				input {
					border-color: $dl-search-button-border-color;
					border-width: $dl-filter-dropdown-border-width;
					color: $dl-search-text-color;
					font-size: $dl-search-text-font-size;
					height: $dl-clear-searchbox-height;
					line-height: 1;
					width: 100%;

					&::placeholder {
						font-size: $dl-search-text-font-size;
					}

					&.q-input-city-state {
						border-right: $dl-filter-dropdown-border-width solid $dl-search-button-border-color;
					}
				}
			}

			.location-button {
				background-color: $dl-dealer-search-button-background-color;
				flex: none;
				height: $dl-location-button-height;
				width: $dl-location-button-width;
			}

			.dealer-search-box {
				float: left;
				margin-right: $dl-location-button-width;
				width: 88%;

				&::placeholder {
					color: $alto-grey;
					font-family: $font-family-sans;
					font-size: 1.125rem;
				}
			}

			.dealer-search-box,
			.dealer-name-search-box {
				margin-bottom: 1rem;
				padding: .5rem 1rem;
			}

			.dealer-search-button {
				background-color: $dl-dealer-search-button-background-color;
				flex: none;
				height: $dl-location-button-height;
				opacity: 1;
				width: $dl-location-button-width;

				&:hover {
					background-color: $dl-dealer-search-button-background-color-hover;
					cursor: pointer;
				}
			}

			.clear-searchbox {
				line-height: $dl-clear-searchbox-height;
				position: absolute;
				right: 2rem;
			}

			.search-spinner {
				transform: scale(.4);
			}

			.error {
				color: $alert-color;
				font-size: .75rem;
				margin-bottom: 1rem;
			}
		}

	}

	.dealer-locator-widget {
		.q-dealer-search {
			float: right;

			.dealer-search {
				padding-right: 0;

				.search-field {
					input {
						height: $dl-widget-dealer-search-field-input-height;
					}
				}

				.location-button,
				.dealer-search-button {
					height: $dl-widget-dealer-search-button-height;
					width: $dl-widget-dealer-search-button-width;
				}
			}

			.dl-search-field {
				margin: 0;
			}

			.clear-searchbox {
				line-height: 2.3;
				position: absolute;
				right: 2rem;
			}
		}
	}
}

@media #{$medium-up} {
	.q-dealer-locator {
		.q-dealer-locator-widget {
			.dealer-search {
				padding-right: 1rem;
				width: 18.75rem;
			}
		}
	}
}

@media #{$large-up} {
	.q-dealer-locator {
		.q-dealer-search {
			.dealer-search-by {
				.q-search-type {
					flex: $dl-search-by-flex-medium;
				}
			}
		}
	}
}

@media #{$xlarge-up} {
	.q-dealer-locator {
		.dealer-locator-widget {
			.q-dealer-search {
				flex: $dl-widget-dealer-search-flex;
				order: 2;

				.dealer-search {
					display: initial;
					padding-right: 0;
				}
			}
		}

		.q-dealer-search {
			.dealer-search-by {
				.q-search-type {
					flex: 1;
				}
			}
		}
	}
}
