// Allows the use of rem-calc() or lower-bound() in your variables & settings
@import '../../solar-aem-ui/scss/variables';
@import '../../solar-aem-ui/scss/functions';
@import '../../gmwp-aem-ui/scss/foundation/functions';

// Separator
$separator-primary: $grey-mid;
$separator-secondary: $grey;
$separator-tertiary: $grey-dark;
$separator-margin: .3125rem;

// Multimedia Scroller
$mms-slick-dots-color: transparent;

// Forms
$form-background: $white;
$form-border-primary: $grey-mid;
$form-interactive: $grey;
$form-interactive-hover: $grey-dark;
$form-interactive-disabled: $grey-light;

// Table
$table-brand-subheading: $grey-dark;
$table-text-padding-bottom: 0;

// Trim Info
$trim-info-headline-style: q-headline1;
$trim-info-text-price-float: right;
$trim-info-text-price-letter-spacing: .09rem;
$trim-info-text-from-visibility: hidden;
$trim-info-text-from-display: none;
$trim-info-text-msrp-display: block;
$trim-info-text-msrp-visibility: visible;
$trim-info-body-description-style: q-body2;
$trim-info-full-container-float: left;
$trim-info-full-container-width: 100%;
$trim-info-half-container-float: left;
$trim-info-half-container-text-transform: none;
$trim-info-half-container-width: 50%;
$trim-info-button-secondary-margin-top: 1.25rem;
$trim-info-button-secondary-padding-left: .625rem;
$trim-info-button-primary-margin-top: 1.25rem;
$trim-info-button-primary-margin-padding-right: .625rem;

// Expander
$expander-margin-right: 0;
$expander-collapse-anim-speed: .01;
$expander-expand-anim-speed: .01;

//Tertiary Navigation
$tertiary-button-padding: 1.875rem 1.75rem;

%tertiary-nav-button {
	@extend %list-item-tertiary;
}

// Video Youtube
%video-info-font {
	@extend %q-caps !optional;
}

//Engine Info
$engine-information-transistion: width .25s ease, left .25s ease;

//Transmission Info
$transmission-info-description-padding-top: .625rem;
$transmission-info-transition: width .25s ease, left .25s ease;
$transmission-info-text-padding-top: .625rem;
// Radio button
$radio-button-bg-color: $grey;

// disclosure
$disclosure-close-insert-icon-mq-use: false;
$disclosure-toast-height: 1.25rem;
$disclosure-toast-font-size: 1.25rem;
$disclosure-toast-right-map: (
	small: 1.25rem,
	large: 3.875rem
);
$disclosure-close-small-icon-right: 1.875rem;
$disclosure-close-small-icon-top: 1.75rem;
$disclosure-modal-close-right: 4.375rem;
$disclosure-modal-close-top: 1.875rem;
$disclosure-toast-padding-map: (
	small: .875rem .875rem,
	large: 1.25rem 4.875rem
);
$disclosure-modal-content-padding-map: (
	small: 2.5rem 4.688rem .9375rem .875rem,
	large: 1.875rem 11.25rem 3.75rem 3.75rem,
	xlarge: 2.5rem 11.25rem 3.75rem 8.75rem
);
$gl-disclosure-right-position-small: calc(.625rem + 17px);
$gl-disclosure-padding-small: 0 0 .3125rem;
//Secondary Nav
$nav-secondary-dropdown-box-width-map: (
	xlarge: 35%,
	large: 70%,
	medium: 100%,
	small: 100%
);

$nav-secondary-nav-title-year-toggle-width-map: (
	xlarge: 268px,
	large: 268px,
	medium: 85%,
	small: 85%
);

//vehicle-info-block
$vehicle-block-title-display-map: (
	small: inline-block,
	large: inline-block
);
$vehicle-block-title-padding-bottom-map: (
	small: .25rem,
	large: .3125rem
);
$vehicle-block-title-width-map: (
	small: 100%,
	large: 100%
);
$vehicle-block-price-display-map: (
	small: inline-block,
	large: inline-block
);
$vehicle-block-price-width-map: (
	small: 100%,
	large: 100%
);

// Current Offers
$co-item-tile-bg-color: $grey-dark;
$co-item-tile-text-color: $white;
$co-item-tile-divider-text: 'or';
$co-item-tile-font-size: 1rem;
$co-item-tile-font-weight: $font-weight-normal;
$co-item-tile-text-align: center;
$co-item-tile-text-transform: uppercase;
$co-item-tile-details-bg-color: $white;
$co-item-tile-details-border: 1px solid $grey;
$co-postalcode-location-height: 3.75rem;
$co-postalcode-location-button-width: 3.75rem;
$co-postalcode-location-bg-color: $grey;
$co-postalcode-location-bg-color-hover: $grey;
$co-postalcode-submit-bg-color: $grey;
$co-postalcode-submit-height: 3.75rem;
$co-postalcode-submit-width: 3.75rem;
$co-postalcode-submit-bg-color-disabled: $grey;
$co-postalcode-submit-bg-color-hover: $grey;

// Global Forms
$gf-vehicle-selector-headline-font: q-descriptive1;
$gf-vehicle-selector-subheadline-font: q-body2;
