.q-hmf-filter {
	@extend %hmf-filter;

	border-bottom: $hmf-filter-border-bottom;
	display: inline-block;
	margin-bottom: $hmf-filter-margin-bottom;
	padding-bottom: $hmf-filter-padding-bottom;
	width: 100%;

	&.q-last {
		border-bottom: 0;
	}

	.q-hmf-button-header {
		.q-hmf-filter-title {
			@extend %#{$hmf-filter-title};
			margin-bottom: $hmf-filter-title-margin-bottom;
		}
	}

	.link-list,
	.q-list {
		flex: 0 1 100%;
		list-style: none;
		margin-left: 0;

		&.active {
			display: inherit;
		}

		.gm-filter-by-item {
			background-color: $hmf-filter-background-color;
			float: none;
			line-height: 1.25rem;
			margin: 0;
			padding: 0;
			position: relative;

			.q-filter-label {
				@extend %#{$hmf-filter-label};

				display: inline-block;
				margin-left: 0;
				padding-top: .125rem;
			}

			.q-radio-button {
				display: none;

				+ .q-filter-label {
					@extend %q-radio-button;
				}
			}

			.q-checkbox {
				display: none;

				+ .q-filter-label {
					@extend %hmf-check-box;
				}
			}

			.icon {
				&::before {
					font-size: 1.25rem;
				}
			}

			.q-radio-label {
				&::before {
					display: none;
				}
			}

			&.active {
				.q-radio-button {
					+ .q-filter-label {
						@extend %q-radio-button-active;
					}
				}

				&:not(.filter-all) {
					padding: 0;

					.q-label {
						.icon {
							display: none;
						}
					}
				}

				.q-icon-checkbox {
					@include icon(checkmark);
					color: $hmf-checkbox-active-color;
					font-size: $hmf-checkbox-check-size;
					margin: $hmf-filter-checkbox-margin;
					position: absolute;
					z-index: 2;
				}
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	.gm-filter-by-item {
		.icon-container {
			text-align: center;
		}
	}

	.q-range-slider {
		margin-bottom: .5rem;

		.range-slider {
			background-color: $hmf-filter-range-background-color;
			border: 1px solid $hmf-filter-range-border-color;
			height: $hmf-filter-range-height;
		}

		.slider-container {
			height: 2.25rem;
			padding-left: .5rem;
			padding-right: 1rem;

			.selected-filter-label {
				@extend %#{$hmf-slider-label};

				&.q-min {
					margin-left: -.5rem;
				}

				&.q-max {
					float: right;
					margin-right: -.5rem;
				}
			}
		}

		.ui-slider {
			.ui-slider-range {
				border: 0;
				display: block;
				height: 100%;
				position: absolute;
				top: 0;
				z-index: 1;
			}

			.ui-slider-range-max {
				right: 0;
			}

			.ui-slider-range-min {
				left: -.5rem;
			}
		}

		.ui-widget-header {
			background-color: $hmf-filter-range-header-background-color;
		}

		.ui-slider-horizontal {
			.ui-slider-handle {
				background-color: $hmf-filter-range-handle-background-color;
				border:  solid $brand-primary-color $hmf-filter-range-handle-border-width;
				border-radius: 50%;
				height: $hmf-filter-range-handle-height;
				margin-left: -.625rem;
				position: absolute;
				// Center the slider handle by adding height and border of the handle minus the height of the slide bar / 2 to find the middle
				top: -($hmf-filter-range-handle-height - $hmf-filter-range-height + $hmf-filter-range-handle-border-width / 2) / 2;
				width: $hmf-filter-range-handle-height;
				z-index: 52;

				&:hover {
					background-color: $hmf-filter-range-handle-hover-color;
				}
			}
		}
	}

	.q-hmf-button-group {
		background: $hmf-filter-button-background-color-mobile;
		display: flex;
		flex-flow: row wrap;

		.q-hmf-button-header {
			background-color: $hmf-filter-button-header-background-color;
			flex: 1 100%;
		}

		.q-hmf-button-container {
			display: flex;
			flex: 1 100%;
			flex-wrap: wrap;

			.q-hmf-button-item {
				border-bottom: .25rem solid transparent;
				cursor: pointer;
				flex: 1 auto;
				padding: .5rem .5rem .25rem;
				text-align: center;

				.q-filter-label {
					@extend %#{$hmf-filter-label};
				}

				&.active,
				&:hover {
					border-bottom: .25rem solid $hmf-filter-button-active-border-color;
				}

				&.active {
					background-color: $hmf-filter-button-active-background-color;
				}

				.icon {
					font-size: 1.5rem;
				}
			}
		}
	}

	@media #{$medium-up} {
		.q-expander-content {
			flex: 1 100%;
		}

		.q-expander-header {
			border: 0;
			padding: .5rem;

			.q-label {
				font-size: .875rem;
				font-weight: $font-weight-bold;
			}
		}

		.q-hmf-button-group {
			background: $hmf-filter-button-background-color;
		}
	}

	// Media Queries go here
	@media #{$small-only} {
		.q-range-slider {
			background-color: $white;

			.slider-container {
				height: 3.75rem;
				padding-top: .625rem;
			}
		}

		.q-expander-content {
			width: 100%;
		}
	}
}
