/*
	Component specific styles for the 'Vehicle Information Tile' component
*/
%vehicle-info-headline {
	padding-bottom: $vehicle-info-tile-text-headline-padding-bottom;

	.q-vehicle-info-tile-type {
		@extend %#{$vehicle-info-tile-type-typography};

		color: $vehicle-info-tile-type-color;
	}
}

%vehicle-info-headline-tile-type {
	@extend %vehicle-info-headline;

	.q-vehicle-info-tile-type {
		text-transform: uppercase;
	}
}

%vehicle-info-price {
	@include vehicle-info-price-style;

	.q-from-label {
		@extend %#{$vehicle-info-from-label-typography};

		color: $vehicle-info-tile-from-label-price-color;
		font-weight: $vehicle-info-tile-price-from-label-font-weight;
		margin-bottom: $vehicle-info-tile-from-label-margin-bottom;
		margin-top: $vehicle-info-tile-from-label-margin-top;
		text-transform: $vehicle-info-tile-price-label-text-transform;

		p {
			font-weight: inherit;
		}
	}

	.q-vehicle-info-as-shown-price {
		@extend %#{$vehicle-info-tile-price-as-shown-extend};
		color: $vehicle-info-tile-price-as-shown-color;
		font-weight: $vehicle-info-tile-price-as-shown-font-weight;
		line-height: $vehicle-info-tile-price-as-shown-line-height;
		margin-bottom: $vehicle-info-tile-price-as-shown-margin-bottom;

		p {
			color: inherit;
			display: inline;
			font-size: inherit;
			font-weight: $vehicle-info-tile-price-as-shown-font-weight;
		}
	}
}

.q-vehicle-tile {
	cursor: pointer;

	.q-vehicle-info-tile-image-container {
		display: inline-block;
		width: 49%;

		img {
			float: left;
			height: auto;
			max-width: 100%;
		}
	}

	.q-vehicle-information-tile {
		border-bottom: $vehicle-info-tile-border-bottom;
		margin: 0;
		padding: map-get($vehicle-info-tile-padding-map, small);
		width: 100%;

		.q-vehicle-info-tile-image-container {
			margin-top: $vehicle-info-tile-margin-top;
		}

		.q-vehicle-info-text {
			display: inline-block;
			margin-bottom: $vehicle-info-tile-margin-bottom;
			padding: map-get($vehicle-info-tile-text-padding-map, small);
			vertical-align: bottom;
			width: 49%;

			.q-headline {
				@extend %vehicle-info-headline-tile-type;
			}

			.q-vehicle-info-text-price {
				@extend %vehicle-info-price;
			}
		}

		&:hover {
			&::before {
				@include vehicle-info-tile-active-style(100%, 0, .25rem, $border-left: $vehicle-info-tile-hover-before-border);
			}
		}
	}

	/*
		Component specific styles for the 'Vehicle Information Tile With Year (Secondary Nav) Component
	*/
	.q-vehicle-information-tile-wyear-secondarynav,
	.q-vehicle-information-tile-wyear-helpme {
		border-bottom: $hmf-border;
		margin: 0;
		padding: map-get($vehicle-info-tile-padding-map, small);
		width: 100%;

		.q-vehicle-info-text {
			display: inline-block;
			margin-bottom: 0;
			padding: map-get($vehicle-info-tile-text-padding-map, small);
			vertical-align: bottom;
			width: 49%;

			.q-vehicle-info-text-year {
				.q-vehicle-info-carline-year {
					@extend %#{$vehicle-info-carline-year-typography};

					color: $vehicle-info-tile-year-color;
				}
			}

			.q-headline {
				@extend %vehicle-info-headline-tile-type;
			}

			.q-vehicle-info-text-model {
				@extend %vehicle-info-headline-tile-type;
			}

			.q-vehicle-info-text-price {
				@extend %vehicle-info-price;
			}
		}

		&:hover {
			&::before {
				@include vehicle-info-tile-active-style(100%, 0, .25rem, $border-left: $vehicle-info-tile-hover-before-border);
			}
		}
	}

	.q-vehicle-information-tile-wyear-helpme {
		.q-vehicle-info-text {
			margin-bottom: $vehicle-info-tile-helpme-margin-bottom;

			.q-vehicle-info-text-price {
				@extend %vehicle-info-price;
			}
		}
	}
	@media #{$medium-up} {
		.q-vehicle-information-tile-wyear-secondarynav {
			.q-vehicle-info-tile-image-container {
				width: 59%;
			}

			.q-vehicle-info-text {
				width: 39%;

				.q-vehicle-info-text-year {
					padding-bottom: .25rem;
				}
			}
		}

		/*
			Component specific styles for the 'Vehicle Information Tile With Year (Help me find) Component
		*/
		.q-vehicle-information-tile-wyear-helpme {
			border: 0;
			padding: 0;

			&:hover {
				&::before {
					@include vehicle-info-tile-active-style(100%, 0, .25rem, $border-left: $vehicle-info-tile-hover-before-border);
					border-left: 0;
				}
			}

			&:hover {
				&::after {
					border-bottom: $vehicle-info-tile-hover-border-bottom;
					bottom: $vehicle-info-tile-hover-position-bottom;
					left: $vehicle-info-tile-hover-position-left;
					width: $vehicle-info-tile-hover-width;
				}
			}

			&::after {
				@include vehicle-info-tile-active-style(0, 50%, 0, $border-bottom: $vehicle-info-tile-hover-before-border, $right: 0, $transition: $vehicle-info-tile-hover-border-bottom-transition);
			}

			.q-vehicle-info-tile-image-container {
				padding-bottom: $vehicle-info-tile-image-container-padding-bottom;
				width: 100%;
			}

			.q-vehicle-info-text {
				width: 100%;
			}
		}
	}

	@media #{$xlarge-up} {
		.q-vehicle-information-tile {
			border-bottom: 0;
			padding: map-get($vehicle-info-tile-padding-map, large);

			&:hover {
				&::before {
					border-left: 0;
				}
			}

			&:hover {
				&::after {
					border-bottom: $vehicle-info-tile-hover-border-bottom;
					bottom: $vehicle-info-tile-hover-position-bottom;
					left: $vehicle-info-tile-hover-position-left;
					width: $vehicle-info-tile-hover-width;
				}
			}

			&::after {
				@include vehicle-info-tile-active-style(0, 50%, 0, $border-bottom: $vehicle-info-tile-hover-before-border, $right: 0, $transition: $vehicle-info-tile-hover-border-bottom-transition);
			}

			.q-vehicle-info-tile-image-container {
				padding-bottom: .3125rem;
				width: 100%;
			}

			.q-vehicle-info-text {
				padding: map-get($vehicle-info-tile-text-padding-map, large);
				width: 100%;
			}
		}

		.q-vehicle-information-tile-wyear-helpme {
			padding: map-get($vehicle-info-tile-padding-map, large);

			.q-vehicle-info-text {
				padding: map-get($vehicle-info-tile-text-padding-map, large);
			}
		}
	}
}
