// Table
//
// This is the documentation for Table. Edit table.scss to update this documentation.
//
// markup:
// <p>Sample markup for Table. Replace me with the real deal once you are done editing <strong>table.html</strong> and <em>optionally</em> the table.js file.</p>
//
// Styleguide 99.99

.q-table {
	@include clearfix;

	padding-left: 5%;
	padding-right: 5%;

	.q-tablelayout {
		border: 0;
		width: 100%;
	}

	.q-table-cell {
		padding: .625rem 0 .625rem 1.875rem;
		vertical-align: middle;

		& > div {
			display: inline-block;
			vertical-align: middle;
		}

		&.q-cell-vs {
			background-color: $white;
			padding-left: 0;
			padding-top: 0;
			text-align: center;

			.q-image-svg {
				margin-right: 0;
			}

			.image-svg-container {
				height: $svg-icon-auto;
				position: relative;
				top: $table-cell-vs-top;
				width: $svg-icon-auto;
			}
		}

		&.q-cell-cars {
			background-color: $white;
			border-right: solid 1px $table-cell-border-right-color;
			padding: 0 1.875rem;
			text-align: center;
			vertical-align: bottom;

			&:last-child {
				border-right: 0;
			}
		}

		.q-button {
			padding: 0;
		}

		.q-image-svg {
			margin-right: .65rem;
		}

		.image-svg-container {
			width: $svg-icon-medium;
		}
	}

	.q-table-header {
		background-color: $white;
		border-right: solid 1px $table-cell-border-right-color;
		padding: .625rem 1.875rem;
		text-align: left;

		&:last-child {
			border-right: 0;
		}
	}

	.q-margin-table {
		margin-bottom: .625rem;
		margin-top: 1.4rem;
	}

	.q-text {
		p {
			margin: $table-text-margin;
			padding-bottom: $table-text-padding-bottom;
			padding-top: $table-text-padding-top;
		}
	}

	.q-row-headline {
		background-color: $table-row-headline-bgcolor;
	}

	.q-row-content {
		background-color: $white;

		td {
			border-right: solid 1px $table-cell-border-right-color;

			&:last-child {
				border-right: 0;
			}
		}
	}

	.q-table-content-right {
		padding-right: 1.875rem;
		text-align: right;

		.q-svg-icon {
			height: 1.25rem;
			margin-right: .625rem;
			width: 1.25rem;
		}
	}

	.q-subtitle {
		.q-table-cell {
			background: $table-brand-subheading-bgcolor;
			padding-bottom: $table-brand-subheading-padding-bottom;
			padding-top: $table-brand-subheading-padding-top;
		}
	}

	.q-svg-icon-table {
		float: left;
		margin-right: 1px;
		position: relative;
		top: .1875rem;
	}

	.q-svg-icon {
		display: inline-block;
		height: 3.125rem;
		width: 3.125rem;
	}

	.q-compare-button {
		width: 100%;
	}

	@media #{$medium-up} {
		.q-margin-table {
			margin-top: 3rem;
		}
	}

	@media #{$large-up} {
		.q-table-cell-vs {
			.q-svg-icon {
				@include svg-icon-size(50);
				top: 1.25rem;
			}
		}
	}

	@media #{$xlarge-up} {
		.q-table-cell-vs {
			.q-svg-icon {
				position: relative;
				top: 2.5rem;
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-margin-table {
			margin-top: 3.5rem;
		}
	}
}
