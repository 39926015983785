//Brand Specific Typography Rules
//1. Supserscript/Subscript Sitewide Styles

// Per the Chevrolet Styleguide, at X-small, body2 takes on the appearance of body1.
// The sizes are taken care of but the color needs to be overwritten here
%q-body2 {
	@media #{$small-only} {
		color: $brand-body1-color;
	}
}
