.q-video {
	.q-autosize {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		cursor: pointer;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		&.q-has-play-button {
			&::after {
				@include gmwp-transition((opacity, transform), $anim-fast, ease-out); // This is were the transition is applied, no need for another.

				background-image: get-element(video-play-button);
				background-repeat: no-repeat;
				background-size: 100%;
				content: '';
				height: $video-play-icon-height;
				left: 50%;
				opacity: $video-play-icon-opacity;
				padding-bottom: $video-play-icon-padding-bottom; // This must be the same value as icon width, if the icon changes on hover. And assuming the icon is square.
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%) scale(1);
				width: $video-play-icon-width;
			}

			&:hover {
				&::after {
					opacity: $video-play-icon-hover-opacity;
					// Using scale to transform the icon on hover instead of using width
					transform: translate(-50%, -50%) scale($video-bc-play-icon-hover-scale);
				}
			}

			.q-video-player {
				display: none;
			}

			.q-posterframe {
				display: block;
			}
		}
	}

	.q-posterframe {
		display: none;
	}

	.q-video-player {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
	}

	.q-video-info-container {
		@extend %#{$video-info-title-typography};

		padding: $video-info-padding;
	}

	.q-video-info-title {
		font-weight: $video-title-font-weight;
		letter-spacing: $video-title-letter-spacing;
		padding-right: $video-title-padding;
		text-transform: $video-title-text-transform;
	}
}
