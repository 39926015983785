.q-transmission-info {
	@include grid-row($behavior: collapse);
	display: inline-block; // 'inline-block' will cause element to position incorrectly
	padding: $transmission-info-padding;
	position: relative;

	.q-transmission-info-link {
		cursor: default;
	}

	.q-transmission-info-text {
		padding: $transmission-info-text-padding;

		.q-transmission-info-text-headline {
			@extend %#{$transmission-info-headline-style};
			color: $transmission-info-headline-color;
			text-transform: uppercase;
		}

		.q-transmission-info-text-price {
			padding-top: $transmission-info-text-padding-top;

			.q-transmission-info-descriptive-price {
				@extend %#{$transmission-info-price-style};
				font-weight: $transmission-info-price-font-weight;
			}
		}
	}

	.q-transmission-info-description {
		@extend %#{$transmission-info-description-style};
		padding-bottom: 0;
		padding-top: $transmission-info-description-padding-top;

		.q-transmission-info-description-header {
			@extend %#{$transmission-info-description-header-style};
			font-weight: $transmission-info-description-header-font-weight;
		}

		.q-transmission-info-description-detail {
			padding-bottom: $transmission-info-description-detail-padding-bottom;
		}
	}

	.q-transmission-info-grid {
		@include grid-column(6);
		padding-left: 0;
		padding-right: 0;
		position: relative;
	}

	.q-transmission-info-details-container {
		padding: map-get($transmission-info-details-container-padding-map, small);
	}

	.q-transmission-info-button-container {
		float: left;
		padding-top: 1rem;
		width: 100%;
	}

	@media #{$large-up} {
		&::after {
			border-bottom: $transmission-info-border;
			bottom: $transmission-info-position-bottom;
			content: '';
			display: block;
			left: 50%;
			position: absolute;
			transition: $transmission-info-transition;
			width: 0;
		}

		&:hover {
			&::after {
				bottom: $transmission-info-hover-position-bottom;
				left: 0;
				width: 100%;
			}

			.q-transmission-info-text {
				.q-transmission-info-text-headline {
					color: $transmission-info-headline-hover-color;
				}
			}
		}

		.q-transmission-info-link {
			cursor: pointer;
		}

		.q-transmission-info-button {
			display: none;
		}

		.q-transmission-info-details-container {
			padding: map-get($transmission-info-details-container-padding-map, large);
		}
	}
}
