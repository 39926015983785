/* extend solar nav_secondary for brand-specific styles */

.q-nav-anchor {
	.q-nav-container {
		.q-year-toggle {
			float: right;
			margin-left: .75rem;
			order: 2;

			.q-year-toggle-list-item {
				color: $grey;
				line-height: 1.8rem;

				a {
					color: inherit;
					display: block;
					font-size: .75rem;
					font-weight: $font-weight-bold;
					letter-spacing: .075rem;
					padding-left: .625rem;
					padding-right: .625rem;
					text-align: center;
				}

				&:hover {
					background-color: $gold;
					color: $black;
				}

				&.active {
					background-color: $grey;
					color: $white;

					&:hover {
						background-color: $grey;
						color: $white;
					}
				}
			}
		}

		.q-sibling-nav-container {
			margin-left: auto;
			order: 3;

			.q-sibling-nav-list {
				li:not(.q-cta-list-item) {
					a {
						&::after {
							@include easein-middle;
						}
					}
				}
			}
		}

		&>.q-secondary-nav-button {
			order: 1;

			&.q-dropdown-has-content {
				background-color: $white;
				cursor: pointer;

				&::before {
					/*	Since we are drawing an arrow, using px instead of rem so arrows stay consistent.
						Please do not convert to rems
					*/
					@include icon-triangle(down, 16px, 10px, $grey);
					bottom: 38%;
					position: absolute;
					right: 1.2rem;
				}
			}

			&.q-dropdown-open {
				&::before {
					/*	Since we are drawing an arrow, using px instead of rem so arrows stay consistent.
						Please do not convert to rems
					*/
					@include icon-triangle(up, 16px, 10px, $gold);
					bottom: 38%;
				}
			}
		}

		.q-sibling-nav-active-anchor {
			border-left-color: $gold;
		}

		.q-sibling-nav-container {
			.q-sibling-nav-list {
				li:not(.q-cta-list-item) {
					a {
						&::after {
							@include easein-middle;
						}
					}
				}
			}
		}
	}


}
