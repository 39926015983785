.q-colorizer {
	.tabs-content {
		margin-bottom: 0;

		.content {
			padding: 0;
		}
	}

	@media #{$large-up} {
		.q-colorizer-info {
			justify-content: space-between;

			.q-colorizer-text-container {
				width: auto;
			}

			.q-colorizer-button {
				width: auto;

				.q-button {
					padding-left: 2rem;
					padding-right: 2rem;
				}
			}
		}

		.q-colorizer-chipsets {
			padding-top: 1.25rem;
		}
	}
}
