// Navigation Global
//
// These styles are used for global navigation.
//
// markup:
//
// Styleguide 8.1

nav {
	a {
		text-decoration: none;
	}
}

/* Foundation Top Bar */
.top-bar {
	overflow: hidden;

	.toggle-topbar {
		&.menu-icon {
			a {
				span::after {
					box-shadow: 0 0 0 .125rem $black, 0 .4375rem 0 .125rem $black, 0 .875rem 0 .125rem $black;
					width: 1.375rem;
				}
			}
		}
	}
}

.top-bar-section {
	padding: 0;

	ul {
		li > a {
			display: inline;
			font-family: $font-family-sans;
			font-size: 1.125rem;
		}
	}

	.first-level {
		li > a {
			font-weight: $font-weight-bold;
		}
	}

	.utility {
		li > a {
			font-weight: normal;
		}
	}
}

/* Foundation Off-Canvas */
$off-canvas-title-bg: $white;
$off-canvas-title-color: $off-canvas-bg;
$off-canvas-title-border-top: .1875rem solid $yellow;
$off-canvas-title-border-bottom: .0625rem solid $dark-grey;
$off-canvas-title-margin: 0;
$off-canvas-title-padding: .5rem 1rem;
$off-canvas-title-font-size: 1.125rem;
$off-canvas-search-button-width: 6.75rem;

.left-off-canvas-menu {
	overflow-y: hidden;

	ul {
		&.off-canvas-list {
			li {
				&.has-submenu > a {
					@include icon(arrow-right, after) {
						float: right;
						margin-top: .25rem;
					}
				}
			}
		}
	}
}

.left-submenu {
	.back > a {
		@include icon(arrow-left);

		padding: $off-canvas-link-padding;
	}
}

.right-small {
	width: auto;
}

.off-canvas-list {
	font-size: 1.25rem;
	text-transform: uppercase;

	a {
		text-decoration: none;
	}

	form {
		display: table;
		table-layout: fixed;
		width: 100%;

		input {
			color: $off-canvas-bg;

			font-weight: $font-weight-bold;
			line-height: 1rem;

			padding: .75rem 0 .75rem .9375rem;
			width: 100%;
		}

		input,
		.input-group-btn {
			background-color: $white;
			border: medium none;
			border-radius: 0;
			box-shadow: none;
			display: table-cell;
			margin: 0;
		}

		.input-group-btn {
			width: 2.5rem;
		}

		.button-search {
			background-color: transparent;
			border: medium none;
			box-shadow: none;
			font-size: .75rem;
			line-height: .75rem;
			margin: 0;
			padding: 0;
			position: relative;
			text-align: center;
			width: 100%;

			.icon {
				color: $off-canvas-bg;
			}
		}
	}

	label {
		cursor: default;
	}

	.button {
		background-color: $off-canvas-bg;
	}

	&.has-search > .has-submenu > .left-submenu {
		top: 4.875rem;
	}

	.left-submenu {
		.title {
			background: $off-canvas-title-bg;
			border-bottom: $off-canvas-title-border-bottom;
			border-top: $off-canvas-title-border-top;
			color: $off-canvas-title-color;
			font-size: $off-canvas-title-font-size;
			margin: $off-canvas-title-margin;
			padding: $off-canvas-title-padding;
		}
	}

	&.shopping-tools {
		i {
			vertical-align: inherit;
		}
	}
}

.q-navigation-link-list {
	font-family: $font-family-sans;
	position: relative;
}

// This is for Desktop Style Changes
@media #{$large-up} {
	.q-navigation-link-list {
		.q-navigation-bar {
			background-color: transparent;
			position: relative;

			.q-scroller-content {
				.q-scroller-list {
					.q-scroller-content-item {
						.q-label {
							font-size: 1.125rem;
						}
					}
				}
			}
		}
	}
}
