.q-vehicle-trim-info {
	.q-trim-info-image-container {
		margin-bottom: $trim-info-image-container-margin-bottom;
		padding-bottom: $trim-info-image-container-padding-bottom;
		width: $trim-info-image-container-width;
	}

	.q-trim-info-text-container {
		padding: $trim-info-text-container-padding;
	}

	.q-trim-info-headline {
		@extend %#{$trim-info-headline-style};
	}

	.q-trim-info-text-price {
		float: $trim-info-text-price-float;

		.q-trim-info-text-from {
			@extend %#{$trim-info-descriptive-style};
			display: $trim-info-text-from-display;
			letter-spacing: $trim-info-text-price-letter-spacing;
			visibility: $trim-info-text-from-visibility;
  		}

		.q-trim-info-details {
			margin: $trim-info-details-margin;
			padding: $trim-info-details-padding;
			vertical-align: $trim-info-details-vertical-align;

			.q-trim-info-descriptive-price {
				@extend %#{$trim-info-descriptive-style};
				letter-spacing: $trim-info-text-price-letter-spacing;
			}

		}

		.q-trim-info-text-msrp {
			display: $trim-info-text-msrp-display;
			margin-top: $trim-info-text-msrp-margin-top;
			visibility: $trim-info-text-msrp-visibility;

			.q-trim-info-descriptive-msrp {
				@extend %#{$trim-info-descriptive-msrp-style};
			}
		}

		.q-trim-info-as-shown-price {
			@extend %#{$trim-info-as-shown-style};
			margin-bottom: $q-trim-info-as-shown-margin-bottom;
		}
	}

	.q-trim-info-body-description {
		@extend %#{$trim-info-body-description-style};
		padding-bottom: $trim-info-body-description-padding-bottom;
	}

	.q-trim-info-full-container {
		float: $trim-info-full-container-float;
		position: $trim-info-full-container-position;
		width: $trim-info-full-container-width;
	}

	.q-trim-info-half-container {
		float: $trim-info-half-container-float;
		padding-bottom: $trim-info-half-container-padding-bottom;
		position: $trim-info-half-container-position;
		text-transform: $trim-info-half-container-text-transform;
		width: $trim-info-half-container-width;
	}

	.q-trim-info-button-secondary {
		margin-top: $trim-info-button-secondary-margin-top;
		padding-left: $trim-info-button-secondary-padding-left;
	}

	.q-trim-info-button-primary {
		margin-top: $trim-info-button-primary-margin-top;
		padding-right: $trim-info-button-primary-margin-padding-right;
	}
}
