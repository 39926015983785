// Search Field
//
// This is the documentation for Search Field. Edit search_field.scss to update this documentation.
//
// markup:
// <p>Sample markup for Search Field. Replace me with the real deal once you are done editing <strong>search_field.html</strong> and <em>optionally</em> the search_field.js file.</p>
//
// Styleguide 99.99

.q-search-field {
	// Mobile First SCSS goes here
	margin: 1.25rem auto;
	padding-left: 0;
	padding-right: 0;

	.q-hide {
		display: none;
	}

	.q-show {
		display: block;
	}

	.button {
		background-color: $search-field-button-background-color;
		font-family: $search-field-button-font-family;
		font-size: 1rem;
		height: $search-field-button-height;
		width: $search-field-button-width;

		&.q-icon-search {
			background-image: get-element(search-field-icon);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 1.575rem;
		}
	}

	.button:hover {
		background-color: $search-field-button-hover-color;
	}

	.search-field {
		float: left;
		width: 100%; // fallback width
		width: calc(100% - 70px);

		span {
			&.error {
				background: none;
				color: $red;
				font-style: normal;
				margin-top: 1.25rem;
				padding-left: 0;
			}
		}
	}

	input {
		border: $search-field-input-border;
		box-shadow: none;
		color: $search-field-input-color;
		font-family: $search-field-input-font-family;
		font-size: $search-field-input-font-size;
		height: $search-field-input-height;
		padding: $search-field-input-padding;
		vertical-align: middle;
		width: 100%;

		&:focus {
			background-color: $white;
			border-color: $search-field-input-focus-border-color;
		}

		// sass-lint:disable no-vendor-prefixes
		&::-webkit-input-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&:placeholder {
			color: $search-field-input-placeholder-color;
		}
		// sass-lint:enable no-vendor-prefixes
	}

	.q-suggest-items-container {
		background-color: $white;
		border-left: $search-field-suggest-items-border;
		border-right: $search-field-suggest-items-border;
		position: absolute;
		top: 3.75rem;
		width: 100%;
		width: calc(100% - 70px);
		z-index: 1000;

		li:last-child {
			border-bottom: $search-field-suggest-items-border;
		}
	}

	.q-suggest-item {
		color: $grey-dark;
		font-family: $search-field-suggest-item-font-family;
		font-size: 1.188rem;
		overflow: hidden;
		padding: .625rem 0 .625rem 1.875rem;
		white-space: nowrap;

		&:hover,
		&.ui-state-active {
			background: $grey-very-light;
		}
	}

	.search {
		position: relative;
	}

	.search-bar,
	.search-button {
		padding: 0;
	}
}

.ui-helper-hidden-accessible {
	display: none;
}
