.q-vehicle-trim-info {
	.q-trim-info-text-price {
		.q-trim-info-as-shown-price {
			color: $brand-body2-color;
		}
	}

	.q-trim-info-text-container {
		.separator-light {
			margin-bottom: 0;
		}

		.q-trim-info-full-container {
			.q-trim-info-body-description {
				margin: 1.25rem 0 0;
			}
		}
	}

	@media #{$small-only} {
		.q-trim-info-text-price {
			width: 58%;
		}
	}

	@media #{$xlarge-up} {
		.q-trim-info-headline {
			font-size: $headline1-font-size-lm;
			line-height: $headline1-line-height-lm;
		}

		.q-trim-info-text-price {
			.q-trim-info-details {
				.q-trim-info-text-from {
					font-size: $descriptive1-font-size-lm;
					line-height: $descriptive1-line-height-lm;
				}

				.q-trim-info-descriptive-price {
					font-size: $descriptive1-font-size-lm;
					line-height: $descriptive1-line-height-lm;
				}
			}

			.q-trim-info-text-msrp {
				.q-trim-info-descriptive-msrp {
					font-size: $descriptive2-font-size-lm;
					line-height: $descriptive2-line-height-lm;
				}
			}

			.q-trim-info-as-shown-price {
				font-size: $body2-font-size-lm;
				line-height: $body2-line-height-lm;
			}
		}

		.q-trim-info-body-description {
			font-size: $body2-font-size-lm;
			line-height: $body2-line-height-lm;
		}
	}
}
