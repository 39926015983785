.q-vehicle-trim-selector {
	overflow: hidden;
	padding-left: map-get($vehicle-trim-selector-padding-left, small);
	padding-right: map-get($vehicle-trim-selector-padding-right, small);

	.q-slider {
		overflow: visible;

		&:hover {
			.slick-prev,
			.slick-next {
				opacity: .5;
			}
		}

		.slider-pagination {
			margin-left: 0;
			margin-right: 1.25rem;
			margin-top: 2.5rem;
			text-align: center;

			li {

				button {
					background: 0;
					border: 0;
					border-color: $vehicle-trim-selector-pagination-button-border-color;
					border-radius: 50%;
					border-style: solid;
					border-width: 2px;
					bottom: 0;
					color: transparent;
					left: 0;
					margin: $vehicle-trim-selector-pagination-button-margin;
					padding: 0;
					right: 0;
					top: 0;
					width: $vehicle-trim-selector-pagination-button-width;
				}

				&.slick-active {
					button {
						background-color: $vehicle-trim-selector-pagination-active-background-color;
					}
				}
			}
		}

		.slick-list {
			overflow: visible;
			width: $vehicle-trim-selector-slick-width;

			.slick-track {
				.slick-slide {
					padding: $vehicle-trim-selector-slide-padding;
				}
			}
		}

		/* Arrows */
		.slick-prev,
		.slick-next {
			@include hard-easeout(opacity, $anim-fast);

			background-color: $vehicle-trim-selector-arrow-background-color;
			border-color: $vehicle-trim-selector-arrow-border-color;
			border-radius: $vehicle-trim-selector-arrow-border-radius;
			height: $vehicle-trim-selector-arrow-height;
			margin-top: 0;
			opacity: 0;
			position: absolute;
			top: $vehicle-trim-selector-arrow-top;
			width: $vehicle-trim-selector-arrow-width;

			.no-touch & {
				&:hover {
					opacity: $vehicle-trim-selector-arrow-hover-opacity;
				}
			}

			&.slick-disabled {
				// This is needed to override slick.js inline css for diaply: block
				display: none !important; // sass-lint:disable-line no-important
			}

			&::before {
				@include insert-icon(vehicle-trim-selector-arrow);
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 0;
				content: '';
				height: $vehicle-trim-selector-arrow-before-height;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: $vehicle-trim-selector-arrow-before-width;
			}

			.icon {
				display: none;
			}
		}

		.slick-next {
			right: map-get($vehicle-trim-selector-arrow-next-right, small);
		}

		.slick-prev {
			left: $vehicle-trim-selector-arrow-prev-left;
			transform: rotate(180deg);
			z-index: 1;
		}

		&.q-circle-arrow {
			@include insert-icon(circle-arrow, $svg-icon-xlarge, $svg-icon-xlarge);
		}
	}

	.q-dots-to-numbers {
		@extend %q-descriptive1;
		display: none;
		margin-top: 2.5rem;
		text-align: center;
	}

	.slider-pagination {
		display: none;
	}

	@media #{$large-up} {
		padding-left: map-get($vehicle-trim-selector-padding-left, large);
		padding-right: map-get($vehicle-trim-selector-padding-right, large);

		.q-slider {
			.slick-next {
				right: map-get($vehicle-trim-selector-arrow-next-right, large);
			}
		}
	}

	@media #{$xlarge-up} {
		padding-left: map-get($vehicle-trim-selector-padding-left, xlarge);
		padding-right: map-get($vehicle-trim-selector-padding-right, xlarge);

		.q-slider {
			.slick-next {
				right: map-get($vehicle-trim-selector-arrow-next-right, xlarge);
			}
		}
	}
}
