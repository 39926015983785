/* extend solar nav_segment for brand-specific styles */

.q-nav-segment {
	.q-content-item {
		a {
			&::after {
				border-bottom: .3125rem solid transparent;
				border-left: .4375rem solid $grey-mid;
				border-top: .3125rem solid transparent;
				content: '';
				position: absolute;
				right: 1.875rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&::before {
				background: transparent;
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: .1875rem;
			}

			&:hover {
				&::before {
					background: $brand-primary-color;
				}

				&::after {
					border-left: .4375rem solid $brand-primary-color;
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-content-item {
			background: $grey-very-light;
			border: {
				bottom: .0625rem solid $grey-mid;
				left: .0625rem solid $grey-mid;
			}
			height: 3.375rem;

			&:hover {
				background-color: $white;

				.q-no-link {
					color: $grey-dark;
				}
			}


			&.active {
				background-color: $white;
				border-bottom: 0;
			}

			&:not(:last-of-type) {
				border-right: 0;
			}

			&:first-of-type {
				border-left: 0;
			}

			a {
				line-height: 3.375rem;

				span {
					&::before {
						@include easein-middle;

						background: $brand-primary-color;
						bottom: -.0625rem;
						content: '';
						height: .1875rem;
						left: 50%;
						position: absolute;
						width: 0;
					}
				}

				&.active {
					span {
						&::before {
							@include easein-middle;

							left: 0;
							width: 100%;
						}
					}
				}

				&::before,
				&::after {
					content: none;
				}
			}
		}
	}
}
