// Allows the use of rem-calc() or lower-bound() in your variables & settings
//@import '../vendor/sass/foundation/functions'; //Commenting as part of global refactor.  Cleanup later once tested
@import '../../gmwp-aem-ui/scss/variables';

// We use these to define default font stacks
$font-family-sans: Arial, sans-serif;
$font-family-primary: Louis, Tahoma, sans-serif;
$font-family-secondary: Roboto, Helvetica, sans-serif;

//variable for font-style
$font-weight-lighter: 100;
$font-weight-light: 200;
$font-weight-book: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-ultra: 800;
$font-weight-xbold: 900;

// Variable for Base Font Size
$base-font-size-xs: 16px;
$base-font-size-m: 16px;
$base-font-size-xl: 16px;

// Variable for margin base
$margin-base-none: 0;
$margin-base-small: 1.25rem;
$margin-base-medium: 2.5rem;
$margin-base-large: 5rem;
$margin-base-xlarge: 5rem;
$margin-base-xlarge-up: 7.5rem;

//variable for font style
$font-style-italic: italic;
$font-style-normal: normal;
$font-style-inherit: inherit;

$webkit-tap-highlight-color: rgba(0, 0, 0, 0);

// This variable is required in order for AEM icons to be
// generated properly. DO NOT CHANGE.
$icons-media-query: true;

// Colors
// - - - - - - - - - - - - - - - - - - - - - - - - -
$alabaster-white: #fbfbfb;
$alto-grey: #d8d8d8;
$armadillo-black: #3f3f38;
$black: #000000;
$bright-red: #b50000;
$chevy-yellow: #e2ad0d;
$dark-grey: #cccccc;
$dodger-blue: #3b99fc;
$dove-grey: #666666;
$dusty-grey: #999999;
$emperor-grey: #555555;
$flamingo-orange: #f26522;
$gallery: #ebebeb;
$gallery-grey: #eeeeee;
$gallery-white: #f0f0f0;
$gm-blue: #134a9c;
$gold: #f2bf24;
$gold-light: #ffcf40;
$green: #61af71;
$grey: #808080;
$grey-alto: #d4d4d4;
$grey-dark: #4d4d4d;
$grey-light: #e6e6e6;
$grey-mid: #b3b3b3;
$grey-very-dark: #1a1a1a;
$grey-very-light: #f2f2f2;
$kelly-green: #0a7305;
$light-grey: #f9f9f9;
$medium-grey: #f3f3f3;
$mercury: #e6e6e6;
$mine-shaft: #2b2b2b;
$nav-flyout-box-shadow: rgba(50, 50, 50, .3);
$neptune-blue: #82b8c0;
$red: #e25959;
$scorpion: #606060;
$seashell: #f1f1f1;
$silver: #cbcbcb;
$tia-maria-orange: #c9480c;
$white: #ffffff;
$yellow: #f7d900;
$yellow-mid: #f2bf24;

//Brand Colors
$brand-primary-color: $gold;
$brand-body1-color: $grey-dark;
$brand-body1-invert-color: $white;
$brand-body2-color: $grey;

// Action colors
$border-active: 5px solid $brand-primary-color;	/* Don't use ) here */
$hover-dark-color: $grey;
$hover-light-color: $scorpion;

// Animation Duration
$anim-xslow: .75;
$anim-slow: .5;
$anim-medium: .3;
$anim-fast: .25;
$anim-xfast: .15;
$anim-xxfast: .075;
$anim-instant: 0;

//Brand Link Colors
$brand-link-color: inherit;
$brand-invert-link-color: inherit;
$brand-link-color-hover: $gold;
$brand-invert-link-color-hover: $gold;
$brand-link-color-visited: $grey-dark;
$brand-invert-link-color-visited: $white;
$brand-link-font-weight: $font-weight-medium;
$brand-link-transition-speed: $anim-fast;
$brand-link-decoration: underline;

// Border
$border-grey-mid: 1px solid $grey-mid;

// Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - -
// Must be in patterns files
$button-padding-top-small: 1.125rem;
$button-padding-bottom-small: 1.125rem;
$button-padding-top-large: .375rem;
$button-padding-bottom-large: .375rem;
$button-secondary-opacity: .15;
// Buttons Brand Variables
$button-background-color: transparent;
$button-border-width: .125rem;
$button-no-border-width: auto;
$button-color: $grey-dark;
$button-font: $font-weight-bold .75rem $font-family-secondary;
$button-line-height: 1.375rem;
$button-letter-spacing: normal;
$button-padding: 1.125rem 0;
$button-hover-color: $grey-dark;
$button-icon-size: 1.25rem;
$button-icon-size-large: 1.875rem;
$button-icon-size-stacked: 1.875rem;
$button-icon-size-stacked-large: 1.875rem;
$button-icon-margin: 1.25rem;
$button-icon-margin-bottom: .625rem;
$button-q-link-padding: .625rem 0;
$button-q-link-text-hover: $gold-light;
$button-q-link-font-size: .75rem;
$button-q-link-letter-spacing: normal;
$button-q-link-line-height: 1.375rem;
$button-q-link-q-invert-text-hover: $gold-light;
$button-q-link-q-invert-color: $white;
$button-primary-invert-color: null;
$button-primary-invert-border-color: null;
$button-primary-invert-background-color: null;
$button-primary-invert-hover-background-color: null;
$button-primary-invert-hover-color: $white;
$button-secondary-invert-color: $white;
$button-secondary-invert-border-color: null;
$button-secondary-invert-hover-background-color: null;
$button-secondary-invert-hover-color: $white;
$button-large-size-padding: 1.75rem;
$button-list-item-padding-border-left: 4px solid transparent;
$button-list-border-left-color: $gold;
$button-list-padding-left: 1.625rem;
$button-list-padding-right: 1.875rem;
$button-list-primary-bg-color: transparent;
$button-list-primary-color: $grey-dark;
$button-list-primary-hover-bg-color: transparent;
$button-list-primary-hover-left-bg-color: $gold;
$button-list-primary-line-height: 1.375rem;
$button-list-primary-text-align: left;
$button-list-primary-border-bottom: solid 1px $grey-mid;
$button-list-secondary-bg-color: transparent;
$button-list-secondary-color: $grey-dark;
$button-list-secondary-hover-bg-color: transparent;
$button-list-secondary-hover-left-bg-color: $gold;
$button-list-secondary-line-height: 1.375rem;
$button-list-secondary-text-align: left;
$button-list-secondary-border-bottom: solid 1px $grey-mid;
$button-list-tertiary-bg-color: transparent;
$button-list-tertiary-color: $grey-mid;
$button-list-tertiary-hover-bg-color: transparent;
$button-list-tertiary-hover-left-bg-color: $gold;
$button-list-tertiary-line-height: 1.375rem;
$button-list-tertiary-text-align: left;
$button-list-tertiary-border-bottom: solid 1px $grey-mid;
$button-list-item-icon-tertiary-color: $grey;
$button-list-item-tertiary-hover-color: $grey-dark;
$button-list-item-tertiary-left-border: 4px solid transparent;
$button-list-item-tertiary-hover-left-bg-color: $gold;
$button-list-item-tertiary-anim-speed: $anim-fast;
// Buttons Brand mixin variables
$button-primary-border-primary-color: $gold;
$button-primary-border-fill: 1;
$button-primary-border-fill-color: null;
$button-primary-border-hover-color: null;
$button-secondary-border-primary-color: $grey-mid;
$button-secondary-border-fill: 1;
$button-secondary-border-fill-color: null;
$button-secondary-border-background-color: null;
$button-secondary-border-color: null;
$button-secondary-border-hover-color: null;
$button-icon-inline-border-primary-color: $gold;
$button-icon-inline-border-fill: null;
$button-icon-inline-border-fill-color: null;
$button-icon-inline-border-box-shadow: null;
$button-icon-inline-border-secondary-color: $grey-dark;
$button-icon-inline-border-secondary-border-color: $grey-mid;
$button-icon-inline-border-secondary-fill: null;
$button-icon-inline-border-secondary-fill-color: null;
$button-icon-inline-border-secondary-hover-color: null;
$button-icon-inline-border-secondary-box-shadow: null;
$button-icon-inline-border-secondary-invert-border-color: $grey-mid;
$button-icon-inline-border-secondary-invert-color: $white;
$button-icon-inline-border-secondary-invert-hover-background-color: transparent;
$button-icon-inline-border-secondary-invert-hover-border-color: $grey-mid;
$button-icon-inline-border-secondary-invert-hover-color: $white;

//Button Lists for stacked-icon sizes
$icon-size: xsmall, small, medium, large, xlarge, xxlarge;
$icon-size-value: .625rem, 1.25rem, 1.875rem, 2.5rem, 3.75rem, 5.938rem;

//Headline Font Sizes
$brand-headline-color: $grey-dark;
$brand-headline-heavy-weight-bold: $font-weight-xbold;
$brand-headline-heavy-weight-xbold: $font-weight-xbold;
$brand-heavy-padding-bottom: 0;
$brand-use-underline: false;
$brand-headline-text-decoration: none;

$bold1-font-size-xs: 1.875rem;
$bold1-font-size-lm: 2.5rem;
$bold1-font-size-xl: 3.75rem;
$bold1-font-family: $font-family-primary;
$bold1-font-style: $font-style-inherit;
$bold1-font-weight: $font-weight-bold;
$bold1-use-underline: $brand-use-underline;
$bold1-font-color: $brand-headline-color;

$bold2-font-size-xs: 1.125rem;
$bold2-font-size-lm: 1.375rem;
$bold2-font-size-xl: 2.063rem;
$bold2-font-family: $bold1-font-family;
$bold2-font-style: $bold1-font-style;
$bold2-font-weight: $bold1-font-weight;
$bold2-use-underline: $brand-use-underline;
$bold2-font-color: $brand-headline-color;

$descriptive1-font-size-xs: 1.25rem;
$descriptive1-font-size-lm: 1.563rem;
$descriptive1-font-size-xl: 1.875rem;
$descriptive1-letter-spacing: .09em;
$descriptive1-font-family: $font-family-primary;
$descriptive1-font-style: $font-style-inherit;
$descriptive1-font-weight: $font-weight-normal;
$descriptive1-use-underline: $brand-use-underline;

$descriptive2-font-size-xs: .75rem;
$descriptive2-font-size-lm: .875rem;
$descriptive2-font-size-xl: 1rem;
$descriptive2-letter-spacing: $descriptive1-letter-spacing;
$descriptive2-font-family: $descriptive1-font-family;
$descriptive2-font-style: $descriptive1-font-style;
$descriptive2-font-weight: $font-weight-lighter;
$descriptive2-font-color: $grey;
$descriptive2-use-underline: $brand-use-underline;

$display1-font-size-xs: 2.5rem;
$display1-font-size-lm: 4.125rem;
$display1-font-size-xl: 6.625rem;
$display1-letter-spacing: null;
$display1-font-family: $font-family-primary;
$display1-font-style: $font-style-italic;
$display1-font-weight: $font-weight-bold;
$display1-heavy-weight: $brand-headline-heavy-weight-xbold;
$display1-use-underline: $brand-use-underline;

$display2-font-size-xs: 1.875rem;
$display2-font-size-lm: 3.125rem;
$display2-font-size-xl: 5rem;
$display2-letter-spacing: null;
$display2-font-family: $display1-font-family;
$display2-font-style: $display1-font-style;
$display2-font-weight: $display1-font-weight;
$display2-heavy-weight: $brand-headline-heavy-weight-xbold;
$display2-use-underline: $brand-use-underline;

$display3-font-size-xs: 1.563rem;
$display3-font-size-lm: 2.5rem;
$display3-font-size-xl: 3.75rem;
$display3-font-family: $display1-font-family;
$display3-font-style: $display1-font-style;
$display3-font-weight: $display1-font-weight;
$display3-heavy-weight: $brand-headline-heavy-weight-xbold;
$display3-use-underline: $brand-use-underline;
$display3-letter-spacing-map: (
	small: 0,
);

$headline1-font-size-xs: 1.375rem;
$headline1-font-size-lm: 1.875rem;
$headline1-font-size-xl: 2.5rem;
$headline1-letter-spacing: .02em;
$headline1-font-family: $font-family-primary;
$headline1-font-style: $font-style-inherit;
$headline1-font-weight: $font-weight-normal;
$headline1-use-underline: $brand-use-underline;

$headline2-font-size-xs: 1.25rem;
$headline2-font-size-lm: 1.563rem;
$headline2-font-size-xl: 1.875rem;
$headline2-letter-spacing-map: ();
$headline2-font-family: $headline1-font-family;
$headline2-font-style: $headline1-font-style;
$headline2-font-weight: $headline1-font-weight;
$headline2-use-underline: $brand-use-underline;

$headline3-font-size-xs: 1rem;
$headline3-font-size-lm: 1.375rem;
$headline3-font-size-xl: 1.625rem;
$headline3-letter-spacing: null;
$headline3-font-family: $headline1-font-family;
$headline3-font-style: $headline1-font-style;
$headline3-font-weight: $headline1-font-weight;
$headline3-heavy-style : null;
$headline3-use-underline: $brand-use-underline;

//Text
$body-font-family: $font-family-secondary;

$body-baseline-value: true;

$body1-font-size-xs: .875rem;
$body1-font-size-lm: 1.125rem;
$body1-font-size-xl: 1.5rem;

$body2-font-size-xs: .875rem;
$body2-font-size-lm: .875rem;
$body2-font-size-xl: 1.25rem;

$body1-line-height: 2.5rem;
$body1-line-height-xs: 1.563rem;
$body1-line-height-lm: 1.875rem;
$body1-line-height-xl: $body1-line-height;

$body2-line-height: 1.875rem;
$body2-line-height-lm: 1.25rem;
$body2-line-height-xl: $body2-line-height;

$bold1-line-height-xs: 2.5rem;
$bold1-line-height-lm: 3.125rem;
$bold1-line-height-xl: 4.375rem;

$bold2-line-height-xs: 1.25rem;
$bold2-line-height-lm: 1.875rem;
$bold2-line-height-xl: 2.5rem;

$descriptive1-line-height-xs: 1.25rem;
$descriptive1-line-height-lm: 1.875rem;
$descriptive1-line-height-xl: 2.5rem;

$descriptive2-line-height-xs: 1.25rem;
$descriptive2-line-height-lm: 1.25rem;
$descriptive2-line-height-xl: 1.25rem;

$display1-line-height-xs: 2.5rem;
$display1-line-height-lm: 4.375rem;
$display1-line-height-xl: 6.875rem;

$display2-line-height-xs: 1.875rem;
$display2-line-height-lm: 3.125rem;
$display2-line-height-xl: 5rem;

$display3-line-height-xs: 1.563rem;
$display3-line-height-lm: 2.5rem;
$display3-line-height-xl: 3.75rem;

$headline1-line-height-xs: 1.875rem;
$headline1-line-height-lm: 2.5rem;
$headline1-line-height-xl: 3.125rem;

$headline2-line-height-xs: 1.563rem;
$headline2-line-height-lm: 1.875rem;
$headline2-line-height-xl: 3.125rem;

$headline3-line-height-xs: 1.25rem;
$headline3-line-height-lm: 1.875rem;
$headline3-line-height-xl: 1.875rem;

// Body List Margin
$body-last-margin-bottom: null;
$body1-list-item-margin: 0 0 0 1.25rem;
$body2-list-item-margin: 0 0 0 1.25rem;
$body1-list-item-margin-last: null;
$body-list-item-padding: null;
$body-list-margin-top: 0;

// Body Margin
$body-margin-bottom: null;
$body-last-margin-bottom: null;

// Baseline
$baseline-grid-size: .625rem;
// font cap-height
$baseline-ratio-default: .6;
$baseline-body-ratio-primary: .8;
$baseline-body-ratio-secondary: .7;

// Footer
$footer-background-color: $grey-very-light;
$footer-button-icon-height: 1.5rem;
$footer-button-link-border-bottom: .0625rem solid $grey-light;
$footer-button-link-border-top: .0625rem solid $grey-light;
$footer-button-link-color: (
	small: $grey-dark,
	large: $grey
);
$footer-button-link-font-size: .75rem;
$footer-button-link-font-weight: $font-weight-bold;
$footer-button-link-hover-color: $grey-dark;
$footer-button-link-line-height: 1.25rem;
$footer-button-link-margin: -.0625rem .3125rem 0;
$footer-button-link-margin-bottom: .625rem;
$footer-button-link-padding: (
	small: .9375rem 0,
	large: 0
);
$footer-content-well-background: $white;
$footer-content-well-padding: 1.875rem .625rem;
$footer-expander-media-query: 'small-only';
$footer-expander-button-background-color: $grey-very-light;
$footer-expander-button-active-background-color: $grey-very-light;
$footer-expander-button-hover-background-color: $white;
$footer-expander-button-border-bottom: $border-grey-mid;
$footer-expander-button-border-bottom-active: $border-grey-mid;
$footer-expander-button-border-bottom-hover: $border-grey-mid;
$footer-expander-button-border-top: $border-grey-mid;
$footer-expander-button-border-top-active: $border-grey-mid;
$footer-expander-button-border-top-hover: $border-grey-mid;
$footer-expander-button-headline-color: $grey-dark;
$footer-expander-content-border-bottom: 0;
$footer-expander-padding-top: 0;
$footer-image-vertical-align: middle;
$footer-inlineicon-border-color: $grey-mid !default;
$footer-inlineicon-color: $grey-dark !default;
$footer-padding-top: 2.5rem;
$footer-padding-bottom: 2.5rem;
$footer-remove-expander-anim: false;
$footer-text-color: null;

// Components
// - - - - - - - - - - - - - - - - - - - - - - - - -

// HMF
$hmf-border: $border-grey-mid;
$hmf-group-border-bottom: $border-grey-mid;
$hmf-modal-header-label: q-headline3;
$hmf-checkbox-active-color: $grey;
$hmf-check-box-bg-color: $grey-light;
$hmf-checkbox-check-size: 2.5rem;
$hmf-checkbox-size: 1.25rem;
$hmf-filter-border-bottom: 0;
$hmf-filter-title: q-label1;
$hmf-filter-title-margin-bottom: .625rem;
$hmf-filter-padding-bottom: 0;
$hmf-filter-margin-bottom: 1.875rem;
$hmf-filter-background-color: $white;
$hmf-filter-button-active-border-color: $yellow;
$hmf-filter-button-active-background-color: $grey-very-light;
$hmf-filter-button-background-color-mobile: $gallery-grey;
$hmf-filter-button-background-color: $white;
$hmf-filter-button-header-background-color: $white;
$hmf-filter-checkbox-color: $dark-grey;
$hmf-filter-checkbox-margin: 0 0 0 -.35rem;
$hmf-filter-label-font: $font-family-secondary;
$hmf-filter-range-background-color: $medium-grey;
$hmf-filter-range-border-color: $medium-grey;
$hmf-filter-range-filter-color: $emperor-grey;
$hmf-filter-range-handle-background-color: $grey;
$hmf-filter-range-handle-border-width: 0;
$hmf-group-modal-overlay-background-color: rgba(0, 0, 0, .45);
$hmf-label-margin: 0 .5rem 1rem 0;
$hmf-results-tag-background-color: $grey-very-light;
$hmf-results-tag-color: $grey;
$hmf-results-tag-hover-color: $grey-dark;
$hmf-results-tag-label: hmf-results-tag-label;
$hmf-results-tag-padding: .563rem 1.25rem;
$hmf-results-tag-clear-label-color: $grey;
$hmf-results-tag-clear-label-line-height: 2.5;
$hmf-results-tag-label-font-size: .75rem;
$hmf-results-tag-background-color-hover: null;
$hmf-radio-button-bg-color: $grey;
$hmf-radio-button-border-color: $grey;
$hmf-sort-dropdown-carat-display: block;
$hmf-sort-label: q-descriptive2;
$hmf-sort-label-flex: 0 0 auto;
$hmf-sort-result-count: q-label1;
$hmf-sort-dropdown-font-color: $emperor-grey;
$hmf-sort-dropdown-background-color: $light-grey;
$hmf-sort-dropdown-active-background-color: $gallery-grey;
$hmf-sort-dropdown-border: .0625rem solid $grey-mid;
$hmf-sort-dropdown-border-right-color: $grey;
$hmf-sort-dropdown-carrot: $white;
$hmf-sort-dropdown-padding: .9375rem 1rem;
$hmf-sort-dropdown-item-border-left-color: $white;
$hmf-sort-dropdown-default-border-color: $gallery-grey;
$hmf-sort-dropdown-hover-border-color: $gold;
$hmf-sort-label-padding: 1rem;
$hmf-filter-range-background-color: $gallery;
$hmf-filter-range-border-color: $gallery;
$hmf-filter-range-handle-height: 1.25rem;
$hmf-filter-range-handle-hover-color: $grey;
$hmf-filter-range-height: .25rem;
$hmf-filter-range-header-background-color: $grey-light;
$hmf-filter-label: hmf-filter-label;
$hmf-filter-label-color: $grey;
$hmf-slider-label: q-label2;
$hmf-slider-handle-label-font-size: .875rem;
$hmf-result-count-margin-bottom: 1.875rem;
$hmf-button-hide-filters-flex-map: (
	small: 0 0 100%,
	large: 0 0 33.33%
);
$hmf-dropdown-button-font-map: (
	small: .875rem,
	large: 1rem
);

%hmf-filter {
	margin-bottom: 1.875rem;
}

%hmf-filter-label {
	color: $hmf-filter-label-color;
	font: $font-weight-bold .75rem $font-family-secondary;
	line-height: 1.25rem;
}

%hmf-slider-label {
	color: $hmf-filter-label-color;
	font: $font-weight-normal .75rem $font-family-secondary;
	line-height: 1.25rem;
}

%hmf-dropdown-button {
	&::after {
		right: -2.375rem;
	}
}

// Radio button
$radio-button-bg-color: $grey;
$radio-button-border-color: $grey;
$radio-button-label-margin: 0 .5rem 1rem 0;
$radio-button-border-width: 1px;

// Share
$share-background-color: transparent;
$share-header-font-weight: $font-weight-normal;
$share-header-padding: 0 1rem;
$share-icon-color: $white;
$share-icon-margin: .625rem;
$share-icon-margin-top: 0;
$share-list-padding: 0;
$share-svg-size: 1.375rem;
$social-share-header: q-descriptive2;

// Shopping Tools
$shopping-tools-large-height: 4.438rem;
$shopping-tools-medium-height: 5rem;

// Sitemap
$sitemap-header-margin: 1.25rem;

// Grid Bottom Margin
$gridspacing-small: 1.25rem;
$gridspacing-medium: 2.5rem;
$gridspacing-large: 5rem;
$gridspacing-xlarge: 7.5rem;

// Grid Padding
$gridpadding-default: 5%;
$gridcolumn-gutter: 1.25rem;
$gridpadding-small: 1.25rem;
$gridpadding-small-vw: 2.5vw;
$gridpadding-medium: 2.5rem;
$gridpadding-large: 5rem;

// Grid Gutter
$gridgutter-disabled: 0;
$gridgutter-enabled: 5vw;


// Grid Full Width
$gridwidth-max: 93.75rem;
$gridwidth-full: 120rem;

// Destination Freight Information
$freight-info-border-color: $grey-light;

// Grid background color definitions
$grid-bg-transparent: transparent;
$grid-bg-color-two: $grey-very-light;
$grid-bg-color-three: $grey-light;
$grid-bg-color-four: $grey-dark;
$grid-bg-color-five: $grey-very-dark;
$grid-bg-color-six: $white;

//Content Well background color definitions
$content-well-bg-color-one: $grey-very-dark;
$content-well-bg-color-two: $grey-dark;
$content-well-bg-color-three: $grey-light;
$content-well-bg-color-four: $grey-very-light;

// Forms
$form-background: $white;
$form-border-primary: $grey-mid;
$form-interactive: $grey;
$form-interactive-hover: $grey-dark;
$form-interactive-disabled: $grey-light;

// Slick Slider (multimedia scroller)
// - - - - - - - - - - - - - - - - - - - - - - - - -
$scroller-common-padding: 1.25rem;
$scroller-padding-none: 0;
$scroller-overlay-padding: 1.25rem .625rem 1.25rem .625rem;
$scroller-overlay-padding-top-medium: 1.25rem;
$scroller-overlay-padding-side-medium: 1.25rem;
$scroller-overlay-more-border-bottom: 1px;
$scroller-overlay-more-height-medium: 5rem;
$scroller-overlay-more-right: 3.75rem;
$scroller-overlay-more-bottom: .625rem;
$scroller-position-zero: 0;
$scroller-position-out-of-view: -62.5rem;
$scroller-position-half-way: 50%;

$scroller-indicator-height: 5rem;

$scroller-opacity-full: 1;
$scroller-opacity-arrow-hover: .2;
$scroller-opacity-half: .5;
$scroller-opacity-quarter: .25;
$scroller-opacity-none: 0;

$scroller-none: 0;
$scroller-percent-full: 100%;
$scroller-percent-half: 50%;

$scroller-label-margin: 0 .125rem 0 0;

// Colorizer settings
$colorizer-checkmark-image: none;
$colorizer-checkmark-content: none;
$colorizer-checkmark-border-color: $grey-mid;
$colorizer-tooltip-color: $grey;
$colorizer-tooltip-background-color: $white;
$colorizer-tooltip-border-color: $grey;
$colorizer-use-li: false;
$colorizer-use-select: true;
$colorizer-panel-padding: 0 .3125rem;
$colorizer-panel-active-before-height: 1.25rem;
$colorizer-panel-active-before-margin-top: -.625rem;
$colorizer-panel-active-before-width: 1.25rem;
$colorizer-chip-active-border: 2px solid $colorizer-checkmark-border-color;
$colorizer-chip-active-display: inline;
$colorizer-chip-active-height: 2.5rem;
$colorizer-chip-active-margin: 0;
$colorizer-chip-active-padding: .1875rem 0 0 .1875rem;
$colorizer-chip-active-width: 2.5rem;
$colorizer-chip-active-use-select: false;
$colorizer-chip-active-select-border: null;
$colorizer-chip-border-radius: 0;
$colorizer-chip-height: 2.5rem;
$colorizer-chip-margin: 0;
$colorizer-chip-padding: .3125rem 0 0 .3125rem;
$colorizer-chip-transition: none;
$colorizer-chip-width: 2.5rem;
$colorizer-chip-hover-border: 2px solid $colorizer-checkmark-border-color;
$colorizer-chip-hover-cursor: auto;
$colorizer-chip-hover-height: 2.5rem;
$colorizer-chip-hover-width: 2.5rem;
$colorizer-chip-use-select: false;
$colorizer-box-shadow-color: null;
$colorizer-box-shadow-color-second: null;
$colorizer-box-shadow-color-third: null;
$colorizer-chip-select-box-shadow: null;
$colorizer-chip-select-box-shadow-large: null;
$colorizer-chip-tooltip-bottom: 165%;
$colorizer-chip-tooltip-font-size: null;
$colorizer-chip-tooltip-line-height: null;
$colorizer-chip-tooltip-padding: .4rem .5rem .5rem;
$colorizer-text-font-size: null;
$colorizer-text-line-height: null;
$colorizer-large-use-tabs: false;
$colorizer-xlarge-use-tabs: true;
$colorizer-xlarge-chip-padding: .125rem .125rem .125rem .375rem;
$colorizer-xlarge-chip-margin: 0;
$colorizer-xlarge-active-chip-padding: 0 .125rem .125rem .25rem;
$colorizer-xlarge-hover-chip-padding: 0 .125rem .125rem .25rem;
$colorizer-xlarge-use-select: true;
$colorizer-chip-select-hover-width: 5.4rem;
$colorizer-chip-select-hover-height: 5.4rem;
$colorizer-chip-background-checkmark-display: block;
$colorizer-chip-html-checkmark-display: none;

// Content Overlay
$content-overlay-grey-dark: rgba(77, 77, 77, .8);
$content-overlay-grey-very-dark: rgba(26, 26, 26, .8);
$content-overlay-grey-light: rgba(230, 230, 230, .8);
$content-overlay-grey-very-light: rgba(242, 242, 242, .8);
$content-overlay-gradient-neutral-one: rgba($black, .30);
$content-overlay-gradient-neutral-two: rgba($black, 0);
$content-overlay-gradient-warm-one: rgba(172, 134, 103, .30);
$content-overlay-gradient-warm-two: rgba(172, 134, 103, 0);
$content-overlay-gradient-cool-one: rgba(78, 92, 132, .30);
$content-overlay-gradient-cool-two: rgba(78, 92, 132, 0);
$content-overlay-gradient-white-one: rgba($white, .30);
$content-overlay-gradient-white-two: rgba($white, 0);

//Content Overlay background color definitions
$content-overlay-bg-color-one: $content-overlay-grey-very-dark;
$content-overlay-bg-color-two: $content-overlay-grey-dark;
$content-overlay-bg-color-three: $content-overlay-grey-light;
$content-overlay-bg-color-four: $content-overlay-grey-very-light;

$content-title-font-size: 1.375rem;
$content-title-font-size-medium: 1.375rem;
$content-title-font-size-small: 1rem;
$content-title-font-size-large: 1.375rem;
$content-title-letter-spacing: .00125rem;
$content-title-line-height: 1.875rem;
$content-title-line-height-small: 1.25rem;

$content-bar-top-offset: -3.75rem;
$content-bar-top-offset-small: 0;

$indicator-separator-border-width: .0625rem;
$indicator-separator-margin: 0;

// Expander
$expander-border: $border-grey-mid;
$expander-border-active: $border-grey-mid;
$expander-background-color: $white;
$expander-background-active: $white;
$expander-button-padding: .875rem 1.875rem;
$expander-button-font-color: $grey-dark;
$expander-button-font-family: $font-family-secondary;
$expander-button-font-size: .75rem;
$expander-button-line-height: 2;
$expander-button-font-weight: $font-weight-xbold;
$expander-button-letter-spacing: normal;
$expander-button-text-transform: none;
$expander-disable-font-color: $grey-dark;
$expander-toggle-animation: false;
$expander-icon-minus: 'minus';
$expander-icon-plus-hover: 'plus-hover';
$expander-icon-plus: 'plus';
$expander-icon-margin: auto;
$expander-icon-size-map: (
	xsmall: 1.375rem,
	small: 1.5rem,
	medium: 1.875rem,
	large: 2.5rem,
	xlarge: 3.75rem
);
$expander-icon-right: 1.25rem;
$expander-margin: -.0625rem;
$expander-icon-medium-up: medium;
$expander-icon-medium-down: medium;
$expander-expand-anim-speed: $anim-instant;
$expander-collapse-anim-speed: $anim-instant;
$expander-button-anim-speed: $anim-fast;
$expander-margin-right: 2.813rem !default;

// SVG Icon sizes
$svg-icon-small: .625rem;
$svg-icon-medium: 1.25rem;
$svg-icon-large: 1.875rem;
$svg-icon-xlarge: 2.5rem;
$svg-icon-xxlarge: 3.75rem;
$svg-icon-auto: auto;
$brand-use-icon-mq: false;

// Slider Pagination
$slider-pagination-active-border-color: $grey-mid;
$slider-pagination-background-color: $grey-mid;
$slider-pagination-height: 1.25rem;
$slider-pagination-width: 1.25rem;
$slider-pagination-padding: 0;
$slider-pagination-padding-hider: -.5rem;

// Size of Slider (ie, size of MMS)
$slider-height-min: 11rem;

// Pagination Arrows
$slick-arrow-margin-top-medium: -60;
$slick-arrow-opacity: rgba(0, 0, 0, .2);
$slick-arrow-outer-width: 3.125rem;
$slick-arrow-outer-height: 6.875rem;
$slick-arrow-width: 1.875rem;
$slick-arrow-height: 3.75rem;
$slick-arrow-removed: 0;
$slick-arrow-padding: 1.063rem 0;
$slick-arrow-top: 50%;
$slick-arrow-top-offset: (
	small: -3.438rem,
	large: -5.313rem
);
$slick-arrow-top-offset-no-content-bar: -3.438rem;

// Slider Inidcators ("dots" to show which page is visible)
$slick-dot-li-size: 1.25rem;
$slick-dot-diameter: 1rem;
$slick-dot-diameter-active: 1.25rem;
$slick-dot-border-width: .125rem;
$slick-dot-padding-left: 3.75rem;
$slick-dot-padding-top: 1.25rem;
$slick-dot-padding-small: 0;
$slick-dot-height: (
	medium: 3.75rem,
	large: 3.75rem
);
$slick-dot-margin: 0 .4375rem;
$slick-dot-margin-left: 3.75rem;
$slick-dot-margin-top: (
	small: -.625rem,
	large: 1.25rem
);
$slick-dot-margin-small: 0;
$slick-dot-top-small: 50%;
$slick-numbers-left: 3.75rem;
$slick-numbers-left-small: 0;
$slick-numbers-top: .625rem;
$slick-semi-opaque-bar-height: 3.75rem;
$slick-semi-opaque-bar-offset: -3.75rem;

// Vehicle Info Block
$vehicle-info-block-border-color: $border-grey-mid;

// Vehicle Info Tile
$vehicle-info-tile-border: $border-grey-mid;
$vehicle-info-tile-padding-map: (
	small: 1.25rem,
	large: 0
);
$vehicle-info-tile-text-padding-map: (
	small: 0 0 0 1rem,
	large: 0
);

// Gallery Preview ($gp-)
$gp-item-bg-color: $grey-very-dark;
$gp-item-content-color: $white;
$gp-img-transform-scale: translateZ(0) scale(1.06);
$gp-last-child-color: $brand-primary-color;
$gp-last-child-bg-color: $gp-item-bg-color;
$gp-label-underline: 3px solid $brand-primary-color;
$gp-label-margin-bottom: .5rem;
$gp-label-padding-bottom: .1875rem 0 .3125rem;
$gp-gallery-label-font-size-map: (
	small: .875rem,
	medium: .875rem,
	large: 1.125rem
);
$gp-gallery-label-letter-spacing: null;
$gp-gallery-count-font-size-map: (
	small: .875rem,
	medium: null,
	large: null
);
$gp-gallery-label-text-transform: null;
$gp-gallery-label-text-transform: none;
$gp-gallery-count-display-map: (
	small: none,
	medium: null,
	large: inline-block
);
$gp-gallery-count-padding-map: (
	small: null,
	medium: null,
	large: null
);
$gp-play-icon-map: (
	small: 5rem,
	large: 5rem
);
$gp-img-border-settings: null;
$gp-third-column-width: 33.33%;
$gp-half-column-width: 50%;
$gp-responsive-img-transition: initial;
$gp-responsive-img-duration: 0s;
$gp-responsive-img-opacity-single: null;
$gp-responsive-img-opacity-all: null;
$gp-gallery-count-color: null;
$gp-gallery-label-font-weight: $font-weight-bold;
$gp-gallery-count-font-weight: $font-weight-bold;

// Gallery Layer ($gl-)
$gl-max-width: 120rem;
$gl-background-color: $white;
$gl-header-bg-color: $gl-background-color;
$gl-header-padding: null;
$gl-header-height-map: (
	small: 3.813rem,
	large: 3.813rem,
	xlarge: 3.813rem
);
$gl-header-close-position-map: (
	small: 0,
	large: 5%,
	xlarge: 2.5rem
);
$gl-top-position-map: (
	small: 3.813rem,
	large: 3.813rem,
	xlarge: 3.813rem
);
$gl-thumbnails-background-color: $gl-background-color;
$gl-slider-arrow-height-map: (
	small: 5.313rem,
	medium: null,
	large: 9.375rem,
	xlarge: null
);
$gl-slider-arrow-width-map: (
	small: 2.5rem,
	medium: null,
	large: 5rem,
	xlarge: null
);
$gl-slider-arrow-padding: null;
$gl-slider-arrow-bg-position: null;
$gl-slider-arrow-border-radius: null;
$gl-slider-arrow-bg-color: null;
$gl-slider-arrow-opacity: .7;
$gl-slider-arrow-height-before-map: (
	small: 2.813rem,
	medium: null,
	large: 4.75rem,
	xlarge: null
);
$gl-slider-arrow-width-before-map: (
	small: 1.438rem,
	medium: null,
	large: 2.5rem,
	xlarge: null
);
$gl-play-icon-scale: .5;
$gl-filmstrip-arrow-padding: 1.25rem 0;
$gl-filmstrip-padding-left: 2.188rem;
$gl-filmstrip-padding-right: 2.188rem;
$gl-mms-hover-slider-arrow-opacity: .4;
$gl-filmstrip-arrow-bg-color: $gl-background-color;
$gl-filmstrip-arrow-opacity: 1;
$gl-filmstrip-arrow-height: 100%;
$gl-filmstrip-arrow-width: 1.938rem;
$gl-filmstrip-arrow-before-height: 1.938rem;
$gl-filmstrip-arrow-before-width: 2.125rem;
$gl-filmstrip-arrow-before-opacity: .5;
$gl-filmstrip-background-size: contain;
$gl-filmstrip-background-position: center center;
$gl-filmstrip-item-margin: 0 .9375rem;
$gl-filmstrip-item-border: 3px solid $brand-primary-color;
$gl-thumbnail-item-border: 3px solid $brand-primary-color;
$gl-border-offeset: -3px;
$gl-hover-cursor: arrow;
$gl-close-reveal-padding: .625rem;
$gl-close-reveal-top: 0;
$gl-close-reveal-right: 0;
$gl-close-margin-map: (
	small: 1.125rem 5%,
	large: .875rem 0 0 0,
	xlarge: .875rem 0 0 0
);
$gl-close-icon-size: $svg-icon-large;
$gl-close-icon: close-grey-dark;
$gl-close-icon-hover: null;
$gl-thumbnail-headlines: q-descriptive1;
$gl-thumbnail-headline-color-override: null;
$gl-thumbnail-headline-transform: uppercase;
$gl-thumbnail-headline-margin-bottom: null;
$gl-thumbnail-margin-map: (
	small: 0 5% 1.875rem 0,
	medium: 0 4.6% 1.875rem 0
);
$gl-thumbnail-width-map: (
	small: 30%,
	medium: 21.5%
);
$gl-category-button: q-headline1;
$gl-category-title-headline: q-headline2;
$gl-category-title-font-size: null;
$gl-category-title-open-color: null;
$gl-category-title-padding: null;
$gl-category-button-text-color: $grey-dark;
$gl-button-label-font-size: .75rem;
$gl-button-placeholder: null;
$gl-button-invert-placeholder: null;
$gl-dropdown-border: 0;
$gl-dropdown-color: null;
$gl-dropdown-carat: carat-down-white;
$gl-dropdown-carat-open: carat-down-white;
$gl-dropdown-carat-open-hover: carat-down-white;
$gl-dropdown-carat-hover:  carat-down-white;
$gl-dropdown-carat-size: $svg-icon-medium;
$gl-dropdown-carat-transform: null;
$gl-dropdown-carat-open-rotate: 0deg;
$gl-dropdown-button-open-color: null;
$gl-dropdown-width-map: (
	small: null,
	large: null,
	xlarge: null
);
$gl-dropdown-max-width-map: (
	small: 15.44rem,
	large: null,
	xlarge: 20.5rem
);
$gl-dropdown-item-active-border: 3px solid $brand-primary-color;
$gl-dropdown-item-padding: null;
$gl-dropdown-hover-color: $gl-background-color;
$gl-dropdown-hover-bg-color: $button-secondary-invert-hover-background-color;
$gl-dropdown-bg-color: $gl-background-color;
$gl-dropdown-button: null;
$gl-dropdown-padding-map: (
	small: .3125rem .3125rem .3125rem 5%,
	large: .3125rem .3125rem .3125rem 1.875rem,
	xlarge: .3125rem .3125rem .3125rem 1.875rem
);
$gl-dropdown-margin-shift: 0;
$gl-category-item-button: null;
$gl-next-prev-arrow: slick-arrow-grey;
$gl-caption-font-weight: normal;
$gl-caption-background-color: $grey-light;
$gl-disclosure-link-color: $brand-primary-color;
$gl-disclosure-right-position: calc(1.875rem + 17px);
$gl-disclosure-padding: 0 0 1.563rem;

// Tab Nav
$tab-nav-background-color: $grey-very-light;
$tab-nav-background-color-active: $white;
$tab-nav-background-color-active-hover: $white;
$tab-nav-background-color-hover: $white;
$tab-nav-border: $border-grey-mid;
$tab-nav-border-width: 0 1px 1px 1px;
$tab-nav-button-border: $border-grey-mid;
$tab-nav-button-border-active: $border-grey-mid;
$tab-nav-button-border-width: 1px 1px 1px 0;
$tab-nav-button-border-width-active: 1px 1px 0 0;
$tab-nav-button-padding: 1.09375rem 0;
$tab-nav-button-icon-size: 3.125rem;
$tab-nav-disable-background-color: $white;
$tab-nav-disable-expander-line-height: $expander-button-line-height;
$tab-nav-disable-expander-font-size: $expander-button-font-size;
$tab-nav-disable-expander-border: 0;
$tab-nav-disable-expander-button-border: $border-grey-mid;
$tab-nav-disable-font-color: $grey;
$tab-nav-disable-title-border-bottom: 0;
$tab-nav-expander-content-border-bottom: 0;
$tab-nav-expander-margin-active: 0;
$tab-nav-first-child-button-border: $tab-nav-button-border;
$tab-nav-first-child-button-border-active: $tab-nav-button-border-active;
$tab-nav-font-color: $grey;
$tab-nav-font-color-active: $grey-very-dark;
$tab-nav-font-color-hover: $grey-very-dark;
$tab-nav-font-family: $font-family-secondary;
$tab-nav-font-size: .75rem;
$tab-nav-font-weight: $font-weight-bold;
$tab-nav-remove-content-animation: false;
$tab-nav-swap-title-color: $grey-very-dark;
$tab-nav-text-transform: none;
$tab-nav-title-background-color: $white;
$tab-nav-title-border-bottom: 0;
$tab-nav-title-border-bottom-active: 0;
$tab-nav-title-border-bottom-active-hover: 0;
$tab-nav-title-border-bottom-hover: 0;
$tab-nav-title-line-height: 1.25rem;
$tab-nav-use-transition: true;
$swap-tab-center-align: false;
$swap-tab-expander-background-color: $white;
$swap-tab-expander-border-color: $white;
$swap-tab-font-size: $tab-nav-font-size;
$swap-tab-line-height: 1.25rem;

// RTE Modal
$rte-launch-modal-hover-color: $gold;

// Primary Nav
$nav-primary-brand-logo-flex: none;
$nav-primary-brand-logo-max-width: none;
$nav-primary-brand-logo-padding: 0 .469rem;
$nav-primary-brand-logo-width: 5.625rem;
$nav-primary-collapsed-max-height: calc(100vh - 4.688rem);
$nav-primary-collapsed-menu-icon-size: $svg-icon-medium;
$nav-primary-flyout-back-button-icon-position-left: 2rem;
$nav-primary-flyout-back-button-icon-position-top: -.10rem;
$nav-primary-flyout-back-button-icon-transform: rotate(90deg);
$nav-primary-flyout-back-button-label-margin-left: 2rem;
$nav-primary-flyout-back-button-label-padding-left: 1.125rem;
$nav-primary-flyout-background-color: $white;
$nav-primary-flyout-icon-transform-collapsed: 0deg;
$nav-primary-flyout-icon-transform-opened: 180deg;
$nav-primary-link-label-color: $grey;
$nav-primary-link-label-flyout-open-color: $grey-dark;
$nav-primary-link-label-hover-color: $grey-dark;
$nav-primary-link-label-letter-spacing: 0;
$nav-primary-link-label-line-height: 1.375;
$nav-primary-link-label-max-width: 8.75rem;
$nav-primary-link-margin-large: 0;
$nav-primary-overlay-background-color: $grey-very-dark;
$nav-primary-top-bar-background-color: $grey-very-light;
$nav-primary-top-bar-border-bottom: .0625rem solid $grey-mid;
$nav-primary-top-bar-section-padding: .625rem;

// Primary Nav viewport maps
$nav-primary-content-nav-border-map: (
	small: none,
	large: none
);
$nav-primary-content-nav-justify-content-map: (
	small: flex-end,
	large: flex-end,
	xlarge: flex-start
);
$nav-primary-content-nav-margin-map: (
	small: 0,
	xlarge: 0
);
$nav-primary-content-nav-padding-map: (
	small: 0,
	large: 0,
	xlarge: 0
);
$nav-primary-link-label-font-size-map: (
	small: .75rem,
	xlarge: .938rem,
	xxlarge: 1.125rem
);
$nav-primary-nav-list-container-justify-content-map: (
	small: flex-end,
	xlarge: space-between
);
$nav-primary-nav-list-item-margin-map: (
	small: 0,
	large: 0
);
$nav-primary-nav-list-item-padding-map: (
	small: 0 .469rem,
	xlarge: 0 1.125rem
);
$nav-primary-top-bar-icon-size-map: (
	small: .875rem,
	large: $svg-icon-medium
);
$nav-primary-top-bar-padding-map: (
	small: 0,
	xlarge: .9375rem
);

// Payment Estimator
$pe-vehicle-selector-config-trim-typography: q-descriptive1;
$pe-vehicle-selector-title-typography: q-display3;
$pe-postalcode-popup-typography: q-body2;
$pe-expander-button-typography: q-descriptive2;
$pe-leasebox-offererror-typography: $pe-expander-button-typography;
$pe-starting-price-typography: q-descriptive2;
$pe-finance-lease-title-tagline-typography: $pe-starting-price-typography;
$pe-finance-lease-error-typography: $pe-starting-price-typography;
$pe-customize-payment-title-typography: q-body1;
$pe-label-typography: q-descriptive2;
$pe-financebox-credits-typography: q-body2;
$pe-finance-input-error-typography: $pe-financebox-credits-typography;
$pe-leasebox-inline-disclaimer-typography: $pe-financebox-credits-typography;
$pe-financebox-payment-typography: q-display2;
$pe-financebox-fschedule-typography: q-bold2;
$pe-unacceptable-trade-typography: $pe-financebox-fschedule-typography;
$pe-financebox-left-right-typography: q-body;
$pe-leasebox-credits-typography: $pe-financebox-left-right-typography;
$pe-finance-lease-title-typography: q-headline-2;
$pe-finance-lease-termamount-typography: q-headline3;
$pe-tab-border-color: $grey-mid;
$pe-button-border-color: $pe-tab-border-color;
$pe-content-text-color: $grey-dark;
$pe-brand-padding: 1.25rem;
$pe-customize-payment-background-color: $grey-light;
$pe-brand-modal-background-color: rgba(0, 0, 0, .85);
$pe-brand-hover-cursor: pointer;
$pe-content-background-color: $white;
$pe-postalcode-location-icon: geolocation;
$pe-postalcode-submit-icon: search-icon-white;
$pe-finance-no-options-typography: q-descriptive2;
$pe-finance-lease-box-padding: 1.25rem 1.25rem 0;
$pe-error-color: $red;
$pe-dropdown-fterm-flex: 0 1 75%;
$pe-financebox-flex-left-column: 0 1 50%;
$pe-customize-payment-expander-icon: plus-dark;
$pe-customize-payment-expander-icon-active: minus-dark;
$pe-financedetails-flex-right-typography: $pe-financebox-left-right-typography;
$pe-brand-modal-content-width: 66.66%;
$pe-brand-modal-margin: 0 auto;
$pe-brand-modal-content-color: $white;
$pe-brand-modal-typography: q-body2;
$pe-brand-modal-close-icon: close;
$pe-brand-modal-close-icon-hover: null;
$pe-modal-rewards-card-title: q-descriptive1;
$pe-modal-rewards-card-close-icon: close-circle-dark;
$pe-modal-rewards-card-close-icon-hover: null;
$pe-modal-error-typography: $pe-finance-input-error-typography;
$pe-q-toggle-typography: q-body;
$pe-checkmark: check;

// Nav Segment
$nav-segment-content-container-background-color: $white;
$nav-segment-content-item-background-color: $white;
$nav-segment-content-item-font: $font-weight-bold .75rem $font-family-secondary;
$nav-segment-content-item-label-padding: null;
$nav-segment-content-item-letter-spacing: null;
$nav-segment-content-item-hover-background-color: inherit;

$nav-segment-content-container-border-map: (
	small: none,
	xlarge: none
);
$nav-segment-content-container-padding-map: (
	small: 0,
	xlarge: 0
);
$nav-segment-content-item-border-map: (
	small: 1px solid $grey-mid,
	xlarge: 1px solid $grey-mid
);
$nav-segment-content-item-color-map: (
	small: $grey-dark,
	xlarge: $grey
);
$nav-segment-content-item-flex-map: (
	small: 0 0 100%,
	xlarge: 1 1 auto
);
$nav-segment-content-item-justify-content-map: (
	small: left,
	xlarge: center
);
$nav-segment-content-item-padding-map: (
	small: 1rem 1.875rem,
	large: 1.375rem 1.875rem,
	xlarge: 0
);
$nav-segment-content-item-text-align-map: (
	small: left,
	xlarge: center
);

// Table
$table-brand-subheading-color: $white;
$table-brand-subheading-bgcolor: $grey-dark;
$table-brand-subheading-padding-bottom: 0;
$table-brand-subheading-padding-top: 0;
$table-headline-font-size: .9375rem;
$table-text-font-size: .875rem;
$table-text-padding-left: .5rem;
$table-text-padding-top: .625rem;
$table-text-padding-bottom: .625rem;
$table-text-margin: 0 auto;
$table-cell-vs-top: 2.5rem;
$table-cell-border-right-color: $grey-very-light;
$table-row-headline-bgcolor: $grey-very-light;

// Trim Info
$trim-info-image-container-margin-bottom: .625rem;
$trim-info-image-container-padding-bottom: 0;
$trim-info-image-container-width: 100%;
$trim-info-text-price-letter-spacing: normal;
$trim-info-details-margin: 0;
$trim-info-details-padding: 0;
$trim-info-details-vertical-align: bottom;
$trim-info-text-msrp-margin-top: 0;
$trim-info-descriptive-style: q-descriptive1;
$trim-info-descriptive-msrp-style: q-descriptive2;
$trim-info-as-shown-style: q-body2;
$q-trim-info-as-shown-margin-bottom: 0;
$trim-info-body-description-padding-bottom: 0;
$trim-info-full-container-position: relative;
$trim-info-half-container-position: relative;
$trim-info-headline-style: q-headline1;
$trim-info-text-price-float: right;
$trim-info-text-from-visibility: hidden;
$trim-info-text-from-display: none;
$trim-info-text-msrp-display: block;
$trim-info-text-msrp-visibility: visible;
$trim-info-text-container-padding: 0 0 0 0;
$trim-info-body-description-style: q-body2;
$trim-info-full-container-float: left;
$trim-info-full-container-width: 100%;
$trim-info-half-container-float: left;
$trim-info-half-container-padding-bottom: .625rem;
$trim-info-half-container-text-transform: none;
$trim-info-half-container-width: 50%;
$trim-info-button-secondary-margin-top: 0;
$trim-info-button-secondary-padding-left: 0;
$trim-info-button-primary-margin-top: 0;
$trim-info-button-primary-margin-padding-right: 0;

//Transmission Info - Product Data Card
$transmission-info-margin-bottom: .625rem;
$transmission-info-headline-style: q-descriptive1;
$transmission-info-headline-color: $brand-body1-color;
$transmission-info-headline-hover-color: $brand-body1-color;
$transmission-info-text-padding-top: none;
$transmission-info-price-style: q-body2;
$transmission-info-price-font-weight: $font-weight-normal;
$transmission-info-description-style: q-body2;
$transmission-info-description-header-style: q-caps;
$transmission-info-description-header-font-weight: $font-weight-normal;
$transmission-info-description-padding-top: 0;
$transmission-info-transition: none;
$transmission-info-border: .1875rem solid $gold;
$transmission-info-padding: 1rem;
$transmission-info-details-container-padding-map: (
	small: 0,
	large: 0
);
$transmission-info-description-detail-padding-bottom: 1rem;
$transmission-info-text-padding: 0;
$transmission-info-position-bottom: 0;
$transmission-info-hover-position-bottom: 0;


// Youtube Video
$modal-close-icon-height: 1.875rem;
$modal-close-icon-width: 1.875rem;
$video-background-color: $black;
$video-play-icon-opacity: .75;
$video-play-icon-height: 5rem;
$video-play-icon-padding-bottom: 0;
// $video-play-icon-width must use the same units as $video-play-icon-hover-width
$video-play-icon-width: 5rem;
$video-play-icon-hover-opacity: 1;
$video-play-icon-hover-padding-bottom: 0;
// $video-play-icon-hover-width must use the same units as $video-play-icon-width
$video-play-icon-hover-width: 5rem;
$video-play-icon-hover-scale: $video-play-icon-hover-width / $video-play-icon-width;
$video-info-padding: .625rem 0 .875rem;
$video-title-padding: 0;
$video-title-font-weight: $font-weight-normal;
$video-title-letter-spacing: 1px;
$video-info-title-typography: q-descriptive2;
$video-title-text-transform: uppercase;

// Video Brightcove
$video-bc-aspect-ratio: 56.25%;
$video-bc-info-background: $grey-very-light;
$video-bc-info-height: 3.125rem;
$video-bc-info-padding: .625rem 1.25rem .875rem;
$video-bc-info-inline-padding: .625rem 0 .875rem;
$video-bc-play-icon-height: 5rem;
$video-bc-play-icon-padding-bottom: 0;
// $video-bc-play-icon-width must use the same units as $video-bc-play-icon-hover-width
$video-bc-play-icon-width: 5rem;
// $video-bc-play-icon-hover-width must use the same units as $video-bc-play-icon-width
$video-bc-play-icon-hover-width: 5rem;
$video-bc-play-icon-hover-padding-bottom: 0;
$video-bc-play-icon-hover-scale: $video-bc-play-icon-hover-width / $video-bc-play-icon-width;
$video-bc-play-icon-opacity: .75;
$video-bc-play-icon-hover-opacity: 1;
$video-bc-title-padding: 0;
$video-bc-title-color: $grey;
$video-bc-title-font-weight: $font-weight-normal;
$video-bc-title-letter-spacing: 1px;
$video-bc-title-text-transform: uppercase;
$video-bc-title-typography: q-descriptive2;
$video-bc-time-color: $video-bc-title-color;

//Tertiary Navigation
$tertiary-button-padding: 1.875rem 1.75rem;
$tertiary-list-item-color: $grey-dark;
$tertiary-list-item-border-top: 1px solid $grey-mid;
$tertiary-list-item-border-left: 3px solid transparent;
$tertiary-list-item-hover-border-left-color: $brand-primary-color;
$tertiary-list-item-hover-color: null;
$tertiary-list-layout-border-top: 1px solid $grey-mid;
$tertiary-list-layout-border-bottom: 1px solid $grey-mid;
$tertiary-mobile-background: null;
$tertiary-nav-active-link-border: 3px solid $brand-primary-color;
$tertiary-nav-background-color: null;
$tertiary-nav-flex-display: null;
$tertiary-nav-font-family: $font-family-secondary !default;
$tertiary-nav-font-weight: $font-weight-bold;
$tertiary-nav-justify-content: null;
$tertiary-nav-list-padding: null;
$tertiary-nav-list-item-active-color: $grey-dark;
$tertiary-nav-list-item-color: $tertiary-nav-list-item-active-color;
$tertiary-nav-link-active-color: $grey-dark;
$tertiary-nav-link-bottom: .75rem 0;
$tertiary-nav-link-font-size: .75rem;
$tertiary-nav-link-position: null;
$tertiary-nav-link-text-color: $grey;
$tertiary-nav-link-text-letter-spacing: null;
$tertiary-nav-link-text-line-height: null;
$tertiary-nav-link-transition: null;
$tertiary-nav-hover-after-bottom: null;
$tertiary-nav-hover-after-opacity: null;
$tertiary-nav-hover-after-transition: null;
$tertiary-nav-hover-background: null;
$tertiary-nav-hover-border-bottom: 3px solid $brand-primary-color;
$tertiary-nav-hover-border-color: null;
$tertiary-nav-hover-bottom: 0;
$tertiary-nav-hover-color: $grey-dark;
$tertiary-nav-hover-content: '';
$tertiary-nav-hover-height: 0;
$tertiary-nav-hover-left: 50%;
$tertiary-nav-hover-opacity: null;
$tertiary-nav-hover-position: absolute;
$tertiary-nav-hover-right: 0;
$tertiary-nav-hover-transition: width #{$anim-fast}s ease, left #{$anim-fast}s ease;
$tertiary-nav-hover-width: 0;

%tertiary-nav-button {
	padding: $tertiary-button-padding;
}

// Positioning
// - - - - - - - - - - - - - - - - - - - - - - - - -
$position-z-front: 100;
$position-z-mid: 1;
$position-z-back: -1;
$position-z-top: 55;

%sticky {
	left: 0;
	position: fixed;
	right: 0;
	transform: translateZ(0);
}

// Secondary Navidation
$nav-secondary-dropdown-has-content-bg-color: $white;
$nav-secondary-dropdown-box-bg-color: $white;
$nav-secondary-overlay-bg-color: $grey-very-dark;
$nav-secondary-sibling-medium-bg-color: $grey-very-light;
$nav-secondary-active-border-color: $gold;
$nav-secondary-section-header-bg-color: $grey-dark;
$nav-secondary-sibling-container-bg-color: $grey-very-light;
$nav-secondary-sibling-list-bg-color: $grey-very-light;
$nav-secondary-list-anchor-color: $grey;
$nav-secondary-list-anchor-hover-color: $grey-dark;
$nav-secondary-list-anchor-after-border-color: $gold;
$nav-secondary-list-anchor-after-bottom-position: 0;
$nav-secondary-anchor-bg-color: $grey-very-light;
$nav-secondary-anchor-sticky-top-color: $grey-very-light;
$nav-secondary-border-color: $grey-light;
$nav-secondary-nav-anchor-border-bottom: .0625rem solid $nav-secondary-border-color;
$nav-secondary-nav-anchor-border-top: .0625rem solid $nav-secondary-border-color;
$nav-secondary-anchor-nav-sticky-border-bottom: .0625rem solid $grey-light;
$nav-secondary-anchor-nav-sticky-border-top: .0625rem solid $grey-light;

$nav-secondary-section-header-subtitle-invert-text-color: $white;
$nav-secondary-section-header-subtitle-font-family: $font-family-primary;
$nav-secondary-cta-list-item-font-size: .75rem;
$nav-secondary-cta-list-item-font-weight: $font-weight-bold;
$nav-secondary-headline-color: $brand-headline-color;
$nav-secondary-section-header-subtitle-font-weight: $font-weight-lighter;
$nav-secondary-section-header-subtitle-text-align: left;
$nav-secondary-cta-hover-border-color: $gold;
$nav-secondary-cta-color: null;
$nav-secondary-cta-border-fill: true;
$nav-secondary-cta-fill-color: $button-primary-border-fill-color;
$nav-secondary-button-font-weight: normal;
$nav-secondary-section-header-border-bottom: null;

$nav-secondary-button-font-size-map: (
	desktop: 1.875rem,
	tablet: 1.875rem,
	mobile: 1.375rem
);
$nav-secondary-sibling-font-size-map: (
	xxlarge: 1.125rem,
	xlarge: .9375rem,
	small: .75rem
);
$nav-secondary-section-header-subtitle-font-size-map: (
	desktop: $descriptive2-font-size-xl,
	tablet: $descriptive2-font-size-lm,
	mobile: $descriptive2-font-size-xs
);
$nav-secondary-section-header-subtitle-line-height-map: (
	desktop: $descriptive2-line-height-xl,
	tablet: $descriptive2-line-height-lm,
	mobile: $descriptive2-line-height-xs
);
$nav-secondary-nav-button-padding: .938rem 0 .938rem 1.5rem;
$nav-secondary-cta-list-item-first-child: .0625rem solid $grey-mid;
$nav-secondary-sibling-cta-list-padding-top: .25rem;
$nav-secondary-sibling-cta-padding-left: .75rem !default;
$nav-secondary-sibling-cta-padding-right: $nav-secondary-sibling-cta-padding-left !default;
$nav-secondary-sibling-large-cta-anchor-padding-top: 1.25rem;
$nav-secondary-anchor-list-item-font-size: .75rem;
$nav-secondary-nav-title-width-map: (
	xlarge: 320px,
	large: 320px,
	medium: 100%,
	small: 100%
);
$nav-secondary-dropdown-box-width-map: (
	xlarge: 35%,
	large: 70%,
	medium: 70%,
	small: 100%
);

// Year Toggle
$nav-secondary-nav-title-year-toggle-width-map: (
	xlarge: 320px,
	large: 320px,
	medium: 85%,
	small: 85%
);

// Search Field
$search-field-button-background-color: $grey;
$search-field-button-font-family: $font-family-secondary;
$search-field-button-height: 3.75rem;
$search-field-button-hover-color: $grey-dark;
$search-field-button-width: 4.375rem;
$search-field-icon: search-field-icon;
$search-field-input-border: .0625rem solid $grey-mid;
$search-field-input-border-color: $grey-mid;
$search-field-input-color: $grey-dark;
$search-field-input-focus-border-color: $grey-mid;
$search-field-input-font-family: $font-family-secondary;
$search-field-input-font-size: 1.125rem;
$search-field-input-height: 3.75rem;
$search-field-input-padding: 0 0 0 1.875rem;
$search-field-input-placeholder-color: $grey-mid;
$search-field-suggest-items-border: .0625rem solid $grey-mid;
$search-field-suggest-item-font-family: $font-family-secondary;

// Search Results
$search-field-content-box-link-color: $grey-dark;
$search-field-content-box-link-font-family: $font-family-secondary;
$search-field-content-box-link-hover-color: $gold;
$search-field-results-heading-color: $grey-dark;
$search-field-results-heading-font-family: $font-family-primary;
$search-field-search-summary-link-color: $grey-dark;
$search-field-search-summary-link-font-family: $font-family-secondary;
$search-results-button-hover-color: $grey-dark;
$search-results-hover-color: $grey-dark;
$search-results-link-hover-color: $gold;
$search-results-summary-color: $grey-dark;
$search-results-summary-font-family:  $font-family-secondary;

// Animation
//- - - - - - - - - - - - - - -
$spinner-border: rgba($white, .2);
$spinner-border-width: rem-calc(10);
$spinner-color: $brand-primary-color;
$spinner-height: rem-calc(90);
$spinner-width: $spinner-height;

// Dealer Locator Widget
$dl-widget-dealer-title-flex: 1;
$dl-widget-dealer-title-max-width: auto;
$dl-widget-dealer-search-margin-bottom: auto;
$dl-widget-dealer-search-field-input-height: 2.5rem;
$dl-widget-dealer-search-button-height: 2.5rem;
$dl-widget-dealer-search-clear-searchbox-height: 2.5rem;
$dl-widget-dealer-search-button-width: 2.5rem;
$dl-widget-details-cta-flex: 0 0 50%;
$dl-widget-advanced-search-label-flex: 0 0 12.5rem;
$dl-widget-dealer-search-flex: 0 0 18.75rem;
$dl-widget-advanced-search-max-width: auto;
$dl-widget-advanced-search-label-margin: .625rem;
$dl-widget-shopping-links-flex: 0 0 15.125rem;

// Dealer Locator
$dl-shopping-link-padding: .5rem;
$dl-checkbox-background-color: $grey-light;
$dl-checkbox-font-label-color: $grey;
$dl-checkbox-label-font-size: .75rem;
$dl-checkbox-label-font-weight: $font-weight-bold;
$dl-checkbox-label-font-family: $font-family-secondary;
$dl-checkbox-size: 1.25rem;
$dl-checkbox-check-size: 2.188rem;
$dl-checkbox-margin: .75rem 0 0 .375rem;
$dl-check-icon-color: $grey;
$dl-details-cta-flex: 0 0 50%;
$dl-location-button-height: 3.75rem;
$dl-location-button-width: 3.75rem;
$dl-clear-searchbox-height: $dl-location-button-height;
$dl-open-until-color: $green;
$dl-open-until-font-size: 1.2rem;
$dl-search-button-border-color: $dusty-grey;
$dl-filter-dropdown-border-width: 1px;
$dl-search-column-width: 32rem;
$dl-search-label-color: $dusty-grey;
$dl-search-label-font-size: .875rem;
$dl-search-text-color: $grey-dark;
$dl-search-text-font-size: inherit;
$dl-search-by-flex-medium: 0 0 100%;
$dl-radio-label-color: $grey;
$dl-radio-label-font-size: .75rem;
$dl-radio-label-font-weight: $font-weight-bold;
$dl-radio-button-padding-top: .125rem;
$dl-dealer-search-button-background-color: $grey;
$dl-dealer-search-button-background-color-hover: $grey;
$dl-show-active-dealer-pin-display: inline-block;
$dl-dealer-info-font-size: $base-font-size-xl;
$dl-dealer-info-font-size-sm: $base-font-size-xl;
$dl-dealer-name-info-margin-bottom: .8rem;
$dl-dealer-name-font: q-descriptive1;
$dl-dealer-name-font-weight: inherit;
$dl-dealer-name-hover-color: $gold;
$dl-label-font-weight: $font-style-normal;
$dl-filter-label-font-size: .75rem;
$dl-filter-button-color: $grey;
$dl-filter-button-width: 4rem;
$dl-filter-dropdown-border-color: $dark-grey;
$dl-filter-dropdown-label-color: inherit;
$dl-filter-dropdown-label-font-size: inherit;
$dl-filter-label-font-weight: inherit;
$dl-filter-dropdown-margin-top: .375rem;
$dl-filter-dropdown-carrot-border: none;
$dl-filter-tag-button-border: none;
$dl-filter-tag-button-background-color: $grey-very-light;
$dl-filter-tag-color: $grey;
$dl-filter-button-font-size: inherit;
$dl-filter-icon-font-size: .75rem;
$dl-filter-icon-font-weight: $font-weight-normal;
$dl-filter-label-font-family: $font-family-secondary;
$dl-close-button-color: $grey-mid;
$dl-close-button-border-color: $dl-close-button-color;
$dl-close-button-font-size: .875rem;
$dl-close-button-padding: .375rem;
$dl-contact-icon-display: inline-block;
$dl-contact-icon-height: 1.4rem;
$dl-contact-icon-width: 1.4rem;
$dl-contact-icon-background-horizontal-position: center;
$dl-pagination-margin: auto;
$dl-pagination-padding: auto;
$dl-pagination-font-weight: inherit;
$dl-dealer-location-color: $grey;
$dl-contact-link-font-size: 1.2rem;
$dl-contact-link-color: $grey;
$dl-contact-link-color-hover: $brand-link-color-hover;
$dl-contact-link-border-top: 1px solid $grey-mid;
$dl-contact-link-vertical-padding: 1rem 0;
$dl-modal-title-text-align: center;
$dl-modal-close-icon-size: 1.875rem;
$dl-modal-close-icon: close-circle-dark;
$dl-modal-close-icon-hover: close-circle-dark;
$dl-dealer-details-background-color: $white;
$dl-modal-subtitle-font-family: $font-family-sans;
$dl-details-arrow-prev: slick-arrow-grey;
$dl-details-arrow-next: slick-arrow-grey;
$dl-details-arrow-size: 1.875rem;
$dl-info-icon: info-i;
$dl-info-icon-bg-color: $brand-primary-color;
$dl-info-icon-font-size: 1rem;
$dl-pin-size: 2.25rem;
$dl-pin-label-font: q-descriptive2;
$dl-pin-active-color: $grey-dark;
$dl-pin-icon-line-height: inherit;

// Global Forms
$gf-button-font-family: $font-family-secondary;
$gf-button-weight: $font-weight-bold;
$gf-checkbox-label-padding: 0 0 0 2rem;
$gf-dropdown-square-color: $grey;
$gf-input-border-focus: $grey;
$gf-radio-checkbox-focus: 1px solid $chevy-yellow;
$gf-radio-button-color: $grey;
$gf-checkbox-border: 1px solid $grey-light;
$gf-checkbox-active-border-color: $gf-checkbox-border;
$gf-label-text-color: $grey;
$gf-accordion-border-color: $medium-grey;
$gf-brochure-selected-background-color: $medium-grey;
$gf-brochure-disabled-checkbox-background: $medium-grey;
$gf-brochure-disabled-checkbox-border: $medium-grey;
$gf-brochure-disabled-background: $white;
$gf-button-text-color: $grey-dark;
$gf-button-text-hover-color: $grey-dark;
$gf-dropdown-text-color: $grey-dark;
$gf-dropdown-square-hover: $grey-dark;
$gf-input-text-color: $grey-dark;
$gf-label-text-bold: $grey-dark;
$gf-reveal-modal-background: $grey-dark;
$gf-reveal-modal-border: $grey-dark;
$gf-input-border: 1px solid $grey-mid;
$gf-placeholder-text: $grey-mid;
$gf-multi-link-disabled-color: $dusty-grey;
$gf-disabled-checkbox-color: $dusty-grey;
$gf-disabled-checkbox-border: $dusty-grey;
$gf-datepicker-month-color: $dusty-grey;
$gf-button-disabled-background: $dusty-grey;
$gf-modal-close-icon-border: $gallery-grey;
$gf-datepicker-header-border: $gallery-grey;
$gf-table-border: $gallery-grey;
$gf-data-handler-background: $gallery-grey;
$gf-dealer-items-border-color: $dark-grey;
$gf-groupbox-icon-color: $dark-grey;
$gf-groupbox-title-border-color: $white;
$gf-expander-icon-color: $grey-mid;
$gf-brochure-border-color: $dark-grey;
$gf-dealer-radio-label-text: $dark-grey;
$gf-multi-link-tab-visited-border: $dark-grey;
$gf-multi-link-accordion-active-border: $dark-grey;
$gf-multi-link-tab-active-border: $yellow;
$gf-multi-link-accordion-active-top: $yellow;
$gf-datepicker-day-background-color: $yellow;
$gf-button-border: 2px solid $yellow-mid;
$gf-button-border-hover: 2px solid $yellow-mid;
$gf-button-bg-hover-color: $yellow-mid;
$gf-request-type-background: $white;
$gf-multi-step-bg-color: $white;
$gf-groupbox-border-color: $white;
$gf-multi-link-disabled-border-bottom-color: $white;
$gf-multi-link-tab-active-bg: $white;
$gf-multi-link-bg-color: $white;
$gf-multi-link-border-color: $white;
$gf-accordion-content-bg-color: $white;
$gf-multi-step-span-bg-color: $white;
$gf-brochure-bg-color: $white;
$gf-datepicker-focus-bg-color: $white;
$gf-datepicker-disabled-bg-color: $white;
$gf-datepicker-icon-bg-color: $white;
$gf-datepicker-bg-color: $white;
$gf-datepicker-calendar-bg-color: $white;
$gf-datepicker-border-color: $white;
$gf-dropdown-bg-color: $white;
$gf-thank-you-bg-color: $white;
$gf-reveal-modal-text: $white;
$gf-modal-headline-text: $white;
$gf-modal-close-icon-bg: $white;
$gf-flex-content: $white;
$gf-datepicker-disabled-text: $black;
$gf-separator-color: $black;
$gf-small-label-text: $black;
$gf-dealer-count-color: $black;
$gf-checkbox-checked-label: $black;
$gf-brochure-icon-color: $black;
$gf-image-label: $black;
$gf-modal-close-icon-color: $black;
$gf-confirmation-color: $black;
$gf-groupbox-title-color: $grey-dark;
$gf-groupbox-title-size: 1rem;
$gf-carline-bodystyle-color: $black;
$gf-thank-you-text: $black;
$gf-thank-you-dealer-text: $black;
$gf-pricing-table-text: $black;
$gf-group-title-text: $black;
$gf-error-color: $red;
$gf-checkbox-background-color: $grey-light;
$gf-checkbox-active-background-color: $gf-checkbox-background-color;
$gf-radio-box-shadow: inset 0 0 0 .275rem $white;
$gf-radio-checked-box-shadow: $gf-radio-box-shadow;
$gf-expander-minus-icon: $gold;
$gf-check-icon: check;
$gf-dropdown-icon: arrow-small-down-white;
$gf-dropdown-hover-icon: $gf-dropdown-icon;
$gf-label-text-font: $font-family-primary;
$gf-dropdown-size-map: (
	large: '.625rem .625rem, 4.375rem 100%',
	medium: '.625rem .625rem, 3.75rem 100%',
	small: '.625rem .625rem, 3.75rem 100%'
);
$gf-dropdown-position-map: (
	large: 'calc(100% - 1.75rem) calc(1em + .4375rem), 100% 0',
	medium: 'calc(100% - 1.5rem) calc(1em + .3125rem), 100% 0',
	small: 'calc(100% - 1.5rem) calc(1em + .3125rem), 100% 0'
);
$gf-label-text-weight: $font-weight-lighter;
$gf-label-text-transform: uppercase;
$gf-label-text-font-size-map: (
	small: .75rem,
	large: .875rem
);
$gf-input-height-map: (
	small: 3.125rem,
	medium: 3.125rem,
	large: 3.75rem
);
$gf-checkbox-radio-label-font: .75rem;
$gf-checkbox-label-margin: 0;
$gf-checkbox-height: 1.25rem;
$gf-checkbox-width: 1.25rem;
$gf-checkbox-margin: 0;
$gf-checkbox-top-position: 0;
$gf-radio-height: 1.25rem;
$gf-radio-width: 1.25rem;
$gf-radio-border-radius: .625rem;
$gf-radio-border: 1px solid $grey;
$gf-radio-label-padding: 0 .5rem 1rem 1.5rem;
$gf-radio-top-position: 0;
$gf-dropdown-border: 1px solid $grey-mid;
$gf-paragraph-font-size: .875rem;
$gf-button-height-map: (
	small: 3rem,
	medium: 3rem,
	large: 3.25rem
);
$gf-button-padding: .75rem 3rem;
$gf-button-transform: none;
$gf-button-font-size: .75rem;
$gf-button-line-height: 1.375rem;
$gf-expand-icon-size: 1.5rem;
$gf-white-space: nowrap;
$gf-dealer-hours-font: .875rem;
$gf-dealer-hours-padding: .75rem;
$gf-input-padding-map: (
	small: .0625rem .0625rem .0625rem .625rem,
	medium: .0625rem .0625rem .0625rem 1.25rem,
	large: .0625rem .0625rem .0625rem 1.875rem
);
$gf-dropdown-padding-map: (
	small: .5625rem 4.375rem .5625rem .625rem,
	medium: .5625rem 4.375rem .5625rem 1.25rem,
	large: .9375rem 4.375rem .9375rem 1.875rem
);
$gf-textarea-padding-map: (
	small: .625rem,
	medium: 1.25rem 1.875rem 1.25rem 1.25rem,
	large: 1.25rem 1.875rem
);
$gf-input-font-size-map: (
	small: .875rem,
	medium: 1.125rem,
	large: 1.125rem
);
$gf-dealer-search-animation: .625rem solid $chevy-yellow;
$gf-link-font-size: 1rem;
$gf-optional-details-icon-left: 8.125rem;
$gf-radio-checkbox-weight: $font-weight-bold;
$gf-label-text-letter-spacing: .09em;
$gf-label-line-height: 1.25rem;
$gf-link-font-weight: $font-weight-medium;
$gf-link-color: $grey-dark;
$gf-link-hover-color: $gold;
$gf-mobile-focus-font-size: 1rem;
$gf-flexbox-padding: 0 1rem;
$gf-error-label-font-size: .875rem;
$gf-checkbox-check-top-position: -.2rem;
$gf-checkbox-check-left-position: .3125rem;
$gf-checkbox-check-width: auto;
$gf-secondary-button-border: 2px solid $grey-mid;
$gf-secondary-button-border-hover: 2px solid $grey-mid;
$gf-button-bg-color: transparent;
$gf-secondary-button-bg-color: transparent;
$gf-secondary-button-bg-hover-color: $grey-mid;
$gf-secondary-button-text-hover-color: $grey-dark;
$gf-secondary-button-padding: .935rem 3rem;
$gf-secondary-button-text-color: $grey-dark;
$gf-error-line-height: 1.25rem;
$gf-input-line-height: 1.875rem;
$gf-search-result-item-margin: 0;
$gf-input-font-family: $font-family-secondary;
$gf-link-font-family: $font-family-secondary;
$gf-link-decoration: underline;
$gf-link-hover-decoration: underline;
$gf-label-bottom-margin-map: (
	small: .625rem,
	medium: 1.25rem
);
$gf-vehicle-selector-headline-font: q-descriptive1;
$gf-vehicle-selector-subheadline-font: q-body2;
$gf-secondary-button-font-weight: $font-weight-bold;
$gf-button-letter-spacing: normal;
$gf-error-border-style: 3px solid $gf-error-color;
$gf-groupbox-title-weight: $font-weight-normal;
$gf-paragraph-margin-bottom: 1rem;
$gf-dealer-item-margin-bottom: .5rem;
$gf-groupbox-title-font-family: $font-family-primary;
$gf-request-info-vehicle-label-transform: none;
$gf-input-margin-bottom: 1rem;
$gf-brand-parameter-case: capitalize;
$gf-bold-font-weight: $font-weight-bold;
$gf-dealer-results-checkbox-padding: $gf-checkbox-label-padding;
$gf-optional-expander-icon-color: $gf-link-color;
$gf-optional-expander-icon-hover: $gf-link-hover-color;
$gf-paragraph-body-font: q-body1;

// Global Forms Flexbox
//-- ARE THESE USED SOMEWHERE?? --//
// Kelby will validate and address once he fixes local publisher
$grid--columns: 12;
$grid--gutter: .5rem;

$name--x: gmit-gf-x;
$name--s: gmit-gf-s;
$name--m: gmit-gf-m;
$name--l: gmit-gf-l;
$name--xl: gmit-gf-xl;

$break--x: rem-calc(419);
$break--s: rem-calc(599);
$break--m: rem-calc(959);
$break--l: rem-calc(1199);
$break--xl: rem-calc(1919);

$screen--s:  $break--s + 1;
$screen--m:  $break--m + 1;
$screen--l:  $break--l + 1;
$screen--xl: $break--xl + 1;

//Disclosure Close Icon
$disclosure-close-icon: close;
$disclosure-toast-close-hover-icon: null;
$disclosure-close-small-icon-right: 4.875rem;
$disclosure-close-small-icon-top: 1.75rem;
$disclosure-modal-close-right: 4.375rem;
$disclosure-modal-close-top: 3.75rem;
$disclosure-toast-container-padding: 1.25rem 4.875rem;
$disclosure-toast-height: 2rem;
$disclosure-toast-font-size: 2rem;
$disclosure-close-insert-icon-mq-use: true;
$disclosure-toast-right-map: (
	small: 2.563rem,
	large: 7.875rem
);
$disclosure-content-color: $grey;
$disclosure-text-decoration: underline;
$disclosure-char-border-bottom: null;
$disclosure-link-active-color: inherit;
$disclosure-link-invert-active-color: inherit;
$disclosure-close-icon-height-map: (
	small: $svg-icon-medium,
	large: $svg-icon-large
);
$disclosure-close-icon-width-map: (
	small: $svg-icon-medium,
	large: $svg-icon-large
);
$disclosure-toast-padding-map: (
	small: 1.25rem 4.875rem,
	large: 1.25rem 4.875rem
);
$disclosure-modal-content-padding-map: (
	small: 2.5rem 4.688rem .9375rem 1.875rem,
	large: 1.875rem 11.25rem 3.75rem 3.75rem,
	xlarge: 2.5rem 11.25rem 3.75rem 8.75rem
);
//Vehicle info tile
$vehicle-info-tile-price-label-text-transform: none;
$vehicle-info-tile-price-from-label-font-weight: $font-weight-normal;
$vehicle-info-tile-helpme-margin-bottom: 1.25rem;
$vehicle-info-tile-helpme-text-margin-left: 1rem;
$vehicle-info-tile-with-year-padding: 1.25rem;
$vehicle-info-tile-type-color: $grey-dark;
$vehicle-info-tile-text-hover-color: $brand-link-color-hover;
$vehicle-info-tile-from-label-price-color: $grey;
$vehicle-info-tile-year-color: $grey;
$vehicle-info-tile-animation-border-color: transparent;
$vehicle-info-tile-border: 0;
$vehicle-info-tile-border-bottom: .0625rem solid $grey-mid;
$vehicle-info-tile-hover-animation-top-down: false;
$vehicle-info-tile-text-headline-padding-bottom: 0;
$vehicle-info-tile-type-typography: q-descriptive1;
$vehicle-info-carline-year-typography: q-descriptive2;
$vehicle-info-from-label-typography: $vehicle-info-carline-year-typography;
$vehicle-info-tile-hover-border-bottom: null;
$vehicle-info-tile-hover-position-bottom: null;
$vehicle-info-tile-hover-position-left: 0;
$vehicle-info-tile-hover-width: 100%;
$vehicle-info-tile-hover-before-border: .1875rem solid $gold;
$vehicle-info-tile-hover-after-border: 2px solid $vehicle-info-tile-animation-border-color;
$vehicle-info-tile-hover-border-bottom-transition: width #{$anim-fast}s ease, left #{$anim-fast}s ease;
$vehicle-info-tile-from-label-margin-top: null;
$vehicle-info-tile-from-label-margin-bottom: 0;
$vehicle-info-tile-margin-top: .625rem;
$vehicle-info-tile-margin-bottom: 1.25rem;
$vehicle-info-tile-image-container-padding-bottom: .3125rem;
$vehicle-info-tile-price-as-shown-extend: q-body2;
$vehicle-info-tile-price-as-shown-color: $descriptive2-font-color;
$vehicle-info-tile-price-as-shown-font-weight: null;
$vehicle-info-tile-price-as-shown-line-height: 1.25rem;
$vehicle-info-tile-price-as-shown-margin-bottom: 0;

// Multimedia Scroller
$mms-title-font-weight: $font-weight-normal;
$mms-title-hover-color: $gold;
$mms-slick-dots-active-fill: (
	large: transparent,
	medium: transparent
);
$mms-slick-dots-active-border: (
	large: $grey-mid,
	medium: $grey-mid
);
$mms-svg-icon-size: 2.5rem;
$mms-indicator-background: $grey-mid;
$mms-indicator-border: transparent;
$mms-indicator-border-radius: $scroller-percent-half;
$slick-dot-diameter-hover: 1.9rem;
$slick-dot-diameter-hover-transform: none;
$scroller-arrow-hover-opacity: $scroller-opacity-half;
$mms-transform-indicators: false;
$scroller-overlay-more-bg-color: $white;
$mms-link-lable-color: $grey-dark;
$mms-slick-dots-color: inherit;
$mms-content-text-align: right;
$mms-link-label-color: $grey-dark;
$mms-icon-align: middle;
$mms-title-font: $font-family-primary;

// Content Modal
$modal-use-title-extend: false;
$modal-title-text-align: center;
$modal-title-width: null;
$modal-use-header: false;
$modal-header-background-color: null;
$modal-header-title-color: null;
$modal-header-left: 50%;
$modal-header-margin-left: -45%;
$modal-header-position: absolute;
$modal-header-top: 10vh;
$modal-header-width: 90%;
$modal-content-background-color: $white;
$modal-content-left: 50%;
$modal-content-margin-bottom: 10vh;
$modal-content-margin-left: -45%;
$modal-content-min-height: 12.5rem;
$modal-content-position: absolute;
$modal-content-top: 10vh;
$modal-content-width: 90%;
$modal-content-z-index: 1150;
$modal-use-content-color: false;
$modal-content-color: null;
$modal-close-line-height: 1.2;
$modal-close-position: absolute;
$modal-close-right: .625rem;
$modal-close-icon: close-circle-dark;
$modal-close-video-icon: close-circle-white;
$modal-close-insert-icon-use: false;
$modal-close-icon-animation: false;
$modal-use-close-icon-hover: false;
$modal-close-icon-hover: null;
$modal-close-right-map: (
	small: .625rem,
	medium: 1.563rem
);
$modal-close-top-map: (
	small: 0,
	medium: .3125rem
);
$modal-close-icon-height-map: (
	small: $svg-icon-large,
	medium: $svg-icon-large
);
$modal-close-icon-width-map: (
	small: $svg-icon-large,
	medium: $svg-icon-large
);
$modal-utility-background-top: null;

//Vehicle Info Block Chevy
$vehicle-block-margin-bottom: 2.5rem;
$vehicle-block-info-bar-button-padding: null;
$vehicle-block-info-bar-padding: null;
$vehicle-block-info-bar-position: null;
$vehicle-block-title-hover-color: null;
$vehicle-block-hover-after-left: 0;
$vehicle-block-hover-after-width: 100%;
$vehicle-block-hover-after-border-color: null;
$vehicle-block-hover-after-bottom: null;
$vehicle-block-after-border-bottom: .1875rem solid $brand-primary-color;
$vehicle-block-after-left: 50%;
$vehicle-block-after-width: 0;
$vehicle-block-text-display: table;
$vehicle-block-text-padding: null;
$vehicle-block-title-display-map: (
	small: table-cell,
	large: inline-block
);
$vehicle-block-title-padding-bottom-map: (
	small: 0,
	large: .3125rem
);
$vehicle-block-title-width-map: (
	small: 50%,
	large: 100%
);
$vehicle-block-title-content-extend: q-descriptive1;
$vehicle-block-title-content-color: null;
$vehicle-block-content-margin-bottom: 0;
$vehicle-block-price-display-map: (
	small: table-cell,
	large: inline-block
);
$vehicle-block-price-text-align-map: (
	small: left,
	large: left
);
$vehicle-block-price-vertical-align: bottom;
$vehicle-block-price-width-map: (
	small: 50%,
	large: 100%
);
$vehicle-block-price-label-extend: q-descriptive2;
$vehicle-block-price-label-color: $descriptive2-font-color;
$vehicle-block-price-label-font-weight: null;
$vehicle-block-price-label-line-height: 1.25rem;
$vehicle-block-price-label-margin-bottom: 0;
$vehicle-block-price-as-shown-extend: q-body2;
$vehicle-block-price-as-shown-color: $descriptive2-font-color;
$vehicle-block-price-as-shown-font-weight: null;
$vehicle-block-price-as-shown-line-height: 1.25rem;
$vehicle-block-price-as-shown-margin-bottom: 0;
$vehicle-block-description-padding: null;
$vehicle-block-description-padding-bottom: 1.25rem;
$vehicle-block-description-extend: q-body2;
$vehicle-block-seperator-border-top: .0625rem solid $vehicle-info-block-border-color;

// Vehicle Attribute
$vehicle-attribute-full-container-map: (
	small: column,
	medium: column
);
$vehicle-attribute-padding-map: (
	small: 0,
	medium: 0
);
$vehicle-attribute-label-order: 2;
$vehicle-attribute-details-order: 1;
$vehicle-attribute-label-text-font: q-descriptive2;
$vehicle-attribute-label-line-height: .625;
$vehicle-attribute-details-text-font: q-headline3;
$vehicle-attribute-details-font-weight: $font-weight-xbold;
$vehicle-attribute-bottom-border: 0;
$vehicle-attribute-text-align: center;

// Multimedia Teaser
$mm-teaser-margin-bottom: 1.5rem;
$mm-teaser-separator-color: $grey-mid;
$mm-teaser-separator-display: block;
$mm-teaser-separator-height: .0625rem;
$mm-teaser-separator-border: 0;
$mm-teaser-separator-margin: 0;
$mm-teaser-separator-width: 100%;
$mm-teaser-margin-base-override-bottom: 0;
$mm-teaser-description-margin: 0;
$mm-teaser-headline-b-margin: 0;
$mm-teaser-copy-b-margin: 0;
$mm-teaser-full-panel-padding: 0 0 .9375rem 0;
$mm-teaser-border-hover-color: $yellow-mid;
$mm-teaser-border-b-top: 1px solid $grey;
$mm-teaser-border-b-bottom: $mm-teaser-border-b-top;
$teaser-title-font-padding-map: (
	mobile: 1.875rem 0 1.25rem 0,
	desktop: 1.875rem 0 1.25rem 0
);
$teaser-description-font-padding-map: (
	mobile: .1875rem 0 1.25rem 0,
	desktop: .1875rem 0 1.25rem 0
);
$teaser-description-font-color-map: (
	mobile: $grey-dark,
	desktop: $grey
);
$teaser-b-container-padding-map: (
	mobile: 1.25rem 5%,
	desktop: 0 1.25rem 0 0
);
$teaser-b-media-padding-map: (
	mobile: 0,
	desktop: 0 0 .9375rem 0
);
$teaser-b-title-padding-map: (
	mobile: 0,
	desktop: 0
);
$teaser-b-description-padding-map: (
	mobile: 0,
	desktop: 0 0 1.25rem 0
);

//Engine Component
$engine-information-border-bottom: 1px solid $grey-mid;
$engine-information-price-from-color: $grey;
$engine-information-price-label-extend: q-body2;
$engine-information-price-label-font-weight: normal;
$engine-information-price-label-line-height: 1.875rem;
$engine-border-color-hover: $gold;
$engine-information-opacity-hover: 1;
$engine-information-border-width: .1875rem;

// Responsive Image
$responsive-image-disclosure-text: null;

$engine-information-transistion: none;
$engine-information-title-color: $grey-dark;
$engine-information-title-hover-color: $engine-information-title-color;
$engine-information-headline-style: q-descriptive1;
$engine-information-attribute-text-style: q-descriptive2;

// Vehicle Trim Selector Solar
$vehicle-trim-selector-padding-left: (
	small: .625rem,
	large: 1.25rem,
	xlarge: 3.125rem
);
$vehicle-trim-selector-padding-right: (
	small: .625rem,
	large: 1.25rem,
	xlarge: 3.125rem
);
$vehicle-trim-selector-arrow-next-right: (
	small: -.625rem,
	large: -1.25rem,
	xlarge: -3.125rem
);
$vehicle-trim-selector-slick-width: 90vw;
$vehicle-trim-selector-pagination-button-border-color: transparent;
$vehicle-trim-selector-pagination-button-margin: auto;
$vehicle-trim-selector-pagination-button-width: 1rem;
$vehicle-trim-selector-pagination-active-background-color: transparent;
$vehicle-trim-selector-slide-padding: 0 1.25rem 0 0;
$vehicle-trim-selector-arrow-background-color: $slick-arrow-opacity;
$vehicle-trim-selector-arrow-border-color: transparent;
$vehicle-trim-selector-arrow-border-radius: 2px;
$vehicle-trim-selector-arrow-height: 8.438rem;
$vehicle-trim-selector-arrow-top: 0;
$vehicle-trim-selector-arrow-width: 3.125rem;
$vehicle-trim-selector-arrow-hover-opacity: $scroller-opacity-full;
$vehicle-trim-selector-arrow-before-height: 3.75rem;
$vehicle-trim-selector-arrow-before-width: 1.875rem;
$vehicle-trim-selector-arrow-prev-left: -.625rem;

// Vehicle Info Row Solar
$vehicle-info-row-year-typography: q-descriptive2;
$vehicle-info-row-title-typography: q-descriptive1;
$vehicle-info-row-from-label-typography: q-descriptive2;
$vehicle-info-row-description-typography: q-body2;
$vehicle-info-row-year-color: $descriptive2-font-color;
$vehicle-info-row-price-color: $descriptive2-font-color;
$vehicle-info-row-price-as-shown-extend: q-body2;
$vehicle-info-row-price-as-shown-color: $descriptive2-font-color;
$vehicle-info-row-price-as-shown-font-weight: null;
$vehicle-info-row-price-as-shown-line-height: 1.25rem;
$vehicle-info-row-price-as-shown-margin-bottom: 0;

// Current Offers
$co-item-tile-bg-color: $white;
$co-item-tile-text-color: $black;
$co-item-tile-divider-text: 'or';
$co-item-tile-font-size: $base-font-size-m;
$co-item-tile-font-weight: $font-weight-normal;
$co-item-tile-text-align: null;
$co-item-tile-text-transform: null;
$co-item-tile-details-bg-color: $white;
$co-item-tile-details-border: 1px solid $grey-mid;
$co-postalcode-location-height: 3.75rem;
$co-postalcode-location-button-width: 3.75rem;
$co-postalcode-location-bg-color: $grey-mid;
$co-postalcode-location-bg-color-hover: $grey-mid;
$co-postalcode-target-location-icon: target-location;
$co-postalcode-submit-bg-color: $grey-mid;
$co-postalcode-submit-height: 3.75rem;
$co-postalcode-submit-width: 3.75rem;
$co-postalcode-submit-bg-color-disabled: $grey-mid;
$co-postalcode-submit-bg-color-hover: $grey-mid;
