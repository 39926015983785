.q-dealer-locator {
	.dealer-locator-widget {
		.q-open-hours .q-days-of-week {
			.q-day-of-week {
				margin-left: 2.375rem;
			}
		}
	}

	.q-open-hours {
		display: flex;
		flex: 0 0 100%;

		p {
			margin-bottom: .125rem;
		}

		.q-closed {
			color: $alert-color;
		}

		.q-days-of-week {
			flex: 0 0 100%;
			margin-right: 1rem;

			.q-day-of-week {
				display: inline-block;
				margin-left: 2.375rem;
				width: 4rem;
			}

			.q-contact {
				margin: 0 0 .25rem;
				padding: 1rem .5rem 0 0;

				a {
					border-bottom: 0;
					font-size: .875rem;
				}

				.q-hours-expander-head {
					.q-day-of-week {
						float: none;
						margin-left: 0;
						padding-right: 2rem;
					}

					img {
						@include insert-icon(dealer-hours, $svg-icon-medium, $svg-icon-medium);
						padding-bottom: .25rem;
					}

					p {
						color: $grey-dark;
						font-size: 1rem;
					}

					.icon-plus,
					.icon-minus {
						float: right;
						margin-right: -.5rem;
						margin-top: .3125rem;
					}

					.icon-minus {
						display: none;
					}

					&.active {
						.icon-plus {
							display: none;
						}

						.icon-minus {
							display: initial;
						}
					}
				}
			}

			.q-hours-first {
				display: none;
			}

			.q-hours-expander {
				line-height: 1rem;

				&.active {
					display: block;
				}
			}

			.q-hours-expander-head {
				&.active {
					display: block;
				}

				.q-day-of-week {
					width: 4.375rem;
				}
			}

		}
	}
}

@media #{$xlarge-up} {
	.q-dealer-locator {
		.q-open-hours {
			border-top: 0;

			.q-days-of-week {
				color: $dusty-grey;
				padding-right: 0;


				.q-contact {
					display: none;
				}

				.q-hours-expander {
					// !important needed to force expander open on xlarge
					display: block !important; 	// sass-lint:disable-line no-important
				}

				.q-hour {
					letter-spacing: 1px;
				}

				.q-hours-first {
					color: $emperor-grey;
					display: inline-block;
					font-weight: $font-weight-xbold;
				}

				.q-day-of-week {
					margin-left: 0;
				}
			}
		}

		.dealer-locator-widget .q-open-hours .q-days-of-week {
			.q-day-of-week {
				margin-left: 0;
			}
		}
	}
}
