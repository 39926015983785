// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// Content Well
//
// This is the documentation for Content Well. Edit content_well.scss to update this documentation.
//
// markup:
// <p>Sample markup for Content Well. Replace me with the real deal once you are done editing <strong>content_well.html</strong> and <em>optionally</em> the content_well.js file.</p>
//
// Styleguide 99.99

.q-content-well {
	.content-well-bg-color-one {
		background-color: $content-well-bg-color-one;
	}

	.content-well-bg-color-two {
		background-color: $content-well-bg-color-two;
	}

	.content-well-bg-color-three {
		background-color: $content-well-bg-color-three;
	}

	.content-well-bg-color-four {
		background-color: $content-well-bg-color-four;
	}
}
