@media #{$xlarge-up} {
	.q-vehicle-block {
		.q-vehicle-block-text {
			.q-vehicle-block-title {
				padding: 0;

				.q-vehicle-block-title-content {
					font-size: $descriptive1-font-size-lm;
					line-height: $descriptive1-line-height-lm;
				}
			}

			.q-vehicle-info-text-price {
				.q-from-label {
					font-size: $descriptive2-font-size-lm;
					line-height: $descriptive2-line-height-lm;
				}

				.q-vehicle-info-as-shown-price {
					font-size: $body2-font-size-lm;
					line-height: $body2-line-height-lm;
					margin: 0;
				}
			}
		}

		.q-vehicle-info-text-description {
			font-size: $body2-font-size-lm;
			line-height: $body2-line-height-lm;
		}
	}
}

.q-vehicle-info-bar {
	&:hover {
		&::after {
			@include easein-middle;
		}
	}
}
