// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// GSA Search Results
//
// This is the documentation for GSA Search Results. Edit GSA-search-results.scss to update this documentation.
//
// markup:
// <p>Sample markup for GSA Search Results. Replace me with the real deal once you are done editing <strong>GSA-search-results.html</strong> and <em>optionally</em> the GSA-search-results.js file.</p>
//
// Styleguide 99.99

.q-search-results {
	// Mobile First SCSS goes here
	.q-hide {
		display: none;
	}

	.q-site-search {
		white-space: nowrap;

		label {
			display: inline;
		}

		input {
			display: inline;
			margin-left: .5rem;
			margin-right: .5rem;
		}

		button {
			font-weight: $font-weight-normal;
			padding-bottom: .5rem;
			padding-top: .5rem;
			text-transform: uppercase;
		}
	}

	.q-results-heading {
		color: $search-field-results-heading-color;
		font-family: $search-field-results-heading-font-family;
		font-size: 1.875rem;
		font-style: italic;
		font-weight: $font-weight-bold;
		letter-spacing: -.0625rem;
		line-height: 1.875rem;
	}

	.q-search-summary {
		overflow: hidden;
		width: 100%;

		span {
			display: inline-block;
			margin-bottom: 1rem;
		}

		ul {
			display: inline-block;
			float: right;
			margin-bottom: 0;
			margin-left: -.875rem;
		}

		a {
			color: $search-field-search-summary-link-color;
			font-family: $search-field-search-summary-link-font-family;
		}
	}

	.content-box {
		.q-content-container {
			padding: .5rem 0;
		}

		p {
			margin-bottom: 0;
		}

		a {
			color: $search-field-content-box-link-color;
			font-family: $search-field-content-box-link-font-family;
			transition: #{$anim-xfast}s ease-out;

			&:hover {
				color: $search-field-content-box-link-hover-color;
				text-decoration: underline;
			}
		}

		.q-title {
			font-size: 1.25rem;
			font-weight: $font-weight-bold;
			margin-top: .5rem;
		}

		.q-description {
			color: $search-results-button-hover-color;
			font-family: $font-family-secondary;
			font-size: .875rem;
			line-height: 1.5rem;
			padding-bottom: .5rem;
			width: 100%;
		}
	}

	.q-pagination {
		margin-top: 1.25rem;
	}

	.q-summary {
		color: $search-results-summary-color;
		font-family: $search-results-summary-font-family;
		font-size: 1rem;
	}

	@media #{$small-only} {
		.q-results-heading {
			font-size: 1.75rem;
			margin-bottom: .375rem;
		}

		.content-box {
			.q-title {
				font-size: 1rem;
			}
		}

		.js-summary {
			font-size: .875rem;
		}
	}
}
