//This file contains the following items
//1. Headline
//2. Text
//3. Individual

.q-invert {
	color: $white;
}

//1. Headline
%q-bold1,
.q-bold1 {
	@include q-headline-values($bold1-font-color, $bold1-font-style, $bold1-font-weight, null, null, null, $bold1-font-size-xs, $bold1-line-height-xs, $bold1-font-size-lm, $bold1-line-height-lm, $bold1-font-size-xl, $bold1-line-height-xl, $bold1-font-family, $bold1-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-bold2,
.q-bold2 {
	@include q-headline-values($bold2-font-color, $bold2-font-style, $bold2-font-weight, null, null, null, $bold2-font-size-xs, $bold2-line-height-xs, $bold2-font-size-lm, $bold2-line-height-lm, $bold2-font-size-xl, $bold2-line-height-xl, $bold2-font-family, $bold2-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-descriptive1,
.q-descriptive1 {
	@include q-headline-values($brand-headline-color, $descriptive1-font-style, $descriptive1-font-weight, null, null, $descriptive1-letter-spacing, $descriptive1-font-size-xs, $descriptive1-line-height-xs, $descriptive1-font-size-lm, $descriptive1-line-height-lm, $descriptive1-font-size-xl, $descriptive1-line-height-xl, $descriptive1-font-family, $descriptive1-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-descriptive2,
.q-descriptive2 {
	@include q-headline-values($descriptive2-font-color, $descriptive2-font-style, $descriptive2-font-weight, null, null, $descriptive2-letter-spacing, $descriptive2-font-size-xs, $descriptive2-line-height-xs, $descriptive2-font-size-lm, $descriptive2-line-height-lm, $descriptive2-font-size-xl, $descriptive2-line-height-xl, $descriptive2-font-family, $descriptive2-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-display1,
.q-display1 {
	@include q-headline-values($brand-headline-color, $display1-font-style, $display1-font-weight, $display1-heavy-weight, null, $display1-letter-spacing, $display1-font-size-xs, $display1-line-height-xs, $display1-font-size-lm, $display1-line-height-lm, $display1-font-size-xl, $display1-line-height-xl, $display1-font-family, $display1-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-display2,
.q-display2 {
	@include q-headline-values($brand-headline-color, $display2-font-style, $display2-font-weight, $display2-heavy-weight, null, $display2-letter-spacing, $display2-font-size-xs, $display2-line-height-xs, $display2-font-size-lm, $display2-line-height-lm, $display2-font-size-xl, $display2-line-height-xl, $display2-font-family, $display2-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-display3 {
	@include q-headline-values($brand-headline-color, $display3-font-style, $display3-font-weight, $display3-heavy-weight, null,  map-get($display3-letter-spacing-map, small), $display3-font-size-xs, $display3-line-height-xs, $display3-font-size-lm, $display3-line-height-lm, $display3-font-size-xl, $display3-line-height-xl, $display3-font-family, $display3-use-underline, $body-baseline-value, $baseline-body-ratio-primary);

	@media #{$large-up} {
		letter-spacing: map-get($display3-letter-spacing-map, large);
	}

	@media #{$xlarge-up} {
		letter-spacing: map-get($display3-letter-spacing-map, xlarge);
	}
}

.q-display3 {
	@extend %q-display3;
}

%q-headline1,
.q-headline1 {
	@include q-headline-values($brand-headline-color, $headline1-font-style, $headline1-font-weight, $brand-headline-heavy-weight-bold, null, $headline1-letter-spacing, $headline1-font-size-xs, $headline1-line-height-xs, $headline1-font-size-lm, $headline1-line-height-lm, $headline1-font-size-xl, $headline1-line-height-xl, $headline1-font-family, $headline1-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-headline2 {
	@include q-headline-values($brand-headline-color, $headline2-font-style, $headline2-font-weight, $brand-headline-heavy-weight-bold, null, map-get($headline2-letter-spacing-map, small), $headline2-font-size-xs, $headline2-line-height-xs, $headline2-font-size-lm, $headline2-line-height-lm, $headline2-font-size-xl, $headline2-line-height-xl, $headline2-font-family, $headline2-use-underline, $body-baseline-value, $baseline-body-ratio-primary);

	@media #{$large-up} {
		letter-spacing: map-get($headline2-letter-spacing-map, large);
	}

	@media #{$xlarge-up} {
		letter-spacing: map-get($headline2-letter-spacing-map, xlarge);
	}
}

.q-headline2 {
	@extend %q-headline2;
}

%q-headline3,
.q-headline3 {
	@include q-headline-values($brand-headline-color, $headline3-font-style, $headline3-font-weight, $brand-headline-heavy-weight-bold, $headline3-heavy-style, $headline3-letter-spacing, $headline3-font-size-xs, $headline3-line-height-xs, $headline3-font-size-lm, $headline3-line-height-lm, $headline3-font-size-xl, $headline3-line-height-xl, $headline3-font-family, $headline3-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

%q-heavy,
.q-heavy {
	font-style: normal;
	font-weight: $font-weight-xbold;
}

.q-headline {
	.q-scroller-indicator-text {
		@extend %q-descriptive1;
	}

	@media #{$xlarge-up} {
		.q-scroller-indicator-text {
			color: $white;
		}
	}
}

//2. Text
%q-body {
	@include links($brand-link-color, $brand-link-color-hover, $brand-link-color-visited, $brand-link-font-weight,
		$brand-link-transition-speed, $brand-link-decoration);

	font-family: $body-font-family;

	p {
		margin-bottom: $body-margin-bottom;

		&:last-child {
			margin-bottom: $body-last-margin-bottom;
		}
	}

	ul,
	ol {
		padding-left: $body-list-item-padding;

		&:last-child {
			margin-bottom: $body-last-margin-bottom;
		}

		li {
			margin-top: $body-list-margin-top;
		}
	}

	&.q-invert {
		@include body-invert($brand-body1-invert-color, $brand-invert-link-color, $brand-invert-link-color-hover,
			$brand-invert-link-color-visited, inherit, inherit);
	}
}

%q-body1 {
	@extend %q-body;

	@include font-queries($body1-font-size-xs, $body1-line-height-xs, $body1-font-size-lm, $body1-line-height-lm,
		$body1-font-size-xl, $body1-line-height-xl, $baseline-body-ratio-primary, $body-baseline-value);

	color: $brand-body1-color;

	ul,
	ol {
		margin: $body1-list-item-margin;
	}
}

.q-body1 {
	@extend %q-body1;
}

%q-body2 {
	@extend %q-body;

	@include font-queries($body2-font-size-xs, $body1-line-height-xs, $body2-font-size-lm, $body2-line-height-lm,
		$body2-font-size-xl, $body2-line-height-xl, $baseline-body-ratio-primary, $body-baseline-value);

	color: $brand-body2-color;

	ul,
	ol {
		margin: $body2-list-item-margin;
	}
}

.q-body2 {
	@extend %q-body2;
}

//3. Individual

$label1-color: $grey-dark !default;
$label1-font-family: $font-family-primary !default;
$label1-font-size: 1.5rem !default;
$label1-line-height: 2.5rem !default;

$label2-color: $grey !default;
$label2-font-family: $font-family-secondary !default;
$label2-font-size: 1rem !default;
$label2-line-height: 1.25rem !default;

%q-caps,
.q-caps {
	text-transform: uppercase;
}

%q-label {
	&.q-invert {
		color: $white;
	}
}

%q-label1 {
	@extend %q-label;

	color: $label1-color;
	font-family: $label1-font-family;
	font-size: $label1-font-size;
	line-height: $label1-line-height;
}

.q-label1 {
	@extend %q-label1;
}

%q-label2 {
	@extend %q-label;

	color: $label2-color;
	font-family: $label2-font-family;
	font-size: $label2-font-size;
	line-height: $label2-line-height;
}

.q-label2 {
	@extend %q-label2;
}
