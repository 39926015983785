$share-item-icon-margin: 1rem 0 !default;

.q-share {
	background-color: $share-background-color;
	padding: 0 1rem;

	.q-share-list {
		display: block;
		flex: 0 0 75%;
		list-style: none;
		margin: 0;
		padding: $share-list-padding;

		.q-share-item {
			display: inline-block;
			font-size: 1.125rem;
			font-weight: $font-weight-bold;
			margin: 0;
			position: relative;
			white-space: nowrap;
			width: $share-svg-size;

			&:last-child {
				margin-bottom: 0;
			}

			.q-share-button {
				margin-right: $share-icon-margin;
				text-decoration: none;

				.q-share-icon {
					font-weight: $font-weight-normal;
					margin-top: $share-icon-margin-top;

					&::after {
						color: $share-icon-color;
					}
				}
			}
		}
	}

	.q-share-header {
		@extend %#{$social-share-header} !optional;

		display: block;
		flex: 0 0 25%;
		padding: $share-header-padding;
	}

	.q-share-body {
		align-items: center;
		background-color: $share-background-color;
		display: flex;
		flex: 0 0 100%;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 1.5rem;
	}

	@media #{$medium-up} {
		.q-share-body {
			flex: 0 0 58.3333%;
		}
	}

	@media #{$large-up} {
		.q-share-body {
			flex: 0 0 33.3333%;
		}
	}
}
