@media #{$xlarge-up} {
	.q-vehicle-information-row {
		.q-vehicle-year-title-msrp {
			.q-vehicle-info-row-year {
				font-size: $descriptive2-font-size-lm;
				line-height: $descriptive2-line-height-lm;
			}

			.q-vehicle-info-row-title {
				font-size: $descriptive1-font-size-lm;
				line-height: $descriptive1-line-height-lm;
			}
		}

		.q-vehicle-body-description {
			font-size: $body2-font-size-lm;
			line-height: $body2-line-height-lm;
		}

		.q-vehicle-info-text-price {
			.q-from-label {
				font-size: $descriptive2-font-size-lm;
				line-height: $descriptive2-line-height-lm;
				margin-bottom: 0;
			}

			.q-vehicle-info-as-shown-price {
				font-size: $body2-font-size-lm;
				line-height: $body2-line-height-lm;
			}
		}
	}
}
