.gmit-gf-request-type {
	background: $gf-request-type-background;

	.gmit-gf-button {
		background-color: $gf-button-bg-color;
		border: $gf-button-border;
		color: $gf-button-text-color;
		display: block;
		font-family: $gf-button-font-family;
		font-size: $gf-button-font-size;
		font-weight: $gf-button-weight;
		height: map-get($gf-button-height-map, small);
		letter-spacing: $gf-button-letter-spacing;
		line-height: $gf-button-line-height;
		padding: $gf-button-padding;
		text-transform: $gf-button-transform;

		&.button {
			&.primary,
			&.secondary,
			&.activity {
				padding: $gf-button-padding;
			}
		}

		&:hover {
			background-color: $gf-button-bg-hover-color;
			border: $gf-button-border-hover;
			color: $gf-button-text-hover-color;
		}
	}

	.gmit-gf-radio-group,
	.gmit-gf-checkbox-group {
		list-style: none;
	}

	.gmit-gf-dealer-selector {
		.gmit-gf-dealer-search-results {
			.gmit-gf-dealer-count {
				color: $gf-dealer-count-color;
				font-family: $font-family-sans;
				font-size: .875rem;
				font-weight: $font-weight-bold;
				line-height: 1.14;
			}

			.gmit-gf-dealer-item {
				.gmit-gf-dealer {
					text-decoration: none;

					address {
						font-style: $font-style-normal;
					}
				}
			}
		}

		.gmit-gf-search-options {
			.gmit-gf-multioption-radio {
				.gmit-gf-label-text {
					color: $gf-dealer-radio-label-text;
					font-weight: $gf-radio-checkbox-weight;
				}
			}
		}
	}

	.gmit-gf-dealer-selector-v2 {
		.gmit-gf-search-button {
			&.inline {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	// RAQ dealer box
	.gmit-gf-search-result {
		margin: 0 0 1rem;

		.gmit-gf-search-result-headline {
			@extend %#{$gf-paragraph-body-font};
		}

		.gmit-gf-search-result-list {
			border: $gf-input-border;

			.gmit-gf-result-item {
				margin-top: $gf-search-result-item-margin;
				width: auto;
			}

			.gmit-gf-paragraph {
				padding-bottom: 0;

				p {
					margin-bottom: $gf-dealer-item-margin-bottom;
				}
			}
		}
	}

	.gmit-gf-label-text {
		color: $gf-label-text-color;
		font-family: $gf-label-text-font;
		font-size: map-get($gf-label-text-font-size-map, small);
		font-weight: $gf-label-text-weight;
		height: auto;
		letter-spacing: $gf-label-text-letter-spacing;
		padding: 0;
		text-transform: $gf-label-text-transform;
	}

	.gmit-gf-inline {
		display: inline;
	}

	.gmit-gf-multi-step {
		background: $gf-multi-step-bg-color;

		.gmit-gf-groupbox {
			border-top-color: $gf-groupbox-border-color;
		}

		&.tabs {
			.tab-title {
				.gmit-multi-step-link {
					font-family: $font-family-sans;
					font-size: 1rem;
				}

				&.disabled {
					.gmit-multi-step-link {
						border-bottom-color: $gf-multi-link-disabled-border-bottom-color;
						color: $gf-multi-link-disabled-color;
					}
				}

				&.active {
					.gmit-multi-step-link {
						background: $gf-multi-link-tab-active-bg;
						border-bottom-color: $gf-multi-link-tab-active-border;
						font-weight: $font-weight-bold;
					}
				}

				&.gmit-gf-step-visited {
					.gmit-multi-step-link {
						border-bottom-color: $gf-multi-link-tab-visited-border;
					}
				}
			}
		}

		.accordion-navigation {
			border-bottom-color: $gf-accordion-border-color;

			.gmit-multi-step-link {
				background-color: $gf-multi-link-bg-color;
				border-bottom-color: $gf-multi-link-border-color;
				border-top-color: $gf-multi-link-border-color;
				font-family: $font-family-sans;
				font-size: 1rem;
				font-weight: $font-weight-bold;
				line-height: 1.13;
				text-decoration: none;
				text-transform: uppercase;
			}

			.content {
				&.active {
					background-color: $gf-accordion-content-bg-color;
				}
			}

			&.active {
				.gmit-multi-step-link {
					border-bottom-color: $gf-multi-link-accordion-active-border;
					border-top-color: $gf-multi-link-accordion-active-top;
				}
			}
		}
	}

	span,
	small {
		&.error {
			background: $gf-multi-step-span-bg-color;
			color: $gf-error-color;
			font-size: $gf-error-label-font-size;
			font-style: $font-style-normal;
			font-weight: $font-weight-normal;
		}
	}

	.gmit-gf-input-checkbox {
		&:checked {
			&:disabled {
				+ label {
					&::before {
						border-color: $gf-disabled-checkbox-border;
						color: $gf-disabled-checkbox-color;
					}
				}
			}
		}
	}

	.gmit-gf-multioption-radio {
		.gmit-gf-field-group-container {
			padding-left: 0;
		}
	}

	.gmit-gf-field-group-container {
		padding-left: 0;

		.gmit-gf-field-group {
			.gmit-gf-label-text {
				height: auto;
			}
		}
	}

	.gmit-gf-selected-brochures {
		.gmit-gf-selected-brochure-wrapper {
			background: $gf-brochure-selected-background-color;

			.gmit-gf-icon {
				&.icon {
					color: $gf-brochure-icon-color;
				}
			}
		}

		.gmit-gf-pdf-label-text {
			font-family: $font-family-sans;
			font-size: .75rem;
			line-height: 1.33;
		}

		.gmit-gf-selected-brochures-label {
			font-family: $font-family-sans;
			font-size: .875rem;
			font-weight: $font-weight-bold;
			line-height: 1.14;
		}

		.gmit-gf-selected-brochure-container {
			background: $gf-brochure-selected-background-color;
		}
	}

	.gmit-gf-image-label {
		color: $gf-image-label;
		font-family: $font-family-sans;
		font-size: .875rem;
		font-weight: $font-weight-bold;
		line-height: 1.14;
	}

	.gmit-gf-brochure,
	.gmit-gf-selected-brochures {
		.gmit-flexbox-container {
			&.gmit-gf-disabled {
				.gmit-gf-input-checkbox {
					+ label {
						&::before {
							background: $gf-brochure-disabled-checkbox-background;
							border-color: $gf-brochure-disabled-checkbox-border;
						}
					}
				}
			}
		}
	}

	.gmit-gf-brochure-list {
		.gmit-flexbox-container {
			padding: 0;
		}

		.gmit-gf-row {
			.gmit-flexbox-container {
				.gmit-brochure-col-content {
					background-color: $gf-brochure-bg-color;

					// Checkbox labels for Request Info
					.gmit-gf-label-text {
						font-size: $gf-checkbox-radio-label-font;
						font-weight: $gf-radio-checkbox-weight;
						height: auto;
						margin-left: $gf-checkbox-label-margin;
						text-transform: $gf-request-info-vehicle-label-transform;
					}
				}

				&.gmit-gf-disabled {
					.gmit-brochure-col-content {
						background: $gf-brochure-disabled-background;
					}

					// Disabled style when 3 checkboxes are selected
					.gmit-gf-no-image {
						opacity: .5;
					}
				}
			}
		}

	}

	.gmit-gf-content-separator {
		hr {
			border-color: $gf-separator-color;
		}
	}

	.gmit-gf-datepicker {
		.gmit-gf-input-text {
			height: map-get($gf-input-height-map, small);
		}

		// Remove global forms default icon from page
		.gmit-gf-datepicker-container {
			.gmit-gf-icon {
				&.gmit-gf-down-arrow {
					display: none;
				}
			}
		}

		input {
			&.gmit-gf-input-text {
				&:focus {
					background-color: $gf-datepicker-focus-bg-color;
				}

				&:disabled {
					background-color: $gf-datepicker-disabled-bg-color;
					color: $gf-datepicker-disabled-text;
				}
			}
		}

		div,
		span {
			&.button {
				&.dropdown {
					&.icon {
						&.active {
							background: $gf-datepicker-icon-bg-color;
						}
					}
				}
			}
		}

		.ui-datepicker {
			background: $gf-datepicker-bg-color;
			font-family: $font-family-sans;
			font-size: 1.25rem;

			.ui-datepicker-header {
				border-left-color: $gf-datepicker-header-border;
				border-right-color: $gf-datepicker-header-border;
				border-top-color: $gf-datepicker-header-border;

				a {
					&.ui-datepicker-next {
						span {
							@include icon(arrow-right) {
								font-size: 1.25rem;
								margin-right: .5rem;
							};
						}
					}

					&.ui-datepicker-prev {
						span {
							@include icon(arrow-left) {
								font-size: 1.25rem;
								margin-right: .5rem;
							};
						}
					}
				}

				.ui-datepicker-title {
					font-weight: $font-weight-bold;
					text-transform: uppercase;

				}
			}

			table {
				border-bottom-color: $gf-table-border;
				border-left-color: $gf-table-border;
				border-right-color: $gf-table-border;

				&.ui-datepicker-calendar {
					background: $gf-datepicker-calendar-bg-color;

					thead {

						tr {

							th {
								font-size: 1.25rem;
								font-weight: $font-weight-normal;

							}
						}
					}

					tbody {

						tr {

							td {
								border-color: $gf-datepicker-border-color;
								font-size: 1.25rem;

								&.ui-datepicker-other-month {
									color: $gf-datepicker-month-color;
								}

								.ui-datepicker-current-day {
									background: $gf-datepicker-day-background-color;
								}

								&[data-handler] {
									background: $gf-data-handler-background;

									&.ui-datepicker-current-day {
										background: $gf-datepicker-day-background-color;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.gmit-gf-dealer-selector {
		.gmit-gf-dealer-search-results {

			.gmit-gf-dealer-count {
				color: $gf-dealer-count-color;
				font-family: $font-family-sans;
				font-size: .875rem;
				font-weight: $font-weight-bold;
				line-height: 1.14;
			}

			.gmit-gf-dealer-items {
				border-color: $gf-dealer-items-border-color;

				.gmit-gf-divider {
					border-color: $gf-dealer-items-border-color;
				}
			}
		}

		.gmit-gf-selected-dealer {
			font-family: $font-family-sans;
			font-size: .875rem;
			font-weight: $font-weight-bold;

			.gmit-gf-selected-dealer-name {
				font-family: $font-family-sans;
			}
		}
	}

	.gmit-gf-dropdown,
	.gmit-gf-datepicker {
		margin: 0;

		select,
		.gmit-gf-input-text {
			background-color: $gf-dropdown-bg-color;
			background-image: get-element($gf-dropdown-icon), linear-gradient(to right, $gf-dropdown-square-color, $gf-dropdown-square-color);
			background-position: unquote(map-get($gf-dropdown-position-map, small));
			background-repeat: no-repeat;
			background-size: unquote(map-get($gf-dropdown-size-map, small));
			border: $gf-dropdown-border;
			color: $gf-dropdown-text-color;
			font-family: $gf-input-font-family;
			font-size: map-get($gf-input-font-size-map, small);
			height: map-get($gf-input-height-map, small);
			line-height: $gf-input-line-height;
			padding: map-get($gf-dropdown-padding-map, small);

			&:disabled {
				background-color: $gf-dropdown-bg-color;
				cursor: initial;
				opacity: .3;
			}

			&:hover:not(:disabled) {
				background-image: get-element($gf-dropdown-hover-icon), linear-gradient(to right, $gf-dropdown-square-hover, $gf-dropdown-square-hover);
				cursor: pointer;
			}

			&:focus {
				font-size: $gf-mobile-focus-font-size;
			}

			// Removes Chrome autofill styling of forms
			&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
				box-shadow: 0 0 0 4rem $white inset;

				&:focus,
				&:active {
					box-shadow: 0 0 0 4rem $white inset;
				}
			}
		}
	}

	.gmit-gf-follow-on {
		.gmit-gf-confirmation-message {
			label,
			p {
				color: $gf-confirmation-color;
				font-family: $font-family-sans;
				font-weight: $font-weight-bold;
			}
		}

		.gmit-gf-follow-on-headline {
			font-family: $font-family-sans;
			font-weight: $font-weight-bold;
		}

		.gmit-gf-button {
			&:disabled {
				background-color: $gf-button-disabled-background;

				&:hover {
					background-color: $gf-button-disabled-background;
				}
			}
		}

	}

	.gmit-gf-dealer-selector-v2 {
		.gmit-gf-search-button {
			max-height: none;
		}
	}

	.gmit-gf-bold {
		font-weight: $gf-bold-font-weight;
	}

	.gmit-gf-groupbox {
		background: none;
		border-top: 0;

		.gmit-gf-text-field,
		.gmit-gf-dropdown,
		.gmit-gf-datepicker {
			margin-bottom: $gf-input-margin-bottom;
		}

		&.gmit-gf-collapse {
			border-top-color: $gf-groupbox-border-color;

			.gmit-gf-groupbox-title {
				border-bottom-color: $gf-groupbox-border-color;
			}
		}

		.icon {
			color: $gf-groupbox-icon-color;
			font-family: $font-family-sans;
			font-size: 1.5rem;
		}

		.gmit-gf-groupbox-title {
			border-bottom-color: $gf-groupbox-title-border-color;
			color: $gf-groupbox-title-color;
			font-family: $gf-groupbox-title-font-family;
			font-size: $gf-groupbox-title-size;
			font-weight: $gf-groupbox-title-weight;
			line-height: 1;
			padding-left: 0;
			text-transform: uppercase;

			&.gmit-gf-expander {
				font-weight: $font-weight-normal;
			}
		}

		.gmit-gf-groupbox-content {
			padding: 0;
		}
	}

	.gmit-gf-text-field,
	.gmit-gf-dropdown,
	.gmit-gf-datepicker {
		input {
			&[type='text'],
			&[type='email'],
			&[type='number'] {
				border: $gf-input-border;
				box-shadow: none;
				color: $gf-input-text-color;
				font-family: $gf-input-font-family;
				font-size: map-get($gf-input-font-size-map, small);
				height: map-get($gf-input-height-map, small);
				line-height: $gf-input-line-height;
				padding: map-get($gf-input-padding-map, small);

				// Removes Chrome autofill styling of forms
				&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
					box-shadow: 0 0 0 4rem $white inset;

					&:focus,
					&:active {
						box-shadow: 0 0 0 4rem $white inset;
					}
				}
			}

			&:focus {
				background-color: transparent;
				border-color: $gf-input-border-focus;
				box-shadow: none;
				font-size: $gf-mobile-focus-font-size;
			}

			&::placeholder {
				color: $gf-placeholder-text;
			}
		}
	}

	.gmit-gf-comment-field {
		margin: 0 0 1rem;

		.gmit-gf-label-text {
			height: auto;
			padding: 0;
		}

		textarea {
			border: $gf-input-border;
			box-shadow: none;
			color: $gf-input-text-color;
			font-family: $gf-input-font-family;
			font-size: map-get($gf-input-font-size-map, small);
			line-height: $gf-input-line-height;
			margin-bottom: .625rem;
			min-height: 7em;
			padding: map-get($gf-textarea-padding-map, small);
			resize: none;

			&:focus {
				background-color: $white;
				border-color: $gf-input-border-focus;
				font-size: $gf-mobile-focus-font-size;
			}
		}
	}

	.gmit-gf-carline-wrapper {
		padding-left: 0;
	}

	.gmit-gf-carline-obj {
		margin-bottom: 1.25rem;
		padding-right: 0;
	}

	.gmit-gf-thank-you {
		background: $gf-thank-you-bg-color;
		font-family: $font-family-sans;
		line-height: 1.14;

		.gmit-gf-thank-you-paragraph {
			padding: 1.5rem 0;
		}

		header {
			&.gmit-gf-headline-wrapper {
				.gmit-gf-carline-headline,
				.gmit-gf-bodystyle-headline {
					color: $gf-carline-bodystyle-color;
					font-family: $font-family-sans;
					font-size: 1.5rem;
					font-weight: $font-weight-bold;
					line-height: 1;
				}
			}

			&.gmit-gf-dealer-selector-headline,
			&.gmit-gf-dealer-contact-headline,
			&.gmit-gf-dealer-hours-headline {
				font-family: $font-family-sans;
				font-size: .875rem;
				font-weight: $font-weight-bold;
			}
		}

		.gmit-gf-print-button {
			padding: .5rem 0;
		}

		p {
			color: $gf-thank-you-text;
			font-size: .75rem;
		}

		.gmit-gf-dealer {
			color: $gf-thank-you-dealer-text;
			font-family: $font-family-sans;
			font-size: 1.125rem;
			font-weight: $font-weight-bold;
			line-height: 1;
		}

		.gmit-gf-trim-headline {
			line-height: 1.125rem;
		}

		.gmit-gf-engine-description {
			font-family: $font-family-sans;
		}

		.gmit-gf-carline-pricing {
			font-family: $font-family-sans;

			td {
				line-height: 1rem;
			}
		}

		.gmit-gf-paragraph-label-bold {
			font-family: $font-family-sans;
			font-size: 1.5rem;
			line-height: 1rem;
			padding-bottom: 1rem;
		}

		.gmit-gf-dealer-contact,
		.gmit-gf-dealer-hours,
		.gmit-gf-dealer-address {
			font-family: $font-family-sans;
		}
	}

	.gmit-gf-comment-field {
		.gmit-gf-count {
			float: left;
			font-size: .875rem;
		}
	}

	.gmit-gf-paragraph {
		.gmit-gf-paragraph-label-bold {
			@extend %#{$gf-paragraph-body-font};
		}

		p {
			@extend %#{$gf-paragraph-body-font};
			margin-bottom: $gf-paragraph-margin-bottom;
		}
	}

	.gmit-gf-groupbox {
		.gmit-gf-paragraph {
			p {
				margin-bottom: $gf-paragraph-margin-bottom;
			}
		}
	}

	.gmit-gf-carline-headline,
	.gmit-gf-bodystyle-headline,
	.gmit-gf-trim-headline,
	.gmit-gf-trim-description,
	.gmit-gf-co2-headline,
	.gmit-gf-model-year-headline {
		@extend %#{$gf-vehicle-selector-headline-font};
	}

	.gmit-gf-pricing-headline {
		@extend %#{$gf-vehicle-selector-subheadline-font};
	}

	.gmit-gf-carline-headline,
	.gmit-gf-bodystyle-headline,
	.gmit-gf-co2-headline {
		text-transform: none;
	}

	.gmit-gf-engine-description,
	.gmit-gf-co2-disclaimer,
	.gmit-gf-co2-stats-table {
		font-size: .875rem;
		font-weight: $font-style-normal;
		line-height: 1.14;
	}

	.gmit-gf-co2-disclaimer,
	.gmit-gf-co2-stats-table {
		font-family: $font-family-sans;
		font-size: .875rem;
		font-weight: $font-style-normal;
		line-height: 1.29;
	}

	.gmit-gf-carline-pricing-table {
		tr th,
		tr td,
		.gmit-gf-carline-pricing-label2,
		.gmit-gf-carline-pricing2 {
			color: $gf-pricing-table-text;
		}

		tr:nth-child(2) {
			td {
				font-weight: $font-weight-bold;
			}
		}
	}

	.gmit-gf-group-title {
		color: $gf-group-title-text;
	}

	.gmit-gf-expand-icon {
		&.icon {
			color: $gf-expander-icon-color;
			font-family: $font-family-sans;
			font-size: $gf-expand-icon-size;
			line-height: .58;
		}

		&.minus-icon {
			color: $gf-expander-minus-icon;
		}
	}

	.icon-plus,
	.icon-minus {
		&::before {
			font-size: $gf-expand-icon-size;
		}
	}

	.gmit-gf-co2-image {
		min-height: 17.3125rem;
		min-width: 11.75rem;
	}

	.gmit-gf-layer-window {
		&.reveal-modal {
			background-color: $gf-reveal-modal-background;
			border-color: $gf-reveal-modal-border;
			color: $gf-reveal-modal-text;

			.gmit-gf-modal-header {
				.gmit-gf-modal-headline {
					color: $gf-modal-headline-text;
					font-family: $font-family-sans;
					font-size: 1.75rem;
					font-weight: $font-weight-bold;
					line-height: .86;
				}
			}

			.gmit-gf-modal-container {
				font-size: .75rem;
				line-height: 1.33;
			}

			.close-reveal-modal {
				text-decoration: none;

				.icon-close {
					background-color: $gf-modal-close-icon-bg;
					border: 2px solid $gf-modal-close-icon-border;
					color: $gf-modal-close-icon-color;
					font-size: 2.25rem;
				}

			}
		}
	}

	.gmit-flex-col-content {
		background-color: $gf-flex-content;
		border-top-color: $gf-flex-content;
	}

	.gmit-brochure-col-content {
		border-color: $gf-brochure-border-color;
	}

	.gmit-gf-field-group-container {
		.gmit-gf-input-radio,
		.gmit-gf-input-checkbox {

			+ .gmit-gf-label-text {
				font-size: $gf-checkbox-radio-label-font;
				font-weight: $gf-radio-checkbox-weight;
				line-height: 1.25rem;
				margin: 0;
				padding: $gf-radio-label-padding;
				position: relative;
			}
		}
	}

	.gmit-gf-input-radio {
		+ label {
			&::before {
				border-radius: $gf-radio-border-radius;
				cursor: pointer;
			}
		}

		&:checked {
			+ label {
				&::before {
					background: $gf-radio-button-color;
					box-shadow: $gf-radio-checked-box-shadow;
				}
			}
		}
	}

	.gmit-gf-input-radio {
		left: -625rem;
		position: absolute;

		+ label {
			position: relative;

			&::before {
				border: $gf-radio-border;
				box-shadow: $gf-radio-box-shadow;
				content: '';
				height: $gf-radio-height;
				left: 0;
				position: absolute;
				top: $gf-radio-top-position;
				width: $gf-radio-width;
			}
		}
	}

	// Changing between checked states
	.gmit-gf-input-checkbox:not(:checked) + label::after,
	.gmit-gf-input-radio:not(:checked) + label::after {
		opacity: 0;
		transform: scale(0);
	}

	// Changing between checked states
	.gmit-gf-input-checkbox:checked + label::after,
	.gmit-gf-input-radio:checked + label::after {
		opacity: 1;
		transform: scale(1);
	}

	// Move default checkbox input off screen
	.gmit-gf-field-group-container,
	.gmit-gf-result-item,
	.gmit-brochure-col-content {
		.gmit-gf-input-checkbox {
			left: -625rem;
			position: absolute;

			.gmit-gf-label-text {
				padding: 0;
			}

			&:checked {
				+ label {
					&::before {
						background-color: $gf-checkbox-active-background-color;
						border-color: $gf-checkbox-active-border-color;
					}
				}
			}

			// Brand specific styling can override this by using class name instead of label
			+ label {
				cursor: pointer;
				line-height: $gf-label-line-height;
				margin: .5rem 0 0;
				padding: $gf-checkbox-label-padding;
				position: relative;

				// Checkbox Styles
				&::before {
					background: $gf-checkbox-background-color;
					border: $gf-checkbox-border;
					border-radius: .0625rem;
					content: '';
					cursor: pointer;
					height: $gf-checkbox-height;
					left: 0;
					margin: $gf-checkbox-margin;
					position: absolute;
					top: $gf-checkbox-top-position;
					width: $gf-checkbox-width;
				}

				// Checkmark styles
				&::after {
					content: get-element($gf-check-icon);
					left: $gf-checkbox-check-left-position;
					position: absolute;
					top: $gf-checkbox-check-top-position;
					width: $gf-checkbox-check-width;
				}
			}
		}
	}

	.gmit-gf-result-item {
		.gmit-gf-input-checkbox {
			+ label {
				padding: $gf-dealer-results-checkbox-padding;
			}
		}
	}

	[data-abide] {
		input {
			&[data-invalid] {
				&.gmit-gf-input-text {
					border: $gf-error-border-style;
					transition: border, box-shadow .25s;

					// fixes bug in which error state and chrome auto fill cause border to disappear
					&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
						border: $gf-input-border;
					}
				}
			}
		}

		small {
			&.error {
				background-color: transparent;
				font-size: $gf-error-label-font-size;
				font-style: $font-style-normal;
				font-weight: $font-weight-normal;
			}
		}

		.gmit-gf-brochure-list {
			.gmit-flexbox-container {
				&.error {
					.gmit-gf-input-checkbox {
						+ label {
							&::before {
								border-color: $gf-error-color;
							}
						}
					}
				}
			}
		}

		.error {

			ul {
				.error {
					.gmit-gf-label-text {
						&::before {
							border-color: $gf-error-color;
						}
					}

					.gmit-gf-input-radio,
					.gmit-gf-input-checkbox {

						+ label {

							&::before {
								border-color: $gf-error-color;
							}
						}
					}


				}
			}

			small {
				&.error {
					background-color: transparent;
					font-size: $gf-error-label-font-size;
					font-style: $font-style-normal;
					font-weight: $font-weight-normal;

					.gmit-gf-error-label {
						color: $gf-error-color;
						font-weight: $gf-label-text-weight;
					}
				}

				label {
					color: $gf-small-label-text;
					font-family: $gf-input-font-family;
					font-size: $gf-error-label-font-size;
					line-height: $gf-error-line-height;
				}
			}

			select {
				border: $gf-error-border-style;
				transition: border 0s ease 0s, box-shadow #{$anim-fast}s ease 0s;
			}

			span {
				&.error {
					background-color: transparent;
					font-size: $gf-error-label-font-size;
					font-style: $font-style-normal;
					font-weight: $font-weight-normal;
				}
			}

			label {
				color: $gf-label-text-color;

				&.error {
					color: $gf-error-color;
				}
			}

			> label {
				> small {
					background: transparent;
					color: $gf-error-color;
					display: inline;
					font-size: 60%;
					font-style: $font-style-normal;
					text-transform: capitalize;
				}
			}

			.gmit-gf-field-group-container {
				.gmit-gf-input-radio,
				.gmit-gf-input-checkbox {
					+ label {
						&::before {
							border-color: $gf-error-color;
						}
					}
				}
			}
		}

		&.gmit-gf-front-right,
		&.gmit-gf-front-left {

			label {
				&.gmit-gf-label-text {
					&.error {
						color: $gf-error-color;
					}
				}
			}
		}
	}

	.gmit-gf-text-field {
		margin: 0;

		input {
			+ .gmit-gf-focus-text {
				@include hard-easeout(max-height, $anim-slow);

				display: block;
				max-height: 0;
				overflow: hidden;
			}

			&:focus {
				+ .gmit-gf-focus-text {
					@include hard-easeout(max-height, $anim-slow);

					max-height: 5.3125rem;
					padding-top: .625rem;
				}
			}
		}
	}

	.gmit-gf-text-field,
	.gmit-gf-datepicker,
	.gmit-gf-dropdown,
	.gmit-gf-comment-field,
	.gmit-gf-field-options {
		.gmit-gf-label-text {
			margin-bottom: map-get($gf-label-bottom-margin-map, small);
		}
	}

	// Accessibility keyboard focus styles
	.gmit-gf-input-checkbox:focus + label::before,
	.gmit-gf-input-radio:focus + label::before {
		border: $gf-radio-checkbox-focus;
	}

	.gmit-flexbox-container {
		margin: 0 0 .3rem;
		padding: $gf-flexbox-padding;
	}

	// Cancel and Submit button container classes for layout styling
	.gmit-gf-button-style,
	.gmit-gf-col1-btn {
		align-items: center;
		margin: 0 0 2rem;

		.gmit-gf-link {
			padding-bottom: 0;
		}

		.gmit-gf-submit-button {
			margin-bottom: 0;
		}

		// Secondary Button
		.q-link {
			a {
				align-items: center;
				background-color: $gf-secondary-button-bg-color;
				border: $gf-secondary-button-border;
				color: $gf-secondary-button-text-color;
				display: flex;
				font-family: $gf-button-font-family;
				font-size: $gf-button-font-size;
				font-weight: $gf-secondary-button-font-weight;
				height: map-get($gf-button-height-map, small);
				letter-spacing: $gf-button-letter-spacing;
				line-height: $gf-button-line-height;
				padding: $gf-secondary-button-padding;
				text-decoration: none;
				text-transform: $gf-button-transform;
				transition: background-color 300ms ease-out;

				&:hover {
					background-color: $gf-secondary-button-bg-hover-color;
					border: $gf-secondary-button-border-hover;
					color: $gf-secondary-button-text-hover-color;
					text-decoration: none;
				}
			}
		}
	}

	.q-link {
		&.gmit-gf-link {
			&.gmit-gf-col1-btn {
				margin-bottom: 1rem;

				a {
					justify-content: center;
					text-decoration: none;
				}
			}
		}
	}

	.gmit-gf-paragraph {
		&.gmit-gf-clearfix {
			margin-bottom: 2rem;
		}
	}

	// Mobile Expander icon
	.icon-plus,
	.icon-minus {
		&::before {
			line-height: inherit;
		}
	}

	.q-mod-gmit-gf-button,
	.gmit-gf-button,
	.gmit-gf-full-width {
		width: 100%;
	}

	.q-link {
		font-size: $gf-link-font-size;
		font-weight: $font-weight-bold;

		a {
			color: $gf-link-color;
			font-family: $gf-link-font-family;
			font-weight: $gf-link-font-weight;
			text-decoration: $gf-link-decoration;

			&:hover {
				color: $gf-link-hover-color;
				text-decoration: $gf-link-hover-decoration;

				.icon-plus,
				.icon-minus {
					color: $gf-optional-expander-icon-hover;
				}
			}

			.icon-plus,
			.icon-minus {
				color: $gf-optional-expander-icon-color;
			}
		}

		.gmit-gf-vehicle-link {
			text-decoration: none;
		}

		.gmit-gf-change-vehicle-link {
			text-decoration: none;
		}
	}

	.gmit-gf-formatted-time {
		font-size: $gf-dealer-hours-font;
		padding: $gf-dealer-hours-padding 0;

		.gmit-gf-day-of-week-label {
			font-weight: $font-weight-bold;
			padding-right: .5rem;
		}
	}

	.gmit-gf-loader {
		&::before {
			border-top: $gf-dealer-search-animation;
		}
	}

	.gmit-gf-change-vehicle-link {
		.icon-plus,
		.icon-minus {
			&::before {
				left: $gf-optional-details-icon-left;
				position: absolute;
			}
		}
	}

	// Remove extra padding on vehicle selector
	.gmit-gf-vehicle-selector-v2 {
		.row {
			.row {
				.column {
					padding: 0;
				}
			}
		}

		div {
			padding: 0;
		}
	}

	.gmit-gf-link {
		padding-bottom: 1rem;
	}

	.gmit-gf-brand-headline {
		text-transform: $gf-brand-parameter-case;
	}

	@media #{$medium-up} {
		.gmit-gf-dropdown,
		.gmit-gf-datepicker {
			select,
			.gmit-gf-input-text {
				background-position: unquote(map-get($gf-dropdown-position-map, medium));
				background-size: unquote(map-get($gf-dropdown-size-map, medium));
				font-size: map-get($gf-input-font-size-map, medium);
				padding: map-get($gf-dropdown-padding-map, medium);

				// Override iOS bug fix style that was put in place for mobile viewports
				&:focus {
					font-size: map-get($gf-input-font-size-map, medium);
				}
			}
		}

		.gmit-gf-text-field,
		.gmit-gf-dropdown,
		.gmit-gf-datepicker {
			input {
				&[type='text'],
				&[type='email'],
				&[type='number'] {
					font-size: map-get($gf-input-font-size-map, medium);
					height: map-get($gf-input-height-map, medium);
					padding: map-get($gf-input-padding-map, medium);

					// Override iOS bug fix style that was put in place for mobile viewports
					&:focus {
						font-size: map-get($gf-input-font-size-map, medium);
					}
				}
			}
		}

		.gmit-gf-text-field,
		.gmit-gf-datepicker,
		.gmit-gf-dropdown,
		.gmit-gf-comment-field,
		.gmit-gf-field-options {
			.gmit-gf-label-text {
				margin-bottom: map-get($gf-label-bottom-margin-map, medium);
			}
		}

		.gmit-gf-comment-field {
			textarea {
				font-size: map-get($gf-input-font-size-map, medium);
				padding: map-get($gf-textarea-padding-map, medium);

				// Override iOS bug fix style that was put in place for mobile viewports
				&:focus {
					font-size: map-get($gf-input-font-size-map, medium);
				}
			}
		}

		.gmit-gf-button-style,
		.gmit-gf-col1-btn {
			// Secondary Button
			.q-link {
				a {
					height: map-get($gf-button-height-map, medium);
				}
			}
		}
	}

	@media #{$large-up} {
		.gmit-gf-label-text {
			font-size: map-get($gf-label-text-font-size-map, large);
			height: auto;
			padding: 0;
		}

		.gmit-gf-dropdown,
		.gmit-gf-datepicker {
			select,
			.gmit-gf-input-text {
				background-position: unquote(map-get($gf-dropdown-position-map, large));
				background-size: unquote(map-get($gf-dropdown-size-map, large));
				font-size: map-get($gf-input-font-size-map, large);
				height: map-get($gf-input-height-map, large);
				line-height: $gf-input-line-height;
				padding: map-get($gf-dropdown-padding-map, large);

				// Override iOS bug fix style that was put in place for mobile viewports
				&:focus {
					font-size: map-get($gf-input-font-size-map, large);
				}
			}
		}

		.gmit-gf-button {
			height: map-get($gf-button-height-map, large);
		}

		.gmit-gf-text-field,
		.gmit-gf-dropdown,
		.gmit-gf-datepicker {
			input {
				&[type='text'],
				&[type='email'],
				&[type='number'] {
					font-size: map-get($gf-input-font-size-map, large);
					height: map-get($gf-input-height-map, large);
					line-height: $gf-input-line-height;
					padding: map-get($gf-input-padding-map, large);

					// Override iOS bug fix style that was put in place for mobile viewports
					&:focus {
						font-size: map-get($gf-input-font-size-map, large);
					}
				}
			}
		}

		.gmit-gf-comment-field {
			textarea {
				font-size: map-get($gf-input-font-size-map, large);
				line-height: $gf-input-line-height;
				padding: map-get($gf-textarea-padding-map, large);

				// Override iOS bug fix style that was put in place for mobile viewports
				&:focus {
					font-size: map-get($gf-input-font-size-map, large);
				}
			}
		}

		.gmit-gf-button-style,
		.gmit-gf-col1-btn {
			// Secondary Button
			.q-link {
				a {
					height: map-get($gf-button-height-map, large);
				}
			}
		}
	}

	@media #{$small-only} {
		.gmit-gf-button-style {
			width: 100%;
		}
	}
}
