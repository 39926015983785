.q-dealer-details {
	background-color: $dl-dealer-details-background-color;
	border-radius: 0;
	max-width: 100%;
	width: 100%;

	.dealer-map {
		height: 25rem;
	}

	.q-map {
		height: 100%;
		margin-bottom: 1rem;
		margin-left: -2rem;
		margin-right: -2.5rem;
	}

	.q-separator {
		hr {
			margin-bottom: 2.5rem;
			margin-top: 2.5rem;
		}
	}

	.q-header {
		margin-bottom: 1.5rem;
	}

	.q-modal-header {
		background-color: transparent;
		left: 0;
		margin: auto;
		padding: .75rem;
		position: relative;
		top: 0;
		width: 100%;

		.q-modal-title {
			color: $grey-dark;
			width: 100%;
		}

		.close-reveal-modal {
			position: absolute;

			.q-details-close {
				@include insert-icon($dl-modal-close-icon);
				border-radius: 2rem;
				height: $dl-modal-close-icon-size;
				margin: .75rem;
				padding: $dl-close-button-padding;
				width: $dl-modal-close-icon-size;

				&:hover {
					@include insert-icon($dl-modal-close-icon-hover);
				}
			}
		}

	}

	.q-modal-container {
		margin: 0 1.5rem;
		padding: 1rem 0 2rem;
		width: auto;
	}

	.columns {
		&.right-column {
			padding-left: .625rem;
		}

		.sub-column {
			padding-bottom: 2rem;
		}

		&:last-child {
			padding-bottom: 0;
			padding-right: 0;
		}
	}

	.q-button-container {
		.q-button {
			flex: 0 0 100%;
			margin-top: 1rem;
		}
	}

	.q-modal-title {
		font-weight: $header-font-weight;
		max-width: 100%;
		text-align: $dl-modal-title-text-align;

		span {
			display: inline-block;
			padding-bottom: .25rem;
			vertical-align: middle;
		}

		.icon {
			text-decoration: none;

			&.icon-arrow-right {
				float: none;
				font-size: inherit;
				margin-top: 0;
			}

			&.disabled {
				opacity: .4;
			}
		}
	}

	.q-location-index {
		display: flex;
		flex: 1 0 100%;

		.q-modal-subtitle {
			display: flex;
			font-family: $dl-modal-subtitle-font-family;
			font-size: 1.5rem;
			font-weight: $font-weight-xbold;
			justify-content: center;
			margin-bottom: .5rem;
			margin-left: auto;
			margin-right: auto;
			margin-top: 1rem;
			max-height: 40px;
			padding: .0625rem;

			.q-details-for {
				flex: 0 1 auto;
				line-height: 2;
				text-align: center;
			}

			.q-details-arrow {
				border: 0;
				color: inherit;
				flex: 0 1 auto;
				font-size: 1.5rem;
				height: $dl-details-arrow-size;
				margin: 0 .5rem;
				position: static;

				&.q-arrow-prev {
					@include insert-icon($dl-details-arrow-next);
					background-position: center;
					transform: scaleX(-1);
				}

				&.q-arrow-next {
					@include insert-icon($dl-details-arrow-next);
					background-position: center;
				}

				&::before,
				&::after {
					display: block;
					height: 30px;
					position: relative;
					top: -20px;
				}
			}
		}
	}


	.q-colon {
		&::before {
			content: ':';
			display: inline;
		}
	}

	.q-show-medium-up {
		display: none;
	}

	.q-sales-list {
		flex: 1 0 100%;
	}

	.q-service-list {
		flex: 1 0 100%;
	}

	.margin-bottom {
		margin-bottom: .875rem;
	}

	.padding-right {
		padding-right: 2rem;
	}

	.q-hide-for-details {
		display: none;
	}

	.q-hide-for-modal {
		display: none;
	}

	.q-dealer-services {
		@extend %q-descriptive2;

		flex: 1 0 100%;
		order: 3;

		ul {
			padding-left: .5rem;
		}
	}

	.q-dealer-links-container {
		width: 100%;
	}
}

@media #{$medium-up} {
	.q-dealer-details {
		.q-separator {
			hr {
				margin-bottom: 2.5rem;
				margin-top: 3rem;
			}
		}

		.q-modal-container .q-button {
			margin: 1rem 0 0;
		}

		.q-show-small-only {
			display: none;
		}

		.q-show-medium-up {
			display: inherit;
		}

		.q-map {
			margin-bottom: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

@media #{$medium-down} {
	.q-dealer-details {
		.q-show-large-up {
			display: none;
		}

		.small-only-margin-top {
			margin-top: .5rem;
		}

		.reverse-rows-small {
			display: flex;
			flex-direction: column-reverse;
		}

		.q-modal-container {
			.q-button {
				margin: .5rem 0 0;
			}

			.q-padding-bottom {
				padding-bottom: 3rem;
			}

			.q-button {
				&:last-child {
					margin: 1.5rem 0 0;
				}
			}
		}
	}
}

@media #{$large-up} {
	.q-dealer-details {
		max-width: $row-width;

		.q-separator {
			hr {
				margin-bottom: 1.5rem;
				margin-top: 1.5rem;
			}
		}
	}
}

@media #{$xlarge-up} {
	.q-dealer-details {
		max-width: $row-width;

		.q-sales-list {
			flex: 1;
		}

		.q-service-list {
			flex: 1;
		}

		.q-button-container {
			.q-button {
				flex: 1;
				margin-left: .5rem;
				margin-right: .5rem;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.q-map-container {
			flex: 1;
			order: 3;
		}

		.q-dealer-services {
			flex: 1 0 100%;
			order: 4;
		}
	}

}
