.q-payment-estimator {
	gmst-pe-title {
		display: none;
	}

	.postalcode-label {
		display: none;
	}

	.gmst-modal-symbol-fl {
		font-size: smaller;
		line-height: normal;
		vertical-align: super;

		&::after {
			// Disclosure Dagger Unicode
			content: '\02020';
			font-size: 75%;
			font-weight: normal;
			line-height: 0;
			position: relative;
			text-decoration: underline;
			vertical-align: baseline;
		}
	}

	.hidden {
		display: none !important; //sass-lint:disable-line no-important
		padding: 0;
	}

	.hide-pe-filter-options {
		display: none;
	}

	gmst-pe-financedetailbox {
		width: 100%;
	}

	gmwc-stateful-dropdown {
		display: inherit;

		&[model='year'],
		&[model='carline'],
		&[model='fschedule'] {
			display: none !important; //sass-lint:disable-line no-important
		}
	}

	gmst-pe-financebox,
	gmst-pe-leasebox {
		padding: inherit;
	}

	gmst-pe-title,
	gmst-disclaimers {
		float: left;
		width: 100%;
	}

	// Shopping Tools Select Styling
	.filter-options {
		&[model='carline'] {
			&:not([carline='undefined']) {
				&:not([carline='']) ~ [model='configuration'],
				&:not([carline='']) ~ [for='gmst-filter-option-configuration'] {
					display: block;
				}
			}
		}

		&[model='configuration'] {
			&:not([configuration='undefined']) {
				&:not([configuration='']) {
					&::after {
						content: '';
					}
				}

				&:not([configuration='']) ~ [model='trim'],
				&:not([configuration='']) ~ [for='gmst-filter-option-trim'] {
					display: block;
				}
			}
		}

		&[model='carline'],
		&[model='configuration'],
		&[model='trim'],
		&[model='year'] {
			&::after {
				content: '⇐ Next';
				margin-left: .625rem;
			}
		}

		&[model='trim'] {
			&:not([trim='undefined']) {
				&:not([trim='']) {
					&::after {
						content: '';
					}
				}
			}
		}

		&[model='year'] {
			&:not([year='undefined']) {
				&:not([year='']) {
					&::after {
						content: '';
					}
				}
			}
		}

		&[model='carline'],
		&[model='configuration'],
		&[model='trim'] {
			display: none;
		}

		label {
			&[for='gmst-filter-option-carline'],
			&[for='gmst-filter-option-configuration'],
			&[for='gmst-filter-option-trim'] {
				display: none;
			}
		}
	}

	input {
		&[type='text'] {
			&[readonly] {
				background-color: $white;
			}
		}
	}

	.gmwc-stateful-dropdown-year,
	.gmwc-stateful-dropdown-carline,
	[for='gmwc-stateful-dropdown-year'],
	[for='gmwc-stateful-dropdown-carline'],
	gmst-vsinventory-search {
		display: none;
	}

	.gmst-pe-financedetails-textfield,
	input,
	select,
	.gmwc-stateful-dropdown,
	.gmst-pe-othercredits,
	.gmst-postalcode-input,
	.gmst-modal-nameinput {
		@extend %gf-form-input;
	}

	.gmst-pe-financedetails-textfield {
		&.gmst-pe-input-error {
			border: $gf-error-border-style !important; //sass-lint:disable-line no-important

			// fixes bug in which error state and chrome auto fill cause border to disappear
			&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
				border: $gf-input-border;
			}
		}

		&:hover {
			cursor: text !important; //sass-lint:disable-line no-important
		}
	}

	// Top Container Styling
	.q-pe-top-container {
		display: block;
		float: left;
		width: 100%;

		// Vehicle Image
		gmst-vehicleselector-image {
			display: flex;
			flex-wrap: wrap;
			height: auto;
			min-height: 10rem;
			width: 100%;

			.gmst-vehicleselector-image-vehicleimage {
				align-self: baseline;
				width: 100%;
			}

			.gmst-vehicleselector-image-country {
				display: none;
				flex: 0 1 100%;
				padding-left: $pe-brand-padding;
			}
		}

		// Vehicle Title
		.q-pe-vehicle-title-info {
			display: inline-block;

			gmst-vehicleselector-title {
				display: inline-block;
				min-height: 1rem;
				width: auto;

				.gmst-vehicleselector-title {
					display: inline;
					float: none;
					margin: 0;
					width: 100%;

					.gmst-vehicleselector-image-year,
					.gmst-vehicleselector-image-model {
						@extend %#{$pe-vehicle-selector-title-typography};
						display: inline;
						margin-bottom: 0;
						padding: 0;
						position: relative;
					}

					.gmst-vehicleselector-image-config,
					.gmst-vehicleselector-image-trim {
						@extend %#{$pe-vehicle-selector-config-trim-typography};
						display: inline;
						position: relative;
					}

					.gmst-vehicleselector-image-config {
						clear: left;
					}

					.gmst-vehicleselector-image-model {
						clear: right;
					}
				}
			}

			&:nth-of-type(1) {
				width: 100%;

				gmst-vehicleselector-title {
					.gmst-vehicleselector-title {
						padding-right: 0;
						width: 66.66%;

						.gmst-vehicleselector-image-config,
						.gmst-vehicleselector-image-trim {
							display: none;
						}
					}
				}
			}

			&:nth-of-type(2) {
				width: 66.6667%;

				gmst-vehicleselector-title {
					.gmst-vehicleselector-title {
						.gmst-vehicleselector-image-year,
						.gmst-vehicleselector-image-model {
							display: none;
						}

						.gmst-vehicleselector-image-config,
						.gmst-vehicleselector-image-trim {
							display: inline-block;
						}
					}
				}
			}
		}

		// Starting Price
		gmst-vehicleinfo {
			display: flex;
			width: 100%;

			&.hide-vehicleinfo {
				display: none;
			}

			.price-box-message {
				display: none;
			}

			.starting-price-wrapper {
				@extend %#{$pe-starting-price-typography};
				color: $pe-content-text-color;
				text-align: left;

				&:hover {
					color: $brand-link-color-hover;
					cursor: pointer;
				}

				.starting-totalprice {
					font-weight: $font-weight-normal;
				}

				.gmst-disclosure-startprice {
					.gmst-modal-symbol {
						font-size: smaller;
						line-height: normal;
						vertical-align: super;

						&::after {
							// Disclosure Dagger Unicode
							content: '\02020';
							font-size: 75%;
							font-weight: normal;
							line-height: 0;
							position: relative;
							text-decoration: underline;
							vertical-align: baseline;
						}
					}
				}
			}
		}

		// Vehicle Filters
		gmst-vehicleselector {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;

			span {
				@extend %#{$pe-label-typography};
				float: left;
				width: 100%;
			}

			// Configuration and Trim Drop Downs
			gmwc-stateful-dropdown {
				&[model='configuration'],
				&[model='trim'] {
					display: flex;
					flex: 0 1 100%;
					flex-wrap: wrap;
				}

				&[model='configuartion'][trim='undefined'] {
					+ &[model='trim'] {
						&[trim='undefined'] {
							pointer-events: none;
						}
					}
				}

				.gmwc-stateful-dropdown-trim {
					&:disabled {
						pointer-events: none;
					}
				}
			}

			&.hide-pe-filter-options {
				display: none; // sass-lint:disable-line no-important
			}
		}

		.q-pe-postal-code-container {
			display: flex;
			flex-wrap: wrap;
			float: left;
			justify-content: flex-end;
			position: relative;
			text-align: left;
			width: 100%;

			.postalcode-label + div {
				flex: 0 1 100%;
			}

			.q-postal-code-label,
			.q-postal-code-input {
				flex: 0 1 100%;
				float: left;
			}

			.q-postal-code-label {
				@extend %#{$pe-label-typography};
				float: left;

				&:hover {
					cursor: default;
				}
			}

			.q-postal-code {
				display: flex;
				flex: 0 1 100%;
				flex-wrap: wrap;

				gmst-postalcode {
					&.invalid {
						#gmst-postalcode-container {//sass-lint:disable-line no-ids
							.gmst-postalcode-input {
								border: $gf-error-border-style;

								// fixes bug in which error state and chrome auto fill cause border to disappear
								&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
									border: $gf-input-border;
								}
							}
						}
					}

					// Required to Override
					#gmst-postalcode-container { // sass-lint:disable-line no-ids
						border: $gf-dropdown-border;
						display: flex;
						min-width: auto;

						input {
							min-width: auto;
							padding: map-get($gf-dropdown-padding-map, small);

							&:hover {
								cursor: auto;
							}
						}

						.gmst-postalcode-submit-icon {
							background-image: get-element($pe-postalcode-submit-icon);
							background-position: center center;
							background-repeat: no-repeat;
							background-size: contain;
							border: 0;
							border-radius: 0;
							display: inline-block;

							&::after {
								background-color: transparent;
								border: 0;
							}
						}

						.gmst-postalcode-location-icon {
							background-image: get-element($pe-postalcode-location-icon);
							background-position: center center;
							background-repeat: no-repeat;
							background-size: contain;
							border: 0;
							border-radius: 0;
							display: inline-block;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);

							&::before,
							&::after {
								border: 0;
							}

							i {
								display: none;
							}
						}

						.gmst-postalcode-submit {
							position: absolute;
							right: 0;
						}
					}

					.gmst-postalcode-error {
						@extend %#{$pe-finance-input-error-typography};
						color: $pe-error-color;
					}
				}

				&.row {
					margin: inherit;
					max-width: none;
					width: auto;

					.medium-3,
					.medium-9 {
						width: 100%;
					}

					.columns {
						padding: 0;
					}
				}
			}

			gmst-postalcode-popup {
				position: absolute;
				width: 90%;
				z-index: 9999;

				.gmst-postalcode-popup-shade {
					background-color: $pe-brand-modal-background-color;
					bottom: 0;
					left: 0;
					overflow-y: scroll;
					position: fixed;
					right: 0;
					top: 0;
					z-index: 9998;
				}

				.gmst-postalcode-popup-message {
					background-color: $white;
					display: flex;
					flex-wrap: wrap;
					height: auto;
					margin: 0 auto;
					position: fixed;
					width: 90%;
					z-index: 9999;

					.gmst-postalcode-title-flexbox {
						flex: 0 1 80%;

						span {
							@extend %#{$pe-label-typography};
						}
					}

					> p {
						@extend %#{$pe-postalcode-popup-typography};
						flex: 0 1 80%;
					}

					gmst-postalcode {
						&.invalid {
							#gmst-postalcode-container {//sass-lint:disable-line no-ids
								.gmst-postalcode-input {
									border: $gf-error-border-style;

									// fixes bug in which error state and chrome auto fill cause border to disappear
									&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
										border: $gf-input-border;
									}
								}
							}
						}

						#gmst-postalcode-container {//sass-lint:disable-line no-ids
							border: 0;
							height: auto;

							.gmst-postalcode-submit-icon {
								background-image: get-element($pe-postalcode-submit-icon);
								background-position: center center;
								background-repeat: no-repeat;
								background-size: contain;
								border: 0;
								border-radius: 0;
								left: 50%;
								transform: translateX(-50%);

								&::after {
									background-color: transparent;
									border: 0;
								}
							}

							.gmst-postalcode-location-icon {
								background-image: get-element($pe-postalcode-location-icon);
								background-position: center center;
								background-repeat: no-repeat;
								background-size: contain;
								border: 0;
								border-radius: 0;
								left: 50%;
								top: 50%;
								transform: translate(-50%, -50%);

								&::before,
								&::after {
									border: 0;
								}

								i {
									display: none;
								}
							}

							input {
								&:hover {
									cursor: auto;
								}
							}
						}

						.gmst-postalcode-error {
							@extend %#{$pe-finance-input-error-typography};
							color: $pe-error-color;
							margin: 1.25rem 0 0;
						}
					}

					.gmst-postalcode-popup-close {
						background-color: transparent;
						background-image: get-element($pe-modal-rewards-card-close-icon);
						background-position: center center;
						background-repeat: no-repeat;
						border-color: transparent;
						font-size: 0;
						position: absolute;
						transition: none;

						&:hover {
							background-image: get-element($pe-modal-rewards-card-close-icon-hover);
						}
					}
				}
			}
		}

		.q-dropdown-toggle {
			@extend %#{$pe-q-toggle-typography};
			display: inline-block;
			float: right;
			padding-right: $pe-brand-padding;

			.q-toggle-open,
			.q-toggle-close {
				display: none;

				&.active {
					display: block;
				}
			}

			a {
				&:visited,
				&:active,
				&:focus {
					color: $pe-content-text-color;
				}

				&:hover {
					color: $brand-link-color-hover;
				}
			}
		}
	}

	.q-pe-finance-no-options {
		display: block;
		float: left;
		position: relative;
		width: 100%;

		gmwc-statefull-showhide {
			display: flex;
			justify-content: center;
			min-height: 18.75rem;
			width: 100%;

			span {
				@extend %#{$pe-finance-no-options-typography};
			}
		}

		&.hide-pe-filter-options {
			display: none;
		}
	}

	.q-pe-finance-container {
		clear: both;
		display: none;
		flex-direction: column;
		float: left;
		width: 100%;

		// Finance & Lease Containers
		.q-pe-finance-lease-container {
			.gmst-pe-financebox-main,
			.gmst-pe-leasebox-main {
				display: flex;
				flex-wrap: wrap;
				height: 100%;
				padding: $pe-finance-lease-box-padding;
			}

			.gmst-pe-financebox-termamount-wrap,
			.gmst-pe-leasebox-termamount-wrap {
				display: flex;
				flex: 0 1 100%;
				flex-wrap: wrap;
			}

			// Finance Container
			.q-pe-finance-box-container {
				flex: 0 1 100%;
				height: 100%;

				.gmst-pe-financebox-main {
					.gmst-pe-financebox-credits,
					.gmst-pe-financebox-cash-credits {
						@extend %#{$pe-financebox-credits-typography};
						color: $pe-content-text-color;
						flex: 0 1 100%;

						.gmst-pe-financebox-taxesincluded-check {
							background-image: get-element($pe-checkmark);
							background-repeat: no-repeat;
							background-size: 100%;
						}
					}

					.gmst-pe-financebox-title-wrap {
						display: none;
					}

					.gmst-pe-financebox-termamount {
						.gmst-pe-financebox-payment {
							@extend %#{$pe-financebox-payment-typography}
						}

						.gmst-disclosure-finance {
							display: inline-block;

							&:hover {
								.gmst-pe-financebox-fschedule-text,
								.gmst-modal-symbol-fl {
									color: $brand-link-color-hover;
									cursor: pointer;
								}
							}
						}

						.gmst-pe-financebox-fschedule-text {
							@extend %#{$pe-financebox-fschedule-typography};
						}
					}

					.gmst-pe-financebox-term,
					.gmst-pe-financebox-flex {
						display: flex;
						flex: 0 1 100%;
					}

					.gmst-pe-financebox-flex {
						.gmst-pe-financebox-flex-right-column {
							display: flex;
							flex: 0 1 100%;
							flex-wrap: wrap;

							&.gmst-pe-financedetailbox-input-error-message-wrapper {
								flex-wrap: wrap;

								.gmst-pe-financedetailbox-input-error-message {
									flex: 0 1 100%;
									float: left;
									position: relative;
									right: 0;
									top: 0;
									width: 100%;

									.validity-tooltip {
										background-color: transparent;
										border: 0;
										width: 100%;

										gm-translate {
											@extend %#{$pe-finance-input-error-typography};
											color: $pe-error-color;
										}
									}
								}
							}
						}

						.stat-dropdown {
							display: flex;
							flex: 0 1 100%;

							.gmwc-stateful-dropdown-fterm {
								flex: 0 1 75%;
							}
						}

						.gmst-pe-financebox-flex-left-column {
							display: flex;
							flex: 0 1 50%;

							.gmst-pe-financeAmount { //sass-lint:disable-line class-name-format
								flex: 0 1 100%;
							}
						}

						.fterm-select-label,
						.gmst-pe-financebox-ratecheckboxlabel,
						.gmst-pe-financeAmount { //sass-lint:disable-line class-name-format
							@extend %#{$pe-label-typography};
							flex: 0 1 25%;
							padding-right: $pe-brand-padding;
							text-align: right;
						}
					}

					.gmst-pe-financebox-rate {
						display: flex;
						flex: 0 1 100%;
						justify-content: center;

						.gmst-pe-financebox-ratetitle {
							flex: 0 1 25%;
						}

						.gmst-pe-financebox-ratecustom {
							flex: 0 1 50%;
							max-width: 50%;


							+ span {
								@extend %#{$pe-label-typography};
								color: $pe-content-text-color;
								display: inline-block;
								flex: 0 1 auto;
							}

							&.gmst-pe-input-error {
								border: $gf-error-border-style !important; //sass-lint:disable-line no-important

								// fixes bug in which error state and chrome auto fill cause border to disappear
								&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
									border: $gf-input-border;
								}
							}

							&:hover {
								cursor: text !important; //sass-lint:disable-line no-important
							}
						}

						.gmst-pe-financebox-rate-effectiveRate { // sass-lint:disable-line class-name-format
							display: none;
						}
					}

					.gmst-pe-financebox-fcpurchaseprice {
						align-self: flex-end;
						display: flex;
						flex: 0 1 100%;

						.gmst-pe-financebox-flex-left-column,
						.gmst-pe-financebox-flex-right-column {
							@extend %#{$pe-financebox-left-right-typography};
							color: $pe-content-text-color;
							flex: 0 1 auto;
							width: auto;
						}

						.gmst-pe-financebox-flex-right-column {
							display: flex;
							flex-wrap: wrap;
						}
					}

					.gmst-pe-financebox-schedule {
						display: none !important; //sass-lint:disable-line no-important
					}

					.gmst-pe-financebox-termamount-wrap,
					.gmst-pe-financebox-credits,
					.gmst-pe-financebox-cash-credits,
					.gmst-pe-financebox-schedule,
					.gmst-pe-financebox-term,
					.gmst-pe-financebox-flex {
						align-self: flex-start;
					}
				}

				.gmst-pe-financebox-downpaymenterror {
					gm-translate {
						@extend %#{$pe-leasebox-offererror-typography};
					}

					.gmst-pe-financebox-title-wrap {
						display: none;
					}
				}
			}

			// Lease Container
			.q-pe-lease-box-container {
				align-self: flex-start;
				height: 100%;

				gmst-pe-leasebox {
					display: flex;
					height: 100%;
				}

				.gmst-pe-leasebox-main {
					.gmst-pe-leasebox-title-wrap {
						display: none;
					}

					.gmst-pe-leasebox-termamount {
						.gmst-pe-leasebox-payment {
							@extend %#{$pe-financebox-payment-typography};
						}

						.gmst-pe-financebox-fschedule-text {
							@extend %#{$pe-financebox-fschedule-typography};
						}

						.gmst-disclosure-lease {
							display: inline-block;

							&:hover {
								.gmst-pe-financebox-fschedule-text,
								.gmst-modal-symbol-fl {
									color: $brand-link-color-hover;
									cursor: pointer;
								}
							}
						}
					}

					.gmst-pe-leasebox-inline-disclaimers,
					.gmst-pe-leasebox-dueatsign,
					.gmst-pe-leasebox-credits {
						flex: 0 1 100%;
					}

					.gmst-pe-leasebox-dueatsign {
						color: $pe-content-text-color;
					}

					.gmst-pe-leasebox-inline-disclaimers,
					.lease-disclaimer {
						@extend %#{$pe-leasebox-inline-disclaimer-typography};
					}

					.gmst-pe-leasedetails-schedule-row,
					.gmst-pe-leasebox-rate {
						display: none !important; //sass-lint:disable-line no-important
					}

					.gmst-pe-leasebox-credits {
						@extend %#{$pe-leasebox-credits-typography};
						color: $pe-content-text-color;
					}

					.gmst-pe-leasebox-flex {
						width: 100%;

						.lterm-select-label,
						.lmileage-select-label {
							@extend %#{$pe-label-typography};
							flex: 0 1 25%;
							padding-right: $pe-brand-padding;
							text-align: right;
						}

						.gmwc-stateful-dropdown-lterm,
						.gmwc-stateful-dropdown-lmileage {
							flex: 0 1 75%;
						}
					}

					gmwc-stateful-dropdown {
						display: flex;
						flex: 0 1 100%;
					}

					label {
						&[for='gmwc-stateful-dropdown-lmileage'],
						&[for='gmwc-stateful-dropdown-lterm'] {
							flex: 0 1 25%;
							padding-right: $pe-brand-padding;
							text-align: right;
						}
					}

					div {
						&:first-child {
							flex: 0 1 100%;
						}

						&:last-child {
							flex: 0 1 100%;
						}
					}

					.gmst-pe-leasebox-termamount-wrap,
					.gmst-pe-leasebox-dueatsign,
					.gmst-pe-leasebox-inline-disclaimers,
					.gmst-pe-leasebox-credits,
					.gmst-pe-leasedetails-schedule-row,
					.gmst-pe-leasedetails-term-row,
					.gmst-pe-leasedetails-miles-row,
					.gmst-pe-leasebox-flex,
					.gmst-pe-leasebox-disclaimer {
						align-self: flex-start;
					}
				}

				.gmst-pe-leasebox-offererror {
					gm-translate {
						@extend %#{$pe-leasebox-offererror-typography};
					}

					.gmst-pe-leasebox-title-wrap {
						display: none;
					}

					slot {
						&[name='localDealer'] {
							display: inline;
							float: left;

							a {
								@extend %#{$pe-leasebox-offererror-typography};
							}
						}
					}
				}
			}

			.gmst-pe-financebox-termamount,
			.gmst-pe-leasebox-termamount {
				@extend %#{$pe-finance-lease-termamount-typography};
			}

			.gmst-pe-financebox-title-tagline,
			.gmst-pe-leasebox-title-tagline {
				@extend %#{$pe-finance-lease-title-tagline-typography};
				flex: 0 1 100%;
			}

			label {
				@extend %#{$pe-label-typography};
				float: left;
				position: relative;
			}

			.gmwc-stateful-dropdown-lschedule,
			.gmwc-stateful-dropdown-fschedule,
			.gmst-pe-financebox-rate-effectiveRate, // sass-lint:disable-line class-name-format
			> h3 {
				display: none;
			}

			&.gmst-pe-financebox-downpaymenterror,
			&.gmst-pe-leasebox-offererror,
			&.gmst-pe-leasebox-downpaymenterror {
				@extend %#{$pe-finance-lease-error-typography};
				flex: 1 1 100%;

				p {
					display: inline-block;

					gm-translate {
						display: inline-block;
						text-align: left;
					}
				}
			}

			.q-tabs-content {
				& > .content {
					background-color: $pe-content-background-color;
					border: 1px solid $pe-tab-border-color;
					display: none;
					width: 100%;

					&.active {
						background-color: $pe-content-background-color;
						border: 1px solid $pe-tab-border-color;
						border-top: 0;
						display: flex;
						float: left;
					}
				}

				.q-content {
					flex-wrap: wrap;

					.gmst-pe-finance-cta-slot,
					.gmst-pe-lease-cta-slot {
						align-self: flex-end;
						flex: 0 1 100%;

						div {
							&[slot='cta'] {
								display: flex;
								flex-wrap: wrap;
								justify-content: flex-end;
								width: 100%;

								a {
									@extend %button;
								}

								&:first-child {
									a {
										@extend %q-primary-button;
									}
								}

								&:nth-child(1n + 2) {
									a {
										@extend %q-secondary-button;
									}
								}
							}
						}
					}
				}

				.q-floating-tab {
					.q-tab-title {
						border: 1px solid $pe-tab-border-color;

						&.active {
							border: 1px solid $pe-tab-border-color;
						}
					}
				}
			}

			gmst-pe-financebox,
			gmst-pe-leasebox {
				&.hidden {
					display: none;
				}
			}
		}

		.q-pe-customize-payment {
			background-color: $pe-customize-payment-background-color;

			.finance-content {
				width: 100%;
			}

			.gmst-financedetailbox-accordion {
				align-items: center;
				display: flex;

				&:hover {
					cursor: $pe-brand-hover-cursor;
				}

				.gmst-fincancedetailbox-accordion-title {
					flex: 0 1 80%;

					h3,
					.gmst-pe-financedetails-totaldown,
					.gmst-pe-financedetails-flex-left-column {
						@extend %#{$pe-customize-payment-title-typography};
						text-align: left;
					}

					.gmst-pe-financedetails-flex-left-column {
						@extend %#{$pe-customize-payment-title-typography};
						text-align: left;
					}
				}

				.gmst-ipe-show-hide {
					flex: 0 1 20%;
					position: relative;
					text-align: right;

					.gmst-ipe-show-hide-btn {
						background-color: transparent;
						border-color: transparent;
						margin: auto;

						span {
							background: get-element($pe-customize-payment-expander-icon);
							background-repeat: no-repeat;
							background-size: 100% 100%;
							border: 0;
							content: '';
							display: block;
							margin-left: $expander-icon-margin;
							margin-right: $expander-icon-margin;
							padding: 0;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
						}

						&.active {
							span {
								background: get-element($pe-customize-payment-expander-icon-active);
								background-repeat: no-repeat;
								background-size: 100% 100%;
							}
						}
					}
				}
			}

			.gmst-ipe-financedetailbox {
				display: none;
				height: 0;

				&.active {
					display: block;
					height: auto;
					padding: $pe-brand-padding;
				}

				.gmst-pe-financedetails-flex {
					display: flex;
					flex-wrap: wrap;

					.gmst-pe-financedetailbox-unacceptable-tradein {
						@extend %#{$pe-finance-input-error-typography};
						color: $pe-error-color;
						font-weight: $font-weight-normal;
					}

					.gmst-othercredits,
					.stat-text-link {
						@extend %#{$pe-label-typography};
						flex: 0 1 100%;
					}

					.gmst-pe-financedetails-flex-left-column {
						@extend %#{$pe-label-typography};
						align-self: center;
						display: flex;
						flex: 0 1 100%;
						flex-wrap: wrap;

						gm-translate {
							flex: 0 1 100%;
						}

						slot {
							&[name='finance-cta'] {
								flex: 0 1 100%;
							}
						}

						a {
							&:visited,
							&:active,
							&:focus {
								color: inherit;
							}

							&:hover {
								color: $brand-link-color-hover;
							}
						}
					}

					.gmst-pe-financedetails-flex-right-column {
						display: flex;
						flex: 0 1 100%;
						justify-content: space-between;

						gm-translate {
							@extend %#{$pe-financedetails-flex-right-typography};
							flex: 0 1 20%;
							max-width: 20%;
							padding-right: $pe-brand-padding;
							text-align: right;
						}

						input {
							flex: 0 1 80%;
							max-width: 80%;
						}

						&.gmst-pe-financedetailbox-input-error-message-wrapper {
							flex-wrap: wrap;

							.gmst-pe-financedetailbox-input-error-message {
								flex: 0 1 100%;
								float: left;
								position: relative;
								right: 0;
								top: 0;
								width: 100%;

								.validity-tooltip {
									background-color: transparent;
									border: 0;
									width: 100%;

									gm-translate {
										@extend %#{$pe-finance-input-error-typography};
										color: $pe-error-color;
									}
								}
							}
						}
					}

					&.unacceptable-tradein {
						@extend %#{$pe-unacceptable-trade-typography};
						color: $pe-error-color;
					}

					&:nth-last-of-type(2) {
						justify-content: space-between;

						.gmst-pe-financedetails-flex-left-column,
						.gmst-pe-financedetails-flex-right-column {
							@extend %#{$pe-financebox-left-right-typography};
							color: $pe-content-text-color;
							flex: 0 1 auto;
						}
					}
				}
			}

			.gmst-pe-financedetails-flex {
				display: none;
			}

			.q-expander-button {
				@extend %#{$pe-expander-button-typography};
				display: flex;
			}

			// Finance Container
			gmst-pe-financedetailbox {
				input {
					display: inline;
				}

				&.hidden {
					display: none;
				}
			}
		}

		.q-pe-finance-box-container {
			flex: 0 1 100%;

			.gmst-pe-financebox-main {
				label {
					&[for='gmwc-stateful-dropdown-fschedule'] {
						display: none;
					}
				}

				& div {
					&:first-child {
						flex: 0 1 100%;
					}

					&:nth-child(2) {
						flex: 0 1 100%;
					}
				}

				.gmwc-stateful-dropdown-fterm {
					display: inline-block;
				}

				.gmst-pe-financebox-ratecheckbox,
				.gmst-pe-financebox-ratecheckboxlabel {
					float: left;
					width: auto;
				}

				.gmst-pe-finance-flex,
				.gmst-pe-financebox-rate {
					.gmst-pe-finance-purchaseprice {
						float: right;
					}
				}
			}

			&.show-pe-finance-details {
				display: flex;
				visibility: visible;
			}
		}

		.q-pe-lease-box-container {
			align-self: flex-start;
			flex: 0 1 100%;

			gmst-pe-leasebox {
				display: flex;
				height: 100%;
			}

			.gmst-pe-leasebox-main {
				.gmst-pe-leasedetails-schedule-row,
				.gmst-pe-leasebox-rate {
					display: none;
				}

				.gmst-pe-leasedetails-flex {
					width: 100%;

					.lterm-select-label,
					.lmileage-select-label {
						@extend %#{$pe-label-typography};
						flex: 0 1 25%;
						padding-right: $pe-brand-padding;
						text-align: right;
					}

					.gmwc-stateful-dropdown-lterm,
					.gmwc-stateful-dropdown-lmileage {
						flex: 0 1 75%;
					}
				}

				.gmwc-stateful-dropdown-lmileage,
				.gmwc-stateful-dropdown-lterm {
					float: left;
					width: 100%;
				}

				label {
					&[for='gmwc-stateful-dropdown-lschedule'] {
						display: none;
					}

					&[for='gmwc-stateful-dropdown-lmileage'],
					&[for='gmwc-stateful-dropdown-lterm'] {
						flex: 0 1 25%;
						padding-right: $pe-brand-padding;
						text-align: right;
						text-transform: uppercase;
					}
				}

				div {
					&:first-child {
						flex: 0 1 100%;
					}

					&:last-child {
						flex: 0 1 100%;
					}
				}

				gmwc-stateful-dropdown {
					flex: 0 1 100%;
				}
			}
		}

		&.hide-pe-filter-options {
			display: none;
		}

		&.show-pe-filter-options {
			display: flex;
		}
	}

	@media #{$medium-up} {
		.gmst-pe-financedetails-textfield,
		input,
		select,
		.gmwc-stateful-dropdown,
		.gmst-pe-othercredits,
		.gmst-postalcode-input,
		.gmst-modal-nameinput {
			background-position: unquote(map-get($gf-dropdown-position-map, medium));
			background-size: unquote(map-get($gf-dropdown-size-map, medium));
			font-size: map-get($gf-input-font-size-map, medium);
			padding: map-get($gf-dropdown-padding-map, medium);

			// Override iOS bug fix style that was put in place for mobile viewports
			&:focus {
				font-size: map-get($gf-input-font-size-map, medium);
			}
		}

		.q-pe-top-container {
			.q-vehicle-title-container {
				padding-left: $pe-brand-padding;

				// Vehicle Title & Vehicle Starting Price
				gmst-vehicleselector-title,
				.q-pe-vehicle-title-info {
					width: 100%;
				}
			}

			.q-pe-postal-code-container {
				gmst-postalcode {
					#gmst-postalcode-container {//sass-lint:disable-line no-ids
						input {
							padding: map-get($gf-dropdown-padding-map, medium);
						}
					}
				}

				gmst-postalcode-popup {
					.gmst-postalcode-popup-message {
						width: 75%;
					}
				}
			}
		}

		.q-pe-finance-container {
			.q-pe-finance-lease-container {
				.q-pe-finance-box-container {
					.gmst-pe-financebox-main {
						.gmst-pe-financebox-rate {
							.gmst-pe-financebox-flex-left-column {
								flex: 0 1 33.33%;
							}

							.gmst-pe-financebox-ratecustom {
								flex: 0 1 25%;
								max-width: 25%;

								+ span {
									flex: 0 1 auto;
								}
							}
						}
					}
				}
			}
		}
	}

	@media #{$large-up} {
		.gmst-pe-financedetails-textfield,
		input,
		select,
		.gmwc-stateful-dropdown,
		.gmst-pe-othercredits,
		.gmst-postalcode-input,
		.gmst-modal-nameinput {
			background-position: unquote(map-get($gf-dropdown-position-map, large));
			background-size: unquote(map-get($gf-dropdown-size-map, large));
			font-size: map-get($gf-input-font-size-map, large);
			height: map-get($gf-input-height-map, large);
			line-height: $gf-input-line-height;
			padding: map-get($gf-dropdown-padding-map, large);

			// Override iOS bug fix style that was put in place for mobile viewports
			&:focus {
				font-size: map-get($gf-input-font-size-map, large);
			}
		}

		.q-pe-top-container {
			gmst-vehicleselector-image {
				float: left;
				width: 50%;
			}

			.q-pe-vehicle-title-container {
				float: left;
				width: 50%;

				.q-pe-vehicle-info-title {
					gmst-vehicleselector-title {
						float: left;
						width: auto;

						.gmst-vehicleselector-title {
							display: inline-block;
							float: left;

							.gmst-vehicleselector-image-config,
							.gmst-vehicleselector-image-trim {
								margin: 0;
							}
						}
					}

					&:nth-last-of-type(1) {
						width: 33.33%;
					}
				}
			}

			// Vehicle Filters
			gmst-vehicleselector {
				// Configuration and Trim Drop Downs
				gmwc-stateful-dropdown {
					&[model='configuration'],
					&[model='trim'] {
						display: flex;
						flex: 0 1 50%;
						flex-wrap: wrap;
						max-width: 50%;
					}

					&[model='configuration'] {
						padding-right: $pe-brand-padding;
					}

					&[model='trim'] {
						padding-left: $pe-brand-padding;
					}
				}

				&.hide-pe-filter-options {
					display: none; // sass-lint:disable-line no-important
				}
			}

			.q-pe-postal-code-container {
				float: right;

				.q-postal-code-label {
					flex: 0 1 50%;
					float: right;
					text-align: right;
				}

				.q-postal-code-input {
					flex: 0 1 50%;
					max-width: 50%;
					padding-left: $pe-brand-padding;
				}

				.q-postal-code {
					&.row {
						.large-3,
						.large-9,
						.xlarge-4,
						.xlarge-8 {
							width: 50%;
						}

						.xlarge-4 {
							padding-right: $pe-brand-padding;
						}

						.xlarge-8 {
							padding-left: $pe-brand-padding;
						}
					}

					gmst-postalcode {
						#gmst-postalcode-container {//sass-lint:disable-line no-ids
							input {
								padding: map-get($gf-dropdown-padding-map, large);
							}
						}
					}
				}

				gmst-postalcode-popup {
					.gmst-postalcode-popup-message {
						width: 50%;
					}
				}
			}

			// Vehicle Title & Vehicle Starting Price
			.q-pe-vehicle-title-info,
			gmst-vehicleinfo {
				padding-left: $pe-brand-padding;
			}

			.q-pe-vehicle-title-info {
				&:nth-of-type(1) {
					width: 100%;
				}

				&:nth-of-type(2) {
					width: 50%;
				}
			}

			gmst-vehicleinfo {
				width: 100%;
			}
		}

		.q-pe-finance-container {
			.q-pe-customize-payment {
				gmst-pe-financedetailbox {
					.gmst-financedetailbox-accordion {
						display: flex;
						flex-wrap: wrap;

						.gmst-financedetailbox-title,
						.gmst-pe-financedetailbox-expander-totaldown {
							flex: 0 1 100%;
						}
					}

					.gmst-ipe-financedetailbox {
						.gmst-pe-financedetails-flex {
							.gmst-pe-financedetails-flex-left-column {
								flex: 0 1 42%;
							}

							.gmst-pe-financedetails-flex-right-column {
								flex: 0 1 58%;
								justify-content: flex-start;

								gm-translate {
									flex: 0 1 16.66%;
								}

								input {
									flex: 0 1 40%;
								}
							}

							&:nth-last-of-type(2) {
								.gmst-pe-financedetails-flex-left-column {
									flex: 0 1 42%;
								}

								.gmst-pe-financedetails-flex-right-column {
									flex: 0 1 58%;
								}
							}
						}
					}
				}
			}

			.q-pe-finance-lease-container {
				.q-pe-finance-box-container {
					.gmst-pe-financebox-main {
						.gmst-pe-financebox-flex {
							.left-column {
								flex: 0 1 33.33%;
							}
						}
					}
				}

				.q-floating-tab {
					display: flex;
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-pe-top-container {
			gmst-vehicleselector-image {
				display: inline-block;
				flex: none;
				float: left;
			}

			gmst-vehicleselector-image {
				width: 33.33%;
			}

			.q-pe-vehicle-title-container {
				display: flex;
				flex-wrap: wrap;
				float: left;
				width: 66.66%;

				.q-pe-vehicle-title-info {
					&:nth-of-type(1) {
						flex: 0 1 66.66%;
						max-width: 66.66%;
						order: 1;
					}

					&:nth-of-type(2) {
						flex: 0 1 100%;
						order: 3;
					}
				}

				gmst-vehicleinfo {
					flex: 0 1 33.33%;
					justify-content: flex-end;
					margin-top: 0;
					max-width: 33.33%;
					order: 2;
					position: relative;

					.starting-price-wrapper {
						text-align: right;
					}
				}
			}

			gmst-vehicleselector {
				display: flex;
				float: left;
				width: 66.66%;

				&.hide-pe-filter-options {
					display: flex;
				}
			}

			.q-dropdown-toggle {
				display: none;
			}

			.q-pe-postal-code-container {
				width: 66.66%;

				gmst-postalcode-popup {
					.gmst-postalcode-popup-message {
						width: 35%;
					}
				}
			}
		}

		.q-pe-finance-container {
			display: flex;
			flex: 0 1 100%;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;

			.q-pe-customize-payment {
				display: flex;
				flex: 0 1 33.33%;
				flex-wrap: wrap;
				max-width: 33.33%;
				width: 33.33%;

				.gmst-financedetailbox-accordion {
					pointer-events: none;

					&:hover {
						cursor: default;
					}

					.gmst-fincancedetailbox-accordion-title {
						flex: 0 1 100%;
						width: 100%;

						.gmst-pe-financedetails-flex-left-column {
							display: none;
						}
					}

					.gmst-pe-financedetails-totaldown {
						display: none;
					}

					.gmst-ipe-show-hide {
						display: none;
					}
				}

				gmst-pe-financedetailbox {
					.gmst-ipe-financedetailbox {
						display: block;
						height: auto;

						.gmst-pe-financedetails-flex {
							.gmst-pe-financedetails-flex-left-column {
								flex: 0 1 100%;
								max-width: 100%;
								transform: none;
							}

							.gmst-pe-financedetails-flex-right-column {
								flex: 0 1 100%;
								justify-content: flex-end;
								max-width: 100%;

								gm-translate {
									flex: 0 1 17.66%;
									max-width: 17.66%;
								}

								input {
									flex: 0 1 82%;
									max-width: 82%;
								}
							}
						}
					}
				}
			}

			.q-pe-finance-lease-container {
				display: inline-block;
				flex: 0 1 66.66%;
				flex-wrap: wrap;
				max-width: 66.66%;
				width: 66.66%;

				.q-floating-tab {
					.q-tab-title {
						border: 1px solid $pe-tab-border-color;

						&.active {
							border: 1px solid $pe-tab-border-color;
						}
					}
				}

				.q-tabs-content {
					.q-content {
						&.active {
							border-width: 0;
						}
					}
				}
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-pe-finance-container {
			.q-pe-customize-payment {
				gmst-pe-financedetailbox {
					.gmst-ipe-financedetailbox {
						.gmst-pe-financedetails-flex {
							.gmst-pe-financedetails-flex-left-column {
								flex: 0 1 58.33%;
								max-width: 58.33%;
							}

							.gmst-pe-financedetails-flex-right-column {
								flex: 0 1 41.33%;
								max-width: 41.33%;

								gm-translate {
									flex: 0 1 33.33%;
									max-width: 33.33%;
								}

								input {
									flex: 0 1 66.66%;
									max-width: 66.66%;
								}
							}

							&:nth-last-of-type(2) {
								.gmst-pe-financedetails-flex-left-column {
									transform: none;
								}

								.gmst-pe-financedetails-totaldown {
									justify-content: flex-end;
								}
							}
						}
					}
				}
			}

			.q-pe-finance-lease-container {
				.q-pe-finance-box-container {
					.gmst-pe-financebox-main {
						.gmst-pe-financebox-flex {
							.left-column {
								flex: 0 1 50%;
								max-width: 50%;
							}
						}

						.gmst-pe-financebox-rate {
							.gmst-pe-financebox-ratecustom {
								flex: 0 1 25%;
								max-width: 25%;

								+ span {
									flex: 0 1 50%;
								}
							}

							.gmst-pe-financebox-rate-effectiveRate { // sass-lint:disable-line class-name-format
								+ span {
									flex: 0 1 100%;
								}
							}
						}
					}
				}

				.q-floating-tab {
					.q-tab-title {
						border: 1px solid $pe-tab-border-color;

						.q-tab-button {
							.q-button {
								&::before {
									left: 0;
									width: 100%;
								}
							}

							&:hover {
								cursor: default;
							}
						}
					}
				}

				.tabs-content {
					display: flex;

					& > .content {
						border: 1px solid $pe-tab-border-color;
						display: flex;
						width: 50%;

						&.active {
							border: 1px solid $pe-tab-border-color;
							border-top: 0;
							float: left;
						}
					}
				}
			}
		}
	}

	.no-touch & {
		// Slot CTA Touch Styling
		.gmst-pe-finance-cta-slot,
		.gmst-pe-lease-cta-slot {
			div {
				&[slot='cta'] {
					&:first-child {
						&:hover {
							@extend %q-primary-button-touch;
						}
					}

					&:nth-child(1n + 2) {
						&:hover {
							@extend %q-secondary-button-touch;
						}
					}
				}
			}
		}
	}
}

gmwc-modal {
	background-color: $pe-brand-modal-background-color;
	border: 0;
	display: inline-block;
	height: 100%;
	left: 0;
	min-width: auto;
	padding: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;

	.gmwc-modal-wrapper {
		height: auto;
		left: 50%;
		margin: 0 auto;
		position: absolute;
		transform: translateX(-50%);
		width: 100%;

		.gmwc-modal-titlebar {
			border: 0;

			slot {
				&[name='modal-close'] {
					background-image: get-element($pe-brand-modal-close-icon);
					background-repeat: no-repeat;
					background-size: 100%;
					display: inline-block;
					font-size: 0;
					position: absolute;
					z-index: 9999;

					&:hover {
						background-image: get-element($pe-brand-modal-close-icon-hover);
						cursor: pointer;
					}
				}
			}
		}

		slot {
			&[name='modal-content'] {
				@extend %#{$pe-brand-modal-typography};
				color: $pe-brand-modal-content-color;
				height: 100%;
				left: 50%;
				position: absolute;
				transform: translateX(-50%);
			}
		}
	}

	&.gmwc-rewards-card  {
		.gmwc-modal-wrapper {
			background-color: $white;
			height: 100vh;
			overflow-y: auto;

			slot {
				&[name='modal-title'] {
					position: relative;
					width: 100%;

					span {
						@extend %#{$pe-modal-rewards-card-title};
						display: inline-block;
					}
				}

				&[name='modal-close'] {
					background-image: get-element($pe-modal-rewards-card-close-icon);

					.modal-close {
						background-color: transparent;
						border: 0;
						margin: 0;
						padding: 0;
					}
				}

				&[name='modal-content'] {
					left: 0;
					padding: 0;
					position: relative;
					transform: none;
					width: 100%;

					.gmst-modal-message {
						margin: 0 auto;
						width: 100%;

						input {
							&.error {
								border: $gf-error-border-style !important; //sass-lint:disable-line no-important

								// fixes bug in which error state and chrome auto fill cause border to disappear
								&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
									border: $gf-input-border;
								}
							}
						}

						.gmst-modal-error {
							@extend %#{$pe-modal-error-typography};
							color: $pe-error-color;
						}
					}

					.gmst-modal-firstpage {
						@extend %#{$pe-brand-modal-typography};
						color: $pe-content-text-color;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

						&.closed {
							display: none;
						}

						.gmst-modal-flexcontainer {
							display: flex;
							flex: 0 1 100%;
							flex-wrap: wrap;

							&:nth-of-type(1),
							&:nth-of-type(3) {
								align-self: flex-end;
							}

							&:nth-of-type(4) {
								flex: 0 1 100%;
								max-width: 100%;

								.column-left {
									flex-wrap: wrap;
								}
							}

							label {
								@extend %#{$pe-label-typography};
								flex: 0 1 100%;
							}

							input {
								flex: 0 1 100%;
							}

							.column-right {
								display: none;
							}

							.column-left {
								display: flex;
								flex: 0 1 100%;
								justify-content: space-between;
								width: 100%;
							}
						}

						p {
							@extend %#{$pe-brand-modal-typography};
						}
					}

					.gmst-modal-secondpage {
						@extend %#{$pe-brand-modal-typography};

						p {
							@extend %#{$pe-brand-modal-typography};
						}

						.gmst-modal-flexcontainer {
							color: $pe-content-text-color;

							.column-left {
								@extend %#{$pe-label-typography};
								flex: 0 1 45%;
								max-width: 45%;

								button {
									@extend %q-primary-button;
									flex: 0 1 100%;
								}
							}

							.column-right {
								flex: 0 1 55%;
								max-width: 55%;
							}

							&:nth-of-type(3) {
								.column-left {
									display: none;
								}

								.column-right {
									@extend %#{$pe-label-typography};
									flex: 0 1 100%;
								}
							}

							&:nth-of-type(6) {
								.column-right {
									display: none;
								}

								.column-left {
									display: flex;
									flex: 0 1 100%;
									flex-wrap: wrap;
									justify-content: space-around;
								}
							}
						}
					}

					.gmst-modal-cancelbutton {
						@extend %button;
						@extend %q-secondary-button;
						border-color: $pe-button-border-color !important; //sass-lint:disable-line no-important
						flex: 0 1 100%;
						order: 2;
						text-decoration: none;
					}

					.gmst-modal-showearnings,
					button {
						@extend %button;
						@extend %q-primary-button;
						flex: 0 1 100%;
						order: 1;
					}
				}
			}
		}
	}

	@media #{$large-up} {
		&.gmwc-rewards-card {
			.gmwc-modal-wrapper {
				height: auto;

				slot {
					&[name='modal-content'] {
						.gmst-modal-firstpage {
							.gmst-modal-flexcontainer {
								flex: 0 1 46%;
								justify-content: space-between;
								max-width: 46%;

								&:nth-of-type(4) {
									flex: 0 1 100%;
									max-width: 100%;
								}
							}
						}

						.gmst-modal-secondpage {
							.gmst-modal-flexcontainer {
								.column-left {
									flex: 0 1 25%;

									button {
										flex: 0 1 48%;
									}
								}

								.column-right {
									flex: 0 1 75%;
								}
							}
						}

						.gmst-modal-cancelbutton {
							flex: 0 1 48%;
							order: unset;
						}

						.gmst-modal-showearnings,
						button {
							flex: 0 1 48%;
							order: unset;
						}
					}
				}
			}
		}
	}

	.no-touch & {
		&.gmwc-rewards-card  {
			.gmwc-modal-wrapper {
				slot {
					&[name='modal-content'] {
						.gmst-modal-cancelbutton {
							&:hover {//sass-lint:disable-line nesting-depth
								@extend %q-secondary-button-touch;
							}
						}

						.gmst-modal-showearnings,
						button {
							&:hover {//sass-lint:disable-line nesting-depth
								@extend %q-primary-button-touch;
							}
						}
					}
				}
			}
		}
	}
}
