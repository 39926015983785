.q-vehicle-trim-selector {
	.q-slider {
		.slider-pagination {
			li {
				display: inline;
				margin: 0 .625rem;

				button {
					@include hard-easeout(border-color background-color, $anim-fast);
					background-color: $slider-pagination-background-color;
					height: 1rem;
				}

				&.slick-active {
					button {
						border-color: $slider-pagination-active-border-color;
						height: 1.25rem;
						top: -.125rem;
						width: 1.25rem;
					}
				}
			}
		}

		.slick-list {
			.slick-track {
				.slick-slide {
					padding-right: 1.25rem;
				}
			}
		}
	}

	@media #{$large-up} {
		.q-slider {
			.slick-prev,
			.slick-next {
				&::before {
					height: 3.75rem;
					width: 1.875rem;
				}
			}

			.slick-prev {
				left: -1.25rem;
			}

			.slick-list {
				overflow: hidden;
				width: 100%;
			}
		}
	}

	@media #{$xlarge-up} {
		.q-slider {
			.slick-prev {
				left: -3.125rem;
			}
		}
	}
}
