/* Extend solar nav_primary for Chevrolet-specific styles */

.q-nav-primary {
	.q-nav-collapsed {
		.has-flyout {
			.q-nav-collapsed-link {
				&::after {
					@include vertical-align;
					@include icon-triangle(right, .3125rem, .4375rem, $nav-primary-link-label-color);

					position: absolute;
					right: 1.875rem;
				}

				&:hover::after {
					@include icon-triangle(right, .3125rem, .4375rem, $nav-primary-link-label-hover-color);
				}
			}
		}
	}

	.q-button-back {
		&::after {
			@include vertical-align(absolute);
			@include icon-triangle(left, .3125rem, .4375rem, $grey-mid);
			left: 1.875rem;
		}

		// Override button hover style that has a gold left border
		&:hover {
			border-left-color: transparent !important; // sass-lint:disable-line no-important
		}
	}

	@media #{$xlarge-up} {
		.q-top-bar-section {
			.q-nav-list-item {
				&.has-flyout {
					.q-nav-primary-link-label {
						&::before {
							@include icon-triangle(down, .375rem, .25rem, $nav-primary-link-label-color);

							bottom: .375rem;
							right: -.75rem;
						}
					}
				}

				&.flyout-opened {
					.q-nav-primary-link-label {
						&::before {
							@include icon-triangle(up, .375rem, .25rem, $brand-primary-color);
						}
					}
				}
			}
		}
	}
}
