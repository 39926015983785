.q-disclosure-page-container {

	.q-desclaimer-page-headline {
		background-color: $gold;
		margin: 0;
		margin-top: 1.25rem;
		padding: 1.25rem;
	}
}

// Mobile First SCSS goes here

.q-disclosure-modal-toast {

	.q-disclosure-container {
		background-color: $black; /* Fallback color */
		color: $white;
		display: none; /* Hidden by default */
		font-family: $font-family-secondary;
		font-size: .875rem;
		opacity: .9;
	}

	.q-disclosure-modal-container {
		height: 100%; /* Full height */
		left: 0;
		overflow: auto; /* Enable scroll if needed */
		position: fixed; /* Stay in place */
		top: 0;
		width: 100%; /* Full width */
		z-index: 1200; /* Sit on top */
	}

	.q-modal-content {
		height: 100%;
		margin: 0;
		opacity: .8;
		overflow-y: auto;
		padding: map-get($disclosure-modal-content-padding-map, small);
		width: 100%;

		h3 {
			margin-bottom: 1.25rem;

			a {
				font-weight: 400;
			}
		}

		p {
			margin: 0;
			padding: 0;
		}

		a {
			text-decoration: underline;
		}

		.q-close-disclosure {
			right: $disclosure-close-small-icon-right;
			top: $disclosure-close-small-icon-top;
		}
	}

	.q-close-disclosure {
		font-size: $disclosure-toast-font-size;
		height: $disclosure-toast-height;
		line-height: inherit;
		opacity: 1;
		padding: 0;
		position: absolute;
		right: map-get($disclosure-toast-right-map, small);
		text-align: center;
		top: 1.875rem;
		width: 1.375rem;

		.q-modal-close-icon {
			@include insert-icon($disclosure-close-icon, map-get($disclosure-close-icon-height-map, small),
				map-get($disclosure-close-icon-height-map, small), $disclosure-close-insert-icon-mq-use, $disclosure-toast-close-hover-icon);
			vertical-align: baseline;
		}
	}

	.q-close-disclosure {
		img {
			&:hover,
			&:focus {
				@include hard-easeout(all, $anim-fast);
				cursor: pointer;
				text-decoration: none;
			}
		}
	}

	.q-disclosure-toast-container {
		bottom: 0;
		padding: map-get($disclosure-toast-padding-map, small);
		position: fixed;
		width: 100%;
		z-index: 1200; /* Sit on top of Gallery Layer*/

		.q-toast-content {
			float: left;
			margin-bottom: 1.25rem;
			width: 80%;

			p {
				margin: .3125rem 0;
				}

			.q-close-disclosure {
				top: 1.25rem;
			}
		}
	}
}

.q-disclaimer-body {
	@extend %q-body2;
}
// Media Queries go here
@media #{$large-up} {
	.q-disclosure-modal-toast {
		.q-modal-content {
			padding: map-get($disclosure-modal-content-padding-map, large);
		}

		.q-close-disclosure {
			font-size: 1.25rem;
			height: 1.875rem;
			line-height: 1.1;
			right: map-get($disclosure-toast-right-map, large);
			top: 1.25rem;
			width: 1.875rem;
		}
	}
}

@media #{$xlarge-up} {
	.q-disclosure-modal-toast {

		.q-disclosure-toast-container {

			.q-toast-content {
				width: 90%;
			}
		}

		.q-modal-content {

			padding: map-get($disclosure-modal-content-padding-map, xlarge);

			.q-close-disclosure {
				right: $disclosure-modal-close-right;
				top: $disclosure-modal-close-top;
			}

		}

	}
}

@media #{$xxlarge-up} {
	.q-disclosure-modal-toast {

		.q-modal-content {

			.q-close-disclosure {
				line-height: 1.1;
			}
		}
	}
}
