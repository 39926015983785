.q-disclosure-modal-toast {
	.q-disclosure-toast-container {
		.q-toast-content {
			margin-bottom: 0;
		}
	}
}
// Chevy close icon
@media #{$medium-up} {
	.q-disclosure-modal-toast {
		.q-close-disclosure {
			.q-modal-close-icon {
				height: map-get($disclosure-close-icon-height-map, large);
				width: map-get($disclosure-close-icon-width-map, large);
			}
		}
	}
}
@media #{$large-up} {
	.q-disclosure-modal-toast {
		.q-disclosure-toast-container {
			padding: map-get($disclosure-toast-padding-map, large);
		}
	}
}
