.q-product-selector {
	&.q-vehicle-selector {
		.q-vehicle-selector-row {
			.q-vehicle-selector-item {
				display: block;

				.q-hmf-results & {
					display: none;
				}
			}
		}
	}
}
