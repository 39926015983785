.q-payment-estimator {
	gmst-pe-title,
	gmst-disclaimers {
		float: left;
		margin-bottom: 1rem;
	}

	.gmst-modal-symbol-fl {
		&::after {
			text-decoration: underline;
		}
	}

	gmst-disclaimers {
		margin-top: 2rem;

		ul {
			@extend %q-body2;
		}
	}

	label {
		@extend %#{$pe-label-typography};
	}

	select {
		background-color: $gf-dropdown-bg-color;
		background-image: get-element($gf-dropdown-icon), linear-gradient(to right, $gf-dropdown-square-color, $gf-dropdown-square-color);
		background-position: unquote(map-get($gf-dropdown-position-map, small));
		background-repeat: no-repeat;
		background-size: unquote(map-get($gf-dropdown-size-map, small));

		&:hover {
			background-image: get-element($gf-dropdown-hover-icon), linear-gradient(to right, $gf-dropdown-square-hover, $gf-dropdown-square-hover);
		}
	}

	.gmst-pe-financedetails-textfield,
	input,
	select,
	.gmwc-stateful-dropdown,
	.gmst-pe-othercredits,
	.gmst-postalcode-input {
		@extend %gf-form-input;
	}

	// Top Container Styling
	.q-pe-top-container {
		.q-pe-vehicle-title-info {
			padding: 0 1.25rem;

			// Vehicle Title
			gmst-vehicleselector-title {
				.gmst-vehicleselector-title {
					.gmst-vehicleselector-image-year,
					.gmst-vehicleselector-image-model {
						font-size: 1.5625rem;
						line-height: 1.5625rem;
						text-transform: uppercase;
					}

					.gmst-vehicleselector-image-config,
					.gmst-vehicleselector-image-trim {
						font-size: .9375rem;
						font-style: normal;
						line-height: 1.25rem;
						margin-bottom: 0;
						padding: 0 .3125rem 0 0;
						text-transform: uppercase;
					}

					.gmst-vehicleselector-image-config {
						clear: left;
					}
				}
			}
		}

		// Starting Price
		gmst-vehicleinfo {
			margin-bottom: 1.25rem;
			padding: 0 1.25rem;

			.starting-price-wrapper {
				font-size: .75rem;
				line-height: 1.25rem;

				.starting-totalprice {
					font-weight: $font-weight-normal;
				}
			}
		}

		// Vehicle Filters
		gmst-vehicleselector {
			margin-top: 1.25rem;
			padding: 0 1.25rem;

			span {
				font-size: .75rem;
				line-height: 1.25rem;
				margin-bottom: 0;
				text-transform: uppercase;
			}

			// Configuration and Trim Drop Downs
			gmwc-stateful-dropdown {
				margin-bottom: 0;

				&[model='configuration'],
				&[model='trim'] {
					margin: .1875rem 0 0;
				}
			}
		}

		.q-pe-postal-code-container {
			padding: 0 1.25rem 1.25rem;

			.q-postal-code-label {
				font-size: .75rem;
				line-height: 1.25rem;
				margin: 0 0 .625rem;
				padding-right: 0;
				text-transform: uppercase;
				transform: none;

				label {
					margin-bottom: 0;
				}
			}

			// Postal Code Input Wrapper
			.q-postal-code {
				.q-pe-clear-zip {
					background-color: transparent;
					background-image: get-element('plus-dark');
					background-position: center center;
					background-repeat: no-repeat;
					border-color: transparent;
					font-size: 0;
					height: 1.25rem;
					opacity: .7;
					position: absolute;
					right: 6rem;
					top: 2.875rem;
					transform: rotate(45deg);
					transition: none;
					width: 1.25rem;
					z-index: 1;

					&:hover {
						background-image: get-element('plus-dark');
						cursor: pointer;
						opacity: 1;
					}
				}

				gmst-postalcode {
					&.invalid {
						#gmst-postalcode-container {//sass-lint:disable-line no-ids
							.gmst-postalcode-input {
								transition: border, box-shadow .25s;
							}
						}
					}

					// Required to Override
					#gmst-postalcode-container { // sass-lint:disable-line no-ids
						border: 0;
						height: 3.125rem;

						input {
							border: 1px solid $grey-mid;
							height: 3.125rem;
							width: calc(100% + -7.5rem + 1px);
						}

						.gmst-postalcode-submit,
						.gmst-postalcode-location {
							background-color: $grey;
							border: 0;
							height: 3.125rem;
							margin: 0;
							padding: 15px 0;
							transition: none;
							width: 3.75rem;

							&:hover {
								background-color: $grey-dark;
							}

							&::before,
							&::after {
								content: none;
							}
						}

						.gmst-postalcode-submit-icon {
							height: 1.25rem;
							width: 1.25rem;
						}

						.gmst-postalcode-location-icon {
							height: 1.25rem;
							width: 1.25rem;
						}

						.gmst-postalcode-submit {
							right: 1.25rem;
						}
					}
				}
			}

			gmst-postalcode-popup {
				.gmst-postalcode-popup-message {
					left: 0;
					padding: 1.25rem;
					top: 5rem;

					> p {
						margin-top: 1.5rem;
					}

					.gmst-postalcode-popup-close {
						height: 1.25rem;
						right: 1.25rem;
						top: .9375rem;
						width: 1.25rem;
					}

					gmst-postalcode {
						#gmst-postalcode-container {//sass-lint:disable-line no-ids
							height: 3.875rem;
							margin-top: 2rem;
							width: 80%;

							.gmst-postalcode-location,
							.gmst-postalcode-input,
							.gmst-postalcode-submit {
								display: flex;
								margin: 0;
							}

							.gmst-postalcode-location,
							.gmst-postalcode-submit {
								background-color: $grey;
								border: 0;
								height: 3.125rem;
								margin: 0;
								padding: 15px 0;
								transition: none;
								width: 3.75rem;
							}

							.gmst-postalcode-input {
								border: 1px solid $grey-mid;
								height: 3.125rem;
								width: calc(100% + -7.5rem + 1px);
							}

							.gmst-postalcode-submit-icon {
								height: 1.25rem;
								width: 1.25rem;
							}

							.gmst-postalcode-location-icon {
								height: 1.25rem;
								width: 1.25rem;
							}
						}

						.gmst-postalcode-error {
							margin: 1.25rem 0;
						}
					}
				}
			}
		}

		.q-dropdown-toggle {
			a {
				text-decoration: underline;
			}
		}
	}

	.q-pe-finance-no-options {
		margin-top: 2rem;

		gmwc-statefull-showhide {
			transform: translateY(5rem);
		}
	}

	.q-pe-finance-container {
		// Finance & Lease Containers
		.q-pe-finance-lease-container {
			.gmst-pe-financebox-termamount-wrap,
			.gmst-pe-leasebox-termamount-wrap {
				margin-bottom: 0;
			}

			// Finance Container
			.q-pe-finance-box-container {
				.gmst-pe-financebox-main {
					& div {
						&:first-child {
							margin-bottom: 1.5rem;
							padding: 0;
						}
					}

					.gmwc-stateful-dropdown-fterm {
						margin-bottom: 1.25rem;
						margin-top: 2.5rem;
					}

					.gmst-pe-financebox-credits,
					.gmst-pe-financebox-cash-credits {
						font-size: .875rem;
						font-weight: $font-weight-medium;
						line-height: 1.5625rem;
						text-transform: uppercase;
					}

					.gmst-pe-financebox-termamount {
						.gmst-pe-financebox-payment {
							font-size: 3.75rem;
							line-height: 3.125rem;
						}

						.gmst-pe-financebox-fschedule-text {
							font-size: 1.125rem;
							line-height: 1.25rem;
							text-transform: capitalize;
						}
					}

					.gmst-pe-financebox-flex {
						.gmst-pe-financebox-flex-right-column {
							&.gmst-pe-financedetailbox-input-error-message-wrapper {
								.gmst-pe-financedetailbox-input-error-message {
									.validity-tooltip {
										background-color: transparent;
										border: 0;
										padding: .625rem 0;
									}
								}
							}
						}

						.fterm-select-label,
						.gmst-pe-financebox-ratecheckboxlabel,
						.gmst-pe-financeAmount { //sass-lint:disable-line class-name-format
							text-transform: uppercase;
						}

						.gmst-pe-financebox-ratecheckboxlabel {
							padding-right: 0;
							transform: translateY(.3125rem);
						}

						.fterm-select-label {
							margin-top: 2.5rem;
							transform: translateY(1.25rem);
						}

						.gmst-pe-financeAmount { //sass-lint:disable-line class-name-format
							transform: translateY(.9375rem);
						}
					}

					.gmst-pe-financebox-rate {
						.gmst-pe-financebox-ratetitle {
							margin-right: .625rem;
							text-align: right;
							transform: translateY(.3125rem);
						}

						.gmst-pe-financebox-ratecustom {
							border-color: $grey-mid;
							box-shadow: none;
							height: 3.125rem;
							margin: 0 .1rem 1.25rem;
							margin-bottom: .625rem;
							padding: .0625rem .0625rem .0625rem .625rem;

							&:focus {
								background-color: $white;
								border-color: $grey;
							}

							+ span {
								flex: 0 1 auto;
								font-size: .9375rem;
								line-height: 1.25rem;
								padding: 0 .625rem;
								transform: translateY(.9375rem);
							}
						}

						.gmst-pe-financebox-ratecheckbox {
							-webkit-appearance: none; //sass-lint:disable-line no-vendor-prefixes
							background-color: $grey-light;
							border-radius: .0625rem;
							cursor: pointer;
							height: 1.25rem;
							margin: .3125rem .625rem .625rem 0;
							padding: 0;
							vertical-align: middle;
							width: 1.25rem;

							&:checked {
								&::after {
									content: get-element($gf-check-icon);
									left: .25rem;
									position: relative;
									top: -.625rem;
								}
							}
						}

						.gmst-pe-financebox-rate-effectiveRate { // sass-lint:disable-line class-name-format
							display: none;
						}
					}

					.gmst-pe-financebox-fcpurchaseprice {
						margin: 1.25rem 0;

						.gmst-pe-financebox-flex-left-column,
						.gmst-pe-financebox-flex-right-column {
							font-size: 1.125rem;
							font-weight: $font-weight-bold;
						}

						.gmst-pe-financebox-flex-left-column {
							margin: 0;
						}

						.gmst-pe-financebox-flex-right-column {
							padding-left: .625rem;
						}
					}
				}

				.gmst-pe-financebox-offererror,
				.gmst-pe-financebox-downpaymenterror {
					padding: 1rem;

					slot {
						&[name='localDealer'] {
							padding-right: .5rem;
							text-decoration: underline;
						}
					}
				}
			}

			// Lease Container
			.q-pe-lease-box-container {
				.gmst-pe-leasebox-main {
					.gmst-pe-leasebox-termamount {
						.gmst-pe-leasebox-payment {
							font-size: 3.75rem;
							line-height: 3.125rem;
						}

						.gmst-pe-financebox-fschedule-text {
							font-size: 1.125rem;
							line-height: 1.25rem;
							text-transform: capitalize;
						}
					}

					.gmst-pe-leasebox-dueatsign {
						font-size: 1.125rem;
						font-weight: $font-weight-medium;
						line-height: 1.875rem;
						text-transform: uppercase;
					}

					.lease-disclaimer {
						margin: 1.25rem 0;
					}

					.gmst-pe-leasebox-credits {
						font-size: .875rem;
						font-weight: $font-weight-medium;
						line-height: 1.5625rem;
						text-transform: uppercase;
					}

					.gmst-pe-leasedetails-term-row {
						margin-top: 2.5rem;
					}

					.gmst-pe-leasebox-flex {
						.lterm-select-label,
						.lmileage-select-label {
							text-transform: uppercase;
						}

						.lterm-select-label {
							transform: translateY(1.25rem);
						}

						.lmileage-select-label {
							transform: translateY(.75rem);
						}

						.gmwc-stateful-dropdown-lterm,
						.gmwc-stateful-dropdown-lmileage {
							margin-bottom: 1.25rem;
						}
					}

					.gmst-pe-leasebox-inline-disclaimers,
					.lease-disclaimer {
						font-size: .875rem;
						line-height: 1.5625rem;
					}

					label {
						&[for='gmwc-stateful-dropdown-lmileage'],
						&[for='gmwc-stateful-dropdown-lterm'] {
							transform: translateY(1.25rem);
						}

						&[for='gmwc-stateful-dropdown-lmileage'] {
							transform: translateY(3.75rem);
						}
					}
				}

				.gmst-pe-leasebox-offererror {
					padding: 1.25rem 1.25rem 0;

					slot {
						&[name='localDealer'] {
							padding-right: .3125rem;
							text-decoration: underline;
						}
					}
				}
			}

			.gmst-pe-financebox-termamount,
			.gmst-pe-leasebox-termamount {
				font-weight: $font-weight-xbold;
			}

			.gmst-pe-financebox-title-tagline,
			.gmst-pe-leasebox-title-tagline {
				font-size: .75rem;
				margin-bottom: 1.25rem;
			}

			&.gmst-pe-financebox-downpaymenterror,
			&.gmst-pe-leasebox-offererror,
			&.gmst-pe-leasebox-downpaymenterror {
				padding: 1rem 1.2rem;

				p {
					margin-bottom: 1rem;
				}
			}

			.q-tabs-content {
				& > .content {
					&:first-child {
						border-width: 0 1px 0 0;
					}

					&:last-child {
						border-width: 0 0 0 1px;
					}
				}

				.q-content {
					.gmst-pe-finance-cta-slot,
					.gmst-pe-lease-cta-slot {
						padding: 0 0 1.2rem;

						div {
							&[slot='cta'] {
								margin-top: 1rem;

								&:first-child {
									margin-bottom: 0;
									margin-top: 0;
								}

								&:last-child {
									margin-bottom: .625rem;
								}
							}
						}
					}
				}
			}

			.q-floating-tab {
				.q-tab-title {
					&:first-child {
						border-width: 1px 0 1px 1px;
					}

					&:last-child {
						border-width: 1px 1px 1px 0;
					}

					&.active {
						.q-tab-button {
							.q-button {
								color: $grey;
							}
						}
					}

					.q-tab-button {
						.q-button {
							color: $grey;

							&:hover {
								color: $grey-dark;
							}
						}
					}

					&:hover {
						.q-tab-button {
							.q-button {
								color: $grey-dark;
							}
						}
					}
				}
			}
		}

		.q-pe-customize-payment {
			background-color: $grey-very-light;
			margin: 0;

			.gmst-financedetailbox-accordion {
				padding: .9375rem 5%;

				.gmst-fincancedetailbox-accordion-title {
					h3,
					.gmst-pe-financedetails-totaldown,
					.gmst-pe-financedetails-flex-left-column {
						font-size: .875rem;
						font-weight: $font-weight-bold;
						line-height: 1.5625rem;
						margin-bottom: 0;
						text-align: left;
					}

					.gmst-pe-financedetails-totaldown {
						gm-translate {
							padding-right: .3125rem;
						}
					}
				}

				.gmst-ipe-show-hide {
					.gmst-ipe-show-hide-btn {
						span {
							height: 2rem;
							width: 2rem;
						}
					}
				}
			}

			.gmst-financedetailbox-title {
				@extend %q-body;
				display: none;
				font-weight: $font-weight-medium;
			}

			.gmst-ipe-financedetailbox {
				padding: 0 1.25rem;

				.gmst-pe-financedetails-flex {
					margin-bottom: 1.25rem;

					.gmst-pe-financedetails-flex-left-column {
						color: $grey-dark;
						margin-bottom: .625rem;
						text-transform: uppercase;

						a {
							color: $grey-dark;
							text-decoration: underline;
						}
					}

					.gmst-othercredits,
					.stat-text-link {
						color: $grey-dark;
						margin-bottom: 0;
						text-transform: uppercase;
					}

					.gmst-pe-financedetails-flex-right-column {
						gm-translate {
							transform: translateY(1rem);
						}

						input {
							height: 60px;
							padding-right: .625rem;
						}

						&.gmst-pe-financedetailbox-input-error-message-wrapper {
							flex-wrap: wrap;

							.gmst-pe-financedetailbox-input-error-message {
								.validity-tooltip {
									padding: .625rem 0;
								}
							}
						}
					}

					&:nth-last-of-type(2) {
						.gmst-pe-financedetails-flex-left-column,
						.gmst-pe-financedetails-flex-right-column {
							font-size: 1.125rem;
							font-weight: $font-weight-bold;
							letter-spacing: normal;
							text-transform: capitalize;
							transform: none;
						}
					}
				}
			}

			.q-expander-button {
				padding: 1.25rem 1.2rem !important; // sass-lint:disable-line no-important
			}

			// Finance Container
			gmst-pe-financedetailbox {
				input {
					width: 5rem;
				}
			}
		}
	}

	@media #{$small-only} {
		.gmst-pe-financedetails-textfield,
		input,
		select,
		.gmwc-stateful-dropdown,
		.gmst-pe-othercredits,
		.gmst-postalcode-input {
			font-size: 1rem;
		}

		input {
			padding-right: 1rem;
		}
	}

	@media #{$medium-up} {
		.q-pe-finance-container {
			.q-pe-finance-lease-container {
				.q-pe-finance-box-container {
					.gmst-pe-financebox-main {
						.gmst-pe-financebox-rate {
							.gmst-pe-financebox-ratecustom {
								+ span {
									transform: translateY(.9375rem);
								}
							}

							.gmst-pe-financebox-rate-effectiveRate { // sass-lint:disable-line class-name-format
								+ span {
									transform: translateY(.625rem);
								}
							}
						}
					}
				}
			}
		}
	}

	@media #{$large-up} {
		.q-pe-top-container {
			.q-pe-postal-code-container {
				padding-top: 1.25rem;

				.q-postal-code-label {
					align-self: center;
					margin: 0;
					transform: none;

					label {
						padding-right: 1.25rem;
					}
				}

				.q-postal-code {
					gmst-postalcode {
						#gmst-postalcode-container { // sass-lint:disable-line no-ids
							height: 3.875rem;

							input {
								height: 3.125rem;
								padding-left: 1.375rem;
								padding-right: 2.375rem;
								width: calc(100% + -7.5rem + 1px);
							}

							.gmst-postalcode-submit,
							.gmst-postalcode-location {
								height: 3.125rem;
								width: 3.75rem;
							}
						}
					}

					.q-pe-clear-zip {
						right: 6.25rem;
						top: 2.25rem;
					}
				}

				gmst-postalcode-popup {
					.gmst-postalcode-popup-message {
						gmst-postalcode {
							#gmst-postalcode-container { //sass-lint:disable-line no-ids
								height: 3.875rem;

								.gmst-postalcode-submit,
								.gmst-postalcode-location {
									height: 3.125rem;
									width: 3.75rem;
								}

								.gmst-postalcode-input {
									border: 1px solid $grey-mid;
									height: 3.125rem;
									padding-left: 1.375rem;
									padding-right: 1.375rem;
									width: calc(100% + -7.5rem + 1px);
								}
							}
						}
					}
				}
			}

			gmst-vehicleselector {
				.gmwc-stateful-dropdown {
					background-size: .625rem .625rem, 3.75rem 100%;
					height: 3.125rem;
					margin: 0;
					padding: .5625rem 4.375rem .5625rem 1.25rem;
				}
			}
		}

		.q-pe-finance-container {
			.q-pe-customize-payment {
				gmst-pe-financedetailbox {
					padding: 0;

					.gmst-financedetailbox-accordion {
						padding: .9375rem 1.875rem;

						.gmst-fincancedetailbox-accordion-title {
							h3,
							.gmst-pe-financedetails-totaldown,
							.gmst-pe-financedetails-flex-left-column {
								font-size: 1.125rem;
								line-height: 1.875rem;
							}
						}

						.gmst-ipe-show-hide {
							transform: none;
						}
					}

					.gmst-ipe-financedetailbox {
						.gmst-pe-financedetails-flex {
							&:nth-last-of-type(2) {
								.gmst-pe-financedetails-flex-right-column {
									padding-left: 12%;
								}
							}
						}
					}
				}
			}

			.q-pe-finance-lease-container {
				.gmst-pe-financebox-title-tagline,
				.gmst-pe-leasebox-title-tagline {
					font-size: .875rem;
				}

				.q-pe-finance-box-container {
					.gmst-pe-financebox-main {
						.gmst-pe-financebox-credits,
						.gmst-pe-financebox-cash-credits {
							font-size: 1.125rem;
							line-height: 1.875rem;

							.gmst-pe-financebox-taxesincluded-check {
								height: $svg-icon-medium;
								margin-right: 1rem;
								transform: translateY(.625rem);
								width: $svg-icon-medium;
							}
						}

						.gmst-pe-financebox-termamount {
							.gmst-pe-financebox-fschedule-text {
								font-size: 1.375rem;
								line-height: 1.875rem;
							}
						}
					}
				}

				.q-pe-lease-box-container {
					.gmst-pe-leasebox-main {
						.gmst-pe-leasebox-credits {
							font-size: 1.125rem;
							line-height: 1.875rem;
						}

						.gmst-pe-leasebox-termamount {
							.gmst-pe-financebox-fschedule-text {
								font-size: 1.375rem;
								line-height: 1.875rem;
							}
						}

						.gmst-pe-leasebox-inline-disclaimers,
						.lease-disclaimer {
							line-height: 1.25rem;
						}
					}
				}

				.q-floating-tab {
					.q-tab-title {
						&:first-child {
							border-width: 1px 0 1px 1px;
						}

						&:last-child {
							border-width: 1px 1px 1px 0;
						}
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-pe-top-container {
			.q-pe-vehicle-title-info {
				padding: 0 1.25rem;

				// Vehicle Title
				gmst-vehicleselector-title {
					.gmst-vehicleselector-title {
						.gmst-vehicleselector-image-year,
						.gmst-vehicleselector-image-model {
							font-size: 2.5rem;
							line-height: 2.5rem;
						}

						.gmst-vehicleselector-image-config,
						.gmst-vehicleselector-image-trim {
							font-size: 1.25rem;
							font-style: normal;
							line-height: 1.875rem;
							padding: 0 .3125rem 0 0;
						}
					}
				}
			}

			gmst-vehicleinfo {
				margin: 0;
				padding-right: 0;
				width: 20.33%;

				.starting-price-wrapper {
					font-size: .875rem;
					line-height: 1.25rem;
					margin-bottom: 0;
				}
			}

			gmst-vehicleselector {
				padding-right: 0;

				.gmwc-stateful-dropdown {
					background-size: .625rem .625rem, 4.375rem 100%;
					height: 3.75rem;
					padding: .9375rem 4.375rem .9375rem 1.875rem;
				}
			}

			.q-pe-postal-code-container {
				padding-right: 0;

				.q-postal-code {
					.q-pe-clear-zip {
						right: 6rem;
						top: 2.5rem;
					}

					gmst-postalcode {
						// Required to Override
						#gmst-postalcode-container { // sass-lint:disable-line no-ids
							.gmst-postalcode-submit {
								right: 0;
							}

							input {
								height: 3.75rem;
								padding-left: 1.375rem;
								padding-right: 2.375rem;
								width: calc(100% + -8.75rem + 1px);
							}

							.gmst-postalcode-submit,
							.gmst-postalcode-location {
								height: 3.75rem;
								width: 4.375rem;
							}
						}
					}
				}

				gmst-postalcode-popup {
					.gmst-postalcode-popup-message {
						gmst-postalcode {
							#gmst-postalcode-container { //sass-lint:disable-line no-ids
								.gmst-postalcode-submit,
								.gmst-postalcode-location {
									height: 3.75rem;
									width: 4.375rem;
								}

								.gmst-postalcode-input {
									border: 1px solid $grey-mid;
									height: 3.75rem;
									padding-left: 1.375rem;
									padding-right: 1.375rem;
									width: calc(100% + -8.75rem + 1px);
								}
							}
						}
					}
				}
			}
		}

		.q-pe-finance-container {
			.q-pe-customize-payment {
				.gmst-financedetailbox-accordion {
					.gmst-fincancedetailbox-accordion-title {
						h3 {
							font-size: 1.125rem;
							line-height: 1.875rem;
							text-align: center;
						}
					}
				}

				gmst-pe-financedetailbox {
					.gmst-ipe-financedetailbox {
						.gmst-pe-financedetails-flex {
							.gmst-pe-financedetails-flex-left-column {
								transform: none;
							}
						}
					}
				}
			}

			.q-pe-finance-lease-container {
				flex: 0 1 66.66%;
				width: 66.66%;

				.q-floating-tab {
					.q-tab-title {
						border: 1px solid $pe-tab-border-color;

						&.active {
							border: 1px solid $pe-tab-border-color;
						}
					}
				}

				.q-tabs-content {
					.q-content {
						&.active {
							border-width: 0;
						}

						.gmst-pe-finance-cta-slot,
						.gmst-pe-lease-cta-slot {
							padding: 0 0 1.2rem;

							div {
								&[slot='cta'] {
									margin-top: 1rem;

									&:first-child {
										margin-bottom: 0;
										margin-top: 0;
									}

									&:last-child {
										margin-bottom: .625rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-pe-finance-container {
			.q-pe-customize-payment {
				gmst-pe-financedetailbox {
					.gmst-ipe-financedetailbox {
						.gmst-pe-financedetails-flex {
							&:nth-last-of-type(2) {
								.gmst-pe-financedetails-flex-left-column {
									transform: none;
								}

								.gmst-pe-financedetails-totaldown {
									transform: none;
								}
							}
						}
					}
				}
			}

			.q-pe-finance-lease-container {
				.gmst-pe-financebox-title-tagline,
				.gmst-pe-leasebox-title-tagline {
					font-size: 1rem;
				}

				.q-pe-finance-box-container {
					.gmst-pe-financebox-main {
						.gmst-pe-financebox-rate {
							.gmst-pe-financebox-ratecustom {

								+ span {
									transform: translateY(.9375rem);
								}
							}

							.gmst-pe-financebox-rate-effectiveRate { // sass-lint:disable-line class-name-format
								+ span {
									transform: none;
								}
							}
						}
					}
				}

				.q-floating-tab {
					.q-tab-title {
						pointer-events: none;

						&:first-child {
							border-width: 1px;
						}

						&:last-child {
							border-width: 1px;
						}

						.q-tab-button {
							background-color: $white;

							.q-button {
								color: $grey;
								font-size: 1.125rem;
								pointer-events: none;

								&::before {
									border-bottom: 3px solid $brand-primary-color;
								}

								&:hover {
									color: $grey;
								}
							}

							&:hover {
								cursor: default;
							}
						}

						&.active {
							.q-tab-button {
								.q-button {
									color: $grey;

									&:hover {
										color: $grey;
									}
								}
							}
						}
					}
				}

				.tabs-content {
					& > .content {
						&:first-child {
							border-width: 0 1px 0 0;
						}

						&:last-child {
							border-width: 0 0 0 1px;
						}
					}
				}
			}
		}
	}
}

gmwc-modal {
	&.gmst-modal-disclosure,
	&.gmwc-rewards-card {
		.gmwc-modal-wrapper {
			slot {
				&[name='modal-content'] {
					max-width: 66.66%;
					padding: 2.5rem 0 1.25rem;
					width: 66.66%;
				}
			}
		}
	}

	&.gmst-modal-disclosure {
		.gmwc-modal-wrapper {
			width: 90%;

			slot {
				&[name='modal-content'] {
					left: 45%;
					width: 75%;

					.modal-flex {
						margin: 2.5rem auto 0;
						width: 75%;
					}
				}

				&[name='modal-close'] {
					height: 1.25rem;
					right: 1.875rem;
					top: 1.75rem;
					width: 1.25rem;

					.gmst-modal-disclosure-close {
						height: 1.25rem;
						width: 1.25rem;
					}
				}
			}
		}
	}

	&.gmwc-rewards-card  {
		.gmwc-modal-wrapper {
			margin: 2.5rem auto 0;
			padding: 1rem 2rem;
			width: 90%;

			slot {
				&[name='modal-title'] {
					span {
						padding: 0 1.75rem 0 0;
					}
				}

				&[name='modal-close'] {
					height: 1.25rem;
					right: 1.25rem;
					top: .9375rem;
					width: 1.25rem;

					.modal-close {
						height: 1.25rem;
						width: 1.25rem;
					}
				}

				&[name='modal-content'] {
					width: 100%;

					.gmst-modal-firstpage {
						.gmst-modal-flexcontainer {
							&:nth-of-type(4) {
								margin-top: 2rem;
							}

							label {
								padding-right: 1.25rem;

								&:first-child {//sass-lint:disable-line nesting-depth
									margin-bottom: .625rem;
								}
							}
						}

						p {
							&:first-child {
								margin-bottom: 0;
								padding-bottom: 2rem;
							}

							&:last-child {
								padding: 2rem 0;
							}
						}
					}

					.gmst-modal-secondpage {
						p {
							&:first-child {
								margin-bottom: 0;
								padding-bottom: 2rem;
							}

							&:last-child {
								padding: 2rem 0;
							}
						}

						.gmst-modal-flexcontainer {
							padding-bottom: 1rem;

							.column-left {
								margin-bottom: 0;
								padding-right: 1.25rem;
								transform: translateY(.3125rem);
							}

							.column-right {
								padding-right: 1.25rem;
								text-align: left;
							}

							&:nth-of-type(3) {
								display: inline-block;
								padding-bottom: 1.25rem;
							}

							&:nth-of-type(5) {
								.column-right {
									transform: translateY(.9375rem);
								}
							}

							&:nth-of-type(6) {
								padding-top: 2rem;

								.column-left {
									max-width: 100%;
									padding-right: 0;
								}
							}

							input {
								&[type='text'] {
									padding-right: .625rem;
								}

								&[type='radio'] {
									-webkit-appearance: none;//sass-lint:disable-line no-vendor-prefixes
									border: 0;
									height: auto;
									margin: 0 0 0 2rem;
									padding: 0;
									position: absolute;
									transform: translate(-2rem, 0);
									width: auto;

									&::before {
										border: 1px solid $grey;
										border-radius: 50%;
										content: '';
										cursor: pointer;
										display: inline-block;
										height: 1.25rem;
										width: 1.25rem;
									}

									&:checked {
										&::after {
											background-color: $grey;
											border-radius: 50%;
											content: '';
											display: inline-block;
											height: .75rem;
											left: 50%;
											position: absolute;
											top: 40%;
											transform: translate(-50%, -50%);
											width: .75rem;
										}
									}
								}

								+ gm-translate {
									padding-left: 1.875rem;
								}
							}
						}
					}

					.gmst-modal-showearnings,
					button {
						margin-bottom: 1.25rem;
					}
				}
			}
		}
	}

	@media #{$large-up} {
		&.gmwc-rewards-card {
			.gmwc-modal-wrapper {
				width: 75%;

				slot {
					&[name='modal-title'] {
						span {
							padding: 0 3.75rem 0 0;
						}
					}

					&[name='modal-close'] {
						height: 1.875rem;
						right: 1.5625rem;
						top: 1.25rem;
						width: 1.875rem;

						.modal-close {
							height: 1.875rem;
							width: 1.875rem;
						}
					}

					&[name='modal-content'] {
						.gmst-modal-showearnings,
						button {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&.gmst-modal-disclosure {
			.gmwc-modal-wrapper {
				slot {
					&[name='modal-content'] {
						left: 45%;
						width: 77.66%;

						.modal-flex {
							margin: 0 auto;
							width: 100%;
						}
					}

					&[name='modal-close'] {
						height: 1.875rem;
						right: 2.375rem;
						top: 1.75rem;
						width: 1.875rem;

						.gmst-modal-disclosure-close {
							height: 1.875rem;
							width: 1.875rem;
						}
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		&.gmwc-rewards-card {
			.gmwc-modal-wrapper {
				width: 50%;
			}
		}

		&.gmst-modal-disclosure {
			.gmwc-modal-wrapper {
				slot {
					&[name='modal-content'] {
						left: 50%;
						width: 75%;
					}

					&[name='modal-close'] {
						right: 4.375rem;
					}
				}
			}
		}
	}

	@media #{$xxlarge-up} {
		&.gmwc-rewards-card {
			.gmwc-modal-wrapper {
				width: 33.33%;
			}
		}
	}

	.no-touch & {
		&.gmwc-rewards-card  {
			.gmwc-modal-wrapper {
				slot {
					&[name='modal-content'] {
						.gmst-modal-showearnings,
						button {
							&:hover {//sass-lint:disable-line nesting-depth
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}
