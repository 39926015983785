$font-path: unquote('fonts/');
$font-file-ext: eot, woff2, woff, ttf, svg;
$font-formats: embedded-opentype, woff2, woff, truetype, svg;
$font-list: () !default;

@mixin generate-font-faces {
	@each $family-name, $variations in $font-list {
		@each $filename, $style, $weight in $variations {
			$font-urls: '';

			@each $ext in $font-file-ext {
				$index: index($font-file-ext, $ext);
				$format: nth($font-formats, $index);
				$font-urls: '#{$font-urls}url(\"#{$font-path}#{$filename}.#{$ext}\") format(\"#{$format}\"), ';
			}

			// Trim off ", " at the end of $font-urls
			$font-urls: str-slice($font-urls, 0, str-length($font-urls) - 2);

			@font-face {
				font-family: $family-name;
				font-style: $style;
				font-weight: $weight;
				src: unquote(str-slice($font-urls, 0, str-index($font-urls, ' format(') - 1)); // IE 9 Compatibility
				// Perform a str-replace to add IE fix, and hash to SVG for font name
				src: unquote(str-replace(str-replace($font-urls, '.eot', '.eot?#iefix'), '.svg', '.svg##{$filename}'));
			}
		}
	}
}
