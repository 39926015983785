// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// Animation
//
// This is the documentation for Animation. Edit animation.scss to update this documentation.
//
// markup:
// <p>Sample markup for Animation. Replace me with the real deal once you are done editing <strong>animation.html</strong> and <em>optionally</em> the animation.js file.</p>
//
// Styleguide rev_2016_03_28

.q-animation {
	&.q-xslow {
		@include hard-easeout(width, $anim-xslow);

		&:hover {
			width: 80%;
		}
	}

	&.q-slow {
		@include hard-easeout(width, $anim-slow);

		&:hover {
			width: 80%;
		}
	}

	&.q-medium {
		@include hard-easeout(width, $anim-medium);

		&:hover {
			width: 80%;
		}
	}

	&.q-fast {
		@include hard-easeout(width, $anim-fast);

		&:hover {
			width: 80%;
		}
	}

	&.q-xfast {
		@include hard-easeout(width, $anim-xfast);

		&:hover {
			width: 80%;
		}
	}

	&.q-xxfast {
		@include hard-easeout(background, $anim-xxfast);

		&:hover {
			width: 80%;
		}
	}
}
