
.dealer-search-list {
	flex: 0 0 100%;
	order: 2;
	width: 100%;
	z-index: 10;

	.fixed-width {
		position: absolute;
		top: 1px;
		width: $dl-pin-size;
	}

	.fixed-width-left {
		float: right;
		padding-left: $dl-pin-size;
		padding-right: 2.5rem;
		position: relative;
		width: 100%;

		.q-open-details-arrow {
			font-size: 1.375rem;
			font-weight: bold;
			margin-top: -.6875rem;
			position: absolute;
			right: 0;
			top: 50%;
		}
	}

	.q-dealer-info-icon {
		@include icon($dl-info-icon);

		font-size: $dl-info-icon-font-size;
		position: absolute;
		right: 0;

		&::before {
			background-color: $dl-info-icon-bg-color;
			border-radius: 50%;
			display: inline-block;
			height: 1.375rem;
			line-height: 1.375;
			text-align: center;
			width: 1.375rem;
		}
	}

	.icon-location-pin-text-negative {
		color: $grey;
		display: inline-block;
		font-size: $dl-pin-size;

		&.active {
			color: $dl-pin-active-color;
		}
	}

	.cursor-pointer {
		cursor: $cursor-pointer-value;
		position: relative;
	}

	.pin-icon-label {
		@extend %#{$dl-pin-label-font};

		color: $white;
		line-height: $dl-pin-icon-line-height;
		padding-left: .1rem;
		position: absolute;
		text-align: center;
		top: 2%;
		width: 100%;
	}

	.q-pagination {
		margin-bottom: 1rem;

		span {
			margin: 0 .5rem;

			a {
				display: inline-block;
				font-weight: $dl-pagination-font-weight;
				margin: $dl-pagination-margin;
				padding: $dl-pagination-padding;
				text-decoration: underline;
				width: auto;
			}

			&.active {
				font-weight: $font-weight-bold;

				a {
					color: $brand-primary-color;
					text-decoration: none;
				}
			}
		}
	}

	.q-dealer-list-items {
		.q-dealer-info .q-dealer-name {
			padding-top: 0;

			&:hover {
				color: $dl-dealer-name-hover-color;
			}
		}
	}
}

.q-dealer-info {
	font-size: $dl-dealer-info-font-size-sm;
	margin-bottom: 1rem;

	.padding-right {
		padding-right: 2rem;
	}

	.q-dealer-name {
		@extend %#{$dl-dealer-name-font};

		flex: 1 0 100%;
		font-weight: $dl-dealer-name-font-weight;
		margin-bottom: $dl-dealer-name-info-margin-bottom;
		order: -2;
	}

	.q-dealer-links {
		flex: 1 0 100%;
		padding-right: 0;

		.q-contact {
			border-top: $dl-contact-link-border-top;
			cursor: pointer;
			margin-bottom: 0;
			padding: $dl-contact-link-vertical-padding;

			img {
				display: inline-block;
				margin-right: .5rem;
				margin-top: 0;
				padding-bottom: .25rem;
			}

			a {
				color: $dl-contact-link-color;
				text-decoration: none;

				&:hover {
					color: $dl-contact-link-color-hover;
				}
			}

			&.q-phone {
				margin-bottom: 0;
			}

			&.q-phone {
				img {
					@include insert-icon(dealer-phone, $svg-icon-medium, $svg-icon-medium);
					display: $dl-contact-icon-display;
				}
			}

			&.q-url {
				img {
					@include insert-icon(dealer-website, $height: $dl-contact-icon-height, $width: $dl-contact-icon-width);
					background-position-x: $dl-contact-icon-background-horizontal-position;
				}
			}

			&.q-location {
				img {
					@include insert-icon(dealer-pin, $height: $dl-contact-icon-height, $width: $dl-contact-icon-width);
					background-position-x: $dl-contact-icon-background-horizontal-position;
				}
			}

		}

		.q-dealer-distance,
		.q-dealer-address {
			color: $dl-dealer-location-color;
			cursor: auto;
			margin-bottom: $dl-dealer-name-info-margin-bottom;
		}
	}

	.q-open-until {
		color: $dl-open-until-color;
		cursor: auto;
		flex: 1;
		font-size: $dl-open-until-font-size;

		&.q-hours-inline {
			float: right;
			margin: rem-calc(4 0);
		}
	}

	.q-dealer-address {
		color: $dl-dealer-location-color;
		flex: 1 0 100%;
		order: -1;

		p {
			float: left;
		}
	}
}


@media #{$medium-up} {
	.dealer-search-list {
		float: left;
	}
}


@media #{$xlarge-up} {
	.dealer-search-list {
		flex: 0 0 31.25rem;
		margin: 0 .5rem .5rem 0;
		order: 1;

		.q-pagination-end {
			display: none;
		}
	}

	.q-dealer-list-items {
		height: 25rem;
		overflow-y: scroll;

		.q-dealer-info {
			.q-dealer-name {
				padding-top: 0;
			}
		}
	}

	.q-dealer-info {
		font-size: $dl-dealer-info-font-size;
		margin-bottom: 0;

		.q-open-until {
			flex: 1 0 50%;
		}

		.q-dealer-address {
			flex: 0 0 100%;
			order: 0;
		}

		.q-dealer-links-container {
			flex: $dl-details-cta-flex;

			&.q-no-hours {
				flex: 0 0 100%;
			}

			.q-dealer-links {
				color: $dl-contact-link-color;
				margin-right: 1rem;

				.q-contact {
					border-radius: rem-calc(3);
					border-top: 0;
					line-height: .75rem;
					margin: 1rem 0;
					padding: 0;

					&.q-phone {
						font-weight: normal;
						margin-bottom: 1rem;
					}
				}
			}
		}


		.q-open-hours {
			flex: 0 0 50%;
		}
	}

	.dealer-locator-widget {
		.q-dealer-info {
			.q-dealer-links-container {
				flex: $dl-widget-details-cta-flex;
			}
		}
	}

	.slick-next {
		z-index: auto;
	}
}
