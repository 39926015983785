.q-current-offers {
	input,
	select {
		@extend %gf-form-input;
		margin-bottom: 0;
	}

	gmwc-collapsable-panel {
		&[disabled] {
			.gmst-co-offer-show-hide {
				display: none;
			}
		}
	}

	select {
		background-color: $gf-dropdown-bg-color;
		background-image: get-element($gf-dropdown-icon), linear-gradient(to right, $gf-dropdown-square-color, $gf-dropdown-square-color);
		background-position: unquote(map-get($gf-dropdown-position-map, small));
		background-repeat: no-repeat;
		background-size: unquote(map-get($gf-dropdown-size-map, small));
		height: 3.375rem;
		margin: 0;
		padding: .625rem;

		&:hover {
			background-image: get-element($gf-dropdown-hover-icon), linear-gradient(to right, $gf-dropdown-square-hover, $gf-dropdown-square-hover);
		}
	}

	.btn-prim {
		@extend %button;
		@extend %q-primary-button;

		&:hover {
			@extend %q-primary-button-touch;
		}
	}

	.btn-sec {
		@extend %button;
		@extend %q-secondary-button;

		&:hover {
			@extend %q-secondary-button-touch;
		}
	}

	gmwc-statefull-showhide {
		clear: both;
		display: block;
		position: relative;
		width: 100%;

		&.hidden {
			display: none;
		}
	}

	.gmst-co-offer-item-flex-filter-container {
		display: flex;
		flex-wrap: wrap;

		.gmst-co-offer-item-table {
			flex: 1 0 100%;
			padding-left: 5%;
			padding-right: 5%;
		}

		.gmst-co-offer-item-flex-filter-row {
			flex: 1 0 100%;
			padding-left: 5%;
			padding-right: 5%;

			gmst-cof-multi-option {
				display: flex;
				flex-wrap: wrap;

				.gmwc-multi-select-item {
					flex: 1 0 auto;

					&:first-child {
						flex: 0 0 100%;
					}

					.gmwc-multi-select-bodystylefilter {
						appearance: none;
						border: 0;
						cursor: pointer;
						flex: 0 0 auto;
						float: left;
						vertical-align: middle;

						&+ label {
							display: block;
							flex: 1 0 auto;
							float: none;
							overflow: auto;
						}
					}
				}
			}
		}
	}

	.gmwc-collapsable-panel-header {
		&:hover {
			cursor: pointer;
		}
	}

	.gmst-co-result-group {
		.gmst-co-offer-result {
			display: table;
			position: relative;
			table-layout: fixed;

			.gmst-co-offer-result-cell {
				&:nth-child(1) {
					@include grid-column(4, $collapse: true);
					display: table-cell;
					float: none;
					vertical-align: middle;

					.gmst-co-offer-result-image {
						margin-left: 0;
						max-width: none;
						width: 100%;
					}
				}

				&:nth-child(2) {
					@include grid-column(8, $collapse: true);
					float: left;

					h2 {
						display: inline-block;
					}
				}

				&:nth-child(3) {
					@include grid-column(8, $collapse: true);
					float: left;
				}
			}

			gmwc-collapsable-panel {
				&[disabled] {
					.gmst-co-offer-results-cell {
						&:nth-child(3) {
							@include grid-column(12, $collapse: true);
						}
					}
				}
			}
		}

		.gmst-co-offer-collapsed-result {
			.offer-detail-container {
				.gmst-co-offer-item-tile {
					.gmst-co-offer-item-flex-details-col {
						> div {
							margin: 1rem;

							.btn-prim {
								margin-bottom: 1rem;

								&:last-child {
									margin-bottom: 0;
								}
							}

							.btn-sec {
								margin-bottom: 1rem;

								&:last-child {
									margin-bottom: 0;
								}
							}
						}

						.gmst-co-offer-item-offer-body {
							+ div {
								&[data-offer='Finance'],
								&[data-offer='Lease'],
								&[data-offer='Cash'] {
									margin: 0;

									.gmst-co-offer-tooltip {
										.gmst-co-offer-tooltiptext {
											display: none;

											&.show { //sass-lint:disable-line nesting-depth
												display: block;
											}
										}

										&:hover {
											color: $brand-link-color-hover;
											cursor: pointer;
										}
									}
								}
							}
						}
					}
				}

				&::after {
					background-color: $co-item-tile-bg-color;
					border-radius: 8rem;
					color: $co-item-tile-text-color;
					content: $co-item-tile-divider-text;
					display: block;
					font-size: $co-item-tile-font-size;
					font-weight: $co-item-tile-font-weight;
					height: 2.5rem;
					line-height: 2.5;
					margin: auto;
					text-align: $co-item-tile-text-align;
					text-transform: $co-item-tile-text-transform;
					width: 2.5rem;
				}

				&:last-child {
					margin-left: 0;

					&::after {
						display: none;
					}
				}
			}

			.gmst-co-offer-item-flex-container {
				display: flex;
				flex-wrap: wrap;

				.gmst-co-offer-item-flex-order-1 {
					flex-basis: 100%;
					flex-grow: 0;
					flex-shrink: 1;
				}

				.gmst-co-offer-item-flex-order-2 {
					flex-basis: 100%;
					flex-grow: 0;
					flex-shrink: 1;
				}

				.gmst-co-offer-item-flex-order-3 {
					flex: 100%;
					flex-grow: 0;
					flex-shrink: 1;
				}
			}
		}
	}

	.gmst-co-result-group {
		// offer tiles
		.gmst-co-offer-item-flex-details-col {

			.gmst-co-filterbox {
				display: none;
			}

			.gmst-co-offer-item-flex {
				.btn-prim {
					@extend %button;
					@extend %q-primary-button;

					&:hover {
						@extend %q-primary-button-touch;
					}
				}
			}
		}
	}

	.q-co-results-top {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		width: 100%;

		.q-postal-code,
		gmwc-stateful-dropdown {
			@include grid-column(12, $collapse: true);
			display: inline-block;
		}

		.q-postal-code {
			.q-postal-code-label {
				label {
					width: 100%;
				}
			}

			.q-postal-code-input {
				width: 100%;
			}
		}

		gmwc-stateful-dropdown {
			.filterby-select-label,
			.gmwc-stateful-dropdown-filterby {
				width: 100%;
			}
		}
	}

	gmst-postalcode {
		#gmst-postalcode-container { // sass-lint:disable-line no-ids
			border: 0;
			display: flex;
			flex-direction: row;
			height: auto;

			.gmst-postalcode-location {
				background-color: $co-postalcode-submit-bg-color;
				flex: none;
				height: $co-postalcode-location-height;
				margin-bottom: 0;
				width: $co-postalcode-location-button-width;

				&:hover {
					background-color: $co-postalcode-location-bg-color-hover;
					cursor: pointer;
				}

				.gmst-postalcode-location-icon {
					background-image: get-element($pe-postalcode-location-icon);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: contain;
					border: 0;
					border-radius: 0;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);

					&::before,
					&::after {
						border: 0;
					}

					i {
						display: none;
					}
				}
			}

			.gmst-postalcode-input {
				border: $search-field-input-border;
				box-shadow: none;
				color: $search-field-input-color;
				font-family: $search-field-input-font-family;
				font-size: $search-field-input-font-size;
				height: $search-field-input-height;
				margin-bottom: 0;
				padding: $search-field-input-padding;
				position: relative;
				vertical-align: middle;
				width: 100%;

				// sass-lint:disable-block no-vendor-prefixes
				&::-ms-clear,
				&::-ms-reveal {
					display: none;
					height: 0;
					width : 0;
				}
			}

			.gmst-postalcode-submit {
				background-color: $co-postalcode-submit-bg-color;
				flex: none;
				height: $co-postalcode-submit-height;
				margin: 0;
				width: $co-postalcode-submit-width;

				&[disabled] {
					background-color: $co-postalcode-submit-bg-color-disabled;
				}

				&:hover {
					background-color: $co-postalcode-submit-bg-color-hover;
					cursor: pointer;
				}

				&::after {
					background-color: transparent;
					border: 0;
				}

				.gmst-postalcode-submit-icon {
					background-image: get-element($pe-postalcode-submit-icon);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: contain;
					border: 0;
					border-radius: 0;

					&::after {
						background-color: transparent;
						border: 0;
					}
				}
			}
		}
	}

	@media #{$medium-up} {
		input,
		select {
			background-position: unquote(map-get($gf-dropdown-position-map, medium));
			background-size: unquote(map-get($gf-dropdown-size-map, medium));
			font-size: map-get($gf-input-font-size-map, medium);
			padding: map-get($gf-dropdown-padding-map, medium);

			// Override iOS bug fix style that was put in place for mobile viewports
			&:focus {
				font-size: map-get($gf-input-font-size-map, medium);
			}
		}

		.gmst-co-offer-item-flex-filter-container {
			.gmst-co-offer-item-flex-filter-row {
				gmst-cof-multi-option {
					.gmwc-multi-select-item {
						&:first-child {
							flex: 1 0 auto;
						}
					}
				}
			}
		}
	}

	@media #{$large-up} {
		input,
		select {
			background-position: unquote(map-get($gf-dropdown-position-map, large));
			background-size: unquote(map-get($gf-dropdown-size-map, large));
			font-size: map-get($gf-input-font-size-map, large);
			height: map-get($gf-input-height-map, large);
			line-height: $gf-input-line-height;
			padding: map-get($gf-dropdown-padding-map, large);

			// Override iOS bug fix style that was put in place for mobile viewports
			&:focus {
				font-size: map-get($gf-input-font-size-map, large);
			}
		}

		.q-co-results-top {
			.q-postal-code {
				.q-postal-code-label {
					@include grid-column(2, $collapse: true);
				}

				.q-postal-code-input {
					@include grid-column(6, $collapse: true);
				}
			}

			gmwc-stateful-dropdown {
				.filterby-select-label {
					@include grid-column(2, $collapse: true);
				}

				.gmwc-stateful-dropdown-filterby {
					@include grid-column(6);
				}
			}
		}

		.gmst-co-offer-item-flex-filter-container {
			.gmst-co-offer-item-flex-filter-row {
				.gmwc-multi-select-bodystylefilter {
					&+ label {
						float: left;

						&+ br {
							display: none;
						}
					}
				}
			}
		}

		.gmst-co-result-group {
			.gmst-co-offer-collapsed-result {
				.gmst-co-offer-item-flex-container {
					.gmst-co-offer-item-flex-order-1 {
						flex-basis: 50%;
					}

					.gmst-co-offer-item-flex-order-2 {
						flex-basis: 50%;
					}

					.gmst-co-offer-item-flex-order-3 {
						flex: 100%;
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.gmst-co-result-group {
			.gmst-co-offer-collapsed-result {
				//offer comparison tiles
				.gmst-offer-detail-container {
					align-items: space-around;
					display: flex;
					margin: 0 -1.25rem;

					.offer-detail-container {
						flex-basis: 0;
						flex-grow: 1;
						flex-shrink: 0;
						margin: 0 1.25rem;
						position: relative;

						&:not(:last-child) {
							&::after {
								background-color: $co-item-tile-bg-color;
								border-radius: 8rem;
								color: $co-item-tile-text-color;
								content: $co-item-tile-divider-text;
								font-size: $co-item-tile-font-size;
								font-weight: $co-item-tile-font-weight;
								height: 2.5rem;
								line-height: 2.5;
								margin: auto;
								position: absolute;
								right: -1px;
								text-align: $co-item-tile-text-align;
								text-transform: $co-item-tile-text-transform;
								top: 50%;
								transform: translate(100%, -50%);
								width: 2.5rem;
							}
						}

						.gmst-co-offer-item-tile {
							background-position: center center;
							background-repeat: no-repeat;
							background-size: 100% auto;
							height: 100%;

							.gmst-co-offer-item-flex-details-col {
								background-color: $co-item-tile-details-bg-color;
								border: $co-item-tile-details-border;
								display: flex;
								flex-direction: column;
								height: 100%;
								margin: 0;

								> div {
									margin: 1rem;
								}

								.gmst-co-offer-item-flex {
									margin-top: auto;

									.btn-prim {
										margin-bottom: 1rem;
										width: 100%;

										&:last-child {
											margin-bottom: 0;
										}
									}

									.btn-sec {
										margin-bottom: 1rem;
										width: 100%;

										&:last-child {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
				}

				.gmst-co-offer-item-flex-container {
					.gmst-co-offer-item-flex-order-1 {
						flex-basis: 25%;
					}

					.gmst-co-offer-item-flex-order-2 {
						flex-basis: 41.66667%;
					}

					.gmst-co-offer-item-flex-order-3 {
						flex: 33.33333%;
					}
				}
			}

			.gmst-co-offer-result {
				align-items: center;
				display: flex;
				flex-wrap: wrap;

				.gmst-co-offer-result-cell {
					&:nth-child(1) {
						display: flex;
						flex-basis: 16.6667%;
						flex-grow: 0;
						flex-shrink: 1;
						width: auto;

						.gmst-co-offer-result-image {
							align-self: flex-start;
						}
					}

					&:nth-child(2) {
						flex-basis: 50.3333%;
						flex-grow: 0;
						flex-shrink: 1;
						width: auto;
					}

					&:nth-child(3) {
						flex-basis: 33%;
						flex-grow: 0;
						flex-shrink: 1;
						text-align: right;
						white-space: nowrap;
						width: auto;
						word-wrap: break-word;
					}
				}
			}
		}

		.q-co-results-top {
			.q-postal-code,
			gmwc-stateful-dropdown {
				@include grid-column(6, $collapse: true);
			}

			.q-postal-code {
				.q-postal-code-label {
					@include grid-column(2, $collapse: true);
				}

				.q-postal-code-input {
					@include grid-column(8, $collapse: true);
				}
			}

			gmwc-stateful-dropdown {
				@include grid-column(6, $collapse: true);

				.filterby-select-label {
					@include grid-column(2, $collapse: true);
				}

				.gmwc-stateful-dropdown-filterby {
					@include grid-column(9);
				}
			}
		}
	}
}
