// This file is automatically generated. Any modification should be done to "{{brandUi}}/scss/main.scss.mustache" instead.

// Normalize everything
@import "../../gmwp-aem-ui/scss/normalize";
@import "../../gmwp-aem-ui/scss/functions";
@import "variables";
@import "settings";

// Setting Up Foundation
@import "../../solar-aem-ui/scss/foundation";

// Everything below this is Application Specific

// Import Global Brand SCSS not covered in Foundation 5
// This would include styles that are used globally throughout the site
@import "global";

// Import all icons
@import "../../solar-aem-ui/scss/icons";
@import "icons";
@import "../../solar-aem-ui/scss/icons_extend";

// Vendor specific SCSS files
@import "vendor";

// Import Common Brand Specific Pattern SCSS
// Patterns are reused throughout the rest of the components
// These are brand specific and are used to cover areas that Foundation 5 does not cover
/* inject:patterns */
@import "../../solar-aem-ui/scss/patterns/animation";
@import "../../solar-aem-ui/scss/patterns/disclosure";
@import "../../solar-aem-ui/scss/patterns/fonts";
@import "../../solar-aem-ui/scss/patterns/footer";
@import "../../solar-aem-ui/scss/patterns/forms";
@import "../../solar-aem-ui/scss/patterns/globalforms";
@import "../../solar-aem-ui/scss/patterns/layout";
@import "../../solar-aem-ui/scss/patterns/modal";
@import "../../solar-aem-ui/scss/patterns/navigation";
@import "../../solar-aem-ui/scss/patterns/typography";
@import "patterns/fonts";
@import "patterns/footer";
@import "patterns/globalforms";
@import "patterns/typography";
/* endinject */


// Import SCSS for AEM Components
/* inject:components */
@import "../../solar-aem-ui/components/utility/dealer_locator/dealerLocator";
@import "../../solar-aem-ui/components/content/button/button";
@import "../../solar-aem-ui/components/content/chatnow/chatnow";
@import "../../solar-aem-ui/components/content/colorizer/colorizer";
@import "../../solar-aem-ui/components/content/content_overlay/content_overlay";
@import "../../solar-aem-ui/components/content/content_well/content_well";
@import "../../solar-aem-ui/components/content/disclaimer_global/disclaimer_global";
@import "../../solar-aem-ui/components/content/disclosure_modal_toast/disclosure_modal_toast";
@import "../../solar-aem-ui/components/content/document_finder/document_finder";
@import "../../solar-aem-ui/components/content/expander/expander";
@import "../../solar-aem-ui/components/content/headline/headline";
@import "../../solar-aem-ui/components/content/rte_launch_gallery/rte_launch_gallery";
@import "../../solar-aem-ui/components/content/rte_launch_modal/rte_launch_modal";
@import "../../solar-aem-ui/components/content/search_field/search_field";
@import "../../solar-aem-ui/components/content/search_results/search_results";
@import "../../solar-aem-ui/components/content/separator/separator";
@import "../../solar-aem-ui/components/content/table/table";
@import "../../solar-aem-ui/components/content/vertical_spacing/vertical_spacing";
@import "../../solar-aem-ui/components/framework/iframe/iframe";
@import "../../solar-aem-ui/components/help_me_find/hmf_filter/hmf_filter";
@import "../../solar-aem-ui/components/help_me_find/hmf_group/hmf_group";
@import "../../solar-aem-ui/components/help_me_find/hmf_results/hmf_results";
@import "../../solar-aem-ui/components/help_me_find/hmf_sort/hmf_sort";
@import "../../solar-aem-ui/components/media/gallery_layer/gallery_layer";
@import "../../solar-aem-ui/components/media/image_svg/image_svg";
@import "../../solar-aem-ui/components/media/gallery_preview/gallery_preview";
@import "../../solar-aem-ui/components/media/multimedia_scroller/multimedia_scroller";
@import "../../solar-aem-ui/components/media/multimedia_teaser/multimedia_teaser";
@import "../../solar-aem-ui/components/media/multimedia_teaser_collection/multimedia_teaser_collection";
@import "../../solar-aem-ui/components/media/responsive_image/responsive_image";
@import "../../solar-aem-ui/components/media/slide/slide";
@import "../../solar-aem-ui/components/media/static_image/static_image";
@import "../../solar-aem-ui/components/media/video/video";
@import "../../solar-aem-ui/components/media/video_brightcove/video_brightcove";
@import "../../solar-aem-ui/components/navigation/nav_primary/nav_primary";
@import "../../solar-aem-ui/components/navigation/nav_secondary/nav_secondary";
@import "../../solar-aem-ui/components/navigation/nav_segment/nav_segment";
@import "../../solar-aem-ui/components/navigation/nav_tertiary/nav_tertiary";
@import "../../solar-aem-ui/components/navigation/tab_nav/tab_nav";
@import "../../solar-aem-ui/components/product/engine_information/engine_information";
@import "../../solar-aem-ui/components/product/product_selector/product_selector";
@import "../../solar-aem-ui/components/product/transmission_info/transmission_info";
@import "../../solar-aem-ui/components/shopping_tools/current_offers_vehicle/current_offers_vehicle";
@import "../../solar-aem-ui/components/shopping_tools/payment_estimator/payment_estimator";
@import "../../solar-aem-ui/components/utility/share/share";
@import "../../solar-aem-ui/components/vehicle/destination_freight_information/destination_freight_information";
@import "../../solar-aem-ui/components/vehicle/vehicle_attribute/vehicle_attribute";
@import "../../solar-aem-ui/components/vehicle/vehicle_info_block/vehicle_info_block";
@import "../../solar-aem-ui/components/vehicle/vehicle_info_row/vehicle_info_row";
@import "../../solar-aem-ui/components/vehicle/vehicle_info_tile/vehicle_info_tile";
@import "../../solar-aem-ui/components/vehicle/vehicle_selector/vehicle_selector";
@import "../../solar-aem-ui/components/vehicle/vehicle_trim_info/vehicle_trim_info";
@import "../../solar-aem-ui/components/vehicle/vehicle_trim_selector/vehicle_trim_selector";
@import "../../solar-aem-ui/components/utility/dealer_locator/js/components/details/details.style";
@import "../../solar-aem-ui/components/utility/dealer_locator/js/components/hours/hours.style";
@import "../../solar-aem-ui/components/utility/dealer_locator/js/components/list/list.style";
@import "../../solar-aem-ui/components/utility/dealer_locator/js/components/filter/filter.style";
@import "../../solar-aem-ui/components/utility/dealer_locator/js/components/search/search.style";
@import "../../solar-aem-ui/components/utility/dealer_locator/js/components/spinner/spinner";
@import "../components/content/colorizer/colorizer";
@import "../components/content/disclosure_modal_toast/disclosure_modal_toast";
@import "../components/content/table/tabls";
@import "../components/media/gallery_layer/gallery_layer";
@import "../components/media/multimedia_scroller/multimedia_scroller";
@import "../components/media/multimedia_teaser/multimedia_teaser";
@import "../components/navigation/nav_primary/nav_primary";
@import "../components/navigation/nav_secondary/nav_secondary";
@import "../components/navigation/nav_segment/nav_segment";
@import "../components/navigation/tab_nav/tab_nav";
@import "../components/shopping_tools/current_offers_vehicle/current_offers_vehicle";
@import "../components/shopping_tools/payment_estimator/payment_estimator";
@import "../components/vehicle/destination_freight_information/destination_freight_information";
@import "../components/vehicle/vehicle_info_block/vehicle_info_block";
@import "../components/vehicle/vehicle_info_row/vehicle_info_row";
@import "../components/vehicle/vehicle_info_tile/vehicle_info_tile";
@import "../components/vehicle/vehicle_trim_info/vehicle_trim_info";
@import "../components/vehicle/vehicle_trim_selector/vehicle_trim_selector";
@import "../components/utility/dealer_locator/dealer_locator";
@import "../components/utility/share/share";
/* endinject */

// Import Print-specific styles.  Added at bottom to override any styles in component code
@import "print";
