// Help Me Find Sort
.q-hmf-sort {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 1rem;

	.q-result-count {
		@extend %#{$hmf-sort-result-count};

		flex: 1 0 100%;
		margin-bottom: $hmf-result-count-margin-bottom;
	}

	.q-hmf-match {
		display: none;
	}

	.q-hmf-sort-dropdown-container {
		align-items: center;
		display: flex;
		flex: 1 0 100%;
		flex-wrap: nowrap;
	}

	.q-hmf-sort-label {
		@extend %#{$hmf-sort-label};
		flex: $hmf-sort-label-flex;
		margin-bottom: 0;
		padding-right: 1rem;
	}

	.q-hmf-sort-dropdown {
		flex: 1 1 auto;
		position: relative;

		.dropdown,
		.q-dropdown {
			@extend %hmf-dropdown-button;

			background: $hmf-sort-dropdown-background-color;
			border: $hmf-sort-dropdown-border;
			border-right-color: $hmf-sort-dropdown-border-right-color;
			border-right-width: 3.875rem;
			color: $hmf-sort-dropdown-font-color;
			flex: 1 1 auto;
			font-size: map-get($hmf-dropdown-button-font-map, small);
			margin-bottom: 0;
			padding: $hmf-sort-dropdown-padding;
			text-align: left;
			width: 100%;

			&::after {
				border-top-color: $hmf-sort-dropdown-carrot;
			}

			&:focus {
				outline: none;
			}
		}

		.f-dropdown {
			font-family: $font-family-sans;
			opacity: 1;
			width: 100%;
			z-index: 60;

			&::before,
			&::after {
				display: $hmf-sort-dropdown-carat-display;
			}

			.q-dropdown-item {
				border-left: 3px solid $hmf-sort-dropdown-item-border-left-color;
				font-size: 1.125rem;

				a {
					padding: 1rem;
				}

				&:hover {
					background: $hmf-sort-dropdown-background-color;
					border-left: 3px solid $hmf-sort-dropdown-hover-border-color;
				}

				&.active {
					background: $hmf-sort-dropdown-active-background-color;
					border-left: 3px solid $hmf-sort-dropdown-default-border-color;

					&:hover {
						border-left: 3px solid $hmf-sort-dropdown-hover-border-color;
					}
				}
			}
		}
	}

	@media #{$large-up} {
		padding-left: 1.25rem;
		padding-right: 1.25rem;

		.q-hmf-sort-dropdown {
			.q-dropdown {
				font-size: map-get($hmf-dropdown-button-font-map, large);
			}
		}

		.q-hmf-label {
			padding: $hmf-sort-label-padding;
			text-align: right;
		}

		.q-result-count {
			flex: 1 0 50%;
			margin-bottom: 0;
		}

		.q-hmf-sort-dropdown-container {
			flex: 1 0 50%;
		}
	}
}
