/* Styles for the print header that is added to the top of the body for each page loaded */

$print-header-container-border-bottom: 2px solid $grey-light !default;
$print-header-logo-container-max-width: 5.625rem !default;
$print-header-title-typography: 'q-descriptive1' !default;

.q-print-header {
	display: none;

	.q-print-header-container {
		align-items: center;
		border-bottom: $print-header-container-border-bottom;
		display: flex;
		margin-bottom: 2.5rem;
		padding-bottom: .313rem;

		.title {
			@extend %#{$print-header-title-typography};
			color: $grey-dark;
			display: flex;
			flex: 1 1 0;
			padding-left: 2.5rem;
			text-transform: uppercase;
		}

		.logo-container {
			display: flex;
			flex: 1 1 0;
			max-width: $print-header-logo-container-max-width;

			.logo {
				height: 100%;
				width: 100%;
			}
		}
	}
}

/* Specific print CSS rules go here */
@media print {
	/* sass-lint:disable-block no-important */
	footer,
	.q-chatnow,
	.q-nav-anchor,
	.q-nav-primary,
	.sticky-wrapper,
	.q-disclosure-print-hide,
	.q-disclaimer-footer {
		display: none;
	}

	.q-print-sequence-data {
		display: block;
	}

	.q-disclosure-print-number {
		display: inline-block;
	}

	/* This is the container for the disclosures at the bottom of the printed page */
	.q-disclosure-data-content {
		display: block;
		margin-top: $margin-base-large;

		.q-text {
			.q-body2 {
				a:link {
					color: $grey;
					font-weight: 400;
				}
			}
		}

		.q-print-list-style {
			list-style-type: decimal;
		}
	}

	.show-disclosure {
		.q-print-special-char {
			display: none;
		}
	}

	.headline {
		.show-disclosure {
			text-decoration: none;

			sup span {
				text-decoration: none;
	      }
	    }
	}

	.q-print-reference {
		display: inline-block;
	}

	.q-print-header {
		display: block;
	}

	.q-separator {
		.q-separator-combined {
			height: inherit !important;
		}
	}

	.q-dealer-locator {
		div {
			margin: 0 !important;
		}

		//hide from print
		.dealer-dropdown,
		.q-pagination,
		.q-shopping-links,
		.q-dealer-info .q-dealer-links .q-contact.q-location,
		.q-dealer-info .q-dealer-links .q-contact img,
		.q-checkbox-label,
		.q-print-button,
		.q-dealer-search,
		.hide-large-up {
			display: none !important;
		}

		.q-descriptive1,
		.q-dealer-info .q-dealer-address,
		.q-dealer-info .q-dealer-links .q-contact a {
			font-size: .75rem !important;
			font-weight: normal !important;

		}

		.q-dealer-info .q-dealer-links .q-contact.q-url a::after {
			content: ' (' attr(href) ')';
		}

		.filter-tags .inline-list > li .button {
			border: 1px solid $black;
			font-size: .5625rem !important;
			font-weight: normal !important;
		}

		.q-map-column {
			flex: 1 0 100%;
			margin: 0 0 .5rem .5rem;
			order: 0;
			padding-left: .5rem;
			padding-top: 1.25rem;
			position: relative;
			zoom: 70%;

			.q-map-container {
				height: 100%;
				min-height: 36.25rem;
				padding-bottom: 2rem;

				.dealer-map {
					height: 100%;
				}
			}
		}

		.q-search-column {
			flex: 1 1 0;
		}

		.q-open-hours {
			flex: 1;
		}

		.q-text.q-body2 {
			padding-top: 0;
		}

		.q-dealer-links {
			flex: 1 0 100%;

		}

		.q-dealer-info .q-dealer-links .q-contact {
			border: 0;
			padding: .5rem 0;
		}
	}

	.q-gallery-layer {
		.q-gallery-video-icon {
			display: none !important;
		}

		.q-multimedia-scroller {
			.slick-arrow {
				display: none !important;
			}
		}
	}
}
