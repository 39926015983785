.q-hmf-results {
	width: 100%;

	.q-vehicle-selector {
		border-top: $hmf-border;
	}

	.q-tags-container {
		width: 100%;

		.q-hmf-filter-tags {
			float: left;

			.q-hmf-tag {
				@extend %#{$hmf-results-tag-label};
				@include icon(close, after) {
					float: right;
					line-height: inherit;
					margin-left: 1.875rem;
				}

				background: $hmf-results-tag-background-color;
				color: $hmf-results-tag-color;
				margin-bottom: 1.25rem;
				margin-right: 1.875rem;
				padding: $hmf-results-tag-padding;
				width: auto;

				&:hover {
					background-color: $hmf-results-tag-background-color-hover;
					color: $hmf-results-tag-hover-color;
				}
			}
		}

		.q-hmf-filter-clear-tags {
			float: left;
			padding: $hmf-results-tag-padding;

			.q-link {
				@extend %#{$hmf-results-tag-label};

				color: $hmf-results-tag-clear-label-color;
				text-decoration: underline;
			}
		}
	}

	.q-hmf-filter-tags-clear {
		display: none;
	}

	.q-vehicle-count {
		float: left;

		.q-count-number {
			font-weight: $font-weight-bold;
		}
	}

	.q-hmf-sort {
		position: relative;
	}

	.q-results-container {
		display: block;
		margin: 1rem 0;
		padding: 0;
	}

	.slider-filter {
		opacity: .5;
		transition: opacity #{$anim-slow}s ease;
	}

	.q-hmf-no-results {
		display: none;
		padding: 1.25rem;

		.q-no-vehicles-match {
			@extend %q-label1;
		}

		.q-hmf-filter-clear-tags {
			margin-bottom: 1rem;
		}

		.q-hmf-filter-clear-tags,
		.q-hmf-filter-clear-last {
			flex: 0 0 100%;
		}
	}

	@media #{$medium-up} {
		.q-vehicle-selector {
			border-top: 0;
		}

		.q-vehicle-count {
			margin-left: .5rem;
		}
	}

	@media #{$xlarge-up} {
		.q-tags-container {
			padding: 1.25rem;
		}
	}
}
