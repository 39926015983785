/* Global.scss - For Global Application Styles and Documentation */
@import 'mixins';
@import 'placeholders';

$media-query-map: (
	xs: $small-only,
	sm: $medium-only,
	med: $large-only,
	lg: $xlarge-only,
	xl: $xxlarge-only
);

html {
	/* This fixes the grey highlight effect seen on elements when 'tapping' on
		mobile device */
	-webkit-tap-highlight-color: $webkit-tap-highlight-color; // sass-lint:disable-line no-vendor-prefixes
	-webkit-tap-highlight-color: transparent; /* For some Androids */ // sass-lint:disable-line no-vendor-prefixes
	font-size: $base-font-size-xs;
	height: 100%;
	min-height: 100%;

	@media #{$large-up} {
		font-size: $base-font-size-m;
	}

	@media #{$xlarge-up} {
		font-size: $base-font-size-xl;
	}
}

body {
	height: 100%;
}

//Overriding default Foundation paragraph styles
p {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
}

//Overriding default Foundation list styles
ul,
ol,
dl {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: inherit;
}

.q-page-container {
	border-left: 1px solid $grey-dark;
	border-right: 1px solid $grey-dark;
	min-height: 100vh;
	position: relative;
	z-index: 0;
}

.grid-decoration {
	border-top: .5rem solid $brand-primary-color;
}

.column,
.columns {

	&.grid-column-alignment-left {
		text-align: left;
	}

	&.grid-column-alignment-center {
		text-align: center;
	}

	&.grid-column-alignment-right {
		text-align: right;
	}
}

.q-nbsp::before {
	// This adds a &nbsp; to the element
	content: '\00a0';
}

/* Makes first grid builder have a left and right margin */
.q-gridbuilder {
	padding-left: $gridpadding-small-vw;
	padding-right: $gridpadding-small-vw;

	&.gridfullwidth,
	.q-gridbuilder {
		/* Makes a full bleed grid, and makes any nest grid builder not have left/right margin */
		padding-left: 0;
		padding-right: 0;
	}

	.q-indent-grid-content {
		padding-left: $gridpadding-small-vw;
		padding-right: $gridpadding-small-vw;
	}

	// Global Forms component removes padding when used inside grid so it aligns with other grid content
	.gmit-gf-request-type {
		padding: 0 0 .5rem;
	}
}

.grid-padding-small > .columns,
.grid-padding-small > .column {
	@include grid-padding($gridpadding-small, 1.25rem);
	@include grid-padding($gridpadding-small, $column-gutter/2);
}

.grid-padding-medium > .columns,
.grid-padding-medium > .column {
	@include grid-padding($gridpadding-medium, 1.25rem);
	@include grid-padding($gridpadding-medium, $column-gutter/2);
}

.grid-padding-large > .columns,
.grid-padding-large > .column {
	@include grid-padding($gridpadding-large, 1.25rem);
	@include grid-padding($gridpadding-large, $column-gutter/2);
}

.collapse.grid-padding-small > .columns,
.collapse.grid-padding-small > .column {
	padding: $gridpadding-small;
}

.collapse.grid-padding-medium > .columns,
.collapse.grid-padding-medium > .column {
	padding: $gridpadding-medium;
}

.collapse.grid-padding-large > .columns,
.collapse.grid-padding-large > .column {
	padding: $gridpadding-large;
}


.row.gridspacing-small,
.row.collapse.gridspacing-small {
	margin-bottom: $gridspacing-small;
}

.row.gridspacing-medium,
.row.collapse.gridspacing-medium  {
	margin-bottom: $gridspacing-medium;
}

.row.gridspacing-large,
.row.collapse.gridspacing-large  {
	margin-bottom: $gridspacing-large;
}

.row.gridspacing-xlarge,
.row.collapse.gridspacing-xlarge  {
	margin-bottom: $gridspacing-xlarge;
}

.row {
	&.gridfullwidth {
		max-width: $gridwidth-full;

		.row {
			max-width: $gridwidth-full;
		}
	}

	&.grid-bg-transparent {
		background-color: $grid-bg-transparent;
	}

	&.grid-bg-color-two {
		background-color: $grid-bg-color-two;
	}

	&.grid-bg-color-three {
		background-color: $grid-bg-color-three;
	}

	&.grid-bg-color-four {
		background-color: $grid-bg-color-four;
	}

	&.grid-bg-color-five {
		background-color: $grid-bg-color-five;
	}

	&.grid-bg-color-six {
		background-color: $grid-bg-color-six;
	}

	/* Fix for flex grid in Safari */
	&::before,
	&.row::after {
		width: 0;
	}
}

.position-bottom-right {
	@extend %position-bottom;
	@extend %position-right;
}

.position-bottom-left {
	@extend %position-bottom;
	@extend %position-left;
}

.position-top-left {
	@extend %position-top;
	@extend %position-left;
}

.position-top-right {
	@extend %position-top;
	@extend %position-right;
}


/* Alignment styles */
.q-left {
	float: left;
}

.q-right {
	float: right;
}

.q-center {
	margin: 0 auto;
}

.q-center-transform {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/* Sticky Styles */
.q-sticky {
	.sticky-child {
		z-index: 55;
	}

	&.top {
		.sticky-child {
			@extend %sticky;
			@extend %position-top
		}
	}

	&.bottom {
		.sticky-child {
			@extend %sticky;
			@extend %position-bottom
		}
	}
}

.q-columns-half {
	padding-left: $column-gutter / 2;
	padding-right: $column-gutter / 2;
}

.q-columns-quarter {
	padding-left: $column-gutter / 4;
	padding-right: $column-gutter / 4;
}

.q-no-link {
	text-decoration: none;
}

.q-block {
	display: block;
}

.q-inline {
	display: inline;
}

picture {
	img {
		width: 100%;
	}
}

// font smoothing across the site
* {
	-webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
}

// This will make sure the sticky elements stay centered in page when
// stickiness is turned on, once the user has scrolled down.
.q-sticky {
	@media #{$xxlarge-up} {
		&.top .sticky-child,
		&.bottom .sticky-child {
			@include centered(true);
		}
	}
}

.q-scroll-disabled {
	max-height: 100vh;
	overflow: hidden;
}

.body-scroll-disabled {
	overflow: hidden;
}

.body-scroll-enabled {
	overflow: auto;
}

// Left Right Animations

.q-slide-left {
	animation-duration: #{$anim-fast}s;
	animation-name: slide-left;
	animation-timing-function: ease;
}

.q-slide-right {
	animation-duration: #{$anim-fast}s;
	animation-fill-mode: forwards;
	animation-name: slide-right;
	animation-timing-function: ease;
}

@keyframes slide-right {
	from {
		left: -100%;
	}

	to {
		left: 0;
	}
}

@keyframes slide-left {
	from {
		left: 0;
	}

	to {
		left: -100%;
	}
}

// grid: prevent float from putting items visually where they should not be logically
@media #{$xlarge-up} {
	.q-grid-row-new-desktop {
		clear: left;
	}
}

@media #{$large-only}, #{$medium-only} {
	.q-grid-row-new-tablet {
		clear: left;
	}
}

@media #{$small-only} {
	.q-grid-row-new-mobile {
		clear: left;
	}
}

// Skip Navigation Link Styles
// TODO: Replace ID selector with class
#q-main-content, // sass-lint:disable-line no-ids
.q-skip-nav {
	left: -62.5rem;
	position: absolute;

	&:focus {
		left: 0;
		position: relative;
	}
}

.q-round-image {
	@extend %crop-circle;
}

.q-disclosure-data-content,
.q-print-reference,
.q-print-sequence-data,
.q-disclosure-print-number {
	display: none;
}

.q-margin-top {
	margin-top: 1rem;
}

// RAQ autocomplete search
.ui-autocomplete {
	background-color: $white;
	border: $gf-input-border;
	border-top: 0;
	cursor: pointer;
	font-family: $font-family-primary;
	list-style: none;
	width: 0; // jquery autocomplete automatically makes width equal to input field

	li {
		padding-left: .5rem;
	}
}
