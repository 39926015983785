// page gutters for demo
.page-gutters {
	padding-left: 5%;
	padding-right: 5%;
}

.q-form-page,
.q-form {
	input + .q-form-focus-text {
		@include hard-easeout(all, $anim-slow);
		display: block;
		max-height: 0;
		overflow: hidden;
	}

	input:focus + .q-form-focus-text {
		@include hard-easeout(all, $anim-slow);
		max-height: 5.313rem;
		padding-top: .625rem;
	}

	::placeholder {
		color: $grey-mid;
	}

	// Override Firefox opacity on placeholder text
	::-moz-placeholder { // sass-lint:disable-line no-vendor-prefixes
		opacity: 1;
	}

	select:focus {
		outline: none;
	}

	.q-form-dropdown {
		background-image: get-element(carat-down), linear-gradient(to right, $grey, $grey);
		background-position: calc(100% - 28px) calc(1em + 7px), 100% 0;
		background-repeat: no-repeat;
		background-size: 10px 10px, 70px 100%;
	}

	.q-form-dropdown:hover:not(:disabled) {
		background-image: get-element(carat-down), linear-gradient(to right, $grey-dark, $grey-dark);
	}

	.q-input,
	textarea {
		border-color: $grey-mid;
		box-shadow: none;
		margin-bottom: 0;
		padding: .0625rem .0625rem .0625rem .625rem;

		&:focus {
			background-color: $white;
			border-color: $grey;
		}
	}

	.q-input {
		height: 3.125rem;
	}

	.q-btn-form {
		border-right: 0;
		float: left;
		width: 65%;
	}

	.button.postfix {
		background-color: $grey;
		float: right;
		height: 3.375rem;
		margin-bottom: 0;
		width: 35%;

		&:hover {
			background-color: $grey-dark;
		}

		.q-inline-btn-label {
			color: $white;
			font: $font-weight-bold .75rem $font-family-secondary;
			line-height: 3.375rem;
		}
	}

	select {
		background-color: $white;
		cursor: pointer;

		&:disabled {
			background-color: $white;
			cursor: initial;
			opacity: .3;
		}
	}

	label {
		margin-bottom: .625rem;
	}

	textarea,
	.q-textarea {
		margin-bottom: .625rem;
		min-height: 7em;
		padding: .625rem;
		resize: none;
	}

	.q-textarea {
		border: .0625rem solid $grey-mid;
		color: $grey-dark;

		&:focus {
			background-color: $white;
			border-color: $grey;
			outline: none;
		}
	}

	.q-password {
		font-weight: $font-weight-bold;
		letter-spacing: .3125rem;
	}

	.q-checkbox {
		-webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
		background-color: $grey-light;
		border-radius: .0625rem;
		cursor: pointer;
		height: 1.25rem;
		margin: .3125rem .625rem .625rem 0;
		padding: 0;
		vertical-align: middle;
		width: 1.25rem;


		+ .q-checkbox-label {
			vertical-align: text-bottom;
		}

		&:focus {
			outline: none;
		}

		&:checked::after {
			content: get-element(check);
			left: .25rem;
			position: relative;
			top: -.1875rem;
		}
	}

	.q-checkbox + .q-checkbox-label,
	.q-radio + .q-radio-label {
		margin-left: 0;
	}

	.q-radio:checked + .q-radio-label::before {
		color: $grey;
		content: '\2022';
		font-family: sans-serif;
		font-size: 2.188rem;
		text-align: center;
	}

	.q-radio-label::before {
		border-radius: .625rem;
		cursor: pointer;
	}

	.q-radio-label::before {
		border: .0625rem solid $grey;
		content: '';
		height: 1.25rem;
		left: 0;
		position: absolute;
		width: 1.25rem;
	}

	.q-radio {
		display: none;

		+ .q-radio-label {
			margin-bottom: .625rem;
			padding-left: 1.875rem;
			position: relative;
		}
	}

	.q-checkbox-label,
	.q-radio-label {
		color: $grey;
		font: $font-weight-bold .75rem $font-family-secondary;
		line-height: 1.25rem;
	}

	select {
		margin-bottom: 0;
	}

	.q-form-success {
		color: $green;
	}

	.q-form-error {
		color: $red;
	}

	.q-form-success,
	.q-form-error {
		font: .875rem $font-family-secondary;
		line-height: 1.563rem;
		padding-top: .625rem;
	}

	.q-validation-input.q-input {
		border: 0;
		box-shadow: inset 0 0 0 3px $red;
		transition: border, box-shadow #{$anim-fast}s;

		&:focus {
			border: .0625rem solid $grey-mid;
			box-shadow: none;
		}
	}

	.q-combo-title {
		background-color: $grey-dark;
		color: $white;
		font: $font-weight-normal .9375rem $font-family-primary;
		letter-spacing: .09em;
		line-height: 1.25rem;
		padding: .625rem 0 .625rem 1.875rem;
		padding-left: .625rem;
		text-transform: uppercase;
		width: 100%;
	}

	.q-combobox {
		border: .0625rem solid $grey;

		input {
			display: none;

			&:checked + label {
				background-color: $grey-very-light;
			}

			+ label {
				margin: 0;

				&:hover {
					background-color: $grey-very-light;
				}
			}
		}

		label {
			display: block;
			padding: .625rem 0 .625rem 1.875rem;
			padding-left: .625rem;
			width: 100%;
		}
	}
}

.q-form-dropdown {
	background-color: $form-background;
	background-image: get-element('carat-down-white'), linear-gradient(to right, $form-interactive, $form-interactive);
	background-position: calc(100% - 1.75rem) calc(1em + .4375rem), 100% 0;
	background-repeat: no-repeat;
	background-size: .625rem .625rem, 4.375rem 100%;
	border-color: $form-border-primary;
	box-shadow: none;
	font-size: 1.125rem;
	height: 3.875rem;
	line-height: 1.875rem;
	margin-bottom: 0;
	padding: .9375rem 4.375rem .9375rem 1.875rem;

	&:hover {
		background-color: $form-background;
		background-image: get-element('carat-down-white'), linear-gradient(to right, $form-interactive-hover, $form-interactive-hover);
	}

	&:focus {
		background-color: $form-background;
	}

	&:disabled {
		background-color: $form-background;
		background-image: get-element('carat-down-white'), linear-gradient(to right, $form-interactive-disabled, $form-interactive-disabled);
		border-color: $form-interactive-disabled;
		color: $form-interactive-disabled;
	}
}

@media #{$medium-up} {
	.q-form-page,
	.q-form {
		.q-inline-label {
			margin-bottom: 0;
			padding: .9375rem 0;
		}

		.q-btn-form {
			width: 75%;
		}

		.button.postfix {
			width: 25%;
		}
	}
}

@media #{$large-up} {
	.q-form-page,
	.q-form {
		.q-input,
		textarea,
		.q-textarea,
		.q-form-dropdown,
		.q-combobox label {
			font-size: 1.125rem;
			line-height: 1.875rem;
		}

		.q-combo-title,
		.q-combobox label {
			padding-left: 1.875rem;
		}

		textarea,
		.q-textarea {
			padding: 1.25rem 1.875rem;
		}

		.q-input,
		.q-form-dropdown {
			height: 3.75rem;
			padding-left: 1.875rem;
		}

		.q-combo-title {
			font-size: 1.25rem;
			line-height: 1.875rem;
		}

		.q-body2 {
			color: $grey;
			line-height: 1.25rem;
		}

		.postfix.button {
			height: 3.75rem;

			.q-inline-btn-label {
				line-height: 3.75rem;
			}
		}

		.q-inline-label {
			height: 3.75rem;
			padding: 1.25rem 0;
		}

		.q-btn-form {
			width: 75%;
		}

		.button.postfix {
			width: 25%;
		}
	}
}

@media #{$xlarge-up} {
	.q-form-page,
	.q-form {
		.q-btn-form {
			width: 88%;
		}

		.button.postfix {
			width: 12%;
		}
	}
}

@media #{$medium-down} {
	.q-form-input {
		.q-hack-margins {
			margin-bottom: 2.5rem;
		}
	}
}
