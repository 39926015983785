// Override styles from Foundation Reveal Modal
.reveal-modal {
	background-color: transparent;
	border: 0;
	max-height: 100%;
	max-width: 100%;
	min-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
	position: fixed;
	// This is needed to override Foundations Reveal Modal behavior to position correctly per styleguide
	top: 0 !important; // sass-lint:disable-line no-important
	width: 100%;

	.q-modal-title {
		@if $modal-use-title-extend {
			@extend %q-bold1;
		}
		text-align: $modal-title-text-align;
	}

	.q-modal-header-title {
		width: $modal-title-width;
	}

	.q-modal-header {
		@if $modal-use-header {
			align-items: center;
			background-color: $modal-header-background-color;
			display: flex;
			height: auto;
			justify-content: space-between;
			margin-top: 4rem;
			padding: 1rem;
			z-index: 1150;

			.q-modal-title {
				color: $modal-header-title-color;
			}
		}
		left: $modal-header-left;
		margin-left: $modal-header-margin-left;
		position: $modal-header-position;
		top: $modal-header-top;
		width: $modal-header-width;
	}

	.q-modal-container {
		height: 100%;
		position: relative;
		width: 100%;
	}

	.q-modal-content {
		background-color: $modal-content-background-color;
		left: $modal-content-left;
		margin-bottom: $modal-content-margin-bottom;
		margin-left: $modal-content-margin-left;
		min-height: $modal-content-min-height;
		position: $modal-content-position;
		top: $modal-content-top;
		width: $modal-content-width;
		z-index: $modal-content-z-index;
	}

	@if $modal-use-content-color {
		.q-modal-content * {
			color: $modal-content-color;
		}
	}

	.close-reveal-modal {
		line-height: $modal-close-line-height;
		opacity: 1;
		padding: 0;
		position: $modal-close-position;
		right: map-get($modal-close-right-map, small);
		text-align: center;
		text-decoration: none;
		top: map-get($modal-close-top-map, small);
		z-index: 1200;

		&:hover {
			color: $hover-light-color;
		}

		.q-modal-close-icon {
			@include insert-icon($modal-close-icon, map-get($modal-close-icon-height-map, small), map-get($modal-close-icon-height-map, small), $modal-close-insert-icon-use);
			@if $modal-close-icon-animation {
				@include hard-easeout(background, $anim-slow);
			}
		}
	}

	@if $modal-use-close-icon-hover {
		.close-reveal-modal img {
			&:hover,
			&:focus {
				@include insert-icon($modal-close-icon-hover, map-get($modal-close-icon-height-map, small), map-get($modal-close-icon-height-map, small), $modal-close-insert-icon-use);
				cursor: pointer;
				text-decoration: none;
			}
		}
	}

	// Video modal styles
	&.youtube,
	&.brightcove {
		height: 42.18vw;
		left: 0;
		margin: 0 auto;
		min-height: 0;
		overflow: visible;
		position: fixed;
		right: 0;
		// Videos have their own modal styling and this is to override .reveal-modal top: 0 !important property
		top: 50% !important; //sass-lint:disable-line no-important
		transform: translateY(-50%);
		width: 75%;

		.video-js {
			height: 100%;
			width: 100%;
		}

		.q-autosize {
			height: 100%;
			padding-bottom: 0;
		}

		.close-reveal-modal {
			height: $modal-close-icon-height;
			line-height: inherit;
			position: absolute;
			right: calc(#{$modal-close-icon-width} / -2);
			top: calc(#{$modal-close-icon-height} / -2);
			width: $modal-close-icon-height;

			.q-modal-close-icon {
				@include insert-icon($modal-close-video-icon, $svg-icon-large, $svg-icon-large);

				height: $modal-close-icon-height;
				width: $modal-close-icon-width;
			}

			&:hover {
				color: $grey-very-dark;
			}
		}
	}
}

// Media Queries
@media #{$medium-up} {
	.reveal-modal {
		.close-reveal-modal {
			right: map-get($modal-close-right-map, medium);
			top: map-get($modal-close-top-map, medium);

			.q-modal-close-icon {
				height: map-get($modal-close-icon-height-map, medium);
				width: map-get($modal-close-icon-width-map, medium);
			}
		}
	}
}

.reveal-modal-bg {
	background: $black;
	opacity: .85;
}

.utility-background {
	background: transparent;
	height: 100%;
	position: fixed;
	top: $modal-utility-background-top;
	width: calc(100% - 17px);
}
