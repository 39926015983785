.q-gallery-layer {
	.q-gallery-header {
		.q-dropdown {
			border-bottom: 1px solid $grey-mid;
			border-right: 1px solid $grey-mid;
			height: map-get($gl-header-height-map, small);
			min-width: 14.69rem;

			.q-category-title {
				@extend %q-headline1;
				display: inline-block;
				margin: .625rem .9375rem 0 0;
				padding: 0;
			}

			.svg-icon {
				background-color: $grey;
				height: 3.125rem;
				margin: 0;
				transition: background .25s;
				transition-timing-function: cubic-bezier(0, 1, .5, 1);
				width: 4.375rem;

				.q-gallery-carat-down,
				.q-gallery-carat-up {
					height: .75rem;
					position: absolute;
					top: calc(50% - 6px);
					transform: translateX(1.75rem);
					width: 4.375rem;
				}
			}

			&.open {
				.q-category-title {
					color: $gl-category-title-open-color;
				}

				.svg-icon {
					background-color: $grey-dark;

					.q-gallery-carat-up {
						@include insert-icon($gl-dropdown-carat-open, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
						height: .75rem;
						position: absolute;
						top: calc(50% - 6px);
						transform: translateX(-1.75rem) rotate(180deg);
						width: 4.375rem;
					}
				}
			}

			&:hover {
				.svg-icon {
					background-color: $grey-dark;

					.q-gallery-carat-down,
					.q-gallery-carat-up {
						height: .75rem;
						position: absolute;
						top: calc(50% - 6px);
						transform: translateX(1.75rem);
						width: 4.375rem;
					}
				}

				&.open {
					.svg-icon {
						background-color: $grey-dark;

						.q-gallery-carat-up {
							@include insert-icon($gl-dropdown-carat-open, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
							height: .75rem;
							position: absolute;
							top: calc(50% - 6px);
							transform: translateX(-1.75rem) rotate(180deg);
							width: 4.375rem;
						}
					}
				}
			}
		}

		.q-gallery-header-close {
			.q-gallery-close-icon {
				@include insert-icon($gl-close-icon, 1.25rem, 1.313rem, null, $gl-close-icon-hover);
			}
		}
	}

	.q-gallery-header-dropdown {
		.q-gallery-dropdown-item {
			background-color: $gl-dropdown-bg-color;
			border-bottom: 1px solid $grey-mid;
			content: ' ';
			float: left;
			font-weight: $font-weight-bold;
			padding: 1.75rem 1.875rem;
			width: 100%;

			.q-button-label {
				color: $grey-dark;
			}
		}
	}

	.q-gallery-container {
		.q-gallery-thumbnails {
			.q-gallery-headline {
				font-size: .9375rem;
				letter-spacing: .09em;
				line-height: 1.875rem;
				margin-bottom: 0;
			}
		}

		.q-layer {
			.q-layer-width {
				.q-text {
					color: $grey-dark;
					font-size: .875rem;
				}
			}
		}

		.q-multimedia-scroller {
			.slick-arrow {
				background-color: $black;
				opacity: 0;
				transform: translateY(-50%);
			}

			// Override for MMS Setting
			.slick-prev,
			.slick-next {
				margin-top: inherit;
			}

			.q-slider-item {
				&.slick-active {
					.q-responsive-image-disclosure {
						.q-responsive-image-disclosure-text {
							padding: $gl-disclosure-padding-small;
							right: $gl-disclosure-right-position-small;
						}
					}
				}
			}
		}
	}

	.q-gallery-thumbnail-scroller {
		.q-filmstrip {
			.slick-prev,
			.slick-next {
				&::before {
					margin-top: -50%;
					top: 50%;
				}
			}
		}
	}

	@media #{$medium-up} {
		.q-gallery-container {
			.q-multimedia-scroller {
				.q-slider-item {
					&.slick-active {
						.q-responsive-image-disclosure {
							.q-responsive-image-disclosure-text {
								padding: $gl-disclosure-padding;
								right: $gl-disclosure-right-position;
							}
						}
					}
				}
			}
		}
	}

	@media #{$large-up} {
		.q-gallery-container {
			.q-gallery-thumbnails {
				.q-gallery-headline {
					font-size: 1.25rem;
					line-height: 1.875rem;
				}
			}

			.q-multimedia-scroller {
				.slick-arrow {
					transform: translateY(-50%);
				}
			}
		}

		.q-gallery-header {
			.q-dropdown {
				height: map-get($gl-header-height-map, large);

				.q-category-title {
					display: inline-block;
					font-size: 2.063rem;
					line-height: 2.5rem;
					margin: .3125rem 1.438rem 0 0;
					padding: $gl-category-title-padding;
				}
			}

			.q-gallery-header-close {
				.q-gallery-close-icon {
					@include insert-icon($gl-close-icon, $gl-close-icon-size, $gl-close-icon-size, null, $gl-close-icon-hover);
				}
			}
		}

		.q-gallery-header-dropdown {
			.q-gallery-dropdown-item {
				background-color: $gl-dropdown-bg-color;
				border-bottom: 1px solid $grey-mid;
				content: ' ';
				float: left;
				padding: 1.75rem 1.875rem;

				.q-button-label {
					color: $grey-dark;
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-dropdown {
			height: map-get($gl-header-height-map, xlarge);
		}

		.q-gallery-container {
			.q-layer {
				.q-layer-width {
					.q-text {
						color: $grey;
					}
				}
			}
		}
	}

	// Touch Styles
	.no-touch & {
		.q-gallery-header {
			.q-dropdown {
				&:hover {
					.svg-icon {
						background-color: $grey-dark;

						.q-gallery-carat-down,
						.q-gallery-carat-up {
							height: .75rem;
							position: absolute;
							top: calc(50% - 6px);
							transform: translateX(1.75rem);
							width: 4.375rem;
						}
					}

					&.open {
						.svg-icon {
							background-color: $grey-dark;

							.q-gallery-carat-up {
								@include insert-icon($gl-dropdown-carat-open, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
								height: .75rem;
								position: absolute;
								top: calc(50% - 6px);
								transform: translateX(-1.75rem) rotate(180deg);
								width: 4.375rem;
							}
						}
					}
				}
			}
		}

		.q-gallery-header-dropdown {
			.q-gallery-dropdown-item {
				&:hover {
					background-color: $gl-dropdown-hover-color;
					border-left: 3px solid $brand-primary-color;
					padding-left: 1.688rem;
				}

				&.active {
					&:hover {
						padding-left: 1.875rem;
					}
				}
			}
		}

		.q-multimedia-scroller {
			&:hover {
				.slick-arrow {
					background-color: $black;
					opacity: $gl-mms-hover-slider-arrow-opacity;

					&:hover {
						opacity: $gl-slider-arrow-opacity;
					}
				}
			}
		}
	}
}
