.gmit-gf-request-type {
	// overriding typography classes since Chevy does not have any applicable styles for this text
	.gmit-gf-paragraph {
		p,
		.gmit-gf-paragraph-label-bold {
			color: $grey-dark;
			font-family: $font-family-primary;
			font-size: .875rem;
			line-height: 1.14;
		}

		.gmit-gf-paragraph-label-bold {
			font-size: 1rem;
			font-weight: $font-weight-bold;
		}
	}

	// overriding dealer selector dealer count font label
	.gmit-gf-dealer-selector-v2 {
		.gmit-gf-search-result {
			.gmit-gf-search-result-headline {
				font-size: 1rem;
				margin-bottom: 0;
			}
		}
	}

	// overriding vehicle name, model name, etc font size coming from typography changes in solar
	.gmit-gf-carline-detail-wrapper {
		.gmit-gf-carline-headline,
		.gmit-gf-bodystyle-headline,
		.gmit-gf-pricing-headline,
		.gmit-gf-trim-headline,
		.gmit-gf-trim-description,
		.gmit-gf-co2-headline,
		.gmit-gf-model-year-headline {
			color: $grey-dark;
			font-family: $font-family-sans;
			font-size: 1rem;
			font-weight: $font-weight-bold;
			line-height: 1.31;
		}
	}
}
