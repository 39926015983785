// Anchor Navigation Component (nav_anchor)
//
// markup:
// <div class="q-nav-anchor">
// 		<div class="q-nav-container">
// 			<button id="nav_anchor" class="q-button q-list-item q-list-item-secondary">
// 				<span>2016 Spark</span>
// 			</button>
// 			<div class="q-dropdown-box>
// 				<div class="q-js-nav-anchor> <!-- ANCHORS -->
// 					<ul class="q-dropdown-list no-bullet">
// 						<li data-index="0">
// 							<button class="q-button q-list-item q-list-item-secondary q-button-padding>
// 								<a class="q-list-link" title="Introduction">
// 									<span>Introduction</span>
// 								</a>
// 							</button>
// 						</li>
// 					</ul>
// 				</div>
// 				<div> <!-- CTA -->
// 					<ul class="q-anchor-cta-list hide-for-large-up q-dropdown-list">
// 						<li class="q-cta-list-item">
// 							<a class="q-list-link">
// 								<button class="q-button q-primary-button">
// 									<span>Build & Price</span>
// 								</button>
// 							</a>
// 						</li>
// 					</ul>
// 				</div>
// 				<div> <!-- SIBLING NAV -->
// 					<div class="hide-for-<some breakpoint>-up q-descriptive2 q-invert q-section-header-subtitle q-caps">
// 						Explore Spark
// 					</div>
// 					<ul class="hide-for-<some breakpoint>-up q-scroller-list>
// 						<li data-index="0">
// 							<a>
// 								<button class="q-button q-list-item q-list-item-secondary q-button-padding>
// 									<span>Overview</span>
// 								</button>
// 							</a>
// 						</li>
// 					</ul>
// 				</div>
// 				<div> <!-- SUGGESTED OTHER MODELS -->
// 					<div class="q-suggested-models-container">
// 						<div class="q-descriptive2 q-invert q-section-header-subtitle q-caps">OTHER MODELS</div>
// 						<ul>
// 							<li>
// 								<div class="q-suggested-models-item">
// 									<!-- VEHICLE INFO TILE MARKUP -->
// 								</div>
// 							</li>
// 						</ul>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// </div>
// Styleguide 8.1

// This is namespaced to prevent collision
$nav-secondary-list-item-font-family: $font-family-secondary !default;
$nav-secondary-nav-button-font-family: $font-family-primary !default;

.q-nav-anchor {
	background-color: $nav-secondary-anchor-bg-color;
	border-bottom: $nav-secondary-nav-anchor-border-bottom;
	border-top: $nav-secondary-nav-anchor-border-top;
	display: block;
	position: relative;
	width: 100%;
	z-index: 110;

	.sticky-child {
		transition: top #{$anim-fast}s ease;
	}

	.q-anchor-nav-sticky.top {
		.sticky-child {
			background-color: $nav-secondary-anchor-sticky-top-color;
			border-bottom: $nav-secondary-anchor-nav-sticky-border-bottom;
			border-top: $nav-secondary-anchor-nav-sticky-border-top;
			margin: 0 auto;
			transform: translateZ(0);
			width: 100%;
			z-index: 110;
		}
	}

	.q-sticky.top {
		.sticky-child {
			background-color: $nav-secondary-anchor-sticky-top-color;
			border-bottom: $nav-secondary-anchor-nav-sticky-border-bottom;
			border-top: $nav-secondary-anchor-nav-sticky-border-top;
			z-index: 110;
		}
	}

	.q-overlay {
		background-color: $nav-secondary-overlay-bg-color;
		height: 100%;
		left: 0;
		opacity: .75;
		position: fixed;
		top: 0;
		width: 100%;
	}

	.q-nav-container {
		align-items: center;
		display: flex;
		margin: 0 auto;
		max-width: $gridwidth-max;
		min-height: 4.375rem;
		position: relative;

		.hide-cta-for-specific-breakpoint-up {
			display: block;
		}

		.q-sibling-nav-container {
			.q-sibling-nav-list {
				.show-cta-for-specific-breakpoint-up {
					display: none;
				}

				span {
					font-size: map-get($nav-secondary-sibling-font-size-map, small);
				}
			}
		}

		&>.q-secondary-nav-button {
			@include q-headline-values($nav-secondary-headline-color, normal, $nav-secondary-button-font-weight, null, null, 0, map-get($nav-secondary-button-font-size-map, mobile), 2.5rem, map-get($nav-secondary-button-font-size-map, tablet), 2.5rem, map-get($nav-secondary-button-font-size-map, desktop), 2.5rem, $nav-secondary-nav-button-font-family, $brand-use-underline, false, $baseline-body-ratio-primary);
			background-color: transparent;
			border: transparent;
			cursor: default;
			display: inline-block;
			height: 100%;
			outline: inherit;
			padding: $nav-secondary-nav-button-padding;
			position: relative;
			text-align: left;
			width: 100%;
			z-index: 2;

			span {
				display: inline-block;
				position: relative;

				&::after {
					/*	optional is used to prevent chevy which does
						not need this variables from freaking out.
					*/
					@extend %nav-secondary-subtitle-after !optional;
				}

				p {
					font-size: 1.875rem;
				}
			}
		}

		.q-dropdown-box {
			background-color: $nav-secondary-dropdown-box-bg-color;
			position: absolute;
			width: 100%;
			z-index: 2;

			.q-list-item {
				font-size: $nav-secondary-anchor-list-item-font-size;
			}

			&.q-overlay-scroll-big {
				max-height: 100%;
				max-height: calc(100vh - 90px);
				overflow-y: auto;
			}

			&.q-overlay-scroll-small {
				max-height: 100%;
				max-height: calc(100vh - 160px);
				overflow-y: auto;
			}

			.q-sibling-medium {
				background-color: $nav-secondary-sibling-medium-bg-color;
			}
		}

		.q-dropdown-list {
			background-color: $nav-secondary-dropdown-has-content-bg-color;
			width: 100%;

			li:not(.q-cta-list-item) {
				&:first-child {
					a {
						border-top: $nav-secondary-cta-list-item-first-child;
					}
				}
			}
		}

		.q-anchor-cta-list {
			li {
				padding: 4%;
			}
		}

		.q-section-header-subtitle {
			@include q-headline-values($brand-headline-color, inherit, $nav-secondary-section-header-subtitle-font-weight, null, null, .09em, map-get($nav-secondary-section-header-subtitle-font-size-map, mobile), map-get($nav-secondary-section-header-subtitle-line-height-map, mobile), map-get($nav-secondary-section-header-subtitle-font-size-map, tablet), map-get($nav-secondary-section-header-subtitle-line-height-map, tablet), map-get($nav-secondary-section-header-subtitle-font-size-map, desktop), map-get($nav-secondary-section-header-subtitle-line-height-map, desktop), $nav-secondary-section-header-subtitle-font-family, $brand-use-underline, false, $baseline-body-ratio-primary);

			background-color: $nav-secondary-section-header-bg-color;
			border-bottom: $nav-secondary-section-header-border-bottom;
			padding: 3%;
			text-align: $nav-secondary-section-header-subtitle-text-align;

			&.q-invert {
				color: $nav-secondary-section-header-subtitle-invert-text-color;
			}
		}

		.q-suggested-models-container {
			background-color: $nav-secondary-dropdown-box-bg-color;
		}
	}

	ul {
		margin-bottom: 0;
		margin-left: 0;
	}

	.q-button {
		&.q-cta-button {
			@include button-border($nav-secondary-cta-hover-border-color, $color: $nav-secondary-cta-color);
			@include button-hover($nav-secondary-cta-hover-border-color, $nav-secondary-cta-border-fill, $fill-color: $nav-secondary-cta-fill-color, $hover-color: $button-secondary-invert-hover-color);
		}
	}

	.q-year-toggle {
		display: inline-block;
		float: left;

		.q-year-toggle-list {
			list-style-type: none;
		}
	}

	@media #{$medium-up} {
		&.sticky-child {
			margin: 0 auto;
			width: 100%;
		}

		.q-nav-container {
			left: 0;
			margin-top: 0;
			max-width: $gridwidth-max;

			&>.q-secondary-nav-button {
				letter-spacing: .02em;
				width: map-get($nav-secondary-nav-title-width-map, medium);
			}

			.q-dropdown-box {
				height: auto;
				width: map-get($nav-secondary-dropdown-box-width-map, medium);
			}

			.q-sibling-nav-container {
				background-color: $nav-secondary-sibling-container-bg-color;
				display: inline;
				height: 100%;
				z-index: 2;

				.q-sibling-nav-list {
					background-color: $nav-secondary-sibling-list-bg-color;
					float: right;
					height: 100%;

					.q-cta-list-item {
						float: right;
						margin-left: 0;

						button {
							padding-left: $nav-secondary-sibling-cta-padding-left;
							padding-right: $nav-secondary-sibling-cta-padding-right;
						}
					}
				}
			}
		}
	}

	@media #{$large-up} {
		.q-nav-container {
			&>.q-secondary-nav-button {
				letter-spacing: .02em;
				width: map-get($nav-secondary-nav-title-width-map, large);

				&.q-nav-button-with-year-toggle {
					width: map-get($nav-secondary-nav-title-year-toggle-width-map, large);
				};
			}

			.hide-cta-for-specific-breakpoint-up {
				display: none;
			}

			.q-dropdown-box {
				height: auto;
				width: map-get($nav-secondary-dropdown-box-width-map, large);
			}

			.q-sibling-nav-container {
				// TODO Can remove after all brands refactor to support flex in secondary nav
				margin-left: auto;

				.q-sibling-nav-list {

					.show-cta-for-specific-breakpoint-up {
						display: block;
					}

					li {
						margin: 0 1.563rem 0 0;
					}

					li:not(.q-cta-list-item) {
						font-family: $nav-secondary-list-item-font-family;
						font-size: $nav-secondary-cta-list-item-font-size;
						font-weight: $nav-secondary-cta-list-item-font-weight;
						line-height: 1.25rem;

						a {
							color: $nav-secondary-list-anchor-color;
							padding-bottom: 1.125rem;
							padding-top: $nav-secondary-sibling-large-cta-anchor-padding-top;
							position: relative;
							text-align: center;

							&:hover {
								color: $nav-secondary-list-anchor-hover-color;

								&:hover::after {
									left: 0;
									width: 100%;
								}
							}

							&::after {
								border-bottom: .1875rem solid $nav-secondary-list-anchor-after-border-color;
								bottom: $nav-secondary-list-anchor-after-bottom-position;
								content: '';
								height: 0;
								left: 50%;
								position: absolute;
								right: 0;
								width: 0;
							}
						}

						.q-sibling-nav-active {
							&::after {
								left: 0;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}


	@media #{$xlarge-up} {
		.q-sticky.top {
			.sticky-child {
				left: 0;
				margin: 0 auto;
				width: 100%;
			}
		}

		.q-nav-container {
			.q-sibling-nav-container {
				.q-sibling-nav-list {
					span {
						font-size: map-get($nav-secondary-sibling-font-size-map, xlarge);
					}
				}
			}

			&>.q-secondary-nav-button {
				width: map-get($nav-secondary-nav-title-width-map, xlarge);

				&.q-nav-button-with-year-toggle {
					width: map-get($nav-secondary-nav-title-year-toggle-width-map, xlarge);
				};
			}

			.q-dropdown-box {
				width: map-get($nav-secondary-dropdown-box-width-map, xlarge);
			}

			.q-sibling-nav-container {
				width: 65%;
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-nav-container {
			.q-sibling-nav-container {
				.q-sibling-nav-list {
					span {
						font-size: map-get($nav-secondary-sibling-font-size-map, xxlarge);
					}
				}
			}
		}
	}
}
