.q-engine-information {
	/*
		Component specific styles for the 'engine Information' component
	*/
	align-items: center;
	border-bottom: $engine-information-border-bottom;
	display: flex; // both img and text need to be vertically centered. So Flex is being used
	padding: 1rem;
	position: relative;

	&::before {
		@extend %transition-opacity;

		border-left: $engine-information-border-width solid $engine-border-color-hover;
		content: '';
		display: block; // Override Foundation display: table
		height: 100%;
		left : 0;
		opacity: 0;
		position: absolute;
	}

	&:hover {
		&::before {
			opacity: $engine-information-opacity-hover;
		}
	}

	.q-engine-info-image-container {
		width: 40%;

	}

	.q-engine-information-details {
		padding: 1rem 0 1rem 1rem;
		width: 60%;

		.q-engine-title {
			@extend %#{$engine-information-headline-style}, %q-caps;
			color: $engine-information-title-color;
		}

		.q-engine-information-attribute {
			@extend %#{$engine-information-attribute-text-style};

			margin-bottom: 0;
		}

		.q-engine-info-text-price {
			.q-from-label {
				@extend %#{$engine-information-price-label-extend};
				color: $engine-information-price-from-color;
				font-weight: $engine-information-price-label-font-weight;
				line-height: $engine-information-price-label-line-height;
				margin-top: 0;

				p {
					color: inherit;
					display: inline;
					font-weight: inherit;
				}
			}
		}
	}
	@media #{$large-up} {
		border-bottom: 0;
		display: block;
		padding-top: 0;

		&::before {
			border-left: 0;
		}

		&::after {
			border-bottom: $engine-information-border-width solid $engine-border-color-hover;
			bottom: 0;
			content: '';
			display: block;
			left: 50%;
			position: absolute;
			transition: $engine-information-transistion;
			width: 0;
		}

		&:hover {
			&::after {
				left: 0;
				width: 100%;
			}

			.q-engine-information-details {
				.q-engine-title {
					color: $engine-information-title-hover-color;
				}
			}
		}

		.q-engine-info-image-container {
			width: 100%;
		}

		.q-engine-information-details {
			width: 100%;
		}
	}
}
