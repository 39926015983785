%bc-play-icon {
	// This is were the transition is applied, no need for another.
	@include gmwp-transition(all, $anim-fast, ease-out);

	background-image: get-element(video-play-button);
	background-repeat: no-repeat;
	background-size: 100%;
	content: '';
	height: $video-bc-play-icon-height;
	left: 50%;
	opacity: $video-bc-play-icon-opacity;
	// This must be the same value as icon width, if the icon changes on hover.
	// And assuming the icon is square.
	padding-bottom: $video-bc-play-icon-padding-bottom;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%) scale(1);
	width: $video-bc-play-icon-width;
}

.q-video-brightcove {
	.video-overlay {
		cursor: pointer;
	}

	.q-autosize {
		cursor: pointer;
		padding-bottom: $video-bc-aspect-ratio;
		position: relative;

		&.q-posterframe-active {
			background: $black;

			&.q-has-posterframe {
				padding-bottom: 0;

				.q-posterframe {
					position: relative;
				}
			}

			.q-posterframe {
				display: block;

				&.q-title-length-shown {
					&::after {
						// Fallback for browsers that can't do calc, cough cough IE 10
						top: 50%;
						top: calc(50% - #{$video-bc-info-height} / 2);
					}

					.video-info-container {
						background: $video-bc-info-background;
						bottom: 0;
						padding: $video-bc-info-padding;
						position: absolute;

						.video-info-title {
							color: $video-bc-title-color;
						}

						.video-info-time {
							color: $video-bc-time-color;
						}
					}
				}
			}

			.video-js {
				display: none;
			}
		}
	}

	.q-posterframe {
		background: $black center no-repeat;
		background-size: cover;
		display: none;
		height: 100%;
		position: absolute;
		width: 100%;

		&::after {
			@extend %bc-play-icon;
		}

		&:hover {
			&::after {
				opacity: $video-bc-play-icon-hover-opacity;
				// Using scale to transform the icon on hover instead of using width
				transform: translate(-50%, -50%) scale($video-bc-play-icon-hover-scale);
			}
		}
	}

	.video-info-container {
		height: $video-bc-info-height;
		padding: $video-bc-info-inline-padding;
		width: 100%;
	}

	.video-info-title,
	.video-info-time {
		@extend %#{$video-bc-title-typography};
	}

	.video-info-title {
		font-weight: $video-bc-title-font-weight;
		letter-spacing: $video-bc-title-letter-spacing;
		padding-right: $video-bc-title-padding;
		text-transform: $video-bc-title-text-transform;
	}

	.video-js {
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;

		.bc-player-default_default { // sass-lint:disable-line class-name-format
			background-color: transparent;

			.vjs-control-bar {
				border-top: 0;
			}
		}

		.vjs-play-progress,
		.vjs-volume-level {
			background-color: $brand-primary-color;
			background-image: none;
		}

		.vjs-load-progress {
			background-color: $white;
		}

		.vjs-volume-bar.vjs-slider.vjs-slider-vertical {
			&::before {
				background-color: $white;
				box-shadow: none;
			}
		}

		.vjs-control.vjs-close-button,
		.vjs-share-control,
		.vjs-captions-button,
		.vjs-fullscreen-control,
		.vjs-play-control {
			background-color: transparent;
		}

		&.brightcove-player-video {
			.vjs-big-play-button {
				@extend %bc-play-icon;

				background-color: transparent;

				&::before {
					display: none;
				}

				&:hover {
					box-shadow: none;
					opacity: $video-bc-play-icon-hover-opacity;
					// Using scale to transform the icon on hover instead of using width
					transform: translate(-50%, -50%) scale($video-bc-play-icon-hover-scale);
				}
			}
		}
	}
}
