// Disclaimer Global

.q-disclaimer-global {
	// Mobile First SCSS goes here

	.disclaimer-content {
		margin-top: 1.25rem;

		p {
			color: $disclosure-content-color;
		}
	}
}

.q-disclaimer-footer {
	background: $footer-background-color;
	padding-bottom: $footer-padding-bottom;
	padding-top: $footer-padding-top;
}
