// chevrolet specific icons
//
// .icon-arrow-left - Arrow Left icon
// .icon-arrow-right - Arrow Right icon
// .icon-arrow-up - Arrow Up icon
// .icon-arrow-down - Arrow Down icon
// .icon-book - Book icon
// .icon-car - Car icon
// .icon-close - Close icon
// .icon-envelope - Envelope icon
// .icon-home - Home icon
// .icon-location - Location icon
// .icon-minus - Minus symbol icon
// .icon-plus - Plus symbol icon
// .icon-request-quote - "Request A Quote" icon
// .icon-search - Search icon
// .icon-slider-next - Next arrow for slider
// .icon-slider-prev - Previous arrow for slider
// .icon-steering-wheel - Steering wheel icon
// .icon-zoom-in - Zoom In icon
// .icon-zoom-out - Zoom Out icon
//
// markup:
// <i class="icon {$modifiers}"></i>
//
// Styleguide 13.1

// chevrolet headers with icons
//
// markup:
// <h1><i class="icon icon-car"></i>Header Text</h1>
// <h2><i class="icon icon-car"></i>Header Text</h2>
// <h3><i class="icon icon-car"></i>Header Text</h3>
// <h4><i class="icon icon-car"></i>Header Text</h4>
// <h5><i class="icon icon-car"></i>Header Text</h5>
// <h6><i class="icon icon-car"></i>Header Text</h6>
//
// Styleguide 13.2

//---------------------------------------------------------------
// Icons that shouldn't be displayed to an author in the dialog
$icons-small-extend: (
	arrow-left: '\e6ea',
	arrow-right: '\e6b0',
	back-to-top: '\e60f',
	checkbox-filled-circle: '\e62b',
	checkbox-filled-square: '\e62f',
	collapser: '\e63c',
	dropdown-close: '\e65c',
	dropdown-open: '\e65e',
	expander: '\e66e',
	option-available: '\e6be',
	option-not-available: '\e6c0',
	option-standard: '\e6c3',
	quotes: '\0201C""\0201D""\02018""\02019',
	next-element: '\e6b0',
	page-up: '\e6ca',
	player-controls-backward: '\e6d2',
	player-controls-extend-window: '\e6d4',
	player-controls-fast-backward: '\e6d6',
	player-controls-fast-forward: '\e6d8',
	player-controls-forward: '\e6da',
	player-controls-minimize-window: '\e6dc',
	player-controls-pause: '\e6de',
	player-controls-play: '\e6e0',
	player-controls-sound-off: '\e6e2',
	player-controls-sound-on: '\e6e3',
	player-controls-start: '\e6e6',
	player-controls-stop: '\e6e8',
	previous-element: '\e6ea',
	share: '\e718',
	top-image-slider: '\e77a',
	triangle-up: '\025b2'
);

$icons-large-extend: (
	arrow-left: '\e6e9',
	arrow-right: '\e6ae',
	back-to-top: '\e60e',
	checkbox-filled-circle: '\e628',
	checkbox-filled-square: '\e62d',
	collapser: '\e63b',
	dropdown-close: '\e65b',
	dropdown-open: '\e65d',
	expander: '\e66d',
	option-available: '\e6bd',
	option-not-available: '\e6bf',
	option-standard: '\e6c1',
	quotes: '\0201C""\0201D""\02018""\02019',
	next-element: '\e6ae',
	page-up: '\e6c9',
	player-controls-backward: '\e6d1',
	player-controls-extend-window: '\e6d3',
	player-controls-fast-backward: '\e6d5',
	player-controls-fast-forward: '\e6d7',
	player-controls-forward: '\e6d9',
	player-controls-minimize-window: '\e6db',
	player-controls-pause: '\e6dd',
	player-controls-play: '\e6df',
	player-controls-sound-off: '\e6e1',
	player-controls-sound-on: '\e6e3',
	player-controls-start: '\e6e7',
	player-controls-stop: '\e6e8',
	previous-element: '\e6e9',
	share: '\e717',
	top-image-slider: '\e779',
	triangle-up: '\025b2'
);

$icons-large: map-merge($icons-large, $icons-large-extend);
$icons-small: map-merge($icons-small, $icons-small-extend);

@mixin icon-social($icon-name) {
	.icon-#{$icon-name} {
		height: $share-svg-size;
		width: $share-svg-size;

		&::after {
			content: get-element($icon-name);
		}
	}
}

@include generate-icons;

.icon-arrow-down {
	@include icon(dropdown-open);
}

.icon-arrow-up {
	@include icon(dropdown-close);
}

.icon-checkbox-tick-circle {
	@include icon('checkbox-filled-circle') {
		color: $brand-primary-color;
	}

	@include icon('checkmark', 'after') {
		@include icon-stack;
	};

	@media #{$medium-up} {
		@include icon('tick', 'after') {
			@include icon-stack(.3125rem, .3125rem);
		}
	}
}

.icon-checkbox-tick-square {
	@include icon('checkbox-filled-square') {
		color: $brand-primary-color;
	}

	@include icon('checkmark', 'after') {
		@include icon-stack;
	};

	@media #{$medium-up} {
		@include icon('tick', 'after') {
			@include icon-stack(.1875rem, -.1875rem);
		}
	}
}

.icon-disclaimer {
	@include icon('checkbox-square') {
		@include icon-stack;

		font-size: 2rem;
	}

	@include icon('info', 'after') {
		font-size: 1.25rem;
	}

	font-size: 1.375rem;
	padding: .3125rem;
}

.icon-email-share {
	@include icon('email-share', 'after') {
		@include icon-stack;
	}

	&::before {
		color: $brand-primary-color;
		content: '\e666';
	}
}

.icon-gallery {
	@include icon(zoom) {
		font-size: 1.5rem;
		padding: .25rem;
	}
}

.icon-minus {
	@include icon(collapser);
}

.icon-option-standard {
	&::before {
		color: $brand-primary-color;
	}
}

.icon-phone {
	@include icon(contact);
}

.icon-player-controls-start-highlight {
	@include icon('player-controls-start') {
		color: $brand-primary-color;
	}
}

.icon-plus {
	@include icon(expander);
}

.icon-slider-next {
	@include icon(next-element);
}

.icon-slider-prev {
	@include icon(previous-element);
}

@include icon-social('social-media-email');
@include icon-social('social-media-facebook');
@include icon-social('social-media-google-plus');
@include icon-social('social-media-instagram');
@include icon-social('social-media-pinterest');
@include icon-social('social-media-twitter');
// @include icon-social('social-media-whatsapp');
@include icon-social('social-media-youtube');

//----------- END ICONS ---------------------

// Header icons
// - - - - - - - - - - - - - - - - - - - - - - - - -
h1 > .icon,
h2 > .icon,
h3 > .icon,
h4 > .icon,
h5 > .icon,
h6 > .icon {
	margin-right: .5rem;
	vertical-align: middle;
}

h1 > .icon {
	font-size: $h1-font-size - $h1-font-reduction;
	line-height: $h1-font-size - $h1-font-reduction;
}

h2 > .icon {
	font-size: $h2-font-size - $h2-font-reduction;
	line-height: $h2-font-size - $h2-font-reduction;
}

h3 > .icon {
	font-size: $h3-font-size - $h3-font-reduction;
	line-height: $h3-font-size - $h3-font-reduction;
}

h4 > .icon {
	font-size: $h4-font-size - $h4-font-reduction;
	line-height: $h4-font-size - $h4-font-reduction;
}

h5 > .icon {
	font-size: $h5-font-size - $h5-font-reduction;
	line-height: $h5-font-size - $h5-font-reduction;
}

h6 > .icon {
	font-size: $h6-font-size - $h6-font-reduction;
	line-height: $h6-font-size - $h6-font-reduction;
}

// Media Queries
// - - - - - - - - - - - - - - - - - - - - - - - - -
@media #{$medium-up} {
	h1 > .icon {

		font-size: $h1-font-size;
		line-height: $h1-font-size;
	}

	h2 > .icon {

		font-size: $h2-font-size;
		line-height: $h2-font-size;
	}

	h3 > .icon {

		font-size: $h3-font-size;
		line-height: $h3-font-size;
	}

	h4 > .icon {

		font-size: $h4-font-size;
		line-height: $h4-font-size;
	}

	h5 > .icon {

		font-size: $h5-font-size;
		line-height: $h5-font-size;
	}

	h6 > .icon {

		font-size: $h6-font-size;
		line-height: $h6-font-size;
	}
}
