// Allows the use of rem-calc() or lower-bound() in your variables & settings
@import 'foundation/functions';

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @url https://css-tricks.com/snippets/sass/str-replace-function/
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/* Flex Mixin */
@mixin flexit($values) {
	flex: $values;
}

@mixin flex-order($order) {
	order: $order;
}
