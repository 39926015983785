// Colorizer
.q-colorizer {
	.q-colorizer-tabs {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		//Brand level usage
		@if $colorizer-use-select {
			.q-colorizer-select {
				border-radius: 50%;
				height: 1.875rem;
				width: 1.875rem;
			}
		}

		.tab-title {
			//Brand level usage
			@if $colorizer-use-li {
				margin: .5rem .125rem;
				padding: .375rem;
			}

			.q-colorizer-panel {
				border: 0;
				cursor: default;
				outline: none;
				padding: $colorizer-panel-padding;
			}

			&.active {
				.q-colorizer-panel {
					&::before {
						background-image: $colorizer-checkmark-image;
						background-repeat: no-repeat;
						background-size: 100%;
						content: $colorizer-checkmark-content;
						cursor: pointer;
						display: $colorizer-chip-background-checkmark-display;
						height: $colorizer-panel-active-before-height;
						left: 50%;
						margin-left: -.625rem;
						margin-top: $colorizer-panel-active-before-margin-top;
						position: absolute;
						top: 50%;
						width: $colorizer-panel-active-before-width;
						z-index: 99;
					}
				}

				.q-colorizer-chip {
					border: $colorizer-chip-active-border;
					border-radius: 50%;
					content: ' ';
					display: $colorizer-chip-active-display;
					height: $colorizer-chip-active-height;
					margin: $colorizer-chip-active-margin;
					padding: $colorizer-chip-active-padding;
					width: $colorizer-chip-active-width;

					@if $colorizer-chip-active-use-select {
						.q-colorizer-select {
							border: $colorizer-chip-active-select-border;
							box-shadow: 0 0 0 $white;
							height: 4.6rem;
							width: 4.6rem;
						}
					}

					.q-colorizer-checkmark {
						bottom: 0;
						display: $colorizer-chip-html-checkmark-display;
						height: 22%;
						left: 0;
						margin: auto;
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}
		}

		.q-colorizer-chip {
			border-radius: $colorizer-chip-border-radius;
			display: inline-block;
			float: left;
			height: $colorizer-chip-height;
			margin: $colorizer-chip-margin;
			padding: $colorizer-chip-padding;
			position: relative;
			transition: $colorizer-chip-transition;
			width: $colorizer-chip-width;

			&:hover {
				border: $colorizer-chip-hover-border;
				border-radius: 50%;
				content: ' ';
				cursor: $colorizer-chip-hover-cursor;
				height: $colorizer-chip-hover-height;
				margin: $colorizer-chip-active-margin;
				padding: $colorizer-chip-active-padding;
				width: $colorizer-chip-hover-width;
			}

			@if $colorizer-chip-use-select {
				.q-colorizer-select {
					border-radius: 50%;
					box-shadow: $colorizer-chip-select-box-shadow;
					display: flex;
					height: 3rem;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: width .3s ease, height .3s ease;
					width: 3rem;

					&:hover {
						border: 2px solid $colorizer-chip-border-color;
						box-shadow: 0 0 0 $white;
						cursor: pointer;
						height: 4.6rem;
						width: 4.6rem;
					}

				}
			}

			.q-colorizer-checkmark {
				display: none;
			}

			.q-tooltip-text {
				background-color: $colorizer-tooltip-background-color;
				border: 2px solid $colorizer-tooltip-border-color;
				border-radius: 2px;
				bottom: $colorizer-chip-tooltip-bottom;
				color: $colorizer-tooltip-color;
				font-size: $colorizer-chip-tooltip-font-size;
				left: 50%;
				line-height: $colorizer-chip-tooltip-line-height;
				padding: $colorizer-chip-tooltip-padding;
				position: absolute;
				text-align: center;
				transform: translateX(-50%);
				visibility: hidden;
				width: 12.5rem;

				&::before {
					border-left: 12px solid transparent;
					border-right: 12px solid transparent;
					border-top: 12px solid $colorizer-tooltip-border-color;
				}

				&::after {
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid $white;
				}

				&::before,
				&::after {
					content: '';
					height: 0;
					left: 50%;
					position: absolute;
					top: 100%;
					transform: translateX(-50%);
					width: 0;
				}
			}
		}

		.tabs-content {
			margin-bottom: 0;

			.q-colorizer-content {
				display: block;
				height: auto;
				margin: 0 auto;
				max-width: 100%;
			}
		}
	}

	.q-colorizer-content,
	.q-colorizer-text-indicator,
	.q-colorizer-button {
		margin: 1.25rem 0;
	}

	.q-colorizer-info {
		display: flex;
		flex-direction: column;
		flex-flow: wrap;

		.q-colorizer-chipsets {
			order: 2;
			width: 100%;
		}

		.q-colorizer-text-container {
			align-self: center;
			order: 1;
			text-align: center;
			width: 100%;
		}

		.q-colorizer-button {
			order: 3;
			width: 100%;
		}
	}


	.q-tab-nav {
		div {
			border: 0;
		}
	}

	.q-colorizer-text {
		@extend %q-descriptive1;
		font-size: $colorizer-text-font-size;
		line-height: $colorizer-text-line-height;
	}

	// Media Queries go here

	@media #{$large-up} {
		.q-colorizer-chip {
			&:hover {
				.q-tooltip-text {
					visibility: visible;
				}
			}
		}

		//Brand level usage
		@if $colorizer-use-li {
			.tab-title {
				margin: .5rem;
			}
		}

		.tabs-content {
			.q-colorizer-content {
				width: 200%;
			}
		}

		.q-colorizer-info {
			flex-flow: wrap;

			.q-colorizer-chipsets {
				order: 1;
			}

			.q-colorizer-text-container {
				order: 2;
				text-align: left;
				width: 50%;
			}

			.q-colorizer-button {
				order: 3;
				width: 50%;
			}
		}

		@if $colorizer-large-use-tabs {
			.q-colorizer-tabs {
				.tab-title {
					&.active {
						.q-colorizer-panel {
							&::before {
								height: 1.6rem;
								margin-left: -.625rem;
								margin-top: -.375rem;
								width: 1.6rem;
							}
						}

						.q-colorizer-chip {
							height: 5.6rem;
							padding: .0625rem 0 0 .0625rem;
							width: 5.6rem;

							.q-colorizer-select {
								height: $colorizer-chip-select-hover-height;
								width: $colorizer-chip-select-hover-width;
							}
						}
					}
				}

				.q-colorizer-chip {
					height: 5.6rem;
					padding: .375rem;
					width: 5.6rem;

					&:hover {
						height: 5.6rem;
						padding: .0625rem 0 0 .0625rem;
						width: 5.6rem;
					}

					.q-colorizer-select {
						box-shadow: $colorizer-chip-select-box-shadow-large;
						height: 4.5rem;
						width: 4.5rem;

						&:hover {
							height: $colorizer-chip-select-hover-height;
							width: $colorizer-chip-select-hover-width;
						}

					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.tabs-content {
			.q-colorizer-content {
				width: 100%;
			}
		}

		.q-colorizer-tabs {
			@if $colorizer-xlarge-use-tabs {
				.q-colorizer-chip,
				.tab-title.active .q-colorizer-chip,
				.tab-title .q-colorizer-chip:hover {
					height: 2rem;
					width: 2rem;
				}
			}

			.q-colorizer-chip {
				margin: $colorizer-xlarge-chip-margin;
				padding: $colorizer-xlarge-chip-padding;
			}

			.tab-title {
				&.active {
					.q-colorizer-chip {
						padding: $colorizer-xlarge-active-chip-padding;
					}
				}

				.q-colorizer-chip {
					&:hover {
						padding: $colorizer-xlarge-hover-chip-padding;
					}
				}
			}

			@if $colorizer-xlarge-use-select {
				.q-colorizer-select {
					height: 1.25rem;
					max-width: 100%;
					width: 1.25rem;
				}
			}
		}
	}
}
