@import '../../solar-aem-ui/scss/global';

// Color classes for RTE Text -- DO NOT USE ANYWHERE ELSE
.q-text-color-gold {
	color: $gold;
}

.q-text-color-white {
	color: $white;
}

// Per the Chevy styleguide, at XS, the XL bottom margin goes from 120px to 80px
// http://prototypes.mrm-projects.com/quantum/styleguide.html#!%2Fpage%3D%22sg_grid_layout%22
.row.gridspacing-xlarge,
.row.collapse.gridspacing-xlarge  {

	@media #{$small-only} {
		margin-bottom: 5rem;
	}
}
