.q-vehicle-tile {

	.q-vehicle-information-tile,
	.q-vehicle-information-tile-wyear-secondarynav,
	.q-vehicle-information-tile-wyear-helpme {
		.q-vehicle-info-text {
			.q-vehicle-info-text-year {
				.q-vehicle-info-carline-year {
					margin: 0;
				}
			}

			.q-headline,
			.q-vehicle-info-text-model {
				.q-vehicle-info-tile-type {
					margin: 0;
				}
			}

			.q-vehicle-info-text-price {
				.q-vehicle-info-as-shown-price {
					margin: 0;
				}
			}
		}
	}

	// Typography sizes in vehicle tiles max at their large sizes.  At XLarge, typography sizes
	// should take on their large/tablet sizes.  Must override the XLarge sizes from what is in solar.
	@media #{$xlarge-up} {
		.q-vehicle-information-tile,
		.q-vehicle-information-tile-wyear-secondarynav,
		.q-vehicle-information-tile-wyear-helpme {
			.q-vehicle-info-text {
				.q-vehicle-info-text-year {
					.q-vehicle-info-carline-year {
						font-size: $descriptive2-font-size-lm;
						line-height: $descriptive2-line-height-lm;
					}
				}

				.q-headline,
				.q-vehicle-info-text-model {
					.q-vehicle-info-tile-type {
						font-size: $descriptive1-font-size-lm;
						line-height: $descriptive1-line-height-lm;
					}
				}

				.q-vehicle-info-text-price {
					.q-from-label {
						font-size: $descriptive2-font-size-lm;
						line-height: $descriptive2-line-height-lm;
					}

					.q-vehicle-info-as-shown-price {
						font-size: $body2-font-size-lm;
						line-height: $body2-line-height-lm;
					}
				}
			}
		}
	}
}
