// Help Me Find Group
.q-hmf-group {
	margin: 1rem .5rem;

	.q-hmf-group-items {
		background-color: $white;
		display: none;
		left: 0;
		margin: 0 auto;
		max-height: 100%;
		max-width: 93.75rem;
		overflow-y: scroll;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 130;

		&.open {
			display: block;
			visibility: visible;
		}

		.q-modal-header {
			border-bottom: 1px solid $grey-mid;
			left: 0;
			margin: auto;
			padding: .75rem 1.25rem;
			position: relative;
			top: 0;
			width: 100%;

			.q-modal-title {
				@extend %#{$hmf-modal-header-label};
				text-align: left;
			}

			.close-reveal-modal {
				color: $grey-mid;
				cursor: pointer;
				font-size: 2.5rem;
				font-weight: $font-weight-bold;
				line-height: .6;
				position: absolute;
				right: 1.5625rem;
				top: .3125rem;

				.icon-close {
					border: 2px solid $grey-mid;
					border-radius: 2rem;
					font-size: .875rem;
					padding: .375rem;
				}
			}
		}

		.q-modal-content {
			padding: 1.25rem;
		}

		.q-modal-footer {
			border-top: 1px solid $grey-mid;
			display: flex;
			justify-content: flex-end;
			padding: 1.25rem;

			.q-hmf-button-hide-filters {
				flex: map-get($hmf-button-hide-filters-flex-map, small);
			}
		}

		&.q-edit-mode {
			display: block;
			position: relative;
		}
	}

	.q-hmf-modal-overlay {
		background-color: $hmf-group-modal-overlay-background-color;
		display: none;

		&.active {
			display: initial;
			height: 105vh;
			left: 0;
			position: fixed;
			right: 0;
			top: -5vh;
			width: 100vw;
			z-index: 120;
		}
	}

	@media #{$large-up} {
		.q-hmf-group-items {
			.q-modal-header {
				.q-modal-title {
					margin-bottom: 0;
					margin-top: .375rem;
				}

				.close-reveal-modal {
					line-height: 1.2;
				}
			}

			.q-modal-footer {
				.q-hmf-button-hide-filters {
					flex: map-get($hmf-button-hide-filters-flex-map, large);
				}
			}
		}
	}

	@media #{$xlarge-up} {
		border-bottom: $hmf-group-border-bottom;

		.q-hmf-group-items {
			background-color: initial;
			display: inline;
			margin: auto;
			max-height: initial;
			max-width: initial;
			overflow-y: auto;
			position: relative;
			width: initial;
			z-index: auto;

			.q-modal-header,
			.q-modal-footer {
				display: none;
			}

			.q-hmf-button-hide-filters {
				display: none;
			}

			&.q-edit-mode {
				.q-modal-footer,
				.q-hmf-button-hide-filters {
					display: block;
				}
			}
		}

		.q-hmf-button-show-filters {
			display: none;

			&.q-edit-mode {
				display: block;
			}
		}

		.q-hmf-modal-overlay {
			&.active {
				display: none;
			}
		}

	}
}
