.q-dealer-locator {
	margin-top: 1rem;
	min-height: 26.25rem;
	overflow: visible;
	padding: 0 .75rem .75rem;
	position: relative;

	.row .row {
		margin: 0 auto;
	}

	.q-search-column {
		flex: 0 0 100%;

		.q-dealer-info {
			.q-open-until.q-hours-inline.float-left {
				float: left;
			}

			.q-separator hr {
				border-color: transparent;
				margin-bottom: .5rem;
				margin-top: .5rem;
			}

			.q-dealer-links-container {
				width: 100%;

				.q-dealer-links .q-contact {
					.q-secondary-button {
						font-size: .875rem;
					}
				}
			}
		}
	}

	.q-no-padding {
		padding: 0;
	}

	.q-margin-bottom {
		margin-bottom: 1rem;
	}

	.q-dealer-header {
		font-size: 1.5rem;
		padding-bottom: .875rem;
		padding-top: 1.5rem;
	}

	.q-dealer-title {
		flex: 0 0 100%;
	}

	.q-flex-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.q-flex-auto {
			flex: 1 1 auto;
		}
	}

	.q-map-column {
		flex: 1 0 50%;
		margin-bottom: 1rem;
		order: 0;

		.dealer-map {
			height: 18.75rem;
			position: relative;
			width: 100%;
		}

		.q-checkbox-label {
			float: left;
			margin-bottom: 2px;
		}

		.q-print-button {
			@extend %q-body2;

			float: right;

			.q-link {
				text-decoration: underline;
			}
		}
	}

	.q-map-container {
		flex: 1 0 100%;
	}

	.q-separator {
		hr {
			margin-bottom: 1rem;
			margin-left: 0;
			margin-right: 0;
			margin-top: 1rem;
		}
	}

	a {
		&.phone,
		&.fax,
		&.directions {
			text-decoration: none;
		}
	}

	.q-button {
		margin: 1rem 0 0;
		text-align: center;
	}

	.dealer-locator-widget {
		.q-dealer-pin {
			display: $dl-show-active-dealer-pin-display;
			margin-top: .5rem;
		}

		.q-dealer-pin-label {
			display: none;
		}

		.q-dealer-title {
			flex: 1 0 100%;
			margin-bottom: $dl-widget-dealer-search-margin-bottom;
		}

		.q-advanced-search {
			display: none;
			margin-top: 0;
			padding-bottom: 1rem;

			.q-button {
				margin-top: 0;
				padding: .625rem 1.5rem .625rem 0;
			}
		}

		.q-map-column {
			order: 0;

			.q-map-container {
				.dealer-map {
					height: 18.75rem;
				}
			}
		}

		.q-phone {
			margin-bottom: 0;
		}

		.q-dealer-info {
			.q-active-dealer-pin {
				@include icon(location-pin-text-negative, before) {
					color: $black;
				}
				display: $dl-show-active-dealer-pin-display;
				float: left;
			}

			.q-dealer-links-container {
				width: 100%;
			}
		}
	}

	.q-shopping-links {
		.q-dealer-locator-link {
			flex: 0 0 100%;
		}
	}

	.q-open-hour-list {
		display: none;
	}

	.q-show-for-dealer-details {
		display: none;
	}

	.q-form {
		.q-checkbox-label {
			&::before {
				background-color: $dl-checkbox-background-color;
				content: '';
				height: $dl-checkbox-size;
				margin: $dl-checkbox-margin;
				position: absolute;
				width: $dl-checkbox-size;
			}

			.q-checkbox {
				background-color: $dl-checkbox-background-color;
				margin-left: .375rem;
				margin-top: .75rem;
				visibility: hidden;
			}
		}

		.q-checkbox-label {
			color: $dl-checkbox-font-label-color;
			font: $dl-checkbox-label-font-weight $dl-checkbox-label-font-size $dl-checkbox-label-font-family;
			line-height: 1.25rem;
			position: relative;

			.icon {
				color: $dl-check-icon-color;
				font-size: $dl-checkbox-check-size;
				left: .125rem;
				position: absolute;
				top: .125rem;
			}

		}
	}
}

.q-dealer-locator,
.q-dealer-details {
	.q-contact {
		.q-button {
			display: inline;
			padding-left: 0;

			&::before {
				content: '';
			}
		}

		.icon-arrow-right {
			font-size: 1rem;
			position: relative;
		}
	}
}

.dealer-locator-widget,
.q-dealer-details {
	.q-open-hour-list {
		display: block;
	}

	.q-shopping-links {
		flex-direction: row;
	}

	.q-dealer-results-list {
		.q-shopping-links {
			display: none;
		}
	}
}

.q-dealer-results-list {
	flex: 1 0 100%;

	.q-shopping-links {
		.q-dealer-locator-link {
			padding: 0;

			&:first-child {
				.q-button {
					margin-top: 0;
				}
			}
		}
	}
}

.q-dealer-details {
	.q-show-for-dealer-details {
		display: flex;
		width: 100%;
	}
}

@media #{$medium-up} {
	.q-dealer-locator {
		padding: 0 2rem 2rem;

		.q-dealer-header {
			font-size: 2rem;
			padding-top: 2.5rem;
		}

		.q-contact-url-label {
			display: none;
		}

		.q-contact-url {
			display: initial;
		}
	}
}

@media #{$medium-down} {
	.q-dealer-locator {
		overflow: initial;

		.small-hide {
			display: none;
		}

		.q-contact-url {
			display: initial;
		}

		.q-contact-url {
			display: none;
		}
	}

	.q-dealer-locator,
	.q-dealer-details {
		.q-contact {
			.q-label {
				display: none;
			}

			&.q-fax {
				display: none;
			}
		}
	}
}

@media #{$large-up} {
	.q-dealer-locator {
		.hide-large-up {
			display: none;
		}

		.q-open-hours {
			flex: 1;
		}

		.q-dealer-links {
			flex: 1 0 100%;
			font-size: $dl-contact-link-font-size;
		}
	}
}

@media #{$xlarge-up} {
	.q-dealer-locator {
		.q-flex-container {
			display: flex;
		}

		.q-search-column {
			flex: 0 0 $dl-search-column-width;
		}

		.q-separator {
			hr {
				margin: 2.5rem 0;
			}
		}

		.q-map-column {
			flex: 1 0;
			margin: 0 0 .5rem .5rem;
			order: 2;
			padding-left: .5rem;
			padding-top: 1.25rem;
			position: relative;

			.q-map-container {
				height: 100%;
				min-height: 37.5rem;
				padding-bottom: 2rem;

				.dealer-map {
					bottom: 0;
					height: auto;
					left: .5rem;
					// Needed because GMaps adds inline styles that override this
					position: absolute !important; // sass-lint:disable-line no-important
					right: .5rem;
					top: 4.125rem;
				}
			}
		}

		.q-shopping-links {
			.q-dealer-locator-link {
				flex: $dl-widget-shopping-links-flex;

				&:nth-child(odd) {
					padding-right: $dl-shopping-link-padding;
				}

				&:nth-child(even) {
					padding-left: $dl-shopping-link-padding;
				}
			}
		}

		.q-dealer-results-list {
			flex: $dl-details-cta-flex;

			.q-shopping-links {
				.q-dealer-locator-link {
					flex: 0 0 100%;
					padding: 0;
				}
			}
		}

		.dealer-locator-widget {
			.q-dealer-results-list {
				flex: $dl-widget-details-cta-flex;
			}

			.q-dealer-pin {
				flex: 0 0 1.875rem;
				font-size: 1.875rem;
				position: relative;

				.q-dealer-pin-label {
					display: block;
					font-size: .75rem;
					left: .6875rem;
					position: absolute;
					top: 0;
				}

				@include icon(location-pin-text-negative, before) {
					color: $black;
				}
			}

			.q-separator {
				hr {
					margin: 1rem 0;
				}
			}

			.q-dealer-title {
				flex: $dl-widget-dealer-title-flex;
				max-width: $dl-widget-dealer-title-max-width;
			}

			.q-search-term {
				display: none;
			}

			.q-dealer-search {
				flex: $dl-widget-dealer-search-flex;
			}

			.q-advanced-search {
				display: flex;
				flex: $dl-widget-advanced-search-label-flex;
				max-width: $dl-widget-advanced-search-max-width;
				order: 1;
				padding-bottom: 1rem;

				label {
					color: $dl-search-label-color;
					cursor: default;
					font-size: $dl-search-label-font-size;
					margin: $dl-widget-advanced-search-label-margin;
				}

				.q-button {
					margin: auto;
					padding: .625rem 1.438rem .625rem 0;
				}
			}

			.dealer-search {
				.search-bar {
					.location-button {
						height: $dl-clear-searchbox-height;
						width: $dl-location-button-width;
					}

					.search-field {
						width: calc(100% - 80px);

						&.q-no-geolocate {
							width: calc(100% - 40px);
						}

						input {
							height: $dl-clear-searchbox-height;
							padding-left: 1rem;
						}
					}

					.dealer-search-button {
						background-color: $dl-dealer-search-button-background-color;
						height: $dl-clear-searchbox-height;
						opacity: 1;
						width: $dl-location-button-width;
					}
				}
			}

			.q-map-column {
				order: 2;
				overflow: hidden;

				.q-map-container {
					display: block;
					min-height: 26.88rem;

					.dealer-map {
						bottom: auto;
						height: 25rem;
						left: auto;
						position: relative;
						right: auto;
						top: auto;
					}
				}
			}

			.q-dealer-info {
				.q-active-dealer-pin {
					display: none;
				}
			}

			.q-show-for-widget {
				display: block;
			}

			.q-hide-for-widget {
				display: none;
			}
		}
	}
}

@media #{$xxlarge-up} {
	.q-dealer-locator {
		.dealer-locator-widget {
			.q-dealer-pin {
				margin-top: .5rem;
			}
		}
	}
}
