// TABLE OF CONTENTS
//  a. Utilities
//  b. Grid
// 01. Animation
// 02. Borders
// 03. Button/Link
// 04. Content Overlay
// 05. Expander
// 06. Icons (Non-authorable)
// 07. Typography
// 08. Vehicle Info Tile
// 09. Tabbed Navigation

// a. Utilities
// - - - - - - - - - - - - - - - - - - - - - - - - -
%position-bottom {
	bottom: 0;
	top: auto;
}

%position-left {
	left: 0;
	right: auto;
}

%position-right {
	left: auto;
	right: 0;
}

%position-top {
	bottom: auto;
	top: 0;
}

/**
 * Shorthand mixin for absolute positioning
 *
 * @param {Length} $vertical [top] - Vertical position
 * @param {Length} $horizontal [null] - Horizontal position
 */
@mixin absolute-position($vertical: top, $horizontal: left) {
	@if $vertical == bottom {
		@extend %position-bottom;
	} @else {
		@extend %position-top;
	}

	@if $horizontal == right {
		@extend %position-right;
	} @else {
		@extend %position-left;
	}
}

@mixin centered($absolute: false) {
	@if $absolute {
		left: 50%;
		margin-left: -($xlarge-breakpoint / 2);
		width: $xlarge-breakpoint;
	} @else {
		margin: 0 auto;
		max-width: $xlarge-breakpoint;
	}
}

/**
 * Vertically align content properly
 */
@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}

// b. Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -
@mixin divide-columns($value) {
	padding-left: $column-gutter / $value;
	padding-right: $column-gutter / $value;
}

// Margins for Grids
@mixin calculate-margins($gridspacing-size) {
	.column,
	.columns {
		margin-bottom: $gridspacing-size;
		padding: 0 $gridcolumn-gutter;

		&.gridfullwidth {
			//full width: no padding; no margins;
			margin: 0;
			min-width: inherit;
			padding: 0;
		}
	}
}

@mixin grid-right-gutter {
	padding-right: 1.25rem;
	padding-right: 2.5vw;
}

@mixin grid-left-gutter {
	padding-left: 1.25rem;
	padding-left: 2.5vw;
}

@mixin grid-padding($gridpadding, $gutter) {
	padding: $gridpadding calc(#{$gridpadding} + #{$gutter});
}

// 01. Animation
// - - - - - - - - - - - - - - - - - - - - - - - - -

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes rotate-360 {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

@mixin hard-easeout($transition: background, $duration: $anim-slow) {
	transition: $transition;
	transition-duration: unquote($duration + 's');
	transition-timing-function: cubic-bezier(0, 1, .5, 1);
}

@mixin hard-easeinout($transition, $duration) {
	transition: $transition;
	transition-duration: unquote($duration + 's');
	transition-timing-function: cubic-bezier(.42, 0, .58, 1);
}

@mixin easein-middle {
	transition: width #{$anim-fast}s ease, left #{$anim-fast}s ease;
}

@mixin gmwp-transition($transition: background, $duration: $anim-slow, $timing-function: ease-out) {
	transition: $transition;
	transition-duration: unquote($duration + 's');
	transition-timing-function: $timing-function;
}

// 02. Borders
// - - - - - - - - - - - - - - - - - - - - - - - - -

//Applies a pseudo-element border
@mixin hover-border($hover-color: $dark-grey, $border-style: solid) {
	position: relative;

	&:hover {
		&::after {
			border-color: $hover-color;
		}
	}

	&::after {
		border-style: $border-style;
		content: '';
	}

	@content;
}

/*Primary Nav Link border, e.g bottom*/
@mixin nav-link-border ($transition: bottom, $duration: $anim-fast, $width: 5px, $color: $red, $bottom-position: 0, $left-position: 0, $right-position: 0) {
	@include hard-easeout($transition, $duration);
	border-bottom: $width solid $color;
	bottom: $bottom-position;
	content: '';
	height: 0;
	left: $left-position;
	position: absolute;
	right: $right-position;
	width: 100%;
}

// 03. Button/Link
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin button-style($bcolor, $bwidth, $color, $lheight, $pvertical, $phorizontal, $talign, $hcolor, $transition, $hbwidth, $simbottom, $simright, $list, $fill, $hfill) {
	@include button-style-partial($color, $talign);
	background-color: transparent;
	border-color: $bcolor;
	border-style: solid;
	@if $bwidth {
		border-width: $bwidth;
	} @else {
		border: 0;
	}
	display: block;
	font-family: $font-family-secondary;
	font-size: .75rem;
	font-weight: $font-weight-bold;
	line-height: $lheight;
	margin-bottom: 0;
	outline: none;

	@if $pvertical {
		padding: $pvertical 0;
	}
	width: 100%;

	@if $transition {
		@include hard-easeout($transition, $anim-fast);

		&:hover {
			background-color: $hcolor;
			@if $hbwidth and $hbwidth != 0 {
				box-shadow: inset 0 0 0 $hbwidth $bcolor;
			}
		}
	}

	@if $simbottom or $simright {
		.q-svg-icon {
			fill: $fill;

			&:hover {
				fill: $hfill;
			}

			@if $simbottom {
				margin-bottom: $simbottom;
			}

			@if $simright {
				margin-right: $simright;
			}

			@if $hfill {
				&:hover {
					fill: $hfill;
				}
			}
		}
	}

	//Button Lists check
	@if $list  {
		border-bottom: solid .0625rem $grey-mid;
		box-shadow: none;

		&.q-button-small {
			padding-bottom: 1.125rem;
			padding-top: 1.125rem;
		}

		&.q-button-large {
			padding-bottom: 1.75rem;
			padding-top: 1.75rem;
		}

		&.q-button-padding-none {
			padding-left: 0;
			padding-right: 0;
		}

		&.q-button-padding {
			border-left: 4px solid transparent;
			padding-left: 1.875rem;
			padding-right: 1.875rem;

			&:active,
			&:hover {
				@include hard-easeout(border-color, $anim-fast);
				border-left-color: $gold;
			}
		}

		&.q-button-padding-small {
			border-left: solid .25rem transparent;
			padding: 0 1.125rem;

			&:active,
			&:hover {
				@include hard-easeout(border-color, $anim-fast);
				border-left-color: $gold;
			}
		}

		&.q-button-padding-large {
			padding: 0 1.875rem;
		}
	}
}

@mixin button-style-partial($color, $talign) {
	color: $color;
	text-align: $talign;
}

@mixin button-list-item($bg-color, $color, $line-height, $text-align, $border-bottom) {
	background-color: $bg-color;
	border-bottom: $border-bottom;
	color: $color;
	line-height: $line-height;
	text-align: $text-align;
}

@mixin button-border($border-color, $background-color: null, $color: null) {
	border-color: $border-color;

	@if($background-color) {
		background-color: $background-color;
	}

	@if($color) {
		color: $color;
	}
}

@mixin button-hover($border-color, $fill: false, $fill-color: null, $hover-color: null, $box-shadow: null) {
	&:active,
	&:hover,
	&:focus {
		@if ($fill) {
			background-color: $border-color;
		} @else {
			box-shadow: inset 0 0 0 2px $border-color;
		}

		@if ($fill-color) {
			background-color: $fill-color;
			border-color: $fill-color;
			color: $hover-color;
		}

		@if($box-shadow) {
			box-shadow: $box-shadow;
		}
	}
}

//Button Mixin for various icon sizings small,med,etc...
@mixin svg-icon-size($hw, $media-size: null, $media-height-width: null) {
	height: $hw;
	width: $hw;

	@if ($media-size) {
		@media #{$media-size} {
			height: $media-height-width;
			width: $media-height-width;
		}
	}
}

//Button Mixin icon-hover
@mixin icon-hover($display-normal, $display-hover) {
	.hoverable-container {
		.icon-normal {
			display: $display-normal;
		}

		.icon-hover {
			display: $display-hover;
		}
	}
}

//Button Mixin for SVG Icon Fill handling
@mixin icon-fill($svg-icon-color, $svg-icon-hover-color, $icononly-button) {
	svg {
		height: 100%;
		width: 100%;
	}

	.q-svg-icon {
		fill: $svg-icon-color;
	}

	&:hover {
		.q-svg-icon {
			fill: $svg-icon-hover-color;
		}
	}

	@if $icononly-button {
		width: inherit;

		.q-svg-icon {
			float: left;
		}
	}
}

// 04. Content Overlay
// - - - - - - - - - - - - - - - - - - - - - - - - -
@mixin content-overlay-leftalignment($height, $width) {
	height: $height;
	width: $width;
}

@mixin content-overlay-rightalignment($height, $margin-left, $width) {
	height: $height;
	margin-left: $margin-left;
	width: $width;
}

@mixin content-overlay-set-leftalignment-width-for-breakpoint($breakpoint) {
	.q-fifty-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 50%);
	}

	.q-fifty-eight-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 58%);
	}

	.q-forty-two-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 42%);
	}

	.q-hundred-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 100%);
	}

	.q-seventy-five-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 75%);
	}

	.q-sixty-six-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 66.66%);
	}

	.q-thirty-three-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 33.33%);
	}

	.q-twenty-five-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 25%);
	}
}

@mixin content-overlay-set-rightalignment-width-for-breakpoint($breakpoint) {
	.q-fifty-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 50%, 50%);
	}

	.q-fifty-eight-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 42%, 58%);
	}

	.q-forty-two-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 58%, 42%);
	}

	.q-hundred-percent-width-#{$breakpoint} {
		@include content-overlay-leftalignment(100%, 100%)
	}

	.q-seventy-five-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 25%, 75%);
	}

	.q-sixty-six-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 33.33%, 66.66%);
	}

	.q-thirty-three-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 66.66%, 33.33%);
	}

	.q-twenty-five-percent-width-#{$breakpoint} {
		@include content-overlay-rightalignment(100%, 75%, 25%);
	}
}

// 05. Expander
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin expander-disable {
	> .q-expander-content,
	> div > .q-expander-content {
		display: inline;
	}

	> .q-expander-button,
	> div > .q-expander-button {
		background: none;
		border: 0;
		cursor: default;
		padding: 0 0 1.25rem;

		&::before {
			content: none !important; // sass-lint:disable-line no-important
		}

		&:hover {
			background: none;
			border: 0;
			cursor: default;
		}

		.q-headline-text {
			color: $expander-disable-font-color;
			margin-left: 0;
		}
	}

	&.open {
		> .q-expander-button,
		> div > .q-expander-button {
			background: none;
			border: 0;
		}
	}
}

@mixin expander-icon($size) {
	.q-expander-button {
		&::before {
			@include svg-icon-size(map-get($expander-icon-size-map, $size));
		}
	}
}

// 06. Icons
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin insert-icon($background-image, $height: null, $width: null, $use-icon-mq: null, $background-hover-image: null) {
	background: get-element($background-image) no-repeat;
	background-size: contain;
	box-sizing: border-box;
	height: $height;
	padding-left: $width;
	width: $width;

	@if $background-hover-image {
		&:hover {
			background: get-element($background-hover-image) no-repeat;
		}
	}
	@if $use-icon-mq {
		@media #{$small-up} {
			height: $svg-icon-small;
			padding-left: $svg-icon-small;
			width: $svg-icon-small;
		}
		@media #{$medium-up} {
			height: $svg-icon-medium;
			padding-left: $svg-icon-medium;
			width: $svg-icon-medium;
		}
		@media #{$large-up} {
			height: $svg-icon-large;
			padding-left: $svg-icon-large;
			width: $svg-icon-large;
		}
	}

	@if $background-hover-image {
		&:hover {
			background: get-element($background-hover-image) no-repeat;
		}
	}
}

/* Draw an arrow pointing straight up, down, left and right */
@mixin icon-chevron($orientation, $height, $width, $color, $border-width: 2px) {
	border-bottom: $border-width solid $color;
	border-right: $border-width solid $color;
	content: '';
	height: $height;
	width: $width;

	@if $orientation == 'down' {
		transform: rotate(45deg);
	}

	@if $orientation == 'up' {
		transform: rotate(-135deg);
	}

	@if $orientation == 'right' {
		transform: rotate(-45deg);
	}

	@if $orientation == 'left' {
		transform: rotate(135deg);
	}
}

/* Draw a triangle pointing straight up, down, left and right */
@mixin icon-triangle($position, $height, $width, $color) {
	border: 0;
	content: '';
	position: absolute;

	@if $position == 'up' {
		border-bottom: $height solid $color;
		border-left: $width solid transparent;
		border-right: $width solid transparent;
	}

	@if $position == 'right' {
		border-bottom: $height solid transparent;
		border-left: $width solid $color;
		border-top: $height solid transparent;
	}

	@if $position == 'down' {
		border-left: $width solid transparent;
		border-right: $width solid transparent;
		border-top: $height solid $color;
	}

	@if $position == 'left' {
		border-bottom: $height solid transparent;
		border-right: $width solid $color;
		border-top: $height solid transparent;
	}
}

// 07. Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -

// Typography Baseline
// This function requires that the font-size and line-height be provided in `px` value.
// Parameters:
//   $font-size: the size of the font
//   $line-height: the line height specified
//   $ratio: cap-height of that font (needs to be calculated - usually)
@mixin baseline($font-size, $line-height, $ratio) {
	// Variable Definitions
	// --------------------
	// the value to use as your baseline reference
	$grid-size : $baseline-grid-size;
	// initializing the top padding value to 0
	$top-padding-value: 0;
	// initialize the bottom margin value to 0
	$margin-bottom-value: 0;
	// the font size is not the actual pixel value provided usually, calculate that here by finding the cap-height of the
	// font size to multiple that value by the font size to get the true height of the font.
	$adjusted-font-size: $font-size * $ratio;

	// Top Padding Logic

	// Find the amount that the text is currently padded down
	$padding-provided-by-lineheight: ($line-height - $adjusted-font-size) / 2;
	// Add the value above to the adjusted font size to get the height of the text including top line-height
	$total-height-of-text-before-padding: $padding-provided-by-lineheight + $adjusted-font-size;
	// The sum above needs to be modulus with teh grid size to find out how much it needs to push it down additionally to
	// make a clean baseline alignment.
	$top-padding-modulus: $total-height-of-text-before-padding % $grid-size;

	// The modulus is the amount over the grid size but less than the actual grid size.  Subtract the grid size from the
	// modulus to find out how much you have to push it down.
	$top-padding-value: floor($grid-size - $top-padding-modulus);


	// Bottom Margin Logic
	$margin-bottom-value: $grid-size - (($top-padding-value + $line-height) % $grid-size);

	// Apply the actual styling in REMs
	margin-bottom: $margin-bottom-value;
	padding-top: $top-padding-value;
}

@mixin body-invert($color, $link-color, $link-hover-color, $link-visited-color, $link-font-weight, $link-transition-speed) {
	@include links($link-color, $link-hover-color, $link-visited-color, $link-font-weight, $link-transition-speed, $brand-link-decoration);
	color: $color;
}

@mixin q-headline-values($color, $fontstyle, $fontweight, $heavy-fontweight, $heavy-fontstyle, $letterspacing, $mobile-font-size, $mobile-line-height, $tablet-font-size, $tablet-line-height, $desktop-font-size, $desktop-line-height, $headline-font-family, $use-heavy-underline, $use-baseline, $baseline-ratio : .6) {
	@include font-queries($mobile-font-size, $mobile-line-height, $tablet-font-size, $tablet-line-height, $desktop-font-size, $desktop-line-height, $baseline-ratio, $use-baseline);
	@include links($color, $brand-link-color-hover, $brand-link-color-visited, inherit, $brand-link-transition-speed, $brand-headline-text-decoration);

	color: $color;
	font-family: $headline-font-family;
	font-style: $fontstyle;
	font-weight: $fontweight;
	letter-spacing: $letterspacing;
	margin: 0;
	padding: 0;

	&.q-invert {
		@include links($brand-invert-link-color, $brand-invert-link-color-hover, $brand-invert-link-color-visited, inherit, $brand-link-transition-speed, $brand-headline-text-decoration);
		color: $white;
	}

	&.q-heavy {
		font-style: $heavy-fontstyle;
		font-weight: $heavy-fontweight;
	}

	@if $use-heavy-underline {
		&.q-heavy {
			padding-bottom: $brand-heavy-padding-bottom;
			text-align: center;

			&::after {
				background-color: $brand-primary-color;
				content: ' ';
				display: block;
				height: 3px;
				left: 50%;
				max-width: 10rem;
				position: relative;
				top: 1rem;
				transform: translateX(-50%);
				width: 30%;
			}
		}
	}
}

@mixin font-queries($mobile-font-size, $mobile-line-height, $tablet-font-size, $tablet-line-height, $desktop-font-size, $desktop-line-height, $baseline-ratio, $use-baseline) {
	font-size: $mobile-font-size;
	line-height: $mobile-line-height;
	@if $use-baseline {
		@include baseline($mobile-font-size, $mobile-line-height, $baseline-ratio);
	}

	@media #{$tablet-typography-breakpoint} {
		font-size: $tablet-font-size;
		line-height: $tablet-line-height;
		@if $use-baseline {
			@include baseline($tablet-font-size, $tablet-line-height, $baseline-ratio);
		}
	}

	@media #{$desktop-typography-breakpoint} {
		font-size: $desktop-font-size;
		line-height: $desktop-line-height;
		@if $use-baseline {
			@include baseline($desktop-font-size, $desktop-line-height, $baseline-ratio);
		}
	}
}

@mixin links($color, $hover-color, $visited-color, $link-font-weight, $link-transition-speed, $link-decoration) {
	a {
		color: $color;
		font-weight: $link-font-weight;
		text-decoration: $link-decoration;

		&:hover,
		&:active {
			@include hard-easeout($color, $link-transition-speed);
			color: $hover-color;
		}

		@if $visited-color {
			&:visited {
				color: $visited-color;
			}
		}
	}
}

@mixin font-settings($color: null, $family: $font-family-primary, $size: $base-font-size, $weight: $font-weight-normal) {
	color: $color;
	font-family: $family;
	font-size: $size;
	font-weight: $weight;
}

// 08. Vehicle Info
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin vehicle-info-price-style {
	padding-bottom: 0;
	text-align: left;
	width: 100%;

	.q-from-label {
		color: $grey;

		p {
			color: inherit;
			display: inline;
			font-size: inherit;
		}
	}
}

@mixin vehicle-info-tile-active-style($height: 0, $left: 0%, $width: 100%, $border-bottom: null, $border-left: null, $right: null, $transition: null) {
	@if ($border-bottom) {
		border-bottom: $border-bottom;
		transition: $transition;
	}

	@if ($border-left) {
		@include hard-easeout(opacity, $anim-fast);
		border-left: $border-left;
	}

	bottom: 0;
	content: '';
	height: $height;
	left: $left;
	position: absolute;
	right: $right;
	width: $width;
}

// 09. Tabbed Navigation
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin tab-icon($size) {
	.tabs,
	.q-expander-button {
		.icon-normal,
		.icon-hover {
			@include svg-icon-size(map-get($tab-icon-size-map, $size));
		}
	}
}

@mixin tabs-remove-transition {
	.q-expander {
		.q-expander-content {
			@include hard-easeout(height, 0);
		}
	}
}

/* $medium-up etc variables are not available in _variables.scss
   hence for now, we are declaring a map as a means to acess
   the media variables dynamically.
*/
$media-query-names: (
	'small-only': $small-only,
	'small-up': $small-up,
	'medium-up': $medium-up,
	'medium-down': $medium-down,
	'large-up': $large-up,
	'large-down': $large-down,
	'xlarge-up': $xlarge-up,
	'xxlarge-up': $xxlarge-up,
);
