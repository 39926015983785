$chatnow-background-color: $grey-very-dark !default;
$chatnow-border-radius: 0 !default;
$chatnow-label-color: $white !default;
$chatnow-image-height: 1.25rem !default;
$chatnow-image-width: 1.25rem !default;
$chatnow-opacity: .6 !default;
$chatnow-hover-opacity: .7 !default;
$chatnow-padding: .625rem !default;
$chatnow-font-size: (
	medium: .75rem,
	large: .75rem ) !default;
$chatnow-line-height: (
	medium: 1.25rem,
	large: 1.25rem ) !default;
$chatnow-font-family: $font-family-secondary !default;
$chatnow-font-weight: $font-weight-bold !default;
$chatnow-letter-spacing: normal !default;
$chatnow-icon-bottom-margin: .25rem !default;

.q-chatnow {
	display: none;
}

@media #{$large-up} {
	.q-chatnow {
		@include button-style-partial($white, center);
		@include hard-easeout(opacity, $anim-fast);

		backface-visibility: hidden;
		background: $chatnow-background-color;
		border-radius: $chatnow-border-radius;
		bottom: 3rem;
		color: $chatnow-label-color;
		display: block;
		font-size: map-get($chatnow-font-size, medium);
		opacity: $chatnow-opacity;
		padding: $chatnow-padding;
		position: fixed;
		right: 0;
		z-index: 115;

		&:hover {
			opacity: $chatnow-hover-opacity;
		}

		.q-chat-label {
			@include q-headline-values(
				$brand-headline-color,
				null,
				$chatnow-font-weight,
				$chatnow-font-weight,
				null,
				$chatnow-letter-spacing,
				null,
				null,
				map-get($chatnow-font-size, medium),
				map-get($chatnow-line-height, medium),
				map-get($chatnow-font-size, large),
				map-get($chatnow-line-height, large),
				$chatnow-font-family,
				$brand-use-underline,
				false,
				null
			);

			color: $chatnow-label-color;
			display: block;
			text-align: center;
			width: 100%;
		}

		.q-chat-icon {
			display: inline-block;
			margin-bottom: $chatnow-icon-bottom-margin;

			img {
				height: $chatnow-image-height;
				width: $chatnow-image-width;
			}
		}
	}

	.q-chat-disabled {
		.q-chatnow {
			z-index: 105;
		}
	}
}
